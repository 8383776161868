import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { postData } from "../Networking/Api";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = parseInt(queryParams.get("userId"));
    setUserId(id); // Set userId from URL parameter
  }, [location.search]);

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!password) {
      formIsValid = false;
      errors.password = "Password is required.";
    }

    if (!confirmPassword) {
      formIsValid = false;
      errors.confirmPassword = "Confirm password is required.";
    }

    if (password !== confirmPassword) {
      formIsValid = false;
      errors.confirmPassword = "Passwords do not match.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const payload = {
          id: userId,
          password,
          confirmPassword,
        };
        console.log("Payload:", payload); 

        const response = await postData("auth/changePassword", payload);

        if (response.code === 1) {
          Swal.fire({
            title: "Success",
            text: "Password changed successfully",
            icon: "success",
            timer: 2000,
            showConfirmButton: true,
          });
          navigate("/LogIn");
        } else {
          if (response.status === 400) {
            Swal.fire({
              title: "Error",
              text: response.message || "Password change failed. Please try again.",
              icon: "error",
            });
          }
        }
      } catch (error) {
        Swal.fire("Error", "Password change failed. Please try again.", "error");
      }
    }
  };

  return (
    <section className="w-full">
      <div
        className="w-full h-dvh bg-red-300 bg-no-repeat bg-left bg-cover bg-scroll md:flex md:items-center md:gap-4 px-4 py-4"
        style={{ backgroundImage: "url(./login-bg.jpg)" }}
      >
        <div className="w-2/5 xss:w-full"></div>
        <div className="w-3/5 xss:w-full min-h-80 bg-gray-100 rounded-lg shadow-lg px-4 py-6 xss:mt-20">
          <div className="flex flex-direction-row justify-center gap-3">
            <Link to="/">
              <img src={"./samara-logo.png"} width="300px" className="max-w-full" />
            </Link>
          </div>
          <div className="w-full mt-4">
            <h1 className="font-fontPoppins text-lg text-left">
              Change Password
            </h1>
            <label className="w-full font-fontPoppins text-sm font-medium mb-1">
              Password
            </label>
            <input
              className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
              type="password" // Change input type to password
              name="password"
              value={password}
              placeholder="Enter your password"
              onChange={handlePasswordChange}
            />
            {errors.password && (
              <p className="text-red-500 text-md">{errors.password}</p>
            )}
          </div>
          <div className="w-full mt-4">
            <label className="w-full font-fontPoppins text-sm font-medium mb-1">
              Confirm Password
            </label>
            <input
              className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
              type="password" // Change input type to password
              name="confirmPassword"
              value={confirmPassword}
              placeholder="Enter confirm password"
              onChange={handleConfirmPasswordChange}
            />
            {errors.confirmPassword && (
              <p className="text-red-500 text-md">{errors.confirmPassword}</p>
            )}
          </div>
          <div className="w-full text-center mt-4">
            <button
              type="button"
              onClick={handleSubmit}
              className="w-24 py-2 bg-blueColor rounded-full font-fontPoppins text-xs font-medium text-white hover:bg-opacity-80"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChangePassword;
