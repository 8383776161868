import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { loginPatient, selectPatient } from "../../redux/actions/userSlice";
import { postData, apiUrl, imageBaseUrl } from "../../Networking/Api";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import Swal from "sweetalert2";
import Header from "../Header";
import Footer from "../Footer";
//import ThirdPartyHeader from "./ThirdPartyHeader";
//import ThirdPartyFooter from "./ThirdPartyFooter";
import Loader from "../Loader";
import ClinicBookingHeader from "./ClinicBookingHeader";
import ClinicBookingFooter from "./ClinicBookingFooter";
import Loader2 from "../Loader2";
import ClinicBookingHeader2 from "./ClinicBookingHeader2";

function ClinicRescheduleAppointment() {
  const [logoImage, setLogoImage] = useState("");
  const [Phone, setPhone] = useState("");
  const [Street, setStreet] = useState("");
  const [address, setAddress] = useState("");
  const [clinicname, setClinicName] = useState("");
  const { viewId } = useParams();
  useEffect(() => {
    console.log("Initial Render, View ID:", viewId);
    if (viewId) {
      getRescheduleAppointmentData(viewId);
    }
  }, []);
  // useEffect(() => {
  const getRescheduleAppointmentData = async (viewId) => {
    try {
      const payload = {
        modelName: "patient_appointments",
        whereCondition: {
          id: viewId,
        },
        relations: [
          {
            module: "practitioners",
            subModule: "clinic_locations",
          },
          // {
          //   module: "appointment_type",
          // },
          {
            module: "practitioner_calendars",
          },
          {
            module: "clinics",
          },
        ],
      };
      const response = await postData("masters/getMasterList", payload);

      const logoUrl = response.data[0].clinics.logo_image;
      setClinicName(response.data[0].clinics.name);
      setLogoImage(logoUrl);
      getPractitonerLocationData(
        response.data[0].practitioners.practitioner_location_id
      );
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  getRescheduleAppointmentData();
  // }, []);
  const getPractitonerLocationData = async (practionerLocationId) => {
    try {
      const payload = {
        modelName: "clinic_locations",
        whereCondition: {
          id: practionerLocationId,
        },
      };
      const response = await postData("masters/getMasterList", payload);
      const clinicData = response.data[0];
      setStreet(clinicData.street);
      setPhone(clinicData.phone);
      const { street, city, state, country, zipcode } = clinicData;
      const fullAddress = `${city}, ${state}, ${country}, ${zipcode}`;
      setAddress(fullAddress);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  return (
    <>
      <ClinicBookingHeader2
        logoImage={logoImage}
        Phone={Phone}
        Street={Street}
        address={address}
        clinicname={clinicname}
      />
      <AppointmentSection />

      <ClinicBookingFooter />
    </>
  );
}

function AppointmentSection() {
  const imgW = "40px";
  const googleIconW = "24px";
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const location = useLocation();
  const { state } = location || {};
  const { symptom } = state || {};
  const [doctors, setDoctors] = useState([]);
  const [user, setUser] = useState();
  const patientData = useSelector(selectPatient);
  const { type, id, appointmentType, insuranceType, patientType, viewId } =
    useParams();
  const today = new Date();

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [details, setDetails] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [appoinmentName, setAppoinmentName] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [patientTypeId, setPatientTypeId] = useState("");
  const [clinicinsuranceId, setClinicInsuranceId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [carrierName, setCarrierName] = useState("");
  const [insurancePlanName, setInsurancePlanName] = useState("");
  const [patientAppointmentId, setPatientAppointmentId] = useState("");
  const [rescheduleId, setRescheduleId] = useState("");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState("");
  const [apiResponseCode, setApiResponseCode] = useState(false);

  const [iplocation, setIpLocation] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [locationName, setLocationName] = useState("");

  useEffect(() => {
    const getRescheduleAppointmentData = async () => {
      try {
        const payload = {
          modelName: "patient_appointments",
          whereCondition: {
            id: viewId,
          },
          relations: [
            {
              module: "practitioners",
              subModule: "clinic_locations",
            },
            // {
            //   module: "appointment_type",
            // },
            {
              module: "practitioner_calendars",
            },
            {
              module: "clinics",
            },
          ],
        };
        const response = await postData("masters/getMasterList", payload);

        const logoUrl = response.data[0].clinics.logo_image;
        setPatientAppointmentId(response.data[0].id);
        setLogoImage(logoUrl);
        setPatientTypeId(response.data[0].patient_type_id);
        setClinicInsuranceId(response.data[0].clinic_insurance_id);
        setServiceId(response.data[0].clinic_service_id);
        setInsurance(response.data[0].insurance);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getRescheduleAppointmentData();
  }, []);

  useEffect(() => {
    const getLocationFromIP = async () => {
      try {
        // setLoading(true);
        var currentTimeZone = localStorage.getItem("currentTimeZone");
        // alert(currentTimeZone)
        if (
          currentTimeZone == "null" ||
          currentTimeZone == null ||
          currentTimeZone == ""
        ) {
          // alert("if")
          try {
            // setLoading(true);
            const response = await fetch("https://ipapi.co/json/");
            if (!response.ok) {
              // setLoading(false);
              throw new Error("Network response was not ok");
            }
            const IpData = await response.json();

            setIpLocation({
              lat: IpData.latitude,
              lon: IpData.longitude,
            });

            const locationName = `${IpData.city}, ${IpData.region_code}`;
            setLocationName(locationName);

            const timeZone = `${IpData.utc_offset}, ${IpData.timezone}`;
            setTimeZone(timeZone);
            // alert(timeZone)
            localStorage.setItem("currentTimeZone", timeZone);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          // alert("else")
          setTimeZone(JSON.parse(currentTimeZone));
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching IP location:", error);
        // setLoading(false);
      }
    };
    getLocationFromIP();
  }, []);

  useEffect(() => {
    if (serviceId) {
      getClinicServiceData(serviceId);
    }
  }, [serviceId]);

  useEffect(() => {
    if (clinicinsuranceId) {
      getClinicInsuranceData(clinicinsuranceId);
    }
  }, [clinicinsuranceId]);

  useEffect(() => {
    if (patientAppointmentId) {
      getProviderTimeSlot(patientAppointmentId);
    }
  }, [patientAppointmentId]);

  const getClinicServiceData = async (serviceId) => {
    try {
      const payload = {
        modelName: "clinic_services",
        whereCondition: {
          id: serviceId,
        },
      };
      const response = await postData("masters/getMasterList", payload);

      setAppoinmentName(response.data[0]);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  const getClinicInsuranceData = async (clinicinsuranceId) => {
    try {
      const payload = {
        modelName: "clinic_insurances",
        whereCondition: {
          id: clinicinsuranceId,
        },
        relations: [
          {
            module: "insurance_masters",
          },
          {
            module: "health_insurance_carrier_master",
          },
        ],
      };
      const response = await postData("masters/getMasterList", payload);

      setCarrierName(
        response.data[0].health_insurance_carrier_master
          .health_insurance_carrier_name
      );
      setInsurancePlanName(response.data[0].insurance_masters.insurance_name);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userInfo")) || [];
    setDetails(userDetails);
    const savedAppointment = localStorage.getItem("selectedAppointment");
    const savedSlot = localStorage.getItem("selectedSlot");
    const savedDate = localStorage.getItem("selectedDate");
    const savedDoctor = localStorage.getItem("selectedDoctor");

    if (savedAppointment && savedSlot) {
      setSelectedAppointment(JSON.parse(savedAppointment));
      setSelectedSlot(JSON.parse(savedSlot));
    }
    if (savedDate) {
      setSelectedDate(new Date(savedDate));
    }
    if (savedDoctor) {
      setSelectedDoctor(JSON.parse(savedDoctor));
    }
  }, []);

  useEffect(() => {
    setUser(patientData);
  }, [patientData]);

  // useEffect(() => {
  //   getProviderTimeSlot(patientAppointmentId);
  // }, [selectedDate]);

  useEffect(() => {
    const availableDoctorsList = doctors.filter(
      (doctor) => doctor.practitionerCalendarsData.length > 0
    );
    setAvailableDoctors(availableDoctorsList);
  }, [selectedDate, doctors]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  function formatCustomDate(date) {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const currentTimeZone = localStorage.getItem("currentTimeZone");

    if (formatDate(new Date()) === formatDate(selectedDate)) {
      return `${dayOfWeek} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} ${currentTimeZone}`;
    } else {
      return `${dayOfWeek} ${month} ${day} ${year} 00:00:00 ${currentTimeZone}`;
    }
  }
  const getProviderTimeSlot = async (patientAppointmentId) => {
    try {
      setLoading(true);
      const currentDate = new Date();
      let selectedDate;
      const savedDate = localStorage.getItem("selectedDate");
      if (savedDate) {
        selectedDate = new Date(savedDate);
      } else {
        selectedDate = currentDate;
      }

      const currentTimeZone = localStorage.getItem("currentTimeZone");
      // var patientTimeZone = currentTimeZone.split(",")[1];
      // var timeZone = patientTimeZone.trim();

      const payload = {
        patient_appointment_id: patientAppointmentId,
        date: formatDate(selectedDate),
        date_Time: formatCustomDate(currentDate),
        currentTimeZone: currentTimeZone,
      };

      const apiResponse = await postData(
        "patients/getPractitionorSlotForReschedule",
        payload
      );

      if (apiResponse.code === 1) {
        setApiResponseCode(true);
        setDoctors(apiResponse.data);
        setLoading(false);
        return apiResponse.data;
      } else {
        setLoading(false);
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      setLoading(false);

      return null;
    }
  };

  const handleDateChange = (date) => {
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("selectedDoctor");
    setSelectedSlot(null);
    setSelectedDoctor(null);
    setSelectedDate(date);
    localStorage.setItem("selectedDate", date.toISOString());
    getProviderTimeSlot(patientAppointmentId);
  };

  const handleSlotClick = (doctorId, practitionerCalendarsData, slot) => {
    const selectedSlotData = practitionerCalendarsData.find(
      (data) => data.available_date_time_new === slot.available_date_time_new
    );

    if (selectedSlotData) {
      selectedSlotData.duration = appoinmentName.duration;

      setSelectedAppointment(selectedSlotData);

      setSelectedSlot(slot);
      setSelectedDoctor(doctorId);
      setVerified(true);

      localStorage.setItem(
        "selectedAppointment",
        JSON.stringify(selectedSlotData)
      );
      localStorage.setItem("selectedSlot", JSON.stringify(slot));
      localStorage.setItem("selectedDoctor", JSON.stringify(doctorId));
    }
  };

  const handleClickReschedule = async () => {
    if (!selectedSlot) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select A Practitioner And Appointment Slot!",
      });
      return;
    }
    try {
      selectedAppointment.duration = appoinmentName.duration;

      const payload = {
        booked_slot_id: viewId,
        inputData: {
          is_patient_family: 0,
          patient_family_id: 0,
          practitioner_id: selectedAppointment.practitioner_id,
          price: 0,
          appointment_date_time: selectedAppointment.available_date_time,
          payment_details: "NA",
          status: "Booked",
          practitioner_calendars_id: selectedAppointment.id,
        },
        practitionerCalendarsData: selectedAppointment,
      };

      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "Do You Want To Reschedule This Appointment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reschedule It!",
      });

      if (confirmationResult.isConfirmed) {
        setVerified(false);
        setLoading(true);
        const response = await postData("common/reschedule", payload);

        setVerified(true);

        if (response.code === 1) {
          setLoading(false);
          setRescheduleId(response.data.id);

          const viewId = response.data.id;

          Swal.fire({
            title: "Success!",
            text: "Your Appointment Has Been Successfully Rescheduled.",
            icon: "success",
          }).then(() => {
            navigate(`/clinicbookedappointmentDetails/${viewId}`);
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      Swal.fire({
        title: "Error",
        text: "There was an error rescheduling your appointment. Please try again later.",
        icon: "error",
      });
    }
  };

  const getTimeZone = (offset) => {
    const timezoneMap = {
      "-12": "Etc/GMT+12",
      "-11": "Pacific/Midway",
      "-10": "Pacific/Honolulu",
      "-9": "America/Anchorage",
      "-8": "America/Los_Angeles",
      "-7": "America/Denver",
      "-6": "America/Chicago",
      "-5": "America/New_York",
      "-4": "America/Santiago",
      "-3": "America/Argentina/Buenos_Aires",
      "-2": "Etc/GMT+2",
      "-1": "Etc/GMT+1",
      0: "Etc/GMT",
      1: "Europe/London",
      2: "Europe/Berlin",
      3: "Europe/Moscow",
      4: "Asia/Dubai",
      5: "Asia/Karachi",
      6: "Asia/Dhaka",
      7: "Asia/Bangkok",
      8: "Asia/Shanghai",
      9: "Asia/Tokyo",
      10: "Australia/Sydney",
      11: "Pacific/Noumea",
      12: "Pacific/Fiji",
    };

    const timezone = timezoneMap[offset.toString()];
    return timezone;
  };

  const containerRefs = useRef({});
  useEffect(() => {
    // Initialize refs for each doctor
    doctors.forEach((doctor) => {
      if (!containerRefs.current[doctor.id]) {
        containerRefs.current[doctor.id] = React.createRef();
      }
    });
  }, [doctors]);
  const scrollLeft = (doctorId) => {
    if (containerRefs.current[doctorId]) {
      containerRefs.current[doctorId].current.scrollBy({
        left: -200, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (doctorId) => {
    if (containerRefs.current[doctorId]) {
      containerRefs.current[doctorId].current.scrollBy({
        left: 200, // Adjust the scroll distance as needed
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      {loading ? (
        <Loader2 />
      ) : (
        <section className="w-full mt-4">
          <div className="container mx-auto p-4">
            <h2 className="text-3xl text-center font-bold mb-4">
              Reschedule Appointment
            </h2>
          </div>
          {/* <div className="w-full bg-gray-100 rounded-lg py-4 mt-2"> */}
          <div className="bg-gray-100 rounded-lg m-6 mt-3 py-2">
            <div className="w-full px-4 flex items-center flex-wrap gap-2">
              <div className="p-2 bg-white rounded-lg shadow-sm">
                <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                  Patient Type
                </div>
                <div className="font-fontPoppins text-xs font-medium">
                  {patientTypeId === 2 ? "Existing Patient" : "New Patient"}
                </div>
              </div>
              <div className="p-2 bg-white rounded-lg shadow-sm">
                <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                  Appointment Type
                </div>
                <div className="font-fontPoppins text-xs font-medium">
                  {appoinmentName.services}
                </div>
              </div>
              {clinicinsuranceId === null ? (
                <div className="p-2 bg-white rounded-lg shadow-sm">
                  <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                    Insurance Type
                  </div>
                  <div className="font-fontPoppins text-xs font-medium">
                    {insurance}
                  </div>
                </div>
              ) : (
                <>
                  <div className="p-2 bg-white rounded-lg shadow-sm">
                    <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                      Insurance Carrier
                    </div>
                    <div className="font-fontPoppins text-xs font-medium">
                      {carrierName}
                    </div>
                  </div>
                  <div className="p-2 bg-white rounded-lg shadow-sm">
                    <div className="font-fontPoppins text-f10 font-normal text-gray-400">
                      Insurance Plan
                    </div>
                    <div className="font-fontPoppins text-xs font-medium">
                      {insurancePlanName}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="container">
              <div className="w-full rounded-lg">
                {/* <div className="w-full flex justify-center items-center gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200"> */}
                <div className="w-full flex justify-center items-center gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200">
                  <div className="w-3/12 font-fontPoppins text-sm font-semibold ">
                    Select Date
                  </div>
                  <div className="w-3/4 flex items-center">
                    <i className="fa-solid fa-calendar-alt fa-sm mr-1 mb-1"></i>
                    <ReactDatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="EEEE, dd MMMM"
                      className="font-fontPoppins text-sm fontmedium cursor-pointer p-1 rounded-md"
                      minDate={today}
                      onClick={(e) => e.preventDefault()}
                      placeholderText="Please select a date"
                    />
                  </div>
                </div>
                {apiResponseCode == true && symptom !== null && (
                  <>
                    {availableDoctors.length > 0 ? (
                      availableDoctors.map((doctor, index) => (
                        <div
                          key={index}
                          // className="w-full sm:flex sm:items-center sm:gap-2 md:flex md:items-center md:gap-2 px-4 py-3 border border-t-0 border-l-0 border-r-0 border-gray-200"
                          className="w-full p-3 border border-t-0 border-l-0 border-r-0 border-gray-200 sm:flex sm:items-center sm:gap-2"
                        >
                          <div className="responsive-style w-3/12 xss:w-full flex items-center gap-2">
                            <img
                              src={`${imageBaseUrl}/${doctor.photo}`}
                              width={imgW}
                              alt="Doctor Pic"
                              className="rounded-full"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/placeholder.png";
                              }}
                              F
                            />
                            <div className="w-full">
                              <div className="font-fontPoppins text-f10 font-normal">
                                {doctor.taxonomy_description}
                              </div>
                              <div className="font-fontPoppins text-xs font-semibold">
                                <span>
                                  {doctor.f_name} {doctor.l_name}
                                </span>{" "}
                              </div>
                            </div>
                          </div>

                          {/* <div className="w-3/4 xss:w-full xss:mt-3 flex flex-row overflow-auto items-center justify-between gap-2">
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span className="cursor-pointer px-1">
                                  <i className="fa-solid fa-angle-left"></i>
                                </span>
                              )}
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 &&
                              doctor.practitionerCalendarsData.map((slot) => (
                                <div
                                  key={slot.id}
                                  className={`w-32 bg-white hover:bg-blueColor hover:text-white cursor-pointer px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center ${
                                    user ? "bg-white text-black" : ""
                                  }`}
                                  style={
                                    selectedSlot &&
                                    selectedSlot.available_date_time_new ===
                                      slot.available_date_time_new &&
                                    selectedDoctor === doctor.id
                                      ? {
                                          backgroundColor: "blue",
                                          color: "white",
                                          minWidth: "80px",
                                          marginBottom: "5px",
                                        }
                                      : {
                                          minWidth: "80px",
                                          marginBottom: "5px",
                                        }
                                  }
                                  onClick={() =>
                                    handleSlotClick(
                                      doctor.id,
                                      doctor.practitionerCalendarsData,
                                      slot
                                    )
                                  }
                                >
                                  {slot.available_date}
                                  <br />
                                  {slot.available_time}
                                </div>
                              ))}

                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData.length > 0 &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 1 && (
                                <div
                                  key={doctor.practitionerCalendarsData[0].id}
                                  className={`w-32 bg-grayColor hover:bg-blueColor hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center cursor-pointer}`}
                                  onClick={() => {
                                    handleDateChange(
                                      new Date(
                                        doctor.practitionerCalendarsData[0].available_date_time
                                      )
                                    );
                                  }}
                                >
                                  Next Availability
                                  <br />
                                  {
                                    doctor.practitionerCalendarsData[0]
                                      .available_date
                                  }
                                </div>
                              )}
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span className="cursor-pointer px-1">
                                  <i className="fa-solid fa-angle-right"></i>
                                </span>
                              )}
                          </div> */}
                          <div className="responsive-style md:w-3/4 w-3/4 xss:w-full xss:mt-3 flex flex-row items-center  gap-2">
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span
                                  className="cursor-pointer px-1"
                                  onClick={() => scrollLeft(doctor.id)}
                                >
                                  <i className="fa-solid fa-angle-left"></i>
                                </span>
                              )}
                            <div
                              ref={containerRefs.current[doctor.id]}
                              className="flex flex-row overflow-hidden items-center gap-2"
                            >
                              {doctor.practitionerCalendarsData &&
                                doctor.practitionerCalendarsData[0]
                                  .nextavailable === 0 &&
                                doctor.practitionerCalendarsData.map((slot) => (
                                  <div
                                    key={slot.id}
                                    className={`w-32 bg-white hover:bg-blueColor cursor-pointer hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center ${
                                      user ? "bg-white text-black" : ""
                                    }`}
                                    style={
                                      selectedSlot &&
                                      selectedSlot.available_date_time_new ===
                                        slot.available_date_time_new &&
                                      selectedDoctor === doctor.id
                                        ? {
                                            backgroundColor: "blue",
                                            color: "white",
                                            minWidth: "80px",
                                            marginBottom: "5px",
                                          }
                                        : {
                                            minWidth: "80px",
                                            marginBottom: "5px",
                                          }
                                    }
                                    onClick={() =>
                                      handleSlotClick(
                                        doctor.id,
                                        doctor.practitionerCalendarsData,
                                        slot
                                      )
                                    }
                                  >
                                    {slot.available_date}
                                    <br />
                                    {slot.available_time}
                                  </div>
                                ))}
                            </div>
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData.length > 0 &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 1 && (
                                <div
                                  key={doctor.practitionerCalendarsData[0].id}
                                  className={`w-32 bg-grayColor cursor-pointer hover:bg-blueColor hover:text-white px-2 py-1 shadow-sm rounded-lg font-fontPoppins text-f10 font-medium text-center cursor-pointer}`}
                                  onClick={() => {
                                    handleDateChange(
                                      new Date(
                                        doctor.practitionerCalendarsData[0].available_date_time
                                      )
                                    );
                                  }}
                                >
                                  Next Availability
                                  <br />
                                  {
                                    doctor.practitionerCalendarsData[0]
                                      .available_date
                                  }
                                </div>
                              )}
                            {doctor.practitionerCalendarsData &&
                              doctor.practitionerCalendarsData[0]
                                .nextavailable === 0 && (
                                <span
                                  className="cursor-pointer px-1"
                                  onClick={() => scrollRight(doctor.id)}
                                >
                                  <i className="fa-solid fa-angle-right"></i>
                                </span>
                              )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex justify-center items-center h-full p-10 pb-15">
                        <div className="bg-white shadow-lg rounded-lg p-6 flex items-center space-x-4">
                          <i className="fa fa-exclamation-circle text-red-500 text-2xl"></i>
                          <div>
                            <p className="font-poppins text-lg font-semibold text-gray-700">
                              No Doctors Available For The Selected Date.
                            </p>
                            {/* <p className="font-poppins text-sm text-gray-500 mt-2">
                              Please select another date or try searching for
                              doctors in a different location. If you need
                              immediate assistance, consider visiting a nearby
                              clinic or hospital.
                            </p> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="flex justify-center mt-3">
                <button
                  onClick={handleClickReschedule}
                  className={`w-36 h-10  font-fontPoppins bg-blueColor text-xs rounded-full text-white font-semibold hover:opacity-85 ${
                    !verified ? "cursor-not-allowed" : ""
                  }`}
                  disabled={!verified}
                >
                  Reschedule
                </button>
              </div>
              {/* <div className="w-full mt-6 lg:min-h-240">
                <div className="w-2/5 bg-gray-100 rounded-lg mx-auto p-4 md:flex md:items-center md:justify-evenly xss:w-full">
                  <div className="font-fontPoppins text-base   font-bold xss:text-center md:text-center xss:mb-1 md:mb-1">
                    Not Able To Find Your Appointment?
                  </div>
                  <div className="bg-white rounded-full shadow-sm py-3 px-4 xss:flex xss:items-center xss:justify-between xss:gap-2 sm:flex sm:items-center sm:justify-between sm:gap-3 md:flex md:items-center md:justify-center md:gap-3">
                    <span className="font-fontPoppins text-xs font-medium">
                      Search On
                    </span>{" "}
                    <img
                      onClick={() => navigate("/patientLanding")}
                      src="/samara-logo.png"
                      className="max-w-full h-auto md:w-20 lg:w-28"
                      alt="Sammara Well Logo"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ClinicRescheduleAppointment;
