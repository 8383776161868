import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { postData } from "../Networking/Api";
import Loader2 from "./Loader2";
import Header from "./Header";
import Footer from "./Footer";

function Password() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const userMasterId = localStorage.getItem("userMasterId");

  const validatePassword = () => {
    const errors = {};

    if (!oldPassword) {
      errors.oldPassword = "Old Password Is required.";
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!newPassword) {
      errors.newPassword = "New password is required.";
    }
    //else if (!passwordRegex.test(newPassword)) {
    //   errors.newPassword =
    //     "New password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    // }

    if (newPassword && oldPassword === newPassword) {
      errors.newPassword = "Old Password And New Password Can't Be The Same.";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password Is Required.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Passwords Do Not Match.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleOldPasswordChange = (e) => setOldPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword()) {
      return;
    }

    try {
      setLoading(true);
      const payload = {
        userMasterId: userMasterId,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };

      const response = await postData("auth/resetPassword", payload);
      setLoading(false);
      if (response.code === 1) {
        Swal.fire({
          title: "Success",
          text: "Password changed successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        }).then(() => {
          navigate("/dashboard");
        });
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          oldPassword: response.message || "Old password is incorrect.",
        }));
      }
    } catch (error) {
      Swal.fire("Error", "Password change failed. Please try again.", "error");
    }
  };

  return (
    <>
      <section className="w-full">
        <>{loading && <Loader2 />}</>
        <Header />
        {/* <div
          className="w-full  bg-red-300 bg-no-repeat bg-left bg-cover bg-scroll md:flex md:items-center md:gap-4 px-4 py-4"
          style={{ backgroundImage: "url(./login-bg.jpg)" }}
        > */}
        {/* <div className="w-2/5 xss:w-full"></div> */}
        <div className="margin w-full mt-20 bg-no-repeat md:flex md:items-center md:gap-4 px-4 py-4">
          <img
            src={"./changepassword_bg.jpg"}
            width="500px"
            // height
            className="min-h-80 small"
          />

          <div className=" md:w-3/5 w-full mt-20  min-h-80 bg-gray-100 rounded-lg shadow-lg px-4 py-5 mt-20 md:mt-0 ">
            <div className="flex flex-direction-row justify-center gap-3">
              <Link to="/">
                <img
                  src={"./samara-logo.png"}
                  width="300px"
                  className="max-w-full"
                />
              </Link>
            </div>
            <div className="w-full mt-4">
              <h1 className="font-fontPoppins text-lg text-left">
                Change Password
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="w-full mt-4">
                  <label className="w-full font-fontPoppins text-sm font-medium mb-1">
                    Old Password
                  </label>
                  <input
                    className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
                    type="password"
                    name="oldPassword"
                    value={oldPassword}
                    placeholder="Enter Your Old Password"
                    onChange={handleOldPasswordChange}
                  />
                  {errors.oldPassword && (
                    <p className="text-red-500 text-sm border-red-500">
                      {errors.oldPassword}
                    </p>
                  )}
                </div>

                <div className="w-full mt-4">
                  <label className="w-full font-fontPoppins text-sm font-medium mb-1">
                    New Password
                  </label>
                  <input
                    className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
                    type="password"
                    name="newPassword"
                    value={newPassword}
                    placeholder="Enter New Password"
                    onChange={handleNewPasswordChange}
                  />
                  {errors.newPassword && (
                    <p className="text-red-500 text-sm">{errors.newPassword}</p>
                  )}
                </div>

                <div className="w-full mt-4">
                  <label className="w-full font-fontPoppins text-sm font-medium mb-1">
                    Confirm Password
                  </label>
                  <input
                    className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Retype New Password"
                    onChange={handleConfirmPasswordChange}
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-500 text-sm">
                      {errors.confirmPassword}
                    </p>
                  )}
                </div>

                <div className="w-full text-center mt-4">
                  <button
                    type="submit"
                    className="w-40 py-2 bg-blueColor rounded-full font-fontPoppins text-sm font-medium text-white hover:bg-opacity-80"
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>

      <Footer />
    </>
  );
}

export default Password;
