// src/components/Calendar.js
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tippy from "tippy.js";
// import '@fullcalendar/common/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
import "tippy.js/dist/tippy.css";
import ReactSelect from "react-select";
import Header from "./Header";
import { postData } from "../Networking/Api";
import Swal from "sweetalert2";
import Footer from "./Footer";
import EventPopup from "./NewModal";
import { addDays } from "@fullcalendar/core/internal";
import Loader2 from "./Loader2";
import moment from "moment";
import { endOfMonth, endOfWeek, isSameMonth, startOfMonth } from "date-fns";
const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};
const NewCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [providers, setProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [athenaId, setAthenaId] = useState();
  const [doctorCronoId, setDoctorCronoId] = useState();
  const [modmedId, setModmedId] = useState();
  const [loading, setLoading] = useState(false);
  const [timeZone, setTimeZone] = useState("America/New_York");
  const [samaraPractitionerIdModmed, setSamaraPractitionerIdModmed] =
    useState();
  const [calendarView, setCalendarView] = useState("dayGridMonth");
  const [events, setEvents] = useState([
    // { title: 'Focus time', date: '2024-06-10T08:00:00', details: 'Details about Focus time' },
    // { title: 'Focus time', date: '2024-06-10T09:00:00', details: 'Details about Focus time' },
    // { title: 'Confernce call', date: '2024-06-10T10:00:00', details: 'Details about Focus time' },
    // { title: 'Vc call', date: '2024-06-10T11:00:00', details: 'Details about Focus time' },
    // { title: 'Meeting', date: '2024-06-11T10:00:00', details: 'Details about the Meeting' },
  ]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventSync, setEventSync] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const formatToDatetimePayload = (dateTimeString) => {
    const utcTimestamp = dateTimeString;
    const localTimestamp = moment(utcTimestamp).format("YYYY-MM-DDTHH:mm");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    // const date = new Date(dateString);
    // const year = date.getFullYear();
    // const month = `0${date.getMonth() + 1}`.slice(-2);
    // const day = `0${date.getDate()}`.slice(-2);
    // const hours = `0${date.getHours()}`.slice(-2);
    // const minutes = `0${date.getMinutes()}`.slice(-2);
    // return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const formatDateTimeUS = (dateString, timeZoneData) => {
    console.log("timeZoneData", timeZoneData);

    const utcTimestamp = dateString;
    const localTimestamp = moment
      .utc(utcTimestamp)
      .tz(timeZoneData || "America/New_York")
      .format("YYYY-MM-DD hh:mm A");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };
  useEffect(() => {
    const filterEventsByDate = (date) => {
      const formattedDate = formatDate(date);
      const eventsOnSelectedDate = events.filter((event) =>
        event.start.startsWith(formattedDate)
      );
      setFilteredEvents(eventsOnSelectedDate);
      setSelectedEvent(eventsOnSelectedDate[0] || null);
    };

    filterEventsByDate(selectedDate);
  }, [selectedDate, events]);
  useEffect(() => {
    if (selectedProviderId !== null) {
      getPractitionersCalender();
    }
  }, [selectedProviderId, selectedDate]);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };
  const mapEventData = (event, timeZoneData) => {
    console.log("timezene", timeZoneData);
    const maxDescriptionLength = 20; // You can adjust this value as needed

    const truncatedDescription = truncateText(
      event.description || "N/A",
      maxDescriptionLength
    );
    if (event.kind) {
      // Google Calendar event
      return {
        id: event.id,
        title: event.summary,
        start:
          formatToDatetimeLocal(event.start.dateTime, timeZoneData) ||
          event.start.date,
        end:
          formatToDatetimeLocal(event.end.dateTime, timeZoneData) ||
          event.end.date,
        description: event.description || "",
        details: `
        Start Time: ${formatDateTimeUS(
          event.start.dateTime || event.start.date,
          timeZoneData
        )}<br>
        End Time: ${formatDateTimeUS(
          event.end.dateTime || event.end.date,
          timeZoneData
        )}<br>
        Status: ${event.status}
      `,
        color: event.color || "#1a73e8", // Default color
      };
    } else {
      // Database event
      console.log(
        "practitioner_calendars_id:",
        event.practitioner_calendars_id
      );
      console.log("patient_appointments_id:---->", event.patient_appointments_id);
      console.log("TimeZone123----->", timeZoneData);

      return {
        id: event.google_event_id,
        practitioner_calendars_id: event?.practitioner_calendars_id,
        patient_appointments_id: event?.patient_appointments_id,
        title: `Appointment with ${event?.patient_first_name} ${event?.patient_last_name}`,
        start: formatToDatetimeLocal(event.start_date, timeZoneData),
        end: formatToDatetimeLocal(event.end_date, timeZoneData),
        description: event.google_description || "",
        details: `
        Patient Name: ${event?.patient_first_name} ${
          event?.patient_last_name
        }<br>
        Start Time: ${formatDateTimeUS(event.start_date, timeZoneData)}<br>
        End Time: ${formatDateTimeUS(event.end_date, timeZoneData)}<br>
        Status: ${event.status}
      `,
        color: event.color,
      };
    }
  };
  const getPractitionersCalender = async () => {
    try {
      //  const endDate = formatDate(addDays(new Date(selectedDate), 7));
      //  console.log("endDate",endDate);
      let startDate, endDate;

      const currentDate = new Date();
      if (isSameMonth(selectedDate, currentDate)) {
        // Selected date is in the current month
        startDate = formatDate(currentDate, "yyyy-MM-dd");
        endDate = formatDate(endOfMonth(currentDate), "yyyy-MM-dd");
      } else {
        // Selected date is not in the current month
        startDate = formatDate(startOfMonth(selectedDate), "yyyy-MM-dd");
        endDate = formatDate(endOfMonth(selectedDate), "yyyy-MM-dd");
      }
      //  let endDate;
      //  if (calendarView === "dayGridMonth") {
      //    // Monthly view: endDate is the last day of the month
      //    endDate = formatDate(endOfMonth(selectedDate));
      //  } else if (calendarView === "timeGridWeek") {
      //    // Weekly view: endDate is the end of the week
      //    endDate = formatDate(endOfWeek(selectedDate));
      //  } else if (calendarView === "timeGridDay") {
      //    // Daily view: endDate is the same as selectedDate
      //    endDate = formatDate(selectedDate);
      //  }
      setLoading(true);
      const payload = {
        practitioner_id: selectedProviderId, //105
        date: startDate, //formatDate(selectedDate), //"2024-06-04", //formatDate(selectedDate)
        end_date: endDate, //formatDate(endDate),
      };
      const apiResponse = await postData(
        "practitioners/getPractitionersCalender",
        payload
      );

      if (apiResponse.code === 1) {
        setLoading(false);
        // console.log(
        //   "providers[selectedProviderId].clinicLocation.time_zone",
        //   selectedProviderId
        // );
        var timeZone = '';
        providers.map((entry) => {
          if(entry.id == selectedProviderId){
            if (
              entry.clinic_locations.time_zone != null ||
              entry.clinic_locations.time_zone != ""
            ){
              timeZone = entry.clinic_locations.time_zone;
            } else {
              timeZone = "America/New_York";
            }
              
          }
        });
        setTimeZone(timeZone);
        console.log("selectedTimezone",timeZone);
        const dbEvents = apiResponse.data
          .filter((event) => event.google_event_id)
          .map((event) =>
            mapEventData(
              event,
              // providers[selectedProviderId].clinic_locations.time_zone
              timeZone
            )
          );
          // .map(mapEventData);

        //  const googleEvents = apiResponse.data
        //    .filter((event) => event.kind)
        //    .map(mapEventData);
        // console.log(
        //   "providers[selectedProviderId].clinicLocation.time_zone",
        //   providers[selectedProviderId].clinic_locations.time_zone
        // );

        const googleEvents = apiResponse.data
          .filter((event) => event.kind)
          .map((event) =>
            mapEventData(
              event,
              // providers[selectedProviderId].clinic_locations.time_zone
              timeZone
            )
          );
        const combinedEvents = [...dbEvents, ...googleEvents];

        setEvents(combinedEvents);
        return combinedEvents; // Assuming data is in the 'data' field
      } else {
        // No data found or some issue with the request
        setLoading(false);
        return null;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error searching provider:", error);
      return null; // Error occurred or no data available
    }
  };
  const getSyncPractAppointment = async () => {
    if (!selectedProviderId) {
      Swal.fire({
        title: "Error",
        text: "Please Select Practitioner Before Sync",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    try {
      const payload = {
        athenahealth_ids: athenaId ? [athenaId] : [],
        samara_practitioner_ids: athenaId ? [selectedProviderId] : [],
        reasonid: 1, //static
        departmentid: 1, //static
        drchrono_ids: doctorCronoId ? [doctorCronoId] : [],
        samara_practitioner_ids_drc: doctorCronoId ? [selectedProviderId] : [],
        since: formatDate(selectedDate),
        modmed_ids: modmedId ? [modmedId] : [],
        samara_practitioner_ids_modmed: samaraPractitionerIdModmed
          ? [samaraPractitionerIdModmed]
          : [],
      };
      //console.log("syncPayload", payload);
      const response = await postData(
        "athenaapi/syncPractitionersAppointments",
        payload
      );
      console.log("Sync successful:", response.data);
      if (response.code === 1) {
        //getPractitionersCalender
        Swal.fire({
          title: "Success",
          text: response.message,
          icon: "success",
          timer: 2000,
          // showConfirmButton: true,
        });
        getPractitionersCalender();
      }
      // Handle response data here
    } catch (error) {
      console.error("Error syncing appointments:", error);
      // Handle error here
    }
  };
  useEffect(() => {
    // Save events to localStorage whenever they change
    localStorage.setItem("events", JSON.stringify(events));
  }, [events]);
  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    // console.log("clinicId", storedClinicId);
    const storedProAssistId = localStorage.getItem("clinicIdProAssist");
    // console.log("clinicId", storedProAssistId);
    const storedReferenceId = localStorage.getItem("refernceId");
    // console.log("storedReferenceId", storedReferenceId);
    const storedUserMasterId = localStorage.getItem("userMasterId");
    // console.log("storedUserMasterId", storedUserMasterId);
    const storedType = localStorage.getItem("accountType");
    console.log("storedType", storedType);

    const getProvidersOptions = async () => {
      try {
        // var accountType = localStorage.getItem("accountType");
        // var refernceId = localStorage.getItem("refernceId");
        // if (accountType == 'Practitioners') {
        //   var payload = {
        //     modelName: "practitioners",
        //     whereCondition: {
        //       id: refernceId,
        //       is_active: 1,
        //     },
        //   };
        // } else if (accountType == 'Assistances') {
        //   var payload = {
        //     modelName: "assistance_practitioners_mapping",
        //     whereCondition: {
        //       clinic_id: clinicId,
        //       is_active: 1,
        //     },
        //   };
        //   var assistance_practitioners_mapping_response = await postData("masters/getMasterList", payload);
        //   if (assistance_practitioners_mapping_response.code === 1) {
        //     var assistance_practitioners_mapping_data = assistance_practitioners_mapping_response.data;
        //     var varpractitionerIds = [];
        //     for (let i = 0; i < assistance_practitioners_mapping_data.length; i++) {
        //       const element = assistance_practitioners_mapping_data[i];
        //       varpractitionerIds.push(element.practitioner_id)
        //     }
        //   }
        //   console.log("varpractitionerIds--->", varpractitionerIds);
        //   var payload = {
        //     modelName: "practitioners",
        //     whereCondition: {
        //       id: varpractitionerIds,
        //       is_active: 1,
        //     },
        //   };
        // } else {
        //   var payload = {
        //     modelName: "practitioners",
        //     whereCondition: {
        //       clinic_id: clinicId,
        //       is_active: 1,
        //     },
        //   };
        // }
        setLoading(true);
        const payload = {
          clinic_id: storedClinicId,
          reference_id: storedReferenceId,
          userMasterId: storedUserMasterId,
          type: storedType,
        };
        console.log("payload", payload);
        const response = await postData(
          "/practitioners/getPractitionersListForCalender",
          payload
        );
        console.log("providerListing...", response.data);
        if (response.code == 1) {
          console.log("providerData", response.data);
          setLoading(false);
          setProviders(response.data);
          if (response.data.length > 0) {
            const firstProvider = response.data[0];
            setSelectedProviderId(firstProvider.id);
            setAthenaId(firstProvider.athenahealth_id);
            setDoctorCronoId(firstProvider.drchrono_id);
            setModmedId(firstProvider.modmed_id);
            setSamaraPractitionerIdModmed(firstProvider.id);
          }
          console.log("providerDataid", response.data.id);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching insurance options:", error);
      }
    };
    getProvidersOptions();
  }, []);
  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: `${provider.f_name} ${provider.l_name}`,
  }));

  const selectedProvider = providerOptions.find(
    (option) => option.value === selectedProviderId
  );
  const handleProviderChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedProviderId(selectedOption.value); // Setting selectedProviderId based on the selected option
      const selectedProvider = providers.find(
        (provider) => provider.id === selectedOption.value
      );
      setAthenaId(selectedProvider.athenahealth_id);
      setDoctorCronoId(selectedProvider.drchrono_id);
      setModmedId(selectedProvider.modmed_id);
      setSamaraPractitionerIdModmed(selectedProvider.id);
    } else {
      setSelectedProviderId(null);
      setAthenaId(null);
      setDoctorCronoId(null);
      setModmedId(null);
      setSamaraPractitionerIdModmed(null);
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    //fetchFilteredEvents(date);
    const formattedDate = date.toISOString().split("T")[0];
    const eventsOnSelectedDate = events.filter((event) =>
      event.start.startsWith(formattedDate)
    );
    setFilteredEvents(eventsOnSelectedDate);
    setSelectedEvent(eventsOnSelectedDate[0] || null);
  };

  const handleDateClick = (dateClickInfo) => {
    const clickedDate = new Date();
    const formattedDate = moment(clickedDate).format("YYYY-MM-DDTHH:mm");
    //setSelectedDate(clickedDate);
    setEventDetails({
      title: "",
      start: formattedDate,
      end: "",
      description: "",
    });
    setIsEditMode(false);
    setIsPopupOpen(true);
  };

  // const handleEventClick = (eventInfo) => {
  //   setSelectedEvent(eventInfo.event.extendedProps);
  //   setIsEditMode(true);
  //   setIsPopupOpen(true);
  // };
  const formatToDatetimeLocal = (dateTimeString, timeZoneData) => {
    console.log("timeZoneData", timeZoneData);

    const utcTimestamp = dateTimeString;
    const localTimestamp = moment
      .utc(dateTimeString)
      .tz(timeZoneData || "America/New_York")
      .format("YYYY-MM-DDTHH:mm");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    // const date = new Date(dateString);
    // const year = date.getFullYear();
    // const month = `0${date.getMonth() + 1}`.slice(-2);
    // const day = `0${date.getDate()}`.slice(-2);
    // const hours = `0${date.getHours()}`.slice(-2);
    // const minutes = `0${date.getMinutes()}`.slice(-2);
    // return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleMouseEnter = (info) => {
    const startDate = new Date(info.event.start);
    const endDate = new Date(info.event.end);
    const formattedStartDate = startDate.toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const formattedStartTime = startDate.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const formattedEndTime = endDate.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    tippy(info.el, {
      content: `
        <strong>${info.event.title}</strong><br>
        From ${formattedStartTime} TO ${formattedEndTime}
      `,
      allowHTML: true,
      interactive: true,
    }).show();
  };

  const handleMouseLeave = (info) => {
    tippy(info.el).destroy();
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    title: "",
    start: "",
    end: "",
    description: "",
    practitioner_calendars_id: "",
    patient_appointments_id: "",
  });

  const openPopupForNewEvent = (date) => {
    setEventDetails({ title: "", start: date, end: date, description: "" });
    setIsEditMode(false);
    setIsPopupOpen(true);
  };

  const openPopupForEditEvent = (event) => {
    setEventDetails(event);
    setIsEditMode(true);
    setIsPopupOpen(true);
  };
  const stripHTML = (html) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const handleEventClick = (eventInfo) => {
    //console.log("eventInfo,", eventInfo);
    //console.log("app>>id",eventInfo.event.extendedProps?.patient_appointments_id);
    //console.log("calender>>id",eventInfo.event.extendedProps?.practitioner_calendars_id)
    if (eventInfo.event) {
      // If an existing event is clicked
      setEventDetails({
        id: eventInfo.event.id,
        patient_appointments_id:
          eventInfo.event.extendedProps?.patient_appointments_id,
        practitioner_calendars_id:
          eventInfo.event.extendedProps?.practitioner_calendars_id,
        title: eventInfo.event.title,
        start: formatToDatetimePayload(eventInfo.event.startStr), // Ensure format is correct
        end: formatToDatetimePayload(eventInfo.event.endStr), // Ensure format is correct
        description:
          stripHTML(eventInfo.event.extendedProps?.description) || "",
      });
      setIsEditMode(true); // Set to edit mode
    } else {
      // Reset event details for adding a new event
      setEventDetails({
        id: "",
        patient_appointments_id: "",
        practitioner_calendars_id: "",
        title: "",
        start: "",
        end: "",
        description: "",
      });
      setIsEditMode(false); // Set to add mode
    }

    // Open the popup
    setIsPopupOpen(true);
  };
  // const handleSaveEvent = () => {
  //   console.log("onsaveEventedtails...", eventDetails);

  //   if (isEditMode) {
  //     // Update the existing event
  //     setEvents((prevEvents) =>
  //       prevEvents.map((evt) =>
  //         evt.id == eventDetails.id ? { ...eventDetails } : evt
  //       )
  //     );

  //   } else {
  //     // Add a new event
  //     setEvents((prevEvents) => [
  //       ...prevEvents,
  //       { ...eventDetails, id: new Date().getTime() },
  //     ]);
  //   }

  // const handleSaveEvent = async () => {
  //   console.log("onsaveEventDetails...", eventDetails);
  //   const payload = {
  //     practitioner_id: 9, // Assuming this is a fixed value or you can dynamically get the practitioner_id
  //     fromDate: eventDetails.start,
  //     toDate: eventDetails.end,
  //     summary: eventDetails.title,
  //     description: eventDetails.description,
  //   };

  //   if (isEditMode) {
  //     setEvents((prevEvents) => {
  //       return prevEvents.map((evt) => {
  //         if (evt.id == eventDetails.id) {
  //           console.log("Matching event found:", evt);
  //           return { ...evt, ...eventDetails };
  //         }
  //         return evt;
  //       });
  //     });
  //   } else {
  //     // setEvents((prevEvents) => [
  //     //   ...prevEvents,
  //     //   { ...eventDetails, id: new Date().getTime() },
  //     // ]);
  //      const response = await postData("practitioners/addPractitionersEvent", payload);
  //       if (response.code === 1) {
  //         setEvents((prevEvents) => [
  //           ...prevEvents,
  //           { ...eventDetails, id: new Date().getTime() }, // Generating a unique ID for local state
  //         ]);
  //          //getPractitionersCalender();
  //       } else {
  //         throw new Error("Failed to add the event.");
  //       }
  //   }

  //   setIsPopupOpen(false);
  // };
  const handleSaveEvent = async () => {
    //console.log("onsaveEventDetails...", eventDetails);
    const maxDescriptionLength = 20;
    const payload = {
      practitioner_id: selectedProviderId, // Assuming this is a fixed value or you can dynamically get the practitioner_id
      // fromDate: eventDetails.start,
      // toDate: eventDetails.end,
      fromDate: formatToDatetimePayload(eventDetails.start),
      toDate: formatToDatetimePayload(eventDetails.end),
      summary: eventDetails.title,
      description: eventDetails.description,
    };
    const truncatedDescription = truncateText(
      eventDetails.description || "N/A",
      maxDescriptionLength
    );
    const formattedDetails = `
    Start Time: ${formatDateTimeUS(
      eventDetails.start,
      //providers[selectedProviderId].clinic_locations.time_zone
      timeZone
    )}<br>
    End Time: ${formatDateTimeUS(
      eventDetails.end,
      //providers[selectedProviderId].clinic_locations.time_zone
      timeZone
    )}<br>
    Status: ${eventDetails.status || "N/A"}
  `;

    try {
      setLoading(true);
      if (isEditMode) {
        const updatedPayload = {
          ...payload,
          google_event_id: eventDetails.id,
          patient_appointments_id: eventDetails.patient_appointments_id || "",
          practitioner_calendars_id:
            eventDetails.practitioner_calendars_id || "", // Include the event ID to identify which event to edit
          // Add any other fields that are necessary for updating the event
        };
        console.log("updatedPayloadEdit...", updatedPayload);
        const response = await postData(
          "practitioners/updatePractitionersEvent",
          updatedPayload
        );

        if (response.code === 1) {
          // setEvents((prevEvents) => {
          //   return prevEvents.map((evt) => {
          //     if (evt.id === eventDetails.id) {
          //       console.log("Matching event found:", evt);
          //       return { ...evt, ...eventDetails };
          //     }
          //     return evt;
          //   });
          // });
          setEvents((prevEvents) => {
            const updatedEvents = prevEvents.map((evt) => {
              if (evt.id === eventDetails.id) {
                console.log("Updating event with ID:", evt.id);
                return { ...evt, ...eventDetails, details: formattedDetails };
              }
              return evt;
            });
            //console.log("Updated events array:", updatedEvents); // Debugging line
            return updatedEvents;
          });

          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.message,
          }).then(() => {
            // This will reload the page after clicking "OK"
            // window.location.reload();
            getPractitionersCalender();
          });
        } else if (response.code === 0) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.message,
          });
          // throw new Error("Failed to update the event.");
        }
        // setEvents((prevEvents) => {
        //   return prevEvents.map((evt) => {
        //     if (evt.id === eventDetails.id) {
        //       console.log("Matching event found:", evt);
        //       return { ...evt, ...eventDetails };
        //     }
        //     return evt;
        //   });
        // });
      } else {
        const response = await postData(
          "practitioners/addPractitionersEvent",
          payload
        );
        if (response.code === 1) {
          setIsPopupOpen(false);
          // setEvents((prevEvents) => [
          //   ...prevEvents,
          //   { ...eventDetails, id: new Date().getTime() }, // Generating a unique ID for local state
          // ]);
          setEvents((prevEvents) => {
            const newEvent = {
              ...eventDetails,
              id: new Date().getTime(),
              details: formattedDetails,
            };
            //console.log("Adding new event:", newEvent); // Debugging line
            return [...prevEvents, newEvent];
          });
          //getPractitionersCalender();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.message,
          }).then(() => {
            // This will reload the page after clicking "OK"
            // window.location.reload();
            getPractitionersCalender();
          });
        } else if (response.code === 0) {
          setIsPopupOpen(true);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.message,
          });
          // throw new Error("Failed to add the event.");
        }
      }
    } catch (error) {
      console.error("Error saving event:", error);
      // Optionally, you can display an error message to the user here
    } finally {
      setLoading(false);
      //setIsPopupOpen(false);
    }
  };
  // const handleEventDrop = async (eventInfo) => {
  //   const updatedEvent = {
  //     id: eventInfo.event.id,
  //     title: eventInfo.event.title,
  //     start: eventInfo.event.start.toISOString(),
  //     end: eventInfo.event.end ? eventInfo.event.end.toISOString() : null,
  //     description: eventInfo.event.extendedProps.description || "",
  //   };

  //   try {
  //     // Make an API call to save the updated event details
  //     console.log("Event updated successfully", updatedEvent);
  //   } catch (error) {
  //     console.error("Error updating event", error);
  //     // Optionally, revert the event to its original position if the API call fails
  //     eventInfo.revert();
  //   }
  // };

  const handleEventDrop = async (eventInfo) => {
    console.log("eventInfo", eventInfo);

    console.log(
      "patient_appointments_id",
      eventInfo.event.patient_appointments_id
    );
    console.log(
      "practitioner_calendars_id",
      eventInfo.event.practitioner_calendars_id
    );
    const updatedEvent = {
      id: eventInfo.event.id,
      patient_appointments_id:
        eventInfo.event.extendedProps.patient_appointments_id || "",
      practitioner_calendars_id:
        eventInfo.event.extendedProps.practitioner_calendars_id || "",
      title: eventInfo.event.title,
      start: eventInfo.event.start.toISOString(),
      end: eventInfo.event.end.toISOString(),
      description: eventInfo.event.extendedProps.description || "",
    };
    const maxDescriptionLength = 20;
    const truncatedDescription = truncateText(
      eventDetails.description || "N/A",
      maxDescriptionLength
    );
    const formattedDetails = `
    Description: ${truncatedDescription || "N/A"}<br>
    Start Time: ${formatDateTimeUS(
      updatedEvent.start,
      //providers[selectedProviderId].clinic_locations.time_zone
      timeZone
    )}<br>
    End Time: ${formatDateTimeUS(
      updatedEvent.end,
      //providers[selectedProviderId].clinic_locations.time_zone
      timeZone
    )}<br>
    Status: ${updatedEvent.status || "N/A"}
  `;
    // Prepare the payload for the API call
    setLoading(true);
    const payload = {
      google_event_id: updatedEvent.id,
      practitioner_id: selectedProviderId,
      patient_appointments_id: updatedEvent.patient_appointments_id || "",
      practitioner_calendars_id: updatedEvent.practitioner_calendars_id || "", // Assuming this is a fixed value or you can dynamically get the practitioner_id
      // fromDate: updatedEvent.start,
      // toDate: updatedEvent.end,
      fromDate: formatToDatetimePayload(updatedEvent.start),
      toDate: formatToDatetimePayload(updatedEvent.end),
      summary: updatedEvent.title,
      description: updatedEvent.description,
    };
    //console.log("Dragdroppayload",payload);

    try {
      // Make the API call to update the event details
      const response = await postData(
        "practitioners/updatePractitionersEvent",
        payload
      );

      if (response.code === 1) {
        // Show success alert if the update is successful
        //  setEvents((prevEvents) => {
        //    const updatedEvents = prevEvents.map((evt) => {
        //      if (evt.id === eventDetails.id) {
        //        console.log("Updating event with ID:", evt.id);
        //        return { ...evt, ...eventDetails, details: formattedDetails };
        //      }
        //      return evt;
        //    });
        //    console.log("Updated events array:", updatedEvents); // Debugging line
        //    return updatedEvents;
        //  });
        setLoading(false);
        setEvents((prevEvents) => {
          const updatedEvents = prevEvents.map((evt) => {
            if (evt.id === updatedEvent.id) {
              console.log("Updating event with ID:", evt.id);
              return { ...evt, ...updatedEvent, details: formattedDetails };
            }
            return evt;
          });
          //console.log("Updated events array:", updatedEvents);
          return updatedEvents;
        });
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.message,
        }).then(() => {
          // This will reload the page after clicking "OK"
          getPractitionersCalender();
        });
      } else if (response.code === 0) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.message,
        })
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating event", error);
      // Optionally, revert the event to its original position if the API call fails
      eventInfo.revert();

      // Show error alert
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error,
      });
    }
  };

  // const handleDeleteEvent = () => {
  //   console.log("Current event details:", eventDetails);

  //   if (!eventDetails || !eventDetails.id) {
  //     console.error("Event details or event ID is missing. Cannot delete event.");
  //     return;
  //   }

  //   console.log("Attempting to delete event with ID:", eventDetails.id);

  //   setEvents((prevEvents) => {
  //     const newEvents = prevEvents.filter(
  //       (event) => event.id != eventDetails.id
  //     );
  //     console.log("Events after deletion:", newEvents);
  //     return newEvents;
  //   });

  //   setIsPopupOpen(false);
  // };
  const handleDeleteEvent = async () => {
    //console.log("Current event details:", eventDetails);

    if (!eventDetails || !eventDetails.id) {
      console.error(
        "Event details or event ID is missing. Cannot delete event."
      );
      return;
    }

    //console.log("Attempting to delete event with ID:", eventDetails.id);

    // Update the events in the state by filtering out the deleted event
    // setEvents((prevEvents) => {
    //   const newEvents = prevEvents.filter(
    //     (event) => event.id !== eventDetails.id
    //   );
    //   console.log("Events after deletion:", newEvents);
    //   return newEvents;
    // });

    //  setIsPopupOpen(false);

    try {
      //setLoading(true);
      let apiUrl;
      let payload;

      if (eventDetails.patient_appointments_id) {
        const { value: reasonForCancel } = await Swal.fire({
          title: "Cancel Appointment",
          input: "textarea",
          inputLabel: "Reason For Cancellation",
          inputPlaceholder: "Enter Your Reason For Cancellation...",
          inputValue: "",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
        });

        // If the user clicks "Cancel", exit the function
        if (!reasonForCancel) {
          setLoading(false);
          return;
        }
        setLoading(true);
        var accountType = localStorage.getItem("accountType");
        var userMasterId = localStorage.getItem("userMasterId");
        apiUrl = "common/cancel";
        payload = {
          inputData: {
            id: eventDetails.patient_appointments_id, //patient appointment
            user_type: accountType,
            user_type_id: userMasterId, //user master id
            reasonForCancel,
          },
        };
      } else {
        apiUrl = "practitioners/cancelPractitionersEvent";
        payload = {
          practitioner_id: selectedProviderId,
          google_event_id: eventDetails.id,
        };
        setLoading(true);
      }
      //console.log("deletedpayload", payload);
      const response = await postData(apiUrl, payload);

      if (response.code === 1) {
        setEvents((prevEvents) => {
          const newEvents = prevEvents.filter(
            (event) => event.id !== eventDetails.id
          );
          console.log("Events after deletion:", newEvents);
          return newEvents;
        });

        setIsPopupOpen(false);
        //console.log("Event deleted successfully:", response.message);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.message,
        });
      } else if (response.code === 0) {
        setIsPopupOpen(true);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.message || "Failed to add the event.",
        });
        // throw new Error(`Failed to delete event: ${response.message}`);
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "An error occurred while deleting the event.",
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleDateClick = (info) => {
  //   const clickedDate = new Date(info.dateStr);
  //   setSelectedDate(clickedDate); // Update the selected date
  //   openPopupForNewEvent(clickedDate); // Open the popup for creating a new event
  // };
  // const handleDateClick = (info) => {
  //   const clickedDate = new Date(info.dateStr);
  //   setSelectedDate(clickedDate); // Update the selected date
  //   openPopupForNewEvent(clickedDate); // Open the popup for creating a new event
  // };

  //console.log("updatedeventDetails:",eventDetails);
  //console.log("events..",events);

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <header
            className="w-full  min-h-16 px-4 py-1 md:flex md:items-center"
            style={shadowBottom}
          >
            <div className="w-full flex items-center justify-between mt-3">
              <div className="w-full flex items-center flex justify-between">
                <div>
                  <span>EHR/PMS: Sync on Demand</span>
                  <span
                    onClick={getSyncPractAppointment}
                    title="Sync Practitioner Appointments"
                    className="cursor-pointer border border-black m-3 px-1 py-1 px font-fontPoppins text-2xl font-semibold text-black hover:text-blueColor"
                  >
                    <i className="fa-solid fa-sync"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <label className="font-fontPoppins text-sm xss:text-xs font-medium">
                      Select Practitioner
                    </label>
                  </div>
                  <div className="w-80 xss:w-40">
                    <ReactSelect
                      value={selectedProvider}
                      onChange={handleProviderChange}
                      options={providerOptions}
                      className="w-80 xss:w-40 font-fontPoppins text-sm xss:text-xs font-medium z-50"
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="flex">
            {/* Left-side Date Picker */}
            <div className="w-1/5 p-4">
              <h2 className="text-xl font-bold mb-4">Select Date</h2>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                minDate={new Date()}
              />
            </div>

            {/* Main Calendar w-3/5 */}
            <div className="w-3/5 p-4">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                  start: "prev,next today",
                  center: "title",
                  end: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                events={events}
                //   dateClick={(info) => setSelectedDate(new Date(info.dateStr))}
                //   eventClick={handleEventClick}
                dateClick={handleDateClick} // Combined date click handler
                eventClick={handleEventClick}
                //dateClick={handleDateChange}
                viewDidMount={(viewInfo) => setCalendarView(viewInfo.view.type)}
                eventColor="#1a73e8"
                className="shadow-lg rounded-lg"
                ref={(calendar) => {
                  if (calendar) {
                    const calendarApi = calendar.getApi();
                    calendarApi.gotoDate(selectedDate); // Update the calendar view when the date picker changes
                  }
                }}
                dayMaxEvents={1}
                eventMouseEnter={handleMouseEnter}
                eventMouseLeave={handleMouseLeave}
                eventMinHeight={30}
                editable={true}
                eventDrop={handleEventDrop}
              />
              <EventPopup
                isOpen={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onSave={handleSaveEvent}
                onDelete={handleDeleteEvent}
                eventDetails={eventDetails}
                setEventDetails={setEventDetails}
                isEditMode={isEditMode}
              />
            </div>

            {/* Right-side Event Details */}
            <div className="w-1/5 p-4">
              <h2 className="text-xl font-bold mb-4">
                {" "}
                {selectedDate
                  ? selectedDate.toLocaleString("en-US", {
                      weekday: "short",
                      day: "numeric",
                      month: "short",
                    })
                  : "Select a Date"}
              </h2>
              {/* {filteredEvents.length > 0 ? (
                filteredEvents.map((event) => (
                  <div
                    key={event.id}
                    className="border p-4 rounded-lg shadow mb-4"
                  >
                    <h3 className="text-lg font-bold">{event.title}</h3>
                    <p>{event.description}</p>
                    <p dangerouslySetInnerHTML={{ __html: event.details }}></p>
                  </div>
                ))
              ) : (
                <p>No Appointments On This Date</p>
              )} */}
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event) => {
                  const maxDescriptionLength = 20;
                  const truncatedDescription =
                    event.description.length > maxDescriptionLength
                      ? event.description.substring(0, maxDescriptionLength) +
                        "..."
                      : event.description;

                  return (
                    <div
                      key={event.id}
                      className="border p-4 rounded-lg shadow mb-4"
                      ref={(el) => {
                        if (el) {
                          tippy(el.querySelector(".description-tooltip"), {
                            content: event.description,
                            allowHTML: true,
                            interactive: true,
                          });
                        }
                      }}
                    >
                      <h3 className="text-lg font-bold">{event.title}</h3>
                      <p className="description-tooltip">
                        Discription: {truncatedDescription}
                      </p>
                      <p
                        dangerouslySetInnerHTML={{ __html: event.details }}
                      ></p>
                    </div>
                  );
                })
              ) : (
                <p>No Appointments On This Date</p>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
};

export default NewCalendar;
