// src/ga.js
import ReactGA from "react-ga4";

// export const initGA = (measurementId) => {
//   ReactGA.initialize(measurementId);
// };

// export const logPageView = (pathname) => {
//   // console.log("hello logPageView", pathname);
//   ReactGA.send({ hitType: "pageview", page: pathname });
//   // ReactGA.set({ page: pathname });
//   // ReactGA.pageview(pathname);
// };

export const initGA = () => {
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const productionUrl = "https://samarawell.com"; 

  if (window.location.hostname === new URL(productionUrl).hostname && measurementId) {
    ReactGA.initialize(measurementId);
    console.log("Google Analytics initialized for production");
  } else {
    console.warn("Google Analytics not initialized (non-production environment)");
  }
};

// Log page view
export const logPageView = (pathname) => {
  ReactGA.send({ hitType: "pageview", page: pathname });
};


export const logEvent = (eventCategory, eventAction, eventLabel) => {
  // console.log(eventCategory, eventAction, eventLabel);

  ReactGA.send({
    hitType: "event",
    eventCategory,
    eventAction,
    eventLabel,
  });
};
