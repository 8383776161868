import React from "react";
import ReactApexChart from "react-apexcharts";

class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state with the data from props
    this.state = {
      series: this.props.data || [0, 0],
      options: {
        chart: {
          type: "donut",
          height: 350,
        },
        labels: ["Samara Well", "EHR/PMS"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  }

  componentDidUpdate(prevProps) {
    // Update state if props change
    if (prevProps.data !== this.props.data) {
      this.setState({
        series: this.props.data || [0, 0],
      });
    }
  }

  render() {
    return (
      <div>
        <div>
          <h2>Scheduling Ratio</h2>
        </div>
        <div id="chart" className="relative">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="donut"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default DonutChart;
