import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { imageBaseUrl, apiUrl, postData } from "../../Networking/Api";
import Header from "../Header";
import Footer from "../Footer";
import Swal from "sweetalert2";
import CustomSelect from "../CustomSelect";
import CustomSelectHealth from "../CustomSelectHealth";
import { Switch } from "@headlessui/react";
import Loader2 from "../Loader2";
import axios from "axios";
const CreateClinic = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    website: "",
    //phone: "",
    //faxNumber: "",
    npi_number: "",
    //street: "",
    //city: "",
    //state: "",
    //country: "",
    //address_ype: "",
    //zipcode: "",
    taxonomy_description: "",
    logo_image: "",
  });
  const [originalEmail, setOriginalEmail] = useState("");
  const [originalName, setOriginalName] = useState("");
  const [locations, setLocations] = useState({
    phone: "",
    fax_number: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    address_ype: "",
    is_active: 1,
    is_deleted: 0,
    time_zone: "",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [errors, setErrors] = useState({});
  const [locationError, setLocationError] = useState({});
  const [clinicId, setClinicId] = useState("");
  const [submitButton, setSubmitButton] = useState(true);
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [enlargedImageUrl, setEnlargedImageUrl] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [addLocations, setAddLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clinicIsChecked, setClinicIsChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const targetRef = useRef(null);
  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");

    if (storedClinicId) {
      setClinicId(storedClinicId);
    }

    getClinicDetails(storedClinicId);
    getClinicLocations(storedClinicId);
  }, []);
  const storedAccountType = localStorage.getItem("accountType");
  console.log("accountType....", storedAccountType);

  function formatMobileNumber(value) {
    const numbersOnly = value.replace(/[^\d+]/g, "");

    if (numbersOnly.length <= 3) {
      return numbersOnly;
    } else if (numbersOnly.length <= 6) {
      return `(${numbersOnly.slice(0, 3)})-${numbersOnly.slice(3)}`;
    } else {
      return `(${numbersOnly.slice(0, 3)}) ${numbersOnly.slice(
        3,
        6
      )}-${numbersOnly.slice(6)}`;
    }
  }
  // const handleIsActiveChange = async (locationId, checked) => {
  //   try {
  //     const payload = {
  //       modelName: "clinic_locations", // Adjust modelName as per your API endpoint
  //       id: locationId,
  //       inputData: {
  //         is_active: checked ? 1 : 0,
  //       },
  //     };

  //     const response = await postData("masters/createAndUpdateMaster", payload);

  //     if (response.code === 1) {
  //       const updatedLocations = addLocations.map((location) => {
  //         if (location.id === locationId) {
  //           return {
  //             ...location,
  //             is_active: checked ? 1 : 0,
  //           };
  //         }
  //         return location;
  //       });

  //       setAddLocations(updatedLocations);

  //       Swal.fire({
  //         title: "Success",
  //         text: "Status Changed Successfully",
  //         icon: "success",
  //         timer: 2000,
  //         showConfirmButton: false,
  //       });
  //     } else {
  //       throw new Error("Failed to update status");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while updating isActive:", error);
  //     Swal.fire({
  //       title: "Error",
  //       text: "An error occurred. Please try again later.",
  //       icon: "error",
  //       showConfirmButton: true,
  //     });
  //   }
  // };
  const handleIsActiveChange = async (locationId, checked) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "Do You Want To Change The Status?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change It!",
        cancelButtonText: "No, Cancel!",
      });

      if (confirmationResult.isConfirmed) {
        const updatedLocations = addLocations.map((location) => {
          if (location.id === locationId) {
            return {
              ...location,
              is_active: checked ? 1 : 0,
            };
          }
          return location;
        });

        setAddLocations(updatedLocations);

        // Swal.fire({
        //   title: "Success",
        //   text: "Status Changed Successfully",
        //   icon: "success",
        //   timer: 2000,
        //   showConfirmButton: false,
        // });
      }
    } catch (error) {
      console.error("An error occurred while updating isActive:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred. Please try again later.",
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  const checkNpiRegistered = async (npi_number) => {
    try {
      const response = await postData("practitioners/providerSearch", {
        number: npi_number,
      });
      return response.data && Object.keys(response.data).length > 0;
    } catch (error) {
      console.error("Error checking NPI number:", error);
      return false;
    }
  };
  useEffect(() => {
    // if (organizationData) {
    const { npi_number } = formData ? formData : "";
    // }
    if (npi_number) {
      const timeoutId = setTimeout(() => {
        handleFetchOrgnizationData(npi_number);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [formData?.npi_number]);
  const handleFetchOrgnizationData = async () => {
    const { npi_number } = formData;
    await getOrgNPINumberData(npi_number);
  };
  // const getOrgNPINumberData = async (npi_number) => {
  //   const npiRegistered = await checkNpiRegistered(npi_number);
  //   if (!npiRegistered) {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       npi_number: "",
  //       name: "",
  //       email: "",
  //       website: "",
  //       taxonomy_description: "",
  //       logo_image: "",
  //     }));
  //     setLocations({
  //       phone: "",
  //       fax_number: "",
  //       street: "",
  //       city: "",
  //       state: "",
  //       country: "",
  //       zipcode: "",
  //       address_ype: "",
  //     });
  //     setErrors({
  //       npi_number: "",
  //       name: "",
  //       taxonomy_description: "",
  //       email: "",
  //     });
  //     setLocationError({
  //       phone: "",
  //       fax_number: "",
  //       street: "",
  //       city: "",
  //       state: "",
  //       country: "",
  //       zipcode: "",
  //       address_ype: "",
  //     });
  //     return;
  //   }
  //   try {
  //     const payload = { number: npi_number };
  //     const response = await postData("practitioners/providerSearch", payload);
  //     const providerAddress =
  //       response.data.addresses[1] || response?.data?.addresses[0];

  //     const orgNpiData = response.data;
  //     const number = response?.data?.number || "";
  //     const name = response?.data?.basic?.name || "";
  //     const teleNumber = providerAddress?.teleNumber || "";
  //     const faxNumber = providerAddress?.faxNumber || "";
  //     const streetAdd = providerAddress?.addressLine1 || "";
  //     const City = providerAddress?.city || "";
  //     const postalCode = providerAddress?.postalCode || "";
  //     const stateCode = providerAddress?.state || "";
  //     const countryCode = providerAddress?.countryCode || "";
  //     const specialization =
  //       response?.data?.taxonomies?.length > 0
  //         ? response?.data?.taxonomies[0]?.specialization
  //         : "";
  //     const desc =
  //       response?.data?.taxonomies?.length > 0
  //         ? response?.data?.taxonomies[0]?.desc
  //         : "";
  //     const taxonomyDescription = `${desc}  ${specialization}`;
  //     const email =
  //       response?.data?.endPoints?.length > 0
  //         ? response?.data?.endPoints[0]?.endpoint
  //         : "";

  //     const countryName = countryCode;
  //     const stateName = stateCode;

  //     setFormData((prevData) => ({
  //       ...prevData,
  //       npi_number: number,
  //       name: name,
  //       email:
  //         (orgNpiData?.endPoints?.length > 0
  //           ? orgNpiData?.endPoints[0]?.endpoint
  //           : prevData.email) || prevData.email,
  //       website: prevData.website,
  //       taxonomy_description: taxonomyDescription,
  //       logo_image: prevData.logo_image,
  //     }));

  //     var LocationsData = {
  //       phone: formatMobileNumber(teleNumber),
  //       fax_number: faxNumber,
  //       street: streetAdd,
  //       city: City,
  //       state: stateName,
  //       country: countryName,
  //       zipcode: postalCode,
  //       address_ype: "",
  //       is_active: 1,
  //       is_deleted: 0,
  //     };
  //     // setAddLocations((prevLocations) => [...prevLocations, LocationsData]);
  //     // console.log("Addlocations", addLocations);
  //     const practiceLocations = response?.data?.practiceLocations || [];

  //     setAddLocations((prevLocations) => {
  //       const getLocationKey = (location) =>
  //         `${location.phone}-${location.street}-${location.city}`;

  //       const practiceLocationsFormatted = practiceLocations.map(
  //         (location) => ({
  //           phone: formatMobileNumber(location.teleNumber) || "",
  //           fax_number: location.faxNumber || "",
  //           street: location.addressLine1 || "",
  //           city: location.city || "",
  //           state: location.state || "",
  //           country: location.countryCode || "",
  //           zipcode: location.postalCode || "",
  //           address_ype: "",
  //           is_active: 1,
  //           is_deleted: 0,
  //         })
  //       );

  //       const locationMap = new Map();

  //       prevLocations.forEach((loc) =>
  //         locationMap.set(getLocationKey(loc), loc)
  //       );

  //       if (practiceLocations.length > 0) {
  //         practiceLocationsFormatted.forEach((loc) => {
  //           const key = getLocationKey(loc);
  //           if (!locationMap.has(key)) {
  //             locationMap.set(key, loc);
  //           }
  //         });
  //         const formattedLocationsData = {
  //           phone: LocationsData.phone || "",
  //           fax_number: LocationsData.fax_number || "",
  //           street: LocationsData.street || "",
  //           city: LocationsData.city || "",
  //           state: LocationsData.state || "",
  //           country: LocationsData.country || "",
  //           zipcode: LocationsData.zipcode || "",
  //           address_ype: "",
  //           is_active: LocationsData.is_active || 0,
  //           is_deleted: LocationsData.is_deleted || 0,
  //         };
  //         const key = getLocationKey(formattedLocationsData);
  //         if (!locationMap.has(key)) {
  //           locationMap.set(key, formattedLocationsData);
  //         }
  //       } else {
  //         // Only include LocationsData if practiceLocations is empty
  //         const formattedLocationsData = {
  //           phone: LocationsData.phone || "",
  //           fax_number: LocationsData.fax_number || "",
  //           street: LocationsData.street || "",
  //           city: LocationsData.city || "",
  //           state: LocationsData.state || "",
  //           country: LocationsData.country || "",
  //           zipcode: LocationsData.zipcode || "",
  //           address_ype: LocationsData.address_ype || "",
  //           is_active: LocationsData.is_active || 0,
  //           is_deleted: LocationsData.is_deleted || 0,
  //         };
  //         const key = getLocationKey(formattedLocationsData);
  //         if (!locationMap.has(key)) {
  //           locationMap.set(key, formattedLocationsData);
  //         }
  //       }

  //       // Convert map values to array
  //       const uniqueLocations = Array.from(locationMap.values());

  //       return uniqueLocations;
  //     });
  //     setErrors({
  //       npi_number: "",
  //       name: "",
  //       taxonomy_description: "",
  //       email: "",
  //     });
  //     setLocations({
  //       phone: "",
  //       fax_number: "",
  //       street: "",
  //       city: "",
  //       state: "",
  //       country: "",
  //       zipcode: "",
  //       address_ype: "",
  //     });
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const getOrgNPINumberData = async (npi_number) => {
    const npiRegistered = await checkNpiRegistered(npi_number);
    if (!npiRegistered) {
      setFormData((prevData) => ({
        ...prevData,
        npi_number: "",
        name: "",
        email: "",
        website: "",
        taxonomy_description: "",
        logo_image: "",
      }));
      setLocations({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
      });
      setErrors({
        npi_number: "",
        name: "",
        taxonomy_description: "",
        email: "",
      });
      setLocationError({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
      });
      return;
    }
    try {
      const payload = { number: npi_number };
      const response = await postData("practitioners/providerSearch", payload);
      const providerAddress =
        response.data.addresses[1] || response?.data?.addresses[0];

      const orgNpiData = response.data;
      const number = response?.data?.number || "";
      const name = response?.data?.basic?.name || "";
      const teleNumber = providerAddress?.teleNumber || "";
      const faxNumber = providerAddress?.faxNumber || "";
      const streetAdd = providerAddress?.addressLine1 || "";
      const City = providerAddress?.city || "";
      const postalCode = providerAddress?.postalCode || "";
      const stateCode = providerAddress?.state || "";
      const countryCode = providerAddress?.countryCode || "";
      const specialization =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.specialization
          : "";
      const desc =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.desc
          : "";
      const taxonomyDescription = `${desc}  ${specialization}`;
      const email =
        response?.data?.endPoints?.length > 0
          ? response?.data?.endPoints[0]?.endpoint
          : "";

      const countryName = countryCode;
      const stateName = stateCode;

      setFormData((prevData) => ({
        ...prevData,
        npi_number: number,
        name: name,
        email:
          (orgNpiData?.endPoints?.length > 0
            ? orgNpiData?.endPoints[0]?.endpoint
            : prevData.email) || prevData.email,
        website: prevData.website,
        taxonomy_description: taxonomyDescription,
        logo_image: prevData.logo_image,
      }));

      const LocationsData = {
        phone: formatMobileNumber(teleNumber),
        fax_number: faxNumber,
        street: streetAdd,
        city: City,
        state: stateName,
        country: countryName,
        zipcode: formatZipcode(postalCode),
        address_ype: "",
        is_active: 1,
        is_deleted: 0,
      };
      const practiceLocations = response?.data?.practiceLocations || [];

      setAddLocations((prevLocations) => {
        const getLocationKey = (location) => `${location.street}`;

        const practiceLocationsFormatted = practiceLocations.map(
          (location) => ({
            phone: formatMobileNumber(location.teleNumber) || "",
            fax_number: location.faxNumber || "",
            street: location.addressLine1 || "",
            city: location.city || "",
            state: location.state || "",
            country: location.countryCode || "",
            zipcode: formatZipcode(location.postalCode) || "",
            address_ype: "",
            is_active: 1,
            is_deleted: 0,
          })
        );

        const locationMap = new Map();

        prevLocations.forEach((loc) =>
          locationMap.set(getLocationKey(loc), loc)
        );

        if (practiceLocations.length > 0) {
          practiceLocationsFormatted.forEach((loc) => {
            const key = getLocationKey(loc);
            if (!locationMap.has(key)) {
              locationMap.set(key, loc);
            }
          });
          const formattedLocationsData = {
            phone: LocationsData.phone || "",
            fax_number: LocationsData.fax_number || "",
            street: LocationsData.street || "",
            city: LocationsData.city || "",
            state: LocationsData.state || "",
            country: LocationsData.country || "",
            zipcode: formatZipcode(LocationsData.zipcode) || "",
            address_ype: "",
            is_active: LocationsData.is_active || 0,
            is_deleted: LocationsData.is_deleted || 0,
          };
          const key = getLocationKey(formattedLocationsData);
          if (!locationMap.has(key)) {
            locationMap.set(key, formattedLocationsData);
          }
        } else {
          // Only include LocationsData if practiceLocations is empty
          const formattedLocationsData = {
            phone: LocationsData.phone || "",
            fax_number: LocationsData.fax_number || "",
            street: LocationsData.street || "",
            city: LocationsData.city || "",
            state: LocationsData.state || "",
            country: LocationsData.country || "",
            zipcode: formatZipcode(LocationsData.zipcode) || "",
            address_ype: LocationsData.address_ype || "",
            is_active: LocationsData.is_active || 0,
            is_deleted: LocationsData.is_deleted || 0,
          };
          const key = getLocationKey(formattedLocationsData);
          if (!locationMap.has(key)) {
            locationMap.set(key, formattedLocationsData);
          }
        }

        // Convert map values to array
        const uniqueLocations = Array.from(locationMap.values());

        return uniqueLocations;
      });
      setErrors({
        npi_number: "",
        name: "",
        taxonomy_description: "",
        email: "",
      });
      setLocations({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
        is_active: 1,
        is_deleted: 0,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatZipcode = (zipcode) => {
    if (zipcode.length === 9) {
      return `${zipcode.substring(0, 5)}-${zipcode.substring(5)}`;
    }
    return zipcode;
  };
  const getClinicDetails = async (clinicId) => {
    try {
      setLoading(true);
      const payload = {
        modelName: "clinics",
        whereCondition: {
          id: clinicId,
        },
      };

      const response = await postData("masters/getMasterList", payload);
      setLoading(false);
      setFormData(response.data[0]);
      setOriginalEmail(response.data[0].email);
      setOriginalName(response.data[0].name);
      let img = localStorage.getItem("profilePic");
      if (img.startsWith("clinicLogo/")) {
        console.log("trrrue");

        localStorage.setItem("profilePic", response.data[0].logo_image);
      }
    } catch (error) {
      console.error("Error fetching clinic details:", error);
      setLoading(false);
    }
  };
  const getClinicLocations = async (clinicId) => {
    try {
      const payload = {
        modelName: "clinic_locations",
        whereCondition: {
          clinic_id: clinicId,
          is_deleted: 0,
        },
      };

      const response = await postData("masters/getMasterList", payload);
      if (response.data && Array.isArray(response.data)) {
        setAddLocations(response.data);
      } else {
        console.error("Invalid response format:", response);
        // Handle error or set addLocations to empty array or default value
        setAddLocations([]);
      }
    } catch (error) {
      console.error("Error fetching clinic details:", error);
    }
  };
  const handleLocationChange = (e) => {
    var { name, value } = e.target;

    if (name === "city") {
      value = value.replace(/[^a-zA-Z\s]/g, "").replace(/^\s+/, "");
    }
    if (name === "street") {
      value = value.replace(/^\s+/, "");
    }

    if (name === "fax_number") {
      let numericValue = value.replace(/\D/g, "");
      if (numericValue.length > 3 && numericValue.length <= 6) {
        numericValue = numericValue.slice(0, 3) + "-" + numericValue.slice(3);
      } else if (numericValue.length > 6) {
        numericValue =
          numericValue.slice(0, 3) +
          "-" +
          numericValue.slice(3, 6) +
          "-" +
          numericValue.slice(6, 10);
      }
      value = numericValue;
    }
    if (name === "zipcode") {
      let numericValue = value.replace(/[^\d]/g, "");
      if (numericValue.length > 5) {
        numericValue = numericValue.slice(0, 5) + "-" + numericValue.slice(5);
      }
      value = numericValue;
    }
    if (name === "phone") {
      const formattedValue = formatMobileNumber(value);
      const newValue = formattedValue;
      setLocations({ ...locations, [name]: newValue });
    } else {
      setLocations({ ...locations, [name]: value });
    }
    setLocationError({ ...locationError, [name]: "" });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Function to trim leading spaces
    const trimLeadingSpaces = (str) => {
      let startIndex = 0;
      while (startIndex < str.length && str[startIndex] === " ") {
        startIndex++;
      }
      return str.slice(startIndex);
    };

    // Trim leading spaces for specific fields
    const fieldsToTrim = ["name", "taxonomy_description", "email", "website"];
    let trimmedValue = value;
    if (fieldsToTrim.includes(name)) {
      trimmedValue = trimLeadingSpaces(value);
    }

    // Handle file input for logoImg
    if (name === "logoImg" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const logoImageBase64 = e.target.result.toString();
          setFormData((prevFormData) => ({
            ...prevFormData,
            logo_image: logoImageBase64,
          }));
        }
      };
      reader.readAsDataURL(file);
      return; // Exit the function as we don't need to setFormData again for file input
    }

    // General case for updating form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: trimmedValue,
    }));

    // Clear any existing errors for the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    var accountType = localStorage.getItem("accountType");
    if (accountType != "Clinic") {
      setSubmitButton(false);
    }
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://npiregistry.cms.hhs.gov/RegistryBack/referenceData",
          {
            method: "POST",
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        const data = await response.json();
        setCountries(data.countries || []);
        setStates(data.states || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const validateForm = (data) => {
    let isValid = true;
    const locationError = {};

    if (!data.phone) {
      isValid = false;
      locationError.phone = "Contact Number Is Required.";
    } else if (data.phone.replace(/\D/g, "").length < 10) {
      isValid = false;
      locationError.phone = "Contact Number Must Contain 10 Digits.";
    }

    if (!data.city) {
      isValid = false;
      locationError.city = "City Is Required";
    }
    if (!data.state) {
      isValid = false;
      locationError.state = "State Is Required";
    }
    if (!data.country) {
      isValid = false;
      locationError.country = "Country Is Required";
    }
    if (!data.address_ype) {
      isValid = false;
      locationError.address_ype = "Address Type Is Required";
    }
    if (!data.street) {
      isValid = false;
      locationError.street = "Street Address Is Required";
    }
    if (!data.zipcode) {
      isValid = false;
      locationError.zipcode = "Zip Code Is Required";
    } else if (!/^\d{5}(-\d{4})?$/.test(data.zipcode)) {
      isValid = false;
      locationError.zipcode = "Invalid Zip Code Format";
    }

    setLocationError(locationError);
    return isValid;
  };
  // const addLocation = (e) => {
  //   e.preventDefault();
  //   const isValid = validateForm(locations);
  //   if (!isValid) {
  //     return;
  //   }

  //   if (editingIndex !== null) {
  //     const updatedLocations = addLocations.map((loc, index) =>
  //       index === editingIndex ? locations : loc
  //     );
  //     setAddLocations(updatedLocations);
  //     setEditingIndex(null);
  //   } else {
  //     setAddLocations([...addLocations, locations]);
  //   }

  //   setLocations({
  //     phone: "",
  //     fax_number: "",
  //     street: "",
  //     city: "",
  //     state: "",
  //     country: "",
  //     zipcode: "",
  //     address_ype: "",
  //     is_active: 1,
  //     is_deleted: 0,
  //   });
  // };
  const addLocation = async (e) => {
    e.preventDefault();
    const isValid = validateForm(locations);
    if (!isValid) {
      return;
    }

    // Construct the address string for the API call
    const address = `${locations.street} ${locations.city}, ${locations.state}, ${locations.country}, ${locations.zipcode}`;

    try {
      // Make the API call to get the timezone
      const response = await axios.get(`https://api.geocod.io/v1.7/geocode`, {
        params: {
          q: address,
          api_key: "66c265254966cb09ca45b890a7acc2c8846b068",
          fields: "timezone",
        },
      });

      //const timezone = response.data.results[0].fields.timezone.name;
      const timezone =
        response.data.results[0]?.fields?.timezone?.name || "America/New_York";
      // Update the locations state with the timezone
      const updatedLocations = { ...locations, time_zone: timezone };

      if (editingIndex !== null) {
        const updatedAddLocations = addLocations.map((loc, index) =>
          index === editingIndex ? updatedLocations : loc
        );
        setAddLocations(updatedAddLocations);
        setEditingIndex(null);
      } else {
        setAddLocations([...addLocations, updatedLocations]);
      }

      // Reset the form
      setLocations({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
        is_active: 1,
        is_deleted: 0,
        time_zone: "",
      });
    } catch (error) {
      console.error("Error fetching timezone:", error);
      const updatedLocations = { ...locations, time_zone: "America/New_York" };

      if (editingIndex !== null) {
        const updatedAddLocations = addLocations.map((loc, index) =>
          index === editingIndex ? updatedLocations : loc
        );
        setAddLocations(updatedAddLocations);
        setEditingIndex(null);
      } else {
        setAddLocations([...addLocations, updatedLocations]);
      }

      // Reset the form
      setLocations({
        phone: "",
        fax_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        address_ype: "",
        is_active: 1,
        is_deleted: 0,
        time_zone: "", // Reset time_zone as well
      });
    }
  };
  const editLocation = (index) => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
    const locationToEdit = addLocations[index];
    setLocations(locationToEdit);
    setEditingIndex(index);
  };

  const deleteLocation = async (index) => {
    try {
      if (index === editingIndex) {
        Swal.fire({
          title: "Cannot Delete!",
          text: "You Cannot Delete A Record While It Is Being Edited.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "You Won't Be Able To Revert This!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete It!",
      });

      if (confirmationResult.isConfirmed) {
        const updatedLocations = addLocations.map((location, i) => {
          if (i === index) {
            return { ...location, is_deleted: 1 };
          }
          return location;
        });
        setAddLocations(updatedLocations);

        //   Swal.fire({
        //     title: "Deleted!",
        //     text: "The location has been marked as deleted.",
        //     icon: "success",
        //   });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const checkOrgNameExists = async (orgName) => {
    console.log("orgName", orgName);

    try {
      const payload = {
        modelName: "clinics",
        whereCondition: {
          name: orgName,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  const checkEmailOrgaExists = async (email) => {
    try {
      const payload = {
        modelName: "clinics",
        whereCondition: {
          email: email,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!clinicIsChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    let isValid = true;
    const errors = {};
    if (!formData.name) {
      isValid = false;
      errors.name = "Organization Name Is Required";
    } else if (formData.name !== originalName) {
      const orgNameExists = await checkOrgNameExists(formData.name);
      if (orgNameExists) {
        isValid = false;
        errors.name =
          "The Organization Name Is Already Registered With Samara-Well.";
      }
    }
    if (!formData.email || !formData.email.trim()) {
      isValid = false;
      errors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
    ) {
      isValid = false;
      errors.email = "Invalid Email Format.";
    } else if (formData.email !== originalEmail) {
      const emailExists = await checkEmailOrgaExists(formData.email);
      if (emailExists) {
        isValid = false;
        errors.email =
          "The Organization Email Is Already Registered With Samara-Well.";
      }
    }

    if (
      formData?.website &&
      !/^https?:\/\/(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?$/.test(
        formData.website
      )
    ) {
      isValid = false;
      errors.website = "Valid Website is Required.";
    }
    if (!formData.taxonomy_description) {
      isValid = false;
      errors.taxonomy_description = "Taxonomy Is Required";
    }

    setErrors(errors);

    if (isValid) {
      try {
        setLoading(true);
        const payload = {
          modelName: "clinics",
          id: clinicId,
          inputData: {
            name: formData.name,
            email: formData.email,
            website: formData.website,
            //phone: formData.phone,
            //faxNumber: formData.faxNumber,
            npi_number: formData.npi_number,
            //address_ype: formData.address_ype,
            // street: formData.street,
            // city: formData.city,
            // country: formData.country,
            // state: formData.state,
            // zipcode: formData.zipcode,
            taxonomy_description: formData.taxonomy_description,
            logo_image: formData.logo_image,
          },
        };

        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );

        if (response.code === 1) {
          const response = await postData("masters/getMasterList", {
            modelName: "clinics",
            whereCondition: {
              email: formData.email,
            },
          });
          let img = localStorage.getItem("profilePic");
          if (img.startsWith("clinicLogo/")) {
            localStorage.setItem("profilePic", response.data[0].logo_image);
          }
          let success = false;
          console.log("addlocation..", addLocations);
          for (let i = 0; i < addLocations.length; i++) {
            const loc = addLocations[i];
            let locationPayload;

            if (loc.id) {
              locationPayload = {
                modelName: "clinic_locations",
                id: loc.id,
                inputData: {
                  clinic_id: clinicId,
                  phone: loc.phone,
                  fax_number: loc.fax_number,
                  street: loc.street,
                  city: loc.city,
                  state: loc.state,
                  country: loc.country,
                  zipcode: loc.zipcode,
                  address_ype: loc.address_ype,
                  google_business_link: loc.google_business_link || "",
                  is_active: loc.is_active,
                  is_deleted: loc.is_deleted,
                  time_zone: loc.time_zone,
                },
              };
            } else {
              if (loc.is_deleted == 0) {
                locationPayload = {
                  modelName: "clinic_locations",
                  inputData: {
                    clinic_id: clinicId,
                    phone: loc.phone,
                    fax_number: loc.fax_number,
                    street: loc.street,
                    city: loc.city,
                    state: loc.state,
                    country: loc.country,
                    zipcode: loc.zipcode,
                    address_ype: loc.address_ype,
                    google_business_link: loc.google_business_link || "",
                    is_active: loc.is_active,
                    time_zone: loc.time_zone,
                  },
                };
              }
            }
            console.log("locationPayload", locationPayload);
            const response = await postData(
              "masters/createAndUpdateMaster",
              locationPayload
            );

            if (response.code == 1) {
              success = true;
            }
          }

          if (success) {
            setLoading(false);
            Swal.fire({
              title: "Success",
              text: "Clinic Info Updated Successfully",
              icon: "success",
              timer: 2000,
              showConfirmButton: true,
            });
            setTimeout(() => {}, 2000);
          }
        }
      } catch (error) {
        console.error("Error updating clinic:", error);
        setLoading(false);
      }
    }
  };
  const toggleEnlargeImage = (image) => {
    setEnlargedImageUrl(image);
    setEnlargeImage(!enlargeImage);
  };
  const getLabelByCode = (code, options) => {
    console.log("option", options, code);
    const option = options.find((option) => option.value === code);
    return option ? option.label : code;
  };
  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />

        <div className="pt-16">
          <div className="w-full mt-3">
            <section className="w-full py-2">
              <div className="container mx-auto px-4">
                <h1 className="font-fontPoppins pb-7 md:text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal">
                  Organization Info
                </h1>

                <div className="bg-grayColorLight rounded-lg p-3">
                  <div className="w-full md:flex items-center gap-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Organization NPI
                        {/* <span className="text-red-500">*</span> */}
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter Organization NPI Number"
                        name="npi_number"
                        value={formData.npi_number}
                        onChange={handleChange}
                        // readOnly
                        readOnly={storedAccountType !== "Clinic"}
                      />
                      {errors.npi_number && (
                        <p className="text-red-500 text-xs">
                          {errors.npi_number}
                        </p>
                      )}
                    </div>

                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Organization Name
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        placeholder="Enter Organization Name"
                        // readOnly
                        readOnly={storedAccountType !== "Clinic"}
                      />
                      {errors.name && (
                        <p className="text-red-500 text-xs">{errors.name}</p>
                      )}
                    </div>
                  </div>
                  <div className="w-full mt-3">
                    <div className="w-full md:flex items-center gap-3">
                      <div className="w-full">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Email
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="Enter Your Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          readOnly={storedAccountType !== "Clinic"}
                        />
                        {errors.email && (
                          <p className="text-red-500 text-xs">{errors.email}</p>
                        )}
                      </div>

                      {/* <div className="w-full"> */}
                      {/* <span className="z-10 absolute left-3 top-2">
                        <i className="fa-solid fa-upload"></i>
                      </span> */}
                      <div className="w-full">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Logo Image
                        </label>
                        <div className="flex items-center">
                          <input
                            name="logoImg"
                            onChange={handleChange}
                            className="w-full h-9 rounded-full text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                            style={{
                              paddingLeft: "7px",
                              paddingTop: "7px",
                            }}
                            type="file"
                            accept="image/*"
                            placeholder="Upload"
                            readOnly={storedAccountType !== "Clinic"}
                          />
                          {formData?.logo_image && (
                            <img
                              src={
                                formData.logo_image.startsWith("data:")
                                  ? formData.logo_image
                                  : `${imageBaseUrl}/${formData.logo_image}` ||
                                    "/clinicPlaceholder.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/clinicPlaceholder.png";
                              }}
                              alt="Logo Image"
                              className="w-12 h-12 ml-2 cursor-pointer"
                              onClick={() =>
                                toggleEnlargeImage(formData.logo_image)
                              }
                            />
                          )}
                          {enlargeImage && (
                            <div
                              className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                              onClick={toggleEnlargeImage}
                            >
                              <div className="fixed inset-0 flex items-center justify-center">
                                <div className="w-100 m-3 aspect-square overflow-hidden">
                                  <img
                                    src={
                                      formData?.logo_image.startsWith("data:")
                                        ? formData?.logo_image
                                        : `${imageBaseUrl}/${formData?.logo_image}` ||
                                          "/clinicPlaceholder.png"
                                    }
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = "/clinicPlaceholder.png";
                                    }}
                                    alt="Enlarged"
                                    className="object-contain w-full h-full"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>

                    <div className="w-full md:flex items-center gap-3 mt-3">
                      {/* <div className="w-full">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Organization Contact Number
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="(xxx)-xxx-xxxx"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        maxLength={14}
                        pattern="^\(?\d{3}\)?[-.\s]\d{3}[-.\s]\d{4}$|^(\+\d{1,2}\s)?\(?\d{3}\)?[-.\s]\d{3}[-.\s]\d{4}$"
                      />
                      {errors.phone && (
                        <p className="text-red-500 text-xs">{errors.phone}</p>
                      )}
                    </div> */}

                      <div className="w-full mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Taxonomy Description
                          <span className="text-red-500">*</span>
                        </label>
                        <input
                          name="taxonomy_description"
                          value={formData.taxonomy_description}
                          onChange={handleChange}
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          placeholder="Enter Organization Taxonomy"
                          // readOnly
                          readOnly={storedAccountType !== "Clinic"}
                        />
                        {errors.taxonomy_description && (
                          <p className="text-red-500 text-xs">
                            {errors.taxonomy_description}
                          </p>
                        )}
                      </div>
                      <div className="w-full xss:mt-3 sm:mt-3">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Website
                        </label>
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          placeholder="Website URL"
                          name="website"
                          value={formData.website}
                          onChange={handleChange}
                          readOnly={storedAccountType !== "Clinic"}
                        />
                        {errors.website && (
                          <p className="text-red-500 text-xs">
                            {errors.website}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <div className="w-full md:flex items-center gap-3 mt-3">
                    <div className="w-full">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Street Address<span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter Your Address"
                        name="street"
                        value={formData.street}
                        onChange={handleChange}
                      />
                      {errors.street && (
                        <p className="text-red-500 text-xs">{errors.street}</p>
                      )}
                    </div>

                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        City<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="city"
                        placeholder="Enter Your City"
                        value={formData.city}
                        onChange={handleChange}
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      />
                      {errors.city && (
                        <p className="text-red-500 text-xs">{errors.city}</p>
                      )}
                    </div>
                  </div> */}

                    {/* <div className="w-full md:flex items-center gap-3 mt-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        State<span className="text-red-500">*</span>
                      </label>

                      <CustomSelect
                        options={states.map((state) => ({
                          value: state.stateCode,
                          label: state.stateName,
                        }))}
                        name="state"
                        onChange={handleChange}
                        value={formData.state}
                        placeholder={"Select Your State"}
                      />
                      {errors.state && (
                        <p className="text-red-500 text-xs">{errors.state}</p>
                      )}
                    </div>

                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Country<span className="text-red-500">*</span>
                      </label>

                      <CustomSelect
                        name="country"
                        options={countries.map((country) => ({
                          value: country.countryCode,
                          label: country.countryName,
                        }))}
                        value={formData.country}
                        onChange={handleChange}
                        placeholder="Select Your Country"
                      />
                      {errors.country && (
                        <p className="text-red-500 text-xs">{errors.country}</p>
                      )}
                    </div>
                  </div> */}

                    <div className="w-full md:flex items-center gap-3 mt-3">
                      {/* <div className="w-full">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Zip Code<span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter Zipcode"
                        name="zipcode"
                        value={formData.zipcode}
                        onChange={handleChange}
                        maxLength={10}
                      />
                      {errors.zipcode && (
                        <p className="text-red-500 text-xs">{errors.zipcode}</p>
                      )}
                    </div> */}

                      {/* <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Address Type<span className="text-red-500">*</span>
                      </label>

                      <CustomSelect
                        name="address_ype"
                        options={[
                          { value: "Primary", label: "Primary" },
                          { value: "Secondary", label: "Secondary" },
                        ]}
                        value={formData.address_ype}
                        onChange={handleChange}
                        placeholder={"Select Your Address Type"}
                      />

                      {errors.address_ype && (
                        <p className="text-red-500 text-xs">
                          {errors.address_ype}
                        </p>
                      )}
                    </div> */}
                    </div>

                    <div className="w-full md:flex items-center gap-3 mt-3">
                      {/* <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Website
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Website URL"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                      />
                      {errors.website && (
                        <p className="text-red-500 text-xs">{errors.website}</p>
                      )}
                    </div> */}

                      {/* <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Fax Number
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter Your Fax Number"
                        name="faxNumber"
                        value={formData.faxNumber}
                        onChange={handleChange}
                        maxLength={12}
                      />
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="pt-4" ref={targetRef}>
                  <div className="w-full mt-0">
                    <section className="w-full py-2">
                      <div className="container mx-auto">
                        <label className="font-fontPoppins text-xs font-semibold">
                          Organization Locations
                          <span className="text-red-500">*</span>
                        </label>
                        {storedAccountType == "Clinic" && (
                          <div className="w-full bg-grayColorLight rounded-lg p-3 mt-1">
                            <div className="w-full md:flex justify-between gap-3">
                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  Organization Contact Number
                                  <span className="text-red-500">*</span>
                                </label>
                                <input
                                  className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                                  type="text"
                                  placeholder="(xxx)-xxx-xxxx"
                                  name="phone"
                                  value={locations.phone}
                                  onChange={handleLocationChange}
                                  maxLength={14}
                                  pattern="^\(?\d{3}\)?[-.\s]\d{3}[-.\s]\d{4}$|^(\+\d{1,2}\s)?\(?\d{3}\)?[-.\s]\d{3}[-.\s]\d{4}$"
                                />
                                {locationError.phone && (
                                  <p className="text-red-500 text-xs">
                                    {locationError.phone}
                                  </p>
                                )}
                              </div>
                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  Street Address
                                  <span className="text-red-500">*</span>
                                </label>
                                <input
                                  className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                                  type="text"
                                  placeholder="Enter Your Address"
                                  name="street"
                                  value={locations.street}
                                  onChange={handleLocationChange}
                                />
                                {locationError.street && (
                                  <p className="text-red-500 text-xs">
                                    {locationError.street}
                                  </p>
                                )}
                              </div>
                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  City<span className="text-red-500">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  placeholder="Enter Your City"
                                  value={locations.city}
                                  onChange={handleLocationChange}
                                  className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                                />
                                {locationError.city && (
                                  <p className="text-red-500 text-xs">
                                    {locationError.city}
                                  </p>
                                )}
                              </div>

                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  State<span className="text-red-500">*</span>
                                </label>

                                <CustomSelectHealth
                                  options={states.map((state) => ({
                                    value: state.stateCode,
                                    // value: state.stateName,
                                    label: state.stateName,
                                  }))}
                                  name="state"
                                  onChange={handleLocationChange}
                                  value={locations.state}
                                  placeholder={"Select Your State"}
                                />
                                {locationError.state && (
                                  <p className="text-red-500 text-xs">
                                    {locationError.state}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="w-full md:flex justify-between gap-3">
                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  Country<span className="text-red-500">*</span>
                                </label>

                                <CustomSelectHealth
                                  name="country"
                                  options={countries.map((country) => ({
                                    value: country.countryCode,
                                    // value: country.countryName,
                                    label: country.countryName,
                                  }))}
                                  value={locations.country}
                                  onChange={handleLocationChange}
                                  placeholder="Select Your Country"
                                />
                                {locationError.country && (
                                  <p className="text-red-500 text-xs">
                                    {locationError.country}
                                  </p>
                                )}
                              </div>
                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  Zip Code
                                  <span className="text-red-500">*</span>
                                </label>
                                <input
                                  className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                                  type="text"
                                  placeholder="Enter Zipcode"
                                  name="zipcode"
                                  value={locations.zipcode}
                                  onChange={handleLocationChange}
                                  maxLength={10}
                                />
                                {locationError.zipcode && (
                                  <p className="text-red-500 text-xs">
                                    {locationError.zipcode}
                                  </p>
                                )}
                              </div>

                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  Address Type
                                  <span className="text-red-500">*</span>
                                </label>

                                <CustomSelectHealth
                                  name="address_ype"
                                  options={[
                                    { value: "Primary", label: "Primary" },
                                    { value: "Secondary", label: "Secondary" },
                                  ]}
                                  value={locations.address_ype}
                                  onChange={handleLocationChange}
                                  placeholder={"Select Your Address Type"}
                                />

                                {locationError.address_ype && (
                                  <p className="text-red-500 text-xs">
                                    {locationError.address_ype}
                                  </p>
                                )}
                              </div>
                              <div className="w-full xss:mt-3 sm:mt-3">
                                <label className="font-fontPoppins text-xs font-semibold">
                                  Fax Number
                                </label>
                                <input
                                  className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                                  type="text"
                                  placeholder="Enter Your Fax Number"
                                  name="fax_number"
                                  value={locations.fax_number}
                                  onChange={handleLocationChange}
                                  maxLength={12}
                                />
                              </div>
                              <div className="w-64 mt-3">
                                <label className="font-fontPoppins text-xs font-semibold text-transparent">
                                  .
                                </label>
                                <button
                                  type="submit"
                                  onClick={addLocation}
                                  className="w-full h-10 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                                >
                                  {editingIndex !== null ? "Update" : "Add"}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="w-full mt-3 overflow-auto">
                          <table className="border-collapse table-auto w-full text-sm">
                            <thead>
                              <tr>
                                <th className="sticky top-0 rounded-tl-lg font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                  Contact Number
                                </th>
                                <th className="sticky top-0 font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                  Street Address
                                </th>
                                <th className="sticky top-0 font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                  City
                                </th>
                                <th className="sticky top-0 font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                  State
                                </th>
                                <th className="sticky top-0 font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                  Country
                                </th>
                                <th className="sticky top-0 font-fontPoppins text-xs bg-grayColorLight border-b dark:border-slate-600 font-semibold p-3 pl-8 pb-3 text-left">
                                  Zip Code
                                </th>
                                <th className="sticky top-0 font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                  Address Type
                                </th>
                                <th className="sticky top-0 font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                  Fax Number
                                </th>
                                {storedAccountType == "Clinic" && (
                                  <>
                                    <th className="sticky z-10 top-0 font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                      Status
                                    </th>
                                    <th className="sticky top-0 rounded-tr-lg font-fontPoppins text-xs bg-grayColorLight border-b font-semibold p-3 pl-8 pb-3 text-left">
                                      Actions
                                    </th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {addLocations.map(
                                (location, index) =>
                                  location.is_deleted == 0 && (
                                    <tr key={index}>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {location.phone}
                                      </td>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {location.street}
                                      </td>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {location.city}
                                      </td>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {getLabelByCode(
                                          location.state,
                                          states.map((state) => ({
                                            value: state.stateCode,

                                            label: state.stateName,
                                          }))
                                        )}
                                      </td>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {getLabelByCode(
                                          location.country,
                                          countries.map((country) => ({
                                            value: country.countryCode,

                                            label: country.countryName,
                                          }))
                                        )}
                                      </td>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {location.zipcode}
                                      </td>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {location.address_ype}
                                      </td>
                                      <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                        {location.fax_number}
                                      </td>
                                      {storedAccountType == "Clinic" && (
                                        <>
                                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                            <div className="flex items-center">
                                              <Switch
                                                checked={
                                                  location.is_active === 1
                                                }
                                                onChange={(checked) =>
                                                  handleIsActiveChange(
                                                    location.id,
                                                    checked
                                                  )
                                                }
                                                className={`${
                                                  location.is_active === 1
                                                    ? "bg-green-600"
                                                    : "bg-red-500"
                                                } relative inline-flex items-center h-8 rounded-full w-20 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                              >
                                                {location.is_active === 1 ? (
                                                  <>
                                                    <span className="absolute left-2 text-white sm:left-1">
                                                      Active
                                                    </span>
                                                    <span className="translate-x-12 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="absolute right-2 text-white sm:right-1">
                                                      Inactive
                                                    </span>
                                                    <span className="translate-x-1 inline-block w-6 h-6 transform bg-white rounded-full transition-transform" />
                                                  </>
                                                )}
                                              </Switch>
                                            </div>
                                          </td>
                                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                                            <span
                                              onClick={() =>
                                                editLocation(index)
                                              }
                                              className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor"
                                            >
                                              <i className="fa-solid fa-pencil"></i>
                                            </span>
                                            <span
                                              onClick={() =>
                                                deleteLocation(index)
                                              }
                                              className="text-gray-700 mr-2 cursor-pointer hover:text-red-500"
                                            >
                                              <i className="fa-solid fa-trash"></i>
                                            </span>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="flex mt-4 items-center gap-2">
                          <input
                            type="checkbox"
                            checked={clinicIsChecked}
                            onChange={(e) =>
                              setClinicIsChecked(e.target.checked)
                            }
                          />

                          <span className="font-fontPoppins text-xs font-semibold relative">
                            I have verified all the information and provide
                            consent to Samara Well to use and display this
                            information accordingly.
                          </span>
                        </div>
                        {errorMessage && (
                          <p className="text-red-500  text-xs absolute">
                            {errorMessage}
                          </p>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
                {submitButton && (
                  <div className="w-full mt-4">
                    <div className="w-full mt-4 text-center flex justify-center">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="w-40 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
                      >
                        <span className="me-2">Submit</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
};
export default CreateClinic;
