import React, { useState } from "react";
import PatientsLandingPageHeader from "./PatientsLandingPageHeader";
import PatientsLandingPageFooter from "./PatientsLandingPageFooter";
import CommonModal from "./CommonModal";
import {
  MdOutlineMail,
  MdSupportAgent,
  MdOutlineAddIcCall,
  MdLanguage,
} from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ProviderLandingPageFooter from "./ProviderLandingPageFooter/ProviderLandingPageFooter";

const ContactUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActiveModel, setIsActiveModel] = useState(false);
  const [isAllOtherQueries, setIsAllOtherQueries] = useState(false);
  const navigate = useNavigate();

  const openCommonModal = () => {
    setIsModalOpen(true);
  };

  const closeCommonModal = () => {
    setIsModalOpen(false);
  };

  const isActiveModelOpen = () => {
    setIsActiveModel(true);
  };

  const isActiveModelClose = () => {
    setIsActiveModel(false);
  };

  const isAllOtherQueriesOpen = () => {
    setIsAllOtherQueries(true);
  };

  const isAllOtherQueriesClose = () => {
    setIsAllOtherQueries(false);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
        <PatientsLandingPageHeader />
      </div>
      <div className="pt-16">
        <section>
          <div className="relative w-full h-96">
            <img
              className="absolute h-full w-full object-cover object-center"
              src="https://bucket.material-tailwind.com/magic-ai/bbe71871de8b4d6f23bb0f17a6d5aa342f3dea72677ba7238b18defa3741244d.jpg"
              alt="nature image"
            />
            <div className="absolute inset-0 h-full w-full bg-black/50"></div>
            <div className="relative pt-20 text-center">
              <h1 className="block antialiased tracking-normal font-poppins font-bold leading-[1.3] text-white text-3xl lg:text-4xl mt-8 mb-8">
                Contact Information
              </h1>
              <p className="text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur vel risus eget lorem tincidunt efficitur.
              </p>
            </div>
          </div>
          <div className="mb-8 px-8">
            <div className="container mx-auto">
              <div className="py-12 flex flex-col md:flex-row justify-center rounded-xl border border-white bg-white shadow-md shadow-black/5 saturate-200">
                <div className="my-8 flex flex-wrap justify-center gap-4">
                  <div
                    className="w-full md:w-auto mx-4 border border-black px-4 py-6 rounded-lg text-center hover:bg-black/30 flex flex-col items-center mb-4 md:mb-0"
                    onClick={openCommonModal}
                  >
                    <MdOutlineAddIcCall className="text-black text-5xl mb-4" />
                    <h4 className="font-sans text-center text-black text-2xl font-bold xss:text-lg sm:text-lg leading-tight">
                      Contact Sales
                    </h4>
                    <p className="mt-2 text-lg text-black">
                      Get in touch with our sales team for inquiries.
                    </p>
                  </div>

                  <div
                    className="w-full md:w-auto mx-4 border border-black px-4 py-6 rounded-lg text-center hover:bg-black/30 flex flex-col items-center mb-4 md:mb-0"
                    onClick={isActiveModelOpen}
                  >
                    <MdSupportAgent className="text-black text-5xl mb-4" />
                    <h4 className="font-sans text-center text-black text-2xl font-bold xss:text-lg sm:text-lg leading-tight">
                      Contact Customer Support
                    </h4>
                    <p className="mt-2 text-lg text-black">
                      Need help? Our support team is here for you.
                    </p>
                  </div>

                  <div
                    className="w-full md:w-auto mx-4 border border-black px-4 py-6 rounded-lg text-center hover:bg-black/30 flex flex-col items-center mb-4 md:mb-0"
                    onClick={isAllOtherQueriesOpen}
                  >
                    <FaQuestionCircle className="text-black text-5xl mb-4" />
                    <h4 className="font-sans text-center text-black text-2xl font-bold xss:text-lg sm:text-lg leading-tight">
                      All Other Queries
                    </h4>
                    <p className="mt-2 text-lg text-black">
                      For any other questions, reach out to us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {isActiveModel && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center p-1 shadow-lg mt-3 bg-grayColor rounded-lg">
            <div
              className="bg-white p-8 rounded-lg shadow-lg"
              style={{
                height: "32rem",
                width: "50rem",
                borderRadius: "0.5rem",
              }}
            >
              <div className="flex justify-end mb-8">
                <div className="text-center">
                  <svg
                    onClick={isActiveModelClose}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex justify-center mb-16">
                <h1 className="text-4xl font-semibold">
                  Contact Customer Support
                </h1>
              </div>

              <div className="flex justify-around">
                <div className="flex flex-col items-center">
                  <MdOutlineMail className="text-6xl text-gray-800" />
                  <p className="text-lg mt-4">Mail</p>
                  <a href="mailto:support@samarawell.com" className="text-sm">
                    support@samarawell.com
                  </a>
                </div>

                <div
                  className="flex flex-col items-center"
                  onClick={() => navigate("/FAQ")}
                >
                  <MdLanguage className="text-6xl text-gray-800" />
                  <p className="text-lg mt-4">Help Center</p>
                  <p className="text-sm cursor-pointer">samarawell.com/faq</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {isAllOtherQueries && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center p-1 shadow-lg mt-3 bg-grayColor rounded-lg">
            <div
              className="bg-white p-8 rounded-lg shadow-lg"
              style={{
                height: "32rem",
                width: "50rem",
                borderRadius: "0.5rem",
              }}
            >
              <div className="flex justify-end mb-8">
                <div className="text-center">
                  <svg
                    onClick={isAllOtherQueriesClose}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex justify-center mb-16">
                <h1 className="text-4xl font-semibold">All Other Queries</h1>
              </div>

              <div className="flex justify-around">
                <div className="flex flex-col items-center">
                  <MdOutlineMail className="text-7xl text-gray-800" />
                  <p className="text-xl font-semibold mt-4">Mail</p>
                  <a href="mailto:support@samarawell.com" className="text-sm">
                    info@samarawell.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <CommonModal
          isOpen={isModalOpen}
          onClose={closeCommonModal}
          requestFrom="ContactUs"
          headerText="Contact Sales"
        />
        <ProviderLandingPageFooter />
      </div>
    </>
  );
};

export default ContactUs;
