import { useState } from "react";

import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { apiUrl, postData, imageBaseUrl } from "../../Networking/Api";
import Loader2 from "../Loader2";

const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};

function ClinicBookingHeader2({
  logoImage,
  Phone,
  Street,
  address,
  clinicname,
  //   practionIdlocation,
}) {
  const [location, setLocation] = useState({ lat: null, lon: null }); ///loc name
  const [time, setTime] = useState(new Date().toLocaleTimeString()); ///loc name
  const [locationName, setLocationName] = useState("");

  const [user, setUser] = useState();
  const [providerUser, setProviderUser] = useState();
  const [accountType, setAccountType] = useState(null);
  const [userName, setUsername] = useState();
  const [logoImg, setLogoImg] = useState();
  const [loading, setLoading] = useState(true);

  let { clinicName, type } = useParams();

  useEffect(() => {
    getLocationFromIP();

    // Update the time every second
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const getLocationFromIP = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      console.log("iplocation", data);
      const newLocation = {
        lat: data.latitude,
        lon: data.longitude,
      };
      setLocation(newLocation);
      localStorage.setItem("location", JSON.stringify(newLocation));
      setLoading(false);
      //setLocationName(`${data.city}, ${data.region_code}`);
    } catch (error) {
      console.error("Error fetching IP location:", error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        {/* <header className="w-full">
          <div
            className=" pl-8 flex justify-between items-center px-4 bg-white shadow-sm"
            style={shadowBottom}
          >
            <div className="flex items-center gap-3"></div>
            <div className="flex-grow flex  justify-center">
              <Link to="/">
                <img
                  width={180}
                  height={130}
                  src={logoImage && `${apiUrl}/${logoImage}`}
                  alt="logo"
                  className="p-2"
                />
              </Link>
            </div>

            <div className="p-4">
              <div className="flex items-start space-x-2 mb-2">
                <div className="flex items-start justify-center">
                  <span>
                    <img src="/loc.png" width="44px" alt="Location Icon" />
                  </span>
                </div>
                <div>
                  <span className="text-xs text-gray-500">LOCATION</span>
                  <div className="text-lg font-semibold text-gray-900">
                    {clinicname ? clinicname : clinicname}
                  </div>
                  <div className="text-sm text-gray-700">
                    <p>{Street && Street}</p>
                    <p>{address && address}</p>

                    <p className="text-blue-600 cursor-pointer hover:underline">
                      <a href={`tel:${Phone}`}>{Phone && Phone}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header> */}
        <header className="w-full relative">
          <div
            className="pl-8 pl-8 pb-6 pt-6 flex items-center px-4 bg-white shadow-sm"
            style={shadowBottom}
          >
            <div className="lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:pl-0 pl-4">
              <Link to="/">
                <img
                  width={180}
                  height={130}
                  src={
                    (logoImage && `${imageBaseUrl}/${logoImage}`) ||
                    "/clinicPlaceholder.png"
                  }
                  alt="logo"
                  className="p-2"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/clinicPlaceholder.png";
                  }}
                />
              </Link>
            </div>
            <div className="ml-auto p-4">
              <div className="flex items-start space-x-2 mb-2">
                <div className="flex items-start justify-center">
                  <span>
                    <img src="/loc.png" width="44px" alt="Location Icon" />
                  </span>
                </div>
                <div>
                  <span className="text-xs text-gray-500">LOCATION</span>
                  <div className="text-lg font-semibold text-gray-900">
                    {clinicname ? clinicname : clinicname}
                  </div>
                  <div className="text-sm text-gray-700">
                    <p>{Street && Street}</p>
                    <p>{address && address}</p>
                    <p className="text-blue-600 cursor-pointer hover:underline">
                      <a href={`tel:${Phone}`}>{Phone && Phone}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    </>
  );
}

export default ClinicBookingHeader2;
