import React, { useState } from "react";
import PatientsLandingPageFooter from "./PatientsLandingPageFooter";
import PatientsLandingPageHeader from "./PatientsLandingPageHeader";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import { postData } from "../Networking/Api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ProviderLandingPageFooter from "./ProviderLandingPageFooter/ProviderLandingPageFooter";
import { logEvent } from "../ga";

// Replace this with your JSON data containing continents and their timezones
// import continentTimezones from './continentTimezones.json';

function ScheduleADemo() {
  const [date, setDate] = useState(new Date());
  const [viewState, setViewState] = useState("form"); // 'calendar', 'timeSlots', 'form'
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(
    "America/Los_Angeles"
  );
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",

    companyName: "",
    ehr: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate("");

  const [timeOptions] = useState([
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
  ]);

  // Use the timezones from the JSON data
  const continentTimezones = [];

  const [timezoneOptions] = useState(continentTimezones);

  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  const handleConfirm = async (event) => {
    event.preventDefault();
    console.log("Confirm button clicked");
    const isValid = await validateForm();
    if (isValid) {
      try {
        const payload = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,

          companyName: formData.companyName,
          ehr: formData.ehr,
          requestFrom: "RequestDemo",
        };

        const response = await postData(
          "novu/sendDemoEmailNotification",
          payload
        );

        Swal.fire({
          title: "Success",
          text: "Request Send successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });
        logEvent("User", "demoRequest", formData.email);
        setTimeout(() => {
          navigate(`/`);
        }, 2000);

        setFormData({
          firstName: "",
          lastName: "",
          email: "",

          companyName: "",
          ehr: "",
        });
      } catch (error) {
        console.error("Error sending data:", error);
      }

      console.log("Form Data:", formData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "firstName" || name === "lastName") {
      newValue = newValue.replace(/[^a-zA-Z]/g, "");
    }

    setFormData({
      ...formData,
      [name]: newValue,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = async () => {
    let isValid = true;
    const errors = {};
    if (!formData.firstName) {
      isValid = false;
      errors.firstName = "First Name Is Required";
    } else if (formData.firstName.length < 3) {
      isValid = false;
      errors.firstName = "First Name Must Be At Least 3 Characters Long";
    }
    if (!formData.lastName) {
      isValid = false;
      errors.lastName = "Last Name Is Required";
    } else if (formData.lastName.length < 3) {
      isValid = false;
      errors.lastName = "Last Name Must Be At Least 3 Characters Long";
    }
    if (!formData.email.trim()) {
      isValid = false;
      errors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData.email)
    ) {
      isValid = false;
      errors.email = "Invalid Email Format.";
    }
    if (!formData.companyName) {
      isValid = false;
      errors.companyName = "Company Name Is Required";
    } else if (/^\s/.test(formData.companyName)) {
      isValid = false;
      errors.companyName = "Company Name Should Not Start With A Space";
    } else if (!formData.companyName.trim()) {
      isValid = false;
      errors.companyName = "Company Name Cannot Be Only Spaces";
    }
    // if (!formData.phone) {
    //   isValid = false;
    //   errors.phone = "Phone number is required";
    // }
    if (!formData.ehr) {
      isValid = false;
      errors.ehr = "EHR or PMS Is Required";
    } else if (/^\s/.test(formData.ehr)) {
      isValid = false;
      errors.ehr = "EHR Should Not Start With A Space";
    }
    setErrors(errors);
    return isValid;
  };

  const renderTimeSlots = () => {
    const selectedDateMoment = moment(selectedDate).tz(selectedTimezone);
    const timezoneData = timezoneOptions.find((continent) =>
      continent.timezones.find((tz) => tz.name === selectedTimezone)
    );
    const timeSlots = timezoneData
      ? timezoneData.timezones.find((tz) => tz.name === selectedTimezone)
          .timeSlots
      : timeOptions;

    return (
      <div
        className="bg-gray p-3 shadow-lg"
        style={{ maxHeight: "300px", maxWidth: "500px", overflowY: "auto" }}
      ></div>
    );
  };

  const renderForm = () => {
    return (
      <div className=" p-1 shadow-lg mt-3 bg-grayColor rounded-md">
        <h2 className="flex justify-center text-xl  text-black font-bold mb-4 mt-2">
          Get A Demo
        </h2>
        <div
          className="grid grid-cols-1 gap-4 mt-4 p-4 "
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <div>
            <label className="block text-black p-1" htmlFor="firstName">
              First Name*
            </label>
            <input
              className="p-2  border border-black rounded-lg mt-2 text-black w-full"
              type="text"
              id="firstName"
              placeholder="First Name"
              onChange={handleChange}
              value={formData.firstName}
              name="firstName"
            />
            {errors.firstName && (
              <p className="text-red-500 text-xs">{errors.firstName}</p>
            )}
          </div>
          <div>
            <label className="block text-black p-1" htmlFor="lastName">
              Last Name*
            </label>
            <input
              className="p-2  border border-black rounded-lg text-black w-full mt-2"
              type="text"
              id="lastName"
              placeholder="Last Name"
              onChange={handleChange}
              value={formData.lastName}
              name="lastName"
            />
            {errors.lastName && (
              <p className="text-red-500 text-xs">{errors.lastName}</p>
            )}
          </div>
          <div>
            <label className="block text-black p-1" htmlFor="email">
              Email*
            </label>
            <input
              className="p-2  border border-black rounded-lg text-black w-full mt-2 "
              type="email"
              id="email"
              placeholder="Email"
              onChange={handleChange}
              value={formData.email}
              name="email"
            />
            {errors.email && (
              <p className="text-red-500 text-xs">{errors.email}</p>
            )}
          </div>

          <div>
            <label className="block text-black p-1" htmlFor="company">
              Company Name*
            </label>
            <input
              className="p-2  border border-black rounded-lg text-black w-full mt-2"
              type="text"
              id="company"
              placeholder="Company Name"
              onChange={handleChange}
              value={formData.companyName}
              name="companyName"
            />
            {errors.companyName && (
              <p className="text-red-500 text-xs">{errors.companyName}</p>
            )}
          </div>
          <div>
            <label className="block text-black p-1 pb-3 " htmlFor="ehr">
              EHR or PMS*
            </label>
            <input
              className="p-2  border border-black rounded-lg text-black w-full mt-2"
              type="text"
              id="ehr"
              placeholder="EHR"
              onChange={handleChange}
              value={formData.ehr}
              name="ehr"
            />
            {errors.ehr && <p className="text-red-500 text-xs">{errors.ehr}</p>}
          </div>
        </div>
        <div className="flex justify-center mt-4 p-4">
          {/* <button onClick={() => setViewState('timeSlots')} className='text-center text-black p-2 w-20 border border-black rounded-md hover:text-white bg-discColor mr-2'>Back</button> */}
          <button
            onClick={handleConfirm}
            className="text-center p-2 w-20 border border-black rounded-md bg-blue-500 hover:bg-blue-700"
          >
            Confirm
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
        <PatientsLandingPageHeader />
      </div>
      <div className="pt-16">
        <div className=" p-5 ">
          {/* <div className="flex bg-grayColor flex-row flex-wrap shadow-md rounded-md pr-6  "> */}
          <div className=" flexCol flex  bg-grayColor flex-wrap rounded-md pr-4 ">
            <div className="responsiveDiv w-1/2 p-16 pl-20 pt-20  flex-1 min-w-[200px] ">
              <h1 className="text-5xl font-bold mb-4">Schedule A Demo</h1>
              <p className="mb-4 text-xl">
                Samara Well delivers a superior
                <span className="font-bold text-xl">
                  {" "}
                  Patient Experience Platform
                </span>{" "}
                and{" "}
                <span className="font-bold text-xl">
                  Outpatient Care Co-Pilot
                </span>{" "}
                that:{" "}
                <span className="font-bold text-xl">
                  {" "}
                  Grows Your Practice, Saves Your Time{" "}
                </span>
                and
                <span className="font-bold text-xl">
                  {" "}
                  Delights Your Patients.
                </span>
              </p>
              <p className="mb-2 text-black text-xl">
                To see how it works,
                <span className="font-bold text-xl"> Schedule A Demo!</span>
              </p>
              <ul className="list-dics ml-5 mb-4 text-xl">
                <li>
                  Google Search to Care for your Patients with 1-Touch Samara
                  Scheduling
                </li>
                <li>
                  Boost Your Online Reputation with 100’s of 5-Star Google
                  Reviews
                </li>
                <li>Save Time with Zero No-Shows with Front Office Co-Pilot</li>
                <li>
                  Grow Your Practice & Patients with Samara’s Suite of Analytics
                  & Insights
                </li>
                <li>
                  Improve Your Patient Outcomes with Samara’s Healthcare
                  Adherence as a Service
                </li>
                <li>
                  Fully Interoperable with your EHR & PMS and HIPAA Compliant
                </li>
              </ul>
            </div>
            <div className="form-flex py-16 pl-20 flex-1">
              <div className="calendar-container bg-teal-900   rounded-lg shadow-lg">
                {viewState === "calendar" && (
                  <div>
                    <div className="flex justify-center items-center ">
                      <div className="rounded-full">
                        <img
                          src="/samara2.svg"
                          alt="Logo"
                          className="w-20 h-20 mr-2 rounded-full"
                        />
                      </div>
                    </div>
                    <h3 className="text-center mr-1 text-2xl p-2 text-white font-bold  mb-4">
                      Meet with Samara Well
                    </h3>
                  </div>
                )}
                {viewState === "timeSlots" && (
                  <div className="container flex bg-grayColor text-black flex-col rounded-md justify-center shadow-md text-black mt-10 p-4 ">
                    <div className="flex flex-col justify-start items-left p-3">
                      <h1 className="py-2 text-lg font-bold ">
                        Meeting duration
                      </h1>
                      <button className="border  w-80 border-black text-center bg-discColor">
                        45 mins
                      </button>
                      <h2 className="p-1 text-lg font-bold">
                        What time works best?
                      </h2>
                      <p className="p-1 text-lg ">
                        Showing times for{" "}
                        <span className="text-lg font-bold ">
                          {selectedDate.toLocaleDateString("en-US", {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })}
                        </span>
                      </p>
                      <div className="flex flex-col width-20">
                        <label className=" p-1 mb-1" htmlFor="timezone">
                          Select Timezone:
                        </label>
                        <select
                          className="text-center bg-discColor p-1 px-3 border border-black w-80 text-black"
                          id="timezone"
                          value={selectedTimezone}
                          onChange={handleTimezoneChange}
                        >
                          {timezoneOptions.map((continent, index) =>
                            continent.timezones.map((timezone, tzIndex) => (
                              <option
                                key={`${index}-${tzIndex}`}
                                value={timezone.name}
                              >
                                {timezone.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                    {renderTimeSlots()}
                    <button
                      onClick={() => setViewState("calendar")}
                      className="text-center mt-4 p-2 mr-2 w-20 border border-black rounded-md hover:text-white bg-discColor"
                    >
                      Back
                    </button>
                  </div>
                )}
                {viewState === "form" && renderForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProviderLandingPageFooter />
    </>
  );
}

export default ScheduleADemo;
