// import React from "react";
// import {
//   faFacebook,
//   faInstagram,
//   faLinkedin,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";

// function ProviderLandingPageFooter() {
//   return (
//     <footer className="w-full bg-blueColor text-white font-fontPoppins py-12">
//       <div className="max-w-7xl mx-auto px-4">
//         <div className="flex flex-col items-start space-y-8">
//           <div className="w-full flex justify-between items-start border-b border-gray-600 pb-8">
//             <div className="flex-1">
//               <h4 className="text-lg font-semibold mb-4">Products</h4>
//               <ul>
//                 <li className="mb-2">
//                   <Link
//                     to="/scheduling"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Schedulin
//                   </Link>
//                 </li>
//                 <li className="mb-2">
//                   <Link
//                     to="/reviews-outreach"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Reviews & Outreach
//                   </Link>
//                 </li>
//                 <li className="mb-2">
//                   <Link
//                     to="/insights"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Insights
//                   </Link>
//                 </li>
//                 <li className="mb-2">
//                   <Link
//                     to="/future-roadmap"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Future Roadmap
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div className="flex-1">
//               <h4 className="text-lg font-semibold mb-4">Get Help</h4>
//               <ul>
//                 <li className="mb-2">
//                   <Link
//                     to="/pricing"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Pricing
//                   </Link>
//                 </li>
//                 <li className="mb-2">
//                   <Link
//                     to="/demo"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Demo
//                   </Link>
//                 </li>
//                 <li className="mb-2">
//                   <Link
//                     to="/support"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Support
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div className="flex-1">
//               <h4 className="text-lg font-semibold mb-4">Resources</h4>
//               <ul>
//                 <li className="mb-2">
//                   <Link
//                     to="/faq"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     FAQ
//                   </Link>
//                 </li>
//                 <li className="mb-2">
//                   <Link
//                     to="/pricing"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Pricing
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//             <div className="flex-1">
//               <h4 className="text-lg font-semibold mb-4">Connect</h4>
//               <ul>
//                 <li className="mb-2">
//                   <Link
//                     to="/contactus"
//                     className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                   >
//                     Contact Us
//                   </Link>
//                 </li>
//                 <li className="flex gap-4 mt-4">
//                   <a
//                     href="https://facebook.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <FontAwesomeIcon
//                       icon={faFacebook}
//                       className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                     />
//                   </a>
//                   <a
//                     href="https://twitter.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <FontAwesomeIcon
//                       icon={faTwitter}
//                       className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                     />
//                   </a>
//                   <a
//                     href="https://instagram.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <FontAwesomeIcon
//                       icon={faInstagram}
//                       className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                     />
//                   </a>
//                   <a
//                     href="https://linkedin.com"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     <FontAwesomeIcon
//                       icon={faLinkedin}
//                       className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
//                     />
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="w-full flex justify-between items-center pt-8">
//             <p>&copy; SamaraWell. All rights reserved 2024</p>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default ProviderLandingPageFooter;

import React, { useEffect, useState } from "react";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import hippaLogo from "./hippaLogo.png";
import { postData } from "../../Networking/Api";
// import hippaLogo from "./hippaLogo.png";

function ProviderLandingPageFooter() {
  const [servicesData, setServicesData] = useState([]);

  const navigate = useNavigate();

  const handleNavigation = (serviceId, tab) => {
    navigate(`/Services/${serviceId}/${tab}`);
  };

  useEffect(() => {
    const fetchservicesData = async () => {
      try {
        const payload = {
          modelName: "services",
          pagination: {
            page: 1,
            pageSize: 1000,
          },
          relations: [{ module: "services_details" }],
        };
        const response = await postData("masters/getMasterList", payload);
        if (response.code === 1 && response.status === 200) {
          setServicesData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchservicesData();
  }, []);

  const filteredServices = servicesData.filter((service) => {
    const { services_details, is_active } = service;
    const detailsLength = services_details.length;

    if (is_active === 0) {
      return false;
    }

    if (detailsLength === 0) {
      return false;
    }

    if (detailsLength === 1) {
      return services_details[0].is_active === 1;
    }

    if (detailsLength >= 2) {
      const activeCount = services_details.filter(
        (detail) => detail.is_active === 1
      ).length;
      return activeCount > 0;
    }

    return false;
  });

  return (
    <footer className="w-full bg-blueColor text-white font-fontPoppins pt-12 pb-2">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col items-start">
          <div className="pt-4 w-full flex flex-wrap justify-between items-start border-b border-white-600 pb-5">
            <div className="flex-1 min-w-[200px] mb-4">
              <h4 className="text-lg font-semibold mb-4">PRODUCTS</h4>
              <ul>
                {filteredServices.map((service) => (
                  <li className="mb-2">
                    <div
                      onClick={() => handleNavigation(service.id, service.name)}
                      className="hover:text-paleTurquoise active:text-steelBlue transition duration-300 cursor-pointer"
                    >
                      {service.name}
                    </div>
                  </li>
                ))}
                {/* <li className="mb-2">
                  <div
                    onClick={() => handleNavigation(2, "Review & Outreach")}
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300 cursor-pointer"
                  >
                    Reviews & Outreach
                  </div>
                </li>
                <li className="mb-2">
                  <div
                    onClick={() => handleNavigation(3, "Insights")}
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300 cursor-pointer"
                  >
                    Insights
                  </div>
                </li>
                <li className="mb-2">
                  <div
                    onClick={() => handleNavigation(4, "Future-Roadmap")}
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300 cursor-pointer"
                  >
                    Future Roadmap
                  </div>
                </li> */}
              </ul>
            </div>
            <div className="flex-1 min-w-[200px] mb-4">
              <h4 className="text-lg font-semibold mb-4">GET HELP</h4>
              <ul>
                <li className="mb-2">
                  <Link
                    to="/getpricing"
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                  >
                    Pricing
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/scheduleADemo"
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                  >
                    Demo
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/contactus"
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                  >
                    Support
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex-1 min-w-[200px] mb-4">
              <h4 className="text-lg font-semibold mb-4">RESOURCES</h4>
              <ul>
                <li className="mb-2">
                  <Link
                    to="/faq"
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                  >
                    FAQ
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/getpricing"
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex-1 min-w-[200px] mb-4">
              <h4 className="text-lg font-semibold mb-4">CONNECT</h4>
              <ul>
                <li className="mb-2">
                  <Link
                    to="/contactus"
                    className="hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="flex gap-4 mt-4">
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                    />
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                    />
                  </a>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                    />
                  </a>
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="text-2xl hover:text-paleTurquoise active:text-steelBlue transition duration-300"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-1 min-w-[200px] mb-4">
              <div className="">
                <img src={hippaLogo} alt="Logo" className="w-32 mb-4" />
              </div>
            </div>
          </div>
          <div className="w-full  flex justify-between items-center pt-8">
            <div className="">
              <p>&copy; SamaraWell. All rights reserved 2024</p>
            </div>
            <nav className="ms-auto xss:text-center sm:text-center">
              {/* <span
                href={" "}
                className="font-fontPoppins text-xs text-gray-500 hover:text-gray-700"
              >
                HIPAA Compliant Digital Health
              </span>

              <span className="font-fontPoppins text-xs text-gray-500">
                {" "}
                |{" "}
              </span>
              <span
                href={" "}
                className="font-fontPoppins text-xs text-gray-500 hover:text-gray-700"
              >
                Privacy Policy
              </span>
              <span className="font-fontPoppins text-xs text-gray-500">
                {" "}
                |{" "}
              </span>
              <span
                href={" "}
                className="font-fontPoppins text-xs text-gray-500 hover:text-gray-700"
              >
                Terms Of Service
              </span> */}
              <div>
                HIPAA Compliant Digital Health | Privacy Policy | Terms Of
                Service
              </div>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

// export default PatientsLandingPageFooter;

export default ProviderLandingPageFooter;
