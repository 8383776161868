import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
// import {
//   loginPatient,
//   logout,
//   logoutPatient,
//   logoutProvider,
//   selectPatient,
//   selectProvider,
//   selectUser,
// } from "../redux/actions/userSlice";
import { useEffect } from "react";
// import { postData } from "../Networking/Api";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { apiUrl, postData, imageBaseUrl } from "../../Networking/Api";

const shadowBottom = {
  boxShadow: "0 6px 6px -6px #ccc",
};

// const paddingNav = {
//   padding: "16px",
// };

// const ActionTypes = {
//   SHOW: "SHOW",
//   HIDE: "HIDE",
// };

// const reducer = (state, action) => {
//   switch (action.type) {
//     case ActionTypes.SHOW:
//       return {
//         ...state,
//         [action.itemType]: { ...state[action.itemType], isVisible: true },
//       };

//     case ActionTypes.HIDE:
//       return {
//         ...state,
//         [action.itemType]: { ...state[action.itemType], isVisible: false },
//       };
//     default:
//       return state;
//   }
// };

function ThirdPartyHeader({ logoImage }) {
  //console.log("logoImage",logoImage);
  //   const [state, dispatch] = useReducer(reducer, {
  //     type1: { isVisible: false },
  //     type2: { isVisible: false },
  //     type3: { isVisible: false },
  //     type4: { isVisible: false },
  //   });
  const [location, setLocation] = useState({ lat: null, lon: null }); ///loc name
  const [time, setTime] = useState(new Date().toLocaleTimeString());///loc name
  const [locationName, setLocationName] = useState('');
  const [user, setUser] = useState();
  const [providerUser, setProviderUser] = useState();
  const [accountType, setAccountType] = useState(null);
  const [userName, setUsername] = useState();
  const [logoImg, setLogoImg] = useState();
  // const userData = useSelector(selectUser);
  // console.log("User..", userData);
  //   const patientData = useSelector(selectPatient);
  //   const providerData = useSelector(selectProvider);
  //   console.log("Patient User:", patientData);
  //   console.log("Provider User:", providerData);
  //   const Dispatch = useDispatch();
  //   const navigate = useNavigate();
  // useEffect(() => {
  //   setUser(userData.isLoggedIn);
  // }, [userData]);
  //   useEffect(() => {  
  //     const Username = localStorage.getItem("userName");    
  //     setUsername(Username);                             
  //     const storedAccountType = localStorage.getItem("accountType");
  //     setAccountType(storedAccountType);
  //     console.log("storedAccountTYpe",storedAccountType);
  //   }, []);

  //   useEffect(() => {
  //     setUser(patientData);
  //   }, [patientData]);

  //   useEffect(() => {
  //     setProviderUser(providerData);
  //   }, [providerData]);


  let { type, id } = useParams();

  useEffect(() => {
    let isMounted = true;

    const fetchOptions = async () => {
      try {
        const response = await postData("patients/getAppoinmentTypeDataNew", {
          type: type,
          id: id
        });
        if (isMounted) {
          const data = response.data;
          console.log('data.clinicData', data.clinicData[0].logo_image);
          setLogoImg(data.clinicData[0].logo_image);
        }
      } catch (error) {
        if (isMounted) {
          console.log('Error fetching data:', error);
        }
      }
    };

    fetchOptions();

    return () => {
      isMounted = false;
    };
  }, [type, id]);



  useEffect(() => {
    getLocationFromIP();

    // Update the time every second
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const getLocationFromIP = async () => {
    try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      console.log("iplocation", data);
      const newLocation = {
        lat: data.latitude,
        lon: data.longitude,
      };
      setLocation(newLocation);
      localStorage.setItem('location', JSON.stringify(newLocation));
      setLocationName(`${data.city}, ${data.region_code}`);
    } catch (error) {
      console.error('Error fetching IP location:', error);
    }
  };


  return (
    <header className="w-full">
      <div
        className=" pl-8 flex justify-between items-center px-4 bg-white shadow-sm"
        style={shadowBottom}
      >
        <div className="flex items-center gap-3">
          {/* 
        <span
          className="cursor-pointer"
          onClick={() =>
            dispatch({ type: ActionTypes.SHOW, itemType: "type2" })
          }
        >
          <i class="fa-solid fa-bars fa-lg"></i>
        </span>
        */}
        </div>
        <div className="flex-grow flex  justify-center">
          <Link to="/">
            <img width={180} height={130}
              src={logoImg ? `${imageBaseUrl}/${logoImg}` : `${imageBaseUrl}/${logoImage}`}
              alt="logo"
              className="p-2"
            />
          </Link>
        </div>
        <div>
          <span className="w-48 h-9 px-2  flex justify-center bg-gray-100 rounded-full items-center text-xs leading-normal">
            <img src="/loc.png" width="24px" alt="Location Icon" />
            {locationName}
          </span>
        </div>
      </div>
    </header>

  );
}

export default ThirdPartyHeader;
