import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, loginProvider } from "../redux/actions/userSlice";
import { postData } from "../Networking/Api";
import { logEvent } from "../ga";
import Loader2 from "./Loader2";
function LogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [apiError, setApiError] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    setApiError("");
  };

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe); // Toggle the checkbox state
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    // Email validation
    if (!formData.email.trim()) {
      isValid = false;
      errors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      isValid = false;
      errors.email = "Invalid email format.";
    }

    // Password validation
    if (!formData.password.trim()) {
      isValid = false;
      errors.password = "Password is required.";
    }
    //else if (
    //   !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
    //     formData.password,
    //   )
    // ) {
    //   isValid = false;
    //   errors.password =
    //     'Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.';
    // }

    setErrors(errors);
    return isValid;
  };
  // const responseMessage=(response)=>{
  //   console.log(response);
  //   const decodedToken = jwtDecode(response.credential);
  //   console.log("UserDetails",decodedToken);
  //   navigate('/');
  // }
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("callss");
    if (validateForm()) {
      console.log("Form Data", formData);
      // Proceed with form submission or API call
      try {
        setLoading(true);
        const payload = {
          username: formData.email,
          password: formData.password,
        };
        const response = await postData("auth/userMasterLogin", payload);

        const { accesstoken, data } = response;
        localStorage.setItem("accessToken", data.accesstoken);
        // console.log(data.accesstoken)

        if (data && data.id) {
          //add for patient related data clear when providers login
          localStorage.removeItem("google_access_token");
          localStorage.removeItem("userCreateInfo");
          localStorage.removeItem("accountType");
          localStorage.removeItem("userName");
          localStorage.removeItem("patientLog");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("location");
          localStorage.removeItem("currentTimeZone");
          if (data.type === "Clinic") {
            localStorage.setItem("id", data.id);
            localStorage.setItem("clinicId", data.refernce_id);
          } else if (data.type === "Practitioners") {
            const payload = {
              modelName: "practitioners",
              whereCondition: {
                id: data.refernce_id,
              },
            };
            const PractitionersResponse = await postData(
              "masters/getMasterList",
              payload
            );

            localStorage.setItem(
              "clinicId",
              PractitionersResponse.data[0].clinic_id
            );
          } else if (data.type === "Assistances") {
            const payload = {
              modelName: "assistances",
              whereCondition: {
                id: data.refernce_id,
              },
            };
            const AssistancesResponse = await postData(
              "masters/getMasterList",
              payload
            );

            localStorage.setItem(
              "clinicId",
              AssistancesResponse.data[0].clinic_id
            );
          }
          setLoading(false);
          localStorage.setItem("accountType", data.type);
          localStorage.setItem("userMasterId", data.id);
          localStorage.setItem("refernceId", data.refernce_id);
          if (data.data && data.data.clinic_id) {
            localStorage.setItem("clinicIdProAssist", data.data.clinic_id);
          } else {
            localStorage.setItem("clinicIdProAssist", "");
          }
          // const ClinicName = data.data.name;
          // localStorage.setItem('userName',ClinicName);
          // const UserName = data.data.f_name + " " + data.data.l_name;
          const UserName =
            data.type === "Clinic"
              ? data.data.name
              : `${data.data.f_name} ${data.data.l_name}`;
          localStorage.setItem("userName", UserName);
          let ProfilePic;
          if (data.type === "Clinic") {
            ProfilePic = data.data.logo_image;
          } else if (data.type === "Practitioners") {
            ProfilePic = data.data.photo;
          } else if (data.type === "Assistances") {
            ProfilePic = data.data.assistant_profile_photo;
          }

          localStorage.setItem("profilePic", ProfilePic);
          navigate("/dashboard");
          dispatch(
            loginProvider({
              // user : payload,
              isLoggedIn: true,
            })
          );
          logEvent("User", "Login", formData.email);
        }
        if (response.code == 0) {
          setLoading(false);
          setApiError(response.message);
        }
      } catch (error) {
        if (error && error.response && error.response.status == 500) {
          setLoading(false);
          console.log("Invalid credentials. Please try again.");
        } else {
          console.error("Error posting data to API", error);
          setLoading(false);
        }
      }

      // navigate('/');
      // dispatch(login({
      //   // user : payload,
      //   isLoggedIn : true
      // }))
    } else {
      console.log("Validation Failed");
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate("/ForgotPassword");
  };
  return (
    <section className="w-full">
      <>{loading && <Loader2 />}</>
      <div
        className="w-full h-dvh bg-red-300 bg-no-repeat bg-left bg-cover bg-scroll md:flex md:items-center md:gap-4 px-4 py-4"
        style={{ backgroundImage: "url(./login-bg.jpg)" }}
      >
        <div className="w-2/5 xss:w-full"></div>
        <div className="md:w-3/5 w-full   min-h-80 bg-gray-100 rounded-lg shadow-lg px-4 py-6 mt-20 md:mt-0 ">
          <div className="flex flex-direction-row justify-center gap-3">
            <Link to="/">
              <img
                src={"./samara-logo.png"}
                width="300px"
                alt="logo"
                className="max-w-full"
              />
            </Link>

            <h1 className="font-fontPoppins text-lg font-semibold text-center">
              {/* Log In */}
            </h1>
          </div>

          <div className="w-full mt-4">
            <label className="w-full font-fontPoppins text-sm font-medium mb-1 ml-2">
              Email
            </label>
            <input
              className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
              type="text"
              name="email"
              value={formData.email}
              placeholder="Enter your Username or Email"
              onChange={handleChange}
            />
            {errors.email && (
              <p className="text-red-500 text-md">{errors.email}</p>
            )}
          </div>

          <div className="w-full mt-4">
            <label className="w-full font-fontPoppins text-sm font-medium mb-1 ml-2">
              Password
            </label>
            <input
              className="w-full h-11 font-fontPoppins text-black-100 text-xs px-3 rounded-full shadow-sm focus:outline-none focus:ring-1 focus:ring-blueColor"
              type="password"
              name="password"
              value={formData.password}
              placeholder="Enter Password"
              onChange={handleChange}
            />
            {errors.password && (
              <p className="text-red-500 text-md">{errors.password}</p>
            )}
          </div>

          <div className="w-full flex items-center justify-between mt-4">
            <div>
              <input
                type="checkbox"
                checked={rememberMe}
                // checked
                onChange={handleCheckboxChange}
              />{" "}
              <span className="font-fontPoppins text-sm font-medium">
                Remember me
              </span>
            </div>
            <div
              className="font-fontPoppins text-sm font-medium text-right cursor-pointer hover:text-blueColor"
              onClick={handleForgotPassword}
            >
              Forgot Password
            </div>
          </div>

          <div className="w-full text-center mt-4">
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-24 py-2 bg-blueColor rounded-full font-fontPoppins text-xs font-medium text-white hover:bg-opacity-80"
            >
              Log in <i class="fa-solid fa-arrow-right-long ms-1"></i>
            </button>
          </div>
          {apiError && (
            <div className="w-full text-center mt-4">
              <p className="text-red-500 text-md">{apiError}</p>
            </div>
          )}
          <div className="w-full text-center flex justify-center pt-2">
            {/* <GoogleLogin onSuccess={responseMessage} size='medium' text="signin_with" shape='pill'></GoogleLogin> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default LogIn;
