// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Footer from "../Footer";
// import Header from "../Header";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { useEffect, useState } from "react";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import StepsForRegistration from "../StepsForRegistration";
// import { apiUrl, postData } from "../../Networking/Api";
// import Swal from "sweetalert2";
// import CustomSelectHealth from "../CustomSelectHealth";
// import CustomSelect from "../CustomSelect";
// import Loader from "../Loader";
// import Loader2 from "../Loader2";
// import swal from "sweetalert";

// const ItemTypes = {
//   PROVIDER: "provider",
// };

// const timeOptions = [
//   { time: "12:00", label: "12:00" },
//   { time: "12:15", label: "12:15" },
//   { time: "12:30", label: "12:30" },
//   { time: "12:45", label: "12:45" },
//   { time: "1:00", label: "1:00" },
//   { time: "1:15", label: "1:15" },
//   { time: "1:30", label: "1:30" },
//   { time: "1:45", label: "1:45" },
//   { time: "2:00", label: "2:00" },
//   { time: "2:15", label: "2:15" },
//   { time: "2:30", label: "2:30" },
//   { time: "2:45", label: "2:45" },
//   { time: "3:00", label: "3:00" },
//   { time: "3:15", label: "3:15" },
//   { time: "3:30", label: "3:30" },
//   { time: "3:45", label: "3:45" },
//   { time: "4:00", label: "4:00" },
//   { time: "4:15", label: "4:15" },
//   { time: "4:30", label: "4:30" },
//   { time: "4:45", label: "4:45" },
//   { time: "5:00", label: "5:00" },
//   { time: "5:15", label: "5:15" },
//   { time: "5:30", label: "5:30" },
//   { time: "5:45", label: "5:45" },
//   { time: "6:00", label: "6:00" },
//   { time: "6:15", label: "6:15" },
//   { time: "6:30", label: "6:30" },
//   { time: "6:45", label: "6:45" },
//   { time: "7:00", label: "7:00" },
//   { time: "7:15", label: "7:15" },
//   { time: "7:30", label: "7:30" },
//   { time: "7:45", label: "7:45" },
//   { time: "8:00", label: "8:00" },
//   { time: "8:15", label: "8:15" },
//   { time: "8:30", label: "8:30" },
//   { time: "8:45", label: "8:45" },
//   { time: "9:00", label: "9:00" },
//   { time: "9:15", label: "9:15" },
//   { time: "9:30", label: "9:30" },
//   { time: "9:45", label: "9:45" },
//   { time: "10:00", label: "10:00" },
//   { time: "10:15", label: "10:15" },
//   { time: "10:30", label: "10:30" },
//   { time: "10:45", label: "10:45" },
//   { time: "11:00", label: "11:00" },
//   { time: "11:15", label: "11:15" },
//   { time: "11:30", label: "11:30" },
//   { time: "11:45", label: "11:45" },
// ];

// const weekDays = [
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
//   "Sunday",
// ];
// const Provider = ({
//   name,
//   src,
//   practitioner_id,
//   setIsSlotModelOpen,
//   isSlotModalOpen,
//   providerTimeslots,
//   setProviderTimeSlots,
//   isActiveStatus,
// }) => {
//   const [{ isDragging }, drag] = useDrag(() => ({
//     type: ItemTypes.PROVIDER,
//     item: { name, src, practitioner_id },
//     collect: (monitor) => ({
//       isDragging: monitor.isDragging(),
//     }),
//   }));

//   const [localTimeslots, setLocalTimeslots] = useState({});

//   const [localCheckedDays, setLocalCheckedDays] = useState();
//   const [checkedDays, setCheckedDays] = useState();
//   const [timeslots, setTimeslots] = useState({});
//   const [saveError, setSaveError] = useState(false);
//   const [isActive, setIsActive] = useState(isActiveStatus === 0 ? false : true);

//   const [selectedProvider, setSelectedProvider] = useState("");
//   const [error, setError] = useState(false);
//   const [timeslotError, setTimeSlotError] = useState("");
//   useEffect(() => {
//     console.log("localtime", localTimeslots, timeslots);
//   }, [localTimeslots]);

//   useEffect(() => {
//     if (practitioner_id) {
//       const filteredTimeslots = {};
//       for (const [day, slots] of Object.entries(timeslots)) {
//         if (slots.length > 0) {
//           filteredTimeslots[day] = slots;
//         }
//       }

//       setProviderTimeSlots((prev) => ({
//         ...prev,
//         [practitioner_id]: {
//           timeslots: filteredTimeslots,
//         },
//       }));
//     }
//   }, [timeslots, practitioner_id, setProviderTimeSlots, setIsActive]);
//   useEffect(() => {
//     const fetchWorkingHours = async () => {
//       try {
//         const payload = {
//           modelName: "practitioner_working_hours",
//           pagination: {
//             page: 1,
//             pageSize: 500,
//           },
//           whereCondition: {
//             practitioner_id: practitioner_id,
//           },
//         };
//         const response = await postData("masters/getMasterList", payload);

//         // const convertTo12HourFormat = (time) => {
//         //   if (!time) return null;
//         //   let [hours, minutes, seconds] = time.split(":");
//         //   hours = parseInt(hours);
//         //   let ampm = hours >= 12 ? "PM" : "AM";
//         //   hours = hours % 12;
//         //   hours = hours ? hours : 12;
//         //   return { time: `${hours}:${minutes}`, period: ampm };
//         // };

//         // const convertToDesiredFormat = (data) => {
//         //   const result = {};

//         //   data.forEach((slot) => {
//         //     if (
//         //       slot.is_available === 1 &&
//         //       slot.is_active === 1 &&
//         //       slot.from_time &&
//         //       slot.to_time
//         //     ) {
//         //       const fromTime = convertTo12HourFormat(slot.from_time);
//         //       const toTime = convertTo12HourFormat(slot.to_time);

//         //       if (!result[slot.day]) {
//         //         result[slot.day] = [];
//         //       }

//         //       result[slot.day].push({
//         //         startTime: fromTime.time,
//         //         endTime: toTime.time,
//         //         ampmStart: fromTime.period,
//         //         ampmEnd: toTime.period,
//         //       });
//         //     }
//         //   });

//         //   return result;
//         // };

//         const convertTo12HourFormat = (time) => {
//           if (!time) return null;
//           let [hours, minutes] = time.split(":");
//           hours = parseInt(hours, 10);
//           let ampm = hours >= 12 ? "PM" : "AM";
//           hours = hours % 12;
//           hours = hours ? hours : 12; // the hour '0' should be '12'
//           console.log("efiefh", {
//             time: `${hours}:${minutes.padStart(2, "0")}`,
//             period: ampm,
//           });
//           return { time: `${hours}:${minutes.padStart(2, "0")}`, period: ampm };
//         };

//         const convertToDesiredFormat = (data) => {
//           const result = {};

//           data.forEach((slot) => {
//             if (
//               slot.is_available === 1 &&
//               slot.is_active === 1 &&
//               slot.from_time &&
//               slot.to_time
//             ) {
//               const fromTime = convertTo12HourFormat(slot.from_time);
//               const toTime = convertTo12HourFormat(slot.to_time);

//               if (!result[slot.day]) {
//                 result[slot.day] = [];
//               }

//               result[slot.day].push({
//                 startTime: fromTime.time,
//                 endTime: toTime.time,
//                 ampmStart: fromTime.period,
//                 ampmEnd: toTime.period,
//               });
//             }
//           });

//           return result;
//         };

//         setLocalTimeslots(convertToDesiredFormat(response.data));

//         setTimeslots(convertToDesiredFormat(response.data));
//         setLocalCheckedDays(
//           weekDays.map(
//             (day) => !!convertToDesiredFormat(response.data)[day]?.length
//           )
//         );
//         setCheckedDays(
//           weekDays.map(
//             (day) => !!convertToDesiredFormat(response.data)[day]?.length
//           )
//         );
//       } catch (error) {
//         console.error("Error fetching services:", error);
//       }
//     };

//     fetchWorkingHours();
//   }, []);

//   const handleCheckboxChange = (index) => {
//     setLocalTimeslots((prev) => {
//       return {
//         ...prev,
//         [day]: [{ startTime: "", endTime: "", ampmStart: "", ampmEnd: "" }],
//       };
//     });
//     const day = weekDays[index];
//     const newCheckedDays = [...localCheckedDays];
//     newCheckedDays[index] = !newCheckedDays[index];

//     setLocalTimeslots((prev) => ({
//       ...prev,
//       [day]: newCheckedDays[index] ? prev[day] || [] : [],
//     }));

//     setLocalCheckedDays(newCheckedDays);
//   };
//   const toMinutes = (time, ampm) => {
//     const [hours, minutes] = time.split(":").map(Number);
//     let hour24 = hours;
//     if (ampm === "PM" && hours < 12) hour24 += 12;
//     if (ampm === "AM" && hours === 12) hour24 = 0;
//     return hour24 * 60 + minutes;
//   };

//   const doesOverlap = (newSlot, existingSlots) => {
//     const newStart = toMinutes(newSlot.startTime, newSlot.ampmStart);
//     const newEnd = toMinutes(newSlot.endTime, newSlot.ampmEnd);

//     for (const slot of existingSlots) {
//       const existingStart = toMinutes(slot.startTime, slot.ampmStart);
//       const existingEnd = toMinutes(slot.endTime, slot.ampmEnd);

//       const adjustedNewEnd = newStart > newEnd ? newEnd + 24 * 60 : newEnd;
//       const adjustedExistingEnd =
//         existingStart > existingEnd ? existingEnd + 24 * 60 : existingEnd;

//       if (newStart < adjustedExistingEnd && adjustedNewEnd > existingStart) {
//         return true;
//       }
//     }
//     return false;
//   };
//   const addTimeslot = (day) => {
//     setLocalTimeslots((prev) => {
//       const daySlots = prev[day] || [];
//       const lastSlot = daySlots[daySlots.length - 1];

//       const hasIncompleteSlot = daySlots.some(
//         (slot) =>
//           !slot.startTime || !slot.endTime || !slot.ampmStart || !slot.ampmEnd
//       );

//       if (hasIncompleteSlot) {
//         setError(
//           "Please Fill Out All Existing Timeslots Before Adding A New One."
//         );
//         setTimeout(() => setError(""), 4000);
//         return prev;
//       }

//       const previousSlots = daySlots.slice(0, -1);

//       if (
//         previousSlots.length > 0 &&
//         lastSlot &&
//         doesOverlap(lastSlot, previousSlots)
//       ) {
//         setError(
//           "The New Timeslot Overlaps With An Existing Timeslot. Please Adjust The Timeslot."
//         );
//         setTimeout(() => setError(""), 4000);
//         return prev;
//       }

//       const convertTimeToMinutes = (time, period) => {
//         let [hour, minute] = time.split(":").map(Number);

//         if (period.toUpperCase() === "PM" && hour !== 12) {
//           hour += 12;
//         } else if (period.toUpperCase() === "AM" && hour === 12) {
//           hour = 0;
//         }

//         return hour * 60 + minute;
//       };
//       const cutoffTimeStart = convertTimeToMinutes("6:00", "AM");
//       const cutoffTimeEnd = convertTimeToMinutes("8:00", "PM");
//       const isValidSlot = (slot) => {
//         const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
//         const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

//         return startTime >= cutoffTimeStart && endTime <= cutoffTimeEnd;
//       };

//       if (lastSlot && !isValidSlot(lastSlot)) {
//         setError(
//           "Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot."
//         );
//         setTimeout(() => setError(""), 4000);
//         return prev;
//       }
//       const isOvernightSlot = (slot) => {
//         // if (!slot || typeof slot !== "object") return false;

//         const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
//         const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

//         // If end time is before start time, it's an overnight slot
//         if (startTime === endTime) {
//           return true;
//         }

//         if (endTime < startTime) {
//           return true;
//         }

//         return false;
//       };
//       if (lastSlot && isOvernightSlot(lastSlot)) {
//         setError("Invalid Timeslot, Please Select A valid Timeslot.");
//         setTimeout(() => setError(""), 4000);
//         return prev;
//       }

//       return {
//         ...prev,
//         [day]: [
//           ...daySlots,
//           { startTime: "", endTime: "", ampmStart: "", ampmEnd: "" },
//         ],
//       };
//     });
//   };

//   const saveTimeSlot = (email) => {
//     const selectedDays = weekDays.filter((_, index) => localCheckedDays[index]);

//     const hasOverlap = (slots) => {
//       return slots.some((slot, index) =>
//         doesOverlap(
//           slot,
//           slots.filter((_, i) => i !== index)
//         )
//       );
//     };

//     const hasInvalidTimeslot = (slots) => {
//       return slots.some((slot) => slot.startTime === slot.endTime);
//     };

//     function convertTimeToMinutes(time, period) {
//       let [hour, minute] = time.split(":").map(Number);

//       if (period.toUpperCase() === "PM" && hour !== 12) {
//         hour += 12;
//       } else if (period.toUpperCase() === "AM" && hour === 12) {
//         hour = 0;
//       }

//       return hour * 60 + minute;
//     }

//     const hasSameSlot = (slots) => {
//       return slots.some((slot) => {
//         const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
//         const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

//         if (startTime === endTime) {
//           return true;
//         }

//         return false;
//       });
//     };
//     const cutoffTime = convertTimeToMinutes("8:00", "PM");
//     const hasTimeslotAfterCutoff = (slots) => {
//       return slots.some((slot) => {
//         const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);
//         return endTime > cutoffTime;
//       });
//     };

//     const timeslotAfterCutoffDays = selectedDays.filter(
//       (day) =>
//         localTimeslots[day] && hasTimeslotAfterCutoff(localTimeslots[day])
//     );
//     const cutoffTimeStart = convertTimeToMinutes("6:00", "AM");
//     const hasTimeslotBeforeCutoff = (slots) => {
//       return slots.some((slot) => {
//         const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
//         return startTime < cutoffTimeStart;
//       });
//     };

//     const timeslotBeforeCutoffDays = selectedDays.filter(
//       (day) =>
//         localTimeslots[day] && hasTimeslotBeforeCutoff(localTimeslots[day])
//     );
//     const hasOvernightSlot = (slots) => {
//       return slots.some((slot) => {
//         const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
//         const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

//         // if (startTime === endTime) {
//         //   return true;
//         // }

//         if (endTime < startTime) {
//           return true;
//         }

//         return false;
//       });
//     };

//     const emptyDays = selectedDays.filter(
//       (day) => !localTimeslots[day] || localTimeslots[day].length === 0
//     );

//     const overlappingDays = selectedDays.filter(
//       (day) => localTimeslots[day] && hasOverlap(localTimeslots[day])
//     );

//     const invalidTimeslotDays = selectedDays.filter(
//       (day) => localTimeslots[day] && hasInvalidTimeslot(localTimeslots[day])
//     );

//     const overnightSlotDays = selectedDays.filter(
//       (day) => localTimeslots[day] && hasOvernightSlot(localTimeslots[day])
//     );

//     const sameSlots = selectedDays.filter(
//       (day) => localTimeslots[day] && hasSameSlot(localTimeslots[day])
//     );

//     const incompleteDays = selectedDays.filter(
//       (day) => localTimeslots[day] && !isLastSlotFilled(localTimeslots[day])
//     );

//     if (emptyDays.length > 0) {
//       setError(`Please Select Timeslots For: ${emptyDays.join(", ")}`);
//       setTimeout(() => setError(""), 4000);
//     } else if (timeslotAfterCutoffDays.length > 0) {
//       setError(
//         `Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot In: ${timeslotAfterCutoffDays.join(
//           ", "
//         )}`
//       );
//       setTimeout(() => setError(""), 4000);
//     } else if (timeslotBeforeCutoffDays.length > 0) {
//       setError(
//         `Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot In: ${timeslotBeforeCutoffDays.join(
//           ", "
//         )}`
//       );
//       setTimeout(() => setError(""), 4000);
//     } else if (overlappingDays.length > 0) {
//       setError(
//         `There Are Overlapping Timeslots For: ${overlappingDays.join(", ")}`
//       );
//       setTimeout(() => setError(""), 4000);
//     } else if (incompleteDays.length > 0) {
//       setError("Please Fill Out The Timeslots Before Saving.");
//       setTimeout(() => setError(""), 4000);
//     }
//     // else if (invalidTimeslotDays.length > 0) {
//     //   setError(
//     //     `Invalid Timeslots Found In: ${invalidTimeslotDays.join(
//     //       ", "
//     //     )} (Start Time Cannot Be The Same As End Time)`
//     //   );
//     //   setTimeout(() => setError(""), 4000);
//     // }
//     else if (sameSlots.length > 0) {
//       setError(
//         `Start Time And End Time Cannot Be Same In: ${sameSlots.join(", ")}`
//       );
//       setTimeout(() => setError(""), 4000);
//     } else if (overnightSlotDays.length > 0) {
//       setError(
//         `Overnight Timeslots Found In: ${overnightSlotDays.join(
//           ", "
//         )} (Start Time Cannot Be greater Than End Time)`
//       );
//       setTimeout(() => setError(""), 4000);
//     } else {
//       setSelectedProvider("");
//       setIsSlotModelOpen(false);

//       const filteredTimeslots = {};
//       setTimeslots(localTimeslots);
//       setCheckedDays(localCheckedDays);
//       for (const [day, slots] of Object.entries(timeslots)) {
//         if (slots.length > 0) {
//           filteredTimeslots[day] = slots;
//         }
//       }

//       // setProviderTimeSlots((prev) => ({
//       //   ...prev,
//       //   [email]: {
//       //     timeslots: filteredTimeslots,
//       //   },
//       // }));
//     }
//   };

//   const handleDeleteSlot = (day, idx) => {
//     setLocalTimeslots((prev) => {
//       const updatedDaySlots = prev[day].filter((_, i) => {
//         return i !== idx;
//       });

//       return {
//         ...prev,
//         [day]: updatedDaySlots,
//       };
//     });
//   };

//   const isLastSlotFilled = (slots) => {
//     return slots.every(
//       (slot) => slot.startTime && slot.endTime && slot.ampmStart && slot.ampmEnd
//     );
//   };

//   const handleSlotModal = (email) => {
//     setSelectedProvider(email);
//     setIsSlotModelOpen(true);
//     console.log("timeslots", timeslots);
//     setLocalTimeslots(timeslots);
//     console.log("local", localTimeslots);
//     setLocalCheckedDays(checkedDays);
//   };

//   const handleSelectChange = (day, idx, field, value) => {
//     setLocalTimeslots((prev) => ({
//       ...prev,
//       [day]: prev[day].map((slot, i) =>
//         i === idx ? { ...slot, [field]: value } : slot
//       ),
//     }));
//   };
//   // const toggleStatus = async () => {
//   //   const newIsActive = !isActive;
//   //   setIsActive(newIsActive);

//   //   try {
//   //     const payload = {
//   //       modelName: "practitioners",

//   //       id: practitioner_id,

//   //       inputData: {
//   //         is_active_today: newIsActive ? 1 : 0,
//   //       },
//   //     };

//   //     const response = await postData("masters/createAndUpdateMaster", payload);
//   //   } catch (error) {
//   //     console.error("Error updating active status:", error);
//   //   }
//   // };

//   const toggleStatus = async () => {
//     const newIsActive = !isActive;

//     if (!newIsActive) {
//       const result = await Swal.fire({
//         title: "Are You Sure?",
//         text: "Only Providers Who Are 'Active' Will Be Visible For Scheduling.",
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, Change It!",
//         cancelButtonText: "No, Cancel!",
//       });

//       if (!result.isConfirmed) {
//         return;
//       }
//     }

//     setIsActive(newIsActive);

//     try {
//       const payload = {
//         modelName: "practitioners",
//         id: practitioner_id,
//         inputData: {
//           is_active_today: newIsActive ? 1 : 0,
//         },
//       };

//       const response = await postData("masters/createAndUpdateMaster", payload);
//     } catch (error) {
//       console.error("Error updating active status:", error);
//     }
//   };

//   return (
//     <div className="flex justify-between">
//       <div
//         ref={drag}
//         style={{ opacity: isDragging ? 0.5 : 1 }}
//         className="w-2/6 m-2 py-2 cursor-pointer"
//       >
//         <img
//           src={src || "/placeholder.png"}
//           onError={(e) => {
//             e.target.onerror = null;
//             e.target.src = "/placeholder.png";
//           }}
//           className="rounded-full mx-auto w-10 h-10"
//           alt={name}
//         />
//         <div className="w-full font-fontPoppins text-xs font-semibold text-center">
//           {name}
//         </div>
//       </div>

//       <div className="pt-2 pl-2 my-1 bg-white w-4/12 rounded">
//         {Object.entries(timeslots).some(
//           ([day, slots]) =>
//             checkedDays[weekDays.indexOf(day)] && slots.length > 0
//         ) ? (
//           // Sort the entries by the order of weekdays
//           Object.entries(timeslots)
//             .sort((a, b) => weekDays.indexOf(a[0]) - weekDays.indexOf(b[0]))
//             .map(([day, slots]) =>
//               checkedDays[weekDays.indexOf(day)]
//                 ? slots.map((slot, idx) => (
//                     <div key={idx} className="flex justify-between items-start">
//                       <div className="">{day.slice(0, 3)}:</div>
//                       <div className="w-3/4">
//                         {slot.startTime} {slot.ampmStart}-{slot.endTime}{" "}
//                         {slot.ampmEnd}
//                       </div>
//                     </div>
//                   ))
//                 : null
//             )
//         ) : (
//           <div
//             onClick={() => handleSlotModal(practitioner_id)}
//             className="text-blue-500 text-base font-bold cursor-pointer"
//           >
//             Add
//           </div>
//         )}

//         {Object.entries(timeslots).some(
//           ([day, slots]) =>
//             checkedDays[weekDays.indexOf(day)] && slots.length > 0
//         ) && (
//           <div
//             onClick={() => handleSlotModal(practitioner_id)}
//             className="text-blue-500 text-base font-bold cursor-pointer"
//           >
//             Edit
//           </div>
//         )}
//       </div>

//       <div>
//         <div className="p-2 m-6 mr-5 h-10 bg-white rounded w-24">
//           <div onClick={toggleStatus}>
//             {isActive ? (
//               <div className="flex items-center text-green-500 font-bold cursor-pointer">
//                 <div
//                   className="w-3 h-3 bg-green-500 rounded-full mr-2 "
//                   style={{ marginTop: "1px" }}
//                 />
//                 <span>Active</span>
//               </div>
//             ) : (
//               <div className="flex items-center text-red-500 font-bold cursor-pointer">
//                 <div
//                   className="w-3 h-3 bg-red-500 rounded-full mr-2 "
//                   style={{ marginTop: "1px" }}
//                 />
//                 <span>InActive</span>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       {isSlotModalOpen && selectedProvider === practitioner_id && (
//         <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-85 backdrop-filter backdrop-blur-sm flex justify-center items-center">
//           <div className="bg-white rounded-lg md:w-3/5 shadow-lg border border-gray-200 h-2/3  sm:w-3/4 overflow-x-auto overflow-y-auto">
//             {/* <div className="bg-white rounded-lg w-full sm:w-3/4 md:w-1/2 lg:w-1/2 xl:w-1/3 md:h-2/3 sm:h-2/3 lg:h-4/5 xl:h-5/6 shadow-lg border border-gray-200 overflow-auto"> */}
//             <div className="sticky top-0 bg-white border-b border-gray-300 z-10">
//               <div className="flex p-2 px-4 justify-center items-center">
//                 <p className="text-xl sm:text-xl font-semibold">
//                   Working Hours
//                 </p>
//               </div>
//             </div>
//             <div>
//               {weekDays.map((day, index) => (
//                 <div key={index} className="flex p-2  px-4">
//                   <div className="flex items-start mr-3 m-3 w-32 max-h-auto">
//                     <input
//                       type="checkbox"
//                       className="form-checkbox text-blue-500 mr-2 mt-1"
//                       checked={localCheckedDays[index]}
//                       onChange={() => handleCheckboxChange(index)}
//                     />
//                     <span>{day}</span>
//                   </div>

//                   {localCheckedDays[index] && (
//                     <div className="w-full flex flex-col justify-center">
//                       <div className="w-full flex flex-col ">
//                         {(localTimeslots[day] || []).map((slot, idx) => (
//                           <div
//                             key={idx}
//                             className="flex max-1000:flex-col items-center max-1000:space-y-2 space-x-2 mt-2"
//                           >
//                             <CustomSelect
//                               name="Start Time"
//                               className="border rounded m-3 p-1 text-gray-700 "
//                               value={slot.startTime}
//                               onChange={(e) =>
//                                 handleSelectChange(
//                                   day,
//                                   idx,
//                                   "startTime",
//                                   e.target.value
//                                 )
//                               }
//                               options={timeOptions.map((time, optionIdx) => ({
//                                 value: time.time,
//                                 label: time.label,
//                               }))}
//                             />
//                             {/* { => (
//                                 <option key={optionIdx} value={time}>
//                                   {time}
//                                 </option>
//                               ))} */}
//                             {/* </select> */}
//                             <CustomSelect
//                               className="border rounded p-1 text-gray-700"
//                               name="AM/PM"
//                               value={slot.ampmStart}
//                               onChange={(e) =>
//                                 handleSelectChange(
//                                   day,
//                                   idx,
//                                   "ampmStart",
//                                   e.target.value
//                                 )
//                               }
//                               options={[
//                                 { value: "AM", label: "AM" },
//                                 { value: "PM", label: "PM" },
//                               ]}
//                             />

//                             <span className="text-gray-700">to</span>

//                             <CustomSelect
//                               className="border rounded p-1 text-gray-700 w-10 "
//                               name="End Time"
//                               value={slot.endTime}
//                               onChange={(e) =>
//                                 handleSelectChange(
//                                   day,
//                                   idx,
//                                   "endTime",
//                                   e.target.value
//                                 )
//                               }
//                               options={timeOptions.map((time, optionIdx) => ({
//                                 value: time.time,
//                                 label: time.label,
//                               }))}
//                             />
//                             <CustomSelect
//                               className="border rounded p-1 text-gray-700"
//                               value={slot.ampmEnd}
//                               name="AM/PM"
//                               onChange={(e) =>
//                                 handleSelectChange(
//                                   day,
//                                   idx,
//                                   "ampmEnd",
//                                   e.target.value
//                                 )
//                               }
//                               options={[
//                                 { value: "AM", label: "AM" },
//                                 { value: "PM", label: "PM" },
//                               ]}
//                             />

//                             <span className="ml-3 cursor-pointer ">
//                               <i
//                                 onClick={() => handleDeleteSlot(day, idx)}
//                                 className="fa-solid fa-close"
//                               ></i>
//                             </span>
//                           </div>
//                         ))}
//                       </div>
//                       <button
//                         onClick={() => addTimeslot(day)}
//                         className=" w-1/6 mt-3 p-1 ml-2 bg-gray-500 text-white rounded "
//                         title="Add Timeslot"
//                       >
//                         <i className="fa-solid fa-plus text-xl"></i>
//                       </button>

//                       {timeslotError && (
//                         <p className="text-red-500 text-sm mt-2 ">
//                           {timeslotError}
//                         </p>
//                       )}
//                     </div>
//                   )}

//                   {!localCheckedDays[index] && (
//                     <div className="text-gray-500 text-sm mt-3">
//                       Unavailable
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//             <div className="sticky bottom-0 border-0 bg-white border-t border-gray-300  ">
//               <div className=" h-20 flex justify-center items-center relative">
//                 <div className="flex flex-col items-center">
//                   <div className="flex justify-between items-center ">
//                     <button
//                       onClick={handleSlotModal}
//                       className="ml-2 p-2 bg-blue-500 text-white rounded "
//                     >
//                       Cancel
//                     </button>

//                     <button
//                       onClick={() => saveTimeSlot(practitioner_id)}
//                       className="ml-2 p-2 bg-blue-500 text-white rounded "
//                     >
//                       Submit
//                     </button>
//                   </div>
//                   {error && (
//                     <p className="text-red-500 text-base font-semibold absolute mt-9 ">
//                       {error}
//                     </p>
//                   )}
//                   {saveError && (
//                     <p className="text-green-500 text-base font-semibold  absolute mt-9">
//                       {saveError}
//                     </p>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const ServiceRow = ({ service, onDropProvider, onRemoveProvider }) => {
//   const [{ canDrop, isOver }, drop] = useDrop(
//     () => ({
//       accept: ItemTypes.PROVIDER,
//       drop: (item) => onDropProvider(service, item),
//       canDrop: (item) =>
//         !service.providers.some((provider) => provider.name === item.name),
//       collect: (monitor) => ({
//         isOver: monitor.isOver(),
//         canDrop: monitor.canDrop(),
//       }),
//     }),
//     [service.providers]
//   );

//   const removeItem = (provider) => {
//     onRemoveProvider(service, provider);
//   };

//   return (
//     // <div
//     //   ref={drop}
//     //   className={` w-full overflow-auto border border-l-0 border-r-0 border-t-0 border-solid border-gray-100 py-3 flex items-center gap-3 xss:overflow-auto`}
//     // >
//     //   <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full w-1/5  py-3">
//     //     {service?.services}
//     //   </button>
//     //   <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full  w-8 p-2">
//     //     {service?.duration}
//     //   </button>
//     //   <span className="text-gray-400">
//     //     <i className="fa-solid fa-play fa-lg"></i>
//     //   </span>
//     //   {service &&
//     //   service.providers &&
//     //   Array.isArray(service.providers) &&
//     //   service.providers.length > 0 ? (
//     //     service.providers.map((provider, index) => (
//     //       <div
//     //         key={index}
//     //         className="w-auto p-0 m-0 flex flex-col items-center justify-center"
//     //       >
//     //         <img
//     //           src={provider?.src}
//     //           className="rounded-full mx-auto w-10 h-10"
//     //           alt={provider?.name}
//     //           onError={(e) => {
//     //             e.target.onerror = null;
//     //             e.target.src = "/placeholder.png";
//     //           }}
//     //         />
//     //         <div className="font-fontPoppins text-xs font-semibold text-center">
//     //           {provider?.name}
//     //         </div>
//     //         <span
//     //           onClick={() => removeItem(provider)}
//     //           className="cursor-pointer"
//     //         >
//     //           <i className="  fa-regular fa-circle-xmark"></i>
//     //         </span>
//     //       </div>
//     //     ))
//     //   ) : (
//     //     <div className="text-gray-400 text-sm">No Providers Added</div>
//     //   )}
//     // </div>
//     <div
//       ref={drop}
//       className={`w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 pr-0 flex items-center gap-3 xss:overflow-auto`}
//     >
//       <button className="font-fontPoppins  text-xs font-medium bg-white shadow-sm rounded-full w-[120px]  py-3">
//         {service?.services}
//       </button>
//       <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full  min-w-[40px] p-2">
//         {service?.duration}
//       </button>
//       <span className="text-gray-400">
//         <i className="fa-solid fa-play fa-lg"></i>
//       </span>
//       <div className=" gap-3 flex w-[400px] overflow-x-auto">
//         {service &&
//         service.providers &&
//         Array.isArray(service.providers) &&
//         service.providers.length > 0 ? (
//           service.providers.map((provider, index) => (
//             <div
//               key={index}
//               className="w-auto p-0 m-0 flex flex-col items-center justify-center"
//             >
//               <img
//                 src={provider?.src || "/placeholder.png"}
//                 className="rounded-full mx-auto w-10 h-10"
//                 alt={provider?.name}
//                 onError={(e) => {
//                   e.target.onerror = null;
//                   e.target.src = "/placeholder.png";
//                 }}
//               />
//               <div className="font-fontPoppins text-xs  font-semibold text-center">
//                 {provider?.name}
//               </div>

//               <span
//                 onClick={() => removeItem(provider)}
//                 className="cursor-pointer"
//               >
//                 <i className="  fa-regular fa-circle-xmark"></i>
//               </span>
//             </div>
//           ))
//         ) : (
//           <div className="text-gray-400 text-sm">No Providers Added</div>
//         )}
//       </div>
//     </div>
//   );
// };

// function ServiceProviderMappingProfile() {
//   const [services, setServices] = useState([]);
//   const [clinicServicesData, setClinicServicesData] = useState([]);
//   const [clinicServicesMapping, setClinicServicesMapping] = useState([]);
//   const [providerList, setProviderList] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [addNewService, setAddnewService] = useState("");
//   const [addNewDuration, setAddNewDuration] = useState("");
//   const [isAddNewService, setIsAddnewService] = useState(false);
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(false);
//   const location = useLocation();
//   const { organizationData, providerData } = location.state || {};
//   const [clinicId, setclinicId] = useState("");
//   const [accountType, setAccountType] = useState("");
//   const [errorInvalid, setErrorInvalid] = useState("");
//   const [isSlotModalOpen, setIsSlotModelOpen] = useState(false);
//   const [providerTimeslots, setProviderTimeSlots] = useState({});
//   const navigate = useNavigate();
//   const [mappingisChecked, setMappingisChecked] = useState(true);
//   const [errorMessage, setErrorMessage] = useState();

//   useEffect(() => {
//     const storedClinicId = localStorage.getItem("clinicId");
//     const storedAccountType = localStorage.getItem("accountType");

//     setclinicId(storedClinicId);
//     setAccountType(storedAccountType);
//     fetchServices(storedClinicId).then(() => {
//       getProviderList(storedClinicId);
//     });
//   }, []);

//   useEffect(() => {
//     if (services.length > 0) {
//       const storedClinicId = localStorage.getItem("clinicId");
//       fetchClinicServicesMapping(storedClinicId);
//     }
//   }, [providerList, clinicId]);

//   const fetchServices = async (clinicId) => {
//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "clinic_services",
//         whereCondition: {
//           clinic_id: clinicId,
//           is_active: 1,
//         },
//       };
//       const response = await postData("masters/getMasterList", payload);

//       const servicesWithProviders = response.data.map((service) => ({
//         ...service,
//         providers: [],
//       }));

//       setServices(servicesWithProviders);

//       const clinicServices = response.data.map((service) => ({
//         services_id: service.id,
//         name: service.services,
//         duration: service.duration,
//         is_active: 1,
//       }));
//       setClinicServicesData(clinicServices);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching services:", error);
//       setLoading(false);
//     }
//   };

//   const getProviderList = async (clinicId) => {
//     try {
//       setLoading(true);
//       var accountType = localStorage.getItem("accountType");
//       var refernceId = localStorage.getItem("refernceId");

//       if (accountType == "Practitioners") {
//         var payload = {
//           modelName: "practitioners",
//           whereCondition: {
//             id: refernceId,
//             is_active: 1,
//           },
//         };
//       } else if (accountType == "Assistances") {
//         var payload = {
//           modelName: "assistance_practitioners_mapping",
//           pagination: {
//             page: 1,
//             pageSize: 500,
//           },
//           whereCondition: {
//             clinic_id: clinicId,
//             is_active: 1,
//             assistance_id: refernceId,
//           },
//         };
//         var assistance_practitioners_mapping_response = await postData(
//           "masters/getMasterList",
//           payload
//         );
//         if (assistance_practitioners_mapping_response.code === 1) {
//           var assistance_practitioners_mapping_data =
//             assistance_practitioners_mapping_response.data;
//           var varpractitionerIds = [];
//           for (
//             let i = 0;
//             i < assistance_practitioners_mapping_data.length;
//             i++
//           ) {
//             const element = assistance_practitioners_mapping_data[i];
//             varpractitionerIds.push(element.practitioner_id);
//           }
//         }

//         var payload = {
//           modelName: "practitioners",
//           whereCondition: {
//             id: varpractitionerIds,
//             is_active: 1,
//           },
//         };
//       } else {
//         var payload = {
//           modelName: "practitioners",
//           whereCondition: {
//             clinic_id: clinicId,
//             is_active: 1,
//           },
//         };
//       }

//       const response = await postData("masters/getMasterList", payload);

//       setProviderList(response.data);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error fetching clinic details:", error);
//     }
//   };

//   const fetchClinicServicesMapping = async (clinicId) => {
//     try {
//       setLoading(true);
//       const payload = {
//         modelName: "clinic_services_mapping",
//         pagination: {
//           page: 1,
//           pageSize: 500,
//         },
//         whereCondition: {
//           clinic_id: clinicId,
//           is_active: 1,
//         },
//       };
//       const response = await postData("masters/getMasterList", payload);

//       const mappingByServiceId = response.data.reduce((acc, curr) => {
//         if (!acc[curr.services_id]) {
//           acc[curr.services_id] = [];
//         }
//         acc[curr.services_id].push(curr);
//         return acc;
//       }, {});

//       const updatedServices = services.map((service) => {
//         const mappings = mappingByServiceId[service.id] || [];
//         const providers = mappings.map((mapping) => {
//           const provider = providerList.find(
//             (provider) => provider.id === mapping.practitioner_id
//           );
//           return provider
//             ? {
//                 name: `${provider.f_name} ${provider.l_name}`,
//                 src: `${apiUrl}/${provider.photo}`,
//                 practitioner_id: provider.id,
//               }
//             : null;
//         });
//         return {
//           ...service,
//           providers: providers.filter((provider) => provider !== null),
//         };
//       });

//       setServices(updatedServices);

//       const updatedClinicServicesMapping = response.data
//         .map((mapping) => {
//           const provider = providerList.find(
//             (provider) => provider.id === mapping.practitioner_id
//           );
//           const providerName = provider
//             ? `${provider.f_name} ${provider.l_name}`
//             : "";

//           return {
//             services_id: mapping.services_id,
//             services:
//               services.find((service) => service.id === mapping.services_id)
//                 ?.services || "",
//             name: providerName,
//             provider_practitioner_id: mapping.practitioner_id,
//             is_active: mapping.is_active,
//           };
//         })
//         .filter((mapping) => mapping.name !== "");

//       setClinicServicesMapping(
//         updatedClinicServicesMapping.length > 0
//           ? updatedClinicServicesMapping
//           : []
//       );
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching services:", error);
//       setLoading(false);
//     }
//   };

//   const handleDropProvider = (service, item) => {
//     setServices((prevServices) => {
//       const updatedServices = prevServices.map((s) =>
//         s.services === service.services
//           ? {
//               ...s,
//               providers: [
//                 ...s.providers,
//                 {
//                   name: item.name,
//                   services: service.services,
//                   duration: service.duration,
//                   src: item.src,
//                   practitioner_id: item.practitioner_id,
//                   is_active: 1,
//                 },
//               ],
//             }
//           : s
//       );

//       return updatedServices;
//     });
//     setClinicServicesMapping((prevMappings) => {
//       const updatedMappings = [
//         ...prevMappings,
//         {
//           services_id: service.id,
//           services: service.services,
//           duration: service.duration,
//           name: item.name,
//           provider_practitioner_id: item.practitioner_id,
//           is_active: 1,
//         },
//       ];

//       return updatedMappings;
//     });
//   };

//   const handleRemoveProvider = (service, provider) => {
//     setServices((prevServices) => {
//       const updatedServices = prevServices.map((s) =>
//         s.services === service.services
//           ? {
//               ...s,
//               providers: s.providers.filter((p) => p.name !== provider.name),
//             }
//           : s
//       );

//       return updatedServices;
//     });

//     setClinicServicesMapping((prevMappings) => {
//       const updatedMappings = prevMappings.filter(
//         (mapping) =>
//           !(
//             mapping.services === service.services &&
//             mapping.name === provider.name &&
//             mapping.provider_practitioner_id === provider.practitioner_id
//           )
//       );

//       return updatedMappings;
//     });
//   };

//   const handleModal = () => {
//     setIsModalOpen(false);
//     setIsAddnewService(false);
//     setAddnewService("");
//     setAddNewDuration("");
//   };

//   const HandleAddNewService = () => {
//     setIsAddnewService(true);
//   };

//   const handleAddService = (e) => {
//     e.preventDefault();

//     if (addNewService.trim() && addNewDuration.trim()) {
//       const serviceExists = services.some(
//         (service) =>
//           service.services.toLowerCase() === addNewService.trim().toLowerCase()
//       );

//       if (serviceExists) {
//         setError("Service Already Exists");
//         setTimeout(() => setError(""), 2000);
//         return;
//       }
//       const newServices = [
//         ...services,
//         {
//           services: addNewService.trim(),
//           duration: addNewDuration.trim(),
//           id: 0,
//           providers: [],
//         },
//       ];
//       setServices(newServices);

//       const newClinicService = {
//         name: addNewService.trim(),
//         duration: addNewDuration.trim(),
//         services_id: 0,
//         is_active: 1,
//       };
//       const newClinicServicesData = [...clinicServicesData, newClinicService];
//       setClinicServicesData(newClinicServicesData);

//       setAddnewService("");
//       setAddNewDuration("");
//       setIsAddnewService(false);
//     } else {
//       setError("Please Enter Both Appointment Type And Duration");
//       setTimeout(() => setError(""), 2000);
//     }
//   };

//   const handleDeleteService = (serviceName) => {
//     const filteredServices = services.filter(
//       (service) => service.services !== serviceName
//     );
//     setServices(filteredServices);

//     const filteredClinicServicesData = clinicServicesData.filter(
//       (service) => service.name !== serviceName
//     );
//     setClinicServicesData(filteredClinicServicesData);

//     const filteredClinicServicesMapping = clinicServicesMapping.filter(
//       (mapping) => mapping.services !== serviceName
//     );
//     setClinicServicesMapping(filteredClinicServicesMapping);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "name") {
//       const onlyNumbersRegex = /^\d+$/;
//       const onlySpecialCharsRegex = /^[!@#$%^&*(),.?":{}|<>0-9]+$/;

//       if (onlyNumbersRegex.test(value) || onlySpecialCharsRegex.test(value)) {
//         setErrorInvalid("Invalid Appointment Type");
//         setAddnewService("");
//       } else {
//         setAddnewService(value);
//         setErrorInvalid("");
//       }
//     } else if (name === "duration") {
//       const onlyNumbersRegex = /^\d+$/;

//       if (onlyNumbersRegex.test(value)) {
//         setAddNewDuration(value);
//         setErrorInvalid("");
//       } else {
//         setErrorInvalid("Invalid Duration");
//         setAddNewDuration("");
//       }
//     }
//   };

//   const handleSubmit = async () => {
//     if (!mappingisChecked) {
//       setErrorMessage(
//         "You Must Verify The Information And Provide Your Consent."
//       );
//       setTimeout(() => setErrorMessage(""), 2000);
//       return;
//     }
//     if (clinicServicesMapping.length > 0 && clinicServicesData.length > 0) {
//       setError("");

//       const existingServices1 = clinicServicesData.filter(
//         (service) => service.services_id > 0
//       );
//       const existingServices2 = clinicServicesData.filter(
//         (service) => service.services_id === 0
//       );

//       const mappedServices = existingServices1.map((service) => ({
//         id: service.services_id,
//         services: service.name,
//         duration: service.duration,
//       }));

//       if (existingServices2.length > 0) {
//         mappedServices.push(
//           ...existingServices2.map((service) => ({
//             services: service.name,
//             duration: service.duration,
//           }))
//         );
//       }

//       const servicesMapping = clinicServicesMapping.map((mapping) => {
//         if (mapping.services_id === 0) {
//           return {
//             services_id: mapping.services_id,
//             services: mapping.services,
//             duration: mapping.duration,
//             practitioner_id: mapping.provider_practitioner_id,
//           };
//         } else {
//           return {
//             services_id: mapping.services_id,
//             practitioner_id: mapping.provider_practitioner_id,
//           };
//         }
//       });

//       const payload = {
//         clinic_id: clinicId,
//         services: mappedServices,
//         servicesMapping: servicesMapping,
//       };

//       const daysOfWeek = [
//         "Monday",
//         "Tuesday",
//         "Wednesday",
//         "Thursday",
//         "Friday",
//         "Saturday",
//         "Sunday",
//       ];

//       const convertTo24HourFormat = (time, period) => {
//         let [hours, minutes] = time.split(":");
//         hours = parseInt(hours);
//         minutes = parseInt(minutes);

//         if (period === "PM" && hours !== 12) {
//           hours += 12;
//         } else if (period === "AM" && hours === 12) {
//           hours = 0;
//         }

//         return `${hours.toString().padStart(2, "0")}:${minutes
//           .toString()
//           .padStart(2, "0")}:00`;
//       };

//       const convertToDesiredFormat = (data) => {
//         const result = { practitioner_working_hours_details: [] };

//         for (const id in data) {
//           const practitioner = {
//             practitioner_id: parseInt(id),
//             practitioner_working_hours: [],
//           };

//           daysOfWeek.forEach((day) => {
//             if (data[id].timeslots[day] && data[id].timeslots[day].length > 0) {
//               data[id].timeslots[day].forEach((slot) => {
//                 const fromTime = convertTo24HourFormat(
//                   slot.startTime,
//                   slot.ampmStart
//                 );
//                 const toTime = convertTo24HourFormat(
//                   slot.endTime,
//                   slot.ampmEnd
//                 );
//                 practitioner.practitioner_working_hours.push({
//                   day: day,
//                   from_time: fromTime,
//                   to_time: toTime,
//                   is_available: 1,
//                   is_active: 1,
//                 });
//               });
//             } else {
//               practitioner.practitioner_working_hours.push({
//                 day: day,
//                 is_available: 0,
//                 is_active: 0,
//               });
//             }
//           });

//           result.practitioner_working_hours_details.push(practitioner);
//         }

//         return result;
//       };

//       try {
//         setLoading(true);
//         const [response1, response2] = await Promise.all([
//           postData("practitioners/practitionersServiceMapping", payload),
//           postData(
//             "practitioners/practitionersWorkingHoursMgmt",
//             convertToDesiredFormat(providerTimeslots)
//           ),
//         ]);

//         if (response1.success && response2.success) {
//           setLoading(false);
//           Swal.fire(
//             "Success",
//             "Mapping And Working Hours Updated Successfully",
//             "success"
//           );
//         } else {
//           setLoading(false);
//           Swal.fire(
//             "Error",
//             "Failed to Update Mapping or Working Hours",
//             "error"
//           );
//         }
//       } catch (error) {
//         setLoading(false);
//         console.error("Error posting data:", error);
//         Swal.fire(
//           "Error",
//           "Failed to Update Mapping or Working Hours",
//           "error"
//         );
//       }
//     } else {
//       setLoading(false);
//       setError("Please Add Providers To Appointment Type");
//       setTimeout(() => setError(""), 3000);
//     }
//   };

//   return (
//     <>
//       {loading && <Loader2 />}
//       <>
//         <Header />
//         <div className="pt-16">
//           <DndProvider backend={HTML5Backend}>
//             <section className="w-full">
//               <div className="container mx-auto px-4">
//                 <div>
//                   <h1 className="font-fontPoppins md:text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal">
//                     Appointment To Provider Mapping
//                   </h1>
//                   <h2 className="font-fontPoppins text-center text-gray-700 mt-2 text-l font-bold  xss:text-center sm:text-center">
//                     Drag The Provider Icon To The Appointment Type-Row To Map
//                   </h2>
//                 </div>

//                 <div className="w-full mt-4 md:flex md:justify-center md:gap-4">
//                   <div className="md:w-4/5 xss:w-full tiny:w-full xss-w-full sm:w-full">
//                     <div className="font-fontPoppins text-sm font-semibold px-3  pl-17">
//                       Add Appointment Type{" "}
//                       {/* <span class="bg-gray-500 rounded-full ml-1 p-1 hover:bg-gray-400 cursor-pointer shadow-md transition duration-300">
//                   <i
//                     onClick={() => setIsModalOpen(true)}
//                     className="fa-solid fa-gear fa-xs text-gray-700"
//                   ></i>
//                   </span> */}
//                       <span class="rounded-full ml-2 p-1 bg-gray-100 cursor-pointer shadow-md transition duration-100">
//                         <i
//                           onClick={() => setIsModalOpen(true)}
//                           class="fa-solid fa-plus fa-lg text-gray-900 hover:text-gray-100"
//                           title="Click here to add Appointment services"
//                         ></i>
//                       </span>
//                     </div>
//                     {/* <div className="w-full min-h-full bg-grayColorLight rounded-lg mt-1"> */}
//                     <div className="w-full min-h-96 bg-grayColorLight rounded-lg mt-3 max-h-96 overflow-y-auto">
//                       <div className="w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 flex items-center gap-3 xss:overflow-auto">
//                         <div className="  text-l text-center min-w-[120px] text-center  font-semibold">
//                           Appointment <br /> Type
//                         </div>
//                         <div className="text-l text-center min-w-[40px]  font-semibold">
//                           Duration
//                         </div>
//                         <div className=" text-l w-[330px] text-center font-semibold">
//                           Providers
//                         </div>
//                       </div>

//                       {services &&
//                         services.map(
//                           (service, index) =>
//                             service && (
//                               <div
//                                 key={index}
//                                 // className=" overflow-x-auto border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 flex items-center gap-3 xss:overflow-auto"
//                               >
//                                 <ServiceRow
//                                   service={service}
//                                   onDropProvider={handleDropProvider}
//                                   onRemoveProvider={handleRemoveProvider}
//                                 />
//                               </div>
//                             )
//                         )}
//                     </div>
//                   </div>

//                   <div className="md:w-1/5 xss:w-full tiny:w-full sm:w-full ">
//                     <div className="font-fontPoppins text-sm font-semibold px-3 text-center">
//                       Providers
//                     </div>
//                     {/* <div className="w-full min-h-full bg-grayColorLight rounded-lg p-3 mt-1"> */}
//                     <div className="w-full min-h-96 bg-grayColorLight rounded-lg max-h-96 mt-3 overflow-y-auto">
//                       <div className="w-full border-b py-4 border-solid border-gray-200 flex justify-between  items-center ">
//                         <div className="text-l w-2/6  text-center py-2 font-semibold">
//                           Providers
//                         </div>
//                         <div
//                           // className="  text-l pt-2 pl-8 my-1 w-4/12 text-center font-semibold"

//                           className=" text-l  my-1  text-center w-4/12 font-semibold"
//                           // style={{ paddingLeft: "60px" }}
//                         >
//                           Working Hours
//                         </div>
//                         <div
//                           // className=" text-l text-center rounded w-24  font-semibold"
//                           className="p-2 ml-6 mr-5  font-semibold w-24 text-center"
//                         >
//                           Status
//                         </div>
//                       </div>
//                       {providerList &&
//                         providerList.map((provider, index) => (
//                           <Provider
//                             key={index}
//                             name={`${provider.f_name} ${provider.l_name}`}
//                             src={`${apiUrl}/${provider.photo}`}
//                             practitioner_id={provider.id}
//                             email={provider.email}
//                             isSlotModalOpen={isSlotModalOpen}
//                             setIsSlotModelOpen={setIsSlotModelOpen}
//                             providerTimeslots={providerTimeslots}
//                             setProviderTimeSlots={setProviderTimeSlots}
//                             isActiveStatus={provider.is_active_today}
//                           />
//                         ))}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex mt-4 items-center gap-2">
//                   <input
//                     type="checkbox"
//                     checked={mappingisChecked}
//                     onChange={(e) => setMappingisChecked(e.target.checked)}
//                   />

//                   <span className="font-fontPoppins text-xs font-semibold relative">
//                     I have verified all the information and provide consent to
//                     Samara Well to use and display this information accordingly.
//                   </span>
//                 </div>
//                 {errorMessage && (
//                   <p className="text-red-500  text-xs absolute">
//                     {errorMessage}
//                   </p>
//                 )}
//               </div>
//             </section>
//             {isModalOpen && (
//               <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-75 backdrop-filter backdrop-blur-sm flex justify-center items-center p-4 sm:p-6 lg:p-8">
//                 <div className="bg-white rounded-lg w-full sm:w-3/4 lg:w-1/2 shadow-lg border border-gray-200">
//                   <div className="flex justify-between items-center border-b border-gray-300 p-2 px-4">
//                     <p className="text-lg sm:text-xl font-semibold">
//                       Appointment Types
//                     </p>
//                     <button
//                       onClick={HandleAddNewService}
//                       className="text-blue-700 border border-blue-700 bg-transparent px-2 py-1 hover:text-blue-400 rounded-full focus:outline-none"
//                     >
//                       Add New Appointment Type
//                     </button>
//                   </div>
//                   <div className="p-2 px-4 flex justify-between border-b border-gray-200 font-semibold">
//                     <p className="w-1/3 text-center text-l sm:text-sm">
//                       Appointment Type
//                     </p>
//                     <p className="w-1/3 text-center text-l sm:text-sm">
//                       Duration In Minutes
//                     </p>
//                     <p className="w-1/3 text-center text-l sm:text-sm">
//                       Action
//                     </p>
//                   </div>
//                   {services &&
//                     services.map((service, index) => (
//                       <div
//                         key={index}
//                         className="flex p-2 px-4 justify-between items-center border-b border-gray-200"
//                       >
//                         <p className="w-1/3 text-center text-s sm:text-sm">
//                           {service?.services}
//                         </p>
//                         <p className="w-1/3 text-center text-s sm:text-sm">
//                           {service?.duration}
//                         </p>
//                         <div className="w-1/3 flex justify-center">
//                           <span
//                             onClick={() =>
//                               handleDeleteService(service.services)
//                             }
//                             className="text-gray-400 cursor-pointer hover:text-red-500 text-s sm:text-sm"
//                           >
//                             <i className="fa-solid fa-trash"></i> Delete
//                           </span>
//                         </div>
//                       </div>
//                     ))}
//                   {isAddNewService && (
//                     <>
//                       <div className="p-2 px-4 flex justify-between border-b border-gray-200">
//                         <input
//                           type="text"
//                           name="name"
//                           placeholder="Enter Appointment Type"
//                           className="border border-black rounded-lg py-2 px-3 mb-3 mr-4 w-full shadow-sm"
//                           value={addNewService}
//                           onChange={handleChange}
//                         />

//                         <input
//                           type="text"
//                           name="duration"
//                           placeholder="Enter Duration In Minutes"
//                           className="border border-black rounded-lg py-2 px-3 mb-3 w-full shadow-sm"
//                           value={addNewDuration}
//                           onChange={handleChange}
//                         />
//                       </div>
//                       <div className="text-red-500 ml-2">{error}</div>
//                       <div className="flex justify-center items-center mt-4">
//                         <span
//                           onClick={handleAddService}
//                           className="text-black mr-3 cursor-pointer hover:text-blue-500 text-l sm:text-sm"
//                         >
//                           <i className="fas fa-add"></i> Add
//                         </span>
//                         <span
//                           onClick={() => {
//                             setIsAddnewService(false);
//                             setErrorInvalid("");
//                             setAddnewService("");
//                             setAddNewDuration("");
//                           }}
//                           className="text-black mr-2 cursor-pointer hover:text-red-500 text-l sm:text-sm"
//                         >
//                           <i className="fa-solid fa-trash"></i> Cancel
//                         </span>
//                       </div>
//                     </>
//                   )}
//                   <div className="flex justify-center pb-2">
//                     <span
//                       onClick={handleModal}
//                       className="mr-2 cursor-pointer text-l pt-14 sm:text-sm"
//                     >
//                       <i className="fa-solid fa-close"></i> Close
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </DndProvider>
//           <section className="w-full mt-10 xss:mt-5 sm:mt-5">
//             <div className="container mx-auto px-4 text-center">
//               <button
//                 onClick={handleSubmit}
//                 className="w-40 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
//               >
//                 <span className="me-2">Submit</span>
//               </button>
//               {<p className="text-red-500 ml-2">{error}</p>}
//             </div>
//           </section>

//           <Footer />
//         </div>
//       </>
//     </>
//   );
// }

// export default ServiceProviderMappingProfile;

import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { useEffect, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import StepsForRegistration from "../StepsForRegistration";
import { apiUrl, postData, imageBaseUrl } from "../../Networking/Api";
import Swal from "sweetalert2";
import CustomSelectHealth from "../CustomSelectHealth";
import CustomSelect from "../CustomSelect";
import Loader from "../Loader";
import Loader2 from "../Loader2";
import swal from "sweetalert";
import { TouchBackend } from "react-dnd-touch-backend";

const ItemTypes = {
  PROVIDER: "provider",
};

const timeOptions = [
  { time: "12:00", label: "12:00" },
  { time: "12:15", label: "12:15" },
  { time: "12:30", label: "12:30" },
  { time: "12:45", label: "12:45" },
  { time: "1:00", label: "1:00" },
  { time: "1:15", label: "1:15" },
  { time: "1:30", label: "1:30" },
  { time: "1:45", label: "1:45" },
  { time: "2:00", label: "2:00" },
  { time: "2:15", label: "2:15" },
  { time: "2:30", label: "2:30" },
  { time: "2:45", label: "2:45" },
  { time: "3:00", label: "3:00" },
  { time: "3:15", label: "3:15" },
  { time: "3:30", label: "3:30" },
  { time: "3:45", label: "3:45" },
  { time: "4:00", label: "4:00" },
  { time: "4:15", label: "4:15" },
  { time: "4:30", label: "4:30" },
  { time: "4:45", label: "4:45" },
  { time: "5:00", label: "5:00" },
  { time: "5:15", label: "5:15" },
  { time: "5:30", label: "5:30" },
  { time: "5:45", label: "5:45" },
  { time: "6:00", label: "6:00" },
  { time: "6:15", label: "6:15" },
  { time: "6:30", label: "6:30" },
  { time: "6:45", label: "6:45" },
  { time: "7:00", label: "7:00" },
  { time: "7:15", label: "7:15" },
  { time: "7:30", label: "7:30" },
  { time: "7:45", label: "7:45" },
  { time: "8:00", label: "8:00" },
  { time: "8:15", label: "8:15" },
  { time: "8:30", label: "8:30" },
  { time: "8:45", label: "8:45" },
  { time: "9:00", label: "9:00" },
  { time: "9:15", label: "9:15" },
  { time: "9:30", label: "9:30" },
  { time: "9:45", label: "9:45" },
  { time: "10:00", label: "10:00" },
  { time: "10:15", label: "10:15" },
  { time: "10:30", label: "10:30" },
  { time: "10:45", label: "10:45" },
  { time: "11:00", label: "11:00" },
  { time: "11:15", label: "11:15" },
  { time: "11:30", label: "11:30" },
  { time: "11:45", label: "11:45" },
];

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const Provider = ({
  name,
  src,
  practitioner_id,
  setIsSlotModelOpen,
  isSlotModalOpen,
  providerTimeslots,
  setProviderTimeSlots,
  isActiveStatus,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.PROVIDER,
    item: { name, src, practitioner_id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [localTimeslots, setLocalTimeslots] = useState({});

  const [localCheckedDays, setLocalCheckedDays] = useState();
  const [checkedDays, setCheckedDays] = useState();
  const [timeslots, setTimeslots] = useState({});
  const [saveError, setSaveError] = useState(false);
  const [isActive, setIsActive] = useState(isActiveStatus === 0 ? false : true);

  const [selectedProvider, setSelectedProvider] = useState("");
  const [error, setError] = useState(false);
  const [timeslotError, setTimeSlotError] = useState("");
  useEffect(() => {
    console.log("localtime", localTimeslots, timeslots);
  }, [localTimeslots]);

  const convertTo24HourFormat = (time, period) => {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:00`;
  };
  // const convertToDesiredFormat = (data) => {
  //   const result = { practitioner_working_hours_details: [] };

  //   for (const id in data) {
  //     const practitioner = {
  //       practitioner_id: parseInt(id),
  //       practitioner_working_hours: [],
  //     };

  //     weekDays.forEach((day) => {
  //       if (data[id].timeslots[day] && data[id].timeslots[day].length > 0) {
  //         data[id].timeslots[day].forEach((slot) => {
  //           const fromTime = convertTo24HourFormat(
  //             slot.startTime,
  //             slot.ampmStart
  //           );
  //           const toTime = convertTo24HourFormat(slot.endTime, slot.ampmEnd);
  //           practitioner.practitioner_working_hours.push({
  //             day: day,
  //             from_time: fromTime,
  //             to_time: toTime,
  //             is_available: 1,
  //             is_active: 1,
  //           });
  //         });
  //       } else {
  //         practitioner.practitioner_working_hours.push({
  //           day: day,
  //           is_available: 0,
  //           is_active: 0,
  //         });
  //       }
  //     });

  //     result.practitioner_working_hours_details.push(practitioner);
  //   }

  //   return result;
  // };
  const convertToDesiredFormat = (data) => {
    const result = { practitioner_working_hours_details: [] };
    const weekDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Create a practitioner object
    const practitioner = {
      practitioner_id: practitioner_id,
      practitioner_working_hours: [],
    };

    // Iterate through all weekdays
    weekDays.forEach((day) => {
      if (data[day] && data[day].length > 0) {
        data[day].forEach((slot) => {
          const fromTime = convertTo24HourFormat(
            slot.startTime,
            slot.ampmStart
          );
          const toTime = convertTo24HourFormat(slot.endTime, slot.ampmEnd);
          practitioner.practitioner_working_hours.push({
            day: day,
            from_time: fromTime,
            to_time: toTime,
            is_available: 1,
            is_active: 1,
          });
        });
      } else {
        practitioner.practitioner_working_hours.push({
          day: day,
          is_available: 0,
          is_active: 0,
        });
      }
    });

    // Add the practitioner object to the result array
    result.practitioner_working_hours_details.push(practitioner);
    return result;
  };

  useEffect(() => {
    const updateWorkinHours = async () => {
      if (practitioner_id) {
        const filteredTimeslots = {};
        for (const [day, slots] of Object.entries(timeslots)) {
          if (slots.length > 0) {
            filteredTimeslots[day] = slots;
          }
        }
        console.log(
          "filteredTimeslots",
          filteredTimeslots,
          convertToDesiredFormat(filteredTimeslots)
        );
        setProviderTimeSlots((prev) => ({
          ...prev,
          [practitioner_id]: {
            timeslots: filteredTimeslots,
          },
        }));
      }
    };
    updateWorkinHours();
  }, [timeslots, practitioner_id, setProviderTimeSlots, setIsActive]);
  useEffect(() => {
    const fetchWorkingHours = async () => {
      try {
        const payload = {
          modelName: "practitioner_working_hours",
          pagination: {
            page: 1,
            pageSize: 500,
          },
          whereCondition: {
            practitioner_id: practitioner_id,
          },
        };
        const response = await postData("masters/getMasterList", payload);

        const convertTo12HourFormat = (time) => {
          if (!time) return null;
          let [hours, minutes] = time.split(":");
          hours = parseInt(hours, 10);
          let ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          // console.log("efiefh", {
          //   time: `${hours}:${minutes.padStart(2, "0")}`,
          //   period: ampm,
          // });
          return { time: `${hours}:${minutes.padStart(2, "0")}`, period: ampm };
        };

        const convertToDesiredFormat = (data) => {
          const result = {};

          data.forEach((slot) => {
            if (
              slot.is_available === 1 &&
              slot.is_active === 1 &&
              slot.from_time &&
              slot.to_time
            ) {
              const fromTime = convertTo12HourFormat(slot.from_time);
              const toTime = convertTo12HourFormat(slot.to_time);

              if (!result[slot.day]) {
                result[slot.day] = [];
              }

              result[slot.day].push({
                startTime: fromTime.time,
                endTime: toTime.time,
                ampmStart: fromTime.period,
                ampmEnd: toTime.period,
              });
            }
          });

          return result;
        };

        setLocalTimeslots(convertToDesiredFormat(response.data));

        setTimeslots(convertToDesiredFormat(response.data));
        setLocalCheckedDays(
          weekDays.map(
            (day) => !!convertToDesiredFormat(response.data)[day]?.length
          )
        );
        setCheckedDays(
          weekDays.map(
            (day) => !!convertToDesiredFormat(response.data)[day]?.length
          )
        );
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchWorkingHours();
  }, []);

  const handleCheckboxChange = (index) => {
    setLocalTimeslots((prev) => {
      return {
        ...prev,
        [day]: [{ startTime: "", endTime: "", ampmStart: "", ampmEnd: "" }],
      };
    });
    const day = weekDays[index];
    const newCheckedDays = [...localCheckedDays];
    newCheckedDays[index] = !newCheckedDays[index];

    setLocalTimeslots((prev) => ({
      ...prev,
      [day]: newCheckedDays[index] ? prev[day] || [] : [],
    }));

    setLocalCheckedDays(newCheckedDays);
  };
  const toMinutes = (time, ampm) => {
    const [hours, minutes] = time.split(":").map(Number);
    let hour24 = hours;
    if (ampm === "PM" && hours < 12) hour24 += 12;
    if (ampm === "AM" && hours === 12) hour24 = 0;
    return hour24 * 60 + minutes;
  };

  const doesOverlap = (newSlot, existingSlots) => {
    const newStart = toMinutes(newSlot.startTime, newSlot.ampmStart);
    const newEnd = toMinutes(newSlot.endTime, newSlot.ampmEnd);

    for (const slot of existingSlots) {
      const existingStart = toMinutes(slot.startTime, slot.ampmStart);
      const existingEnd = toMinutes(slot.endTime, slot.ampmEnd);

      const adjustedNewEnd = newStart > newEnd ? newEnd + 24 * 60 : newEnd;
      const adjustedExistingEnd =
        existingStart > existingEnd ? existingEnd + 24 * 60 : existingEnd;

      if (newStart < adjustedExistingEnd && adjustedNewEnd > existingStart) {
        return true;
      }
    }
    return false;
  };
  const addTimeslot = (day) => {
    setLocalTimeslots((prev) => {
      const daySlots = prev[day] || [];
      const lastSlot = daySlots[daySlots.length - 1];

      const hasIncompleteSlot = daySlots.some(
        (slot) =>
          !slot.startTime || !slot.endTime || !slot.ampmStart || !slot.ampmEnd
      );

      if (hasIncompleteSlot) {
        setError(
          "Please Fill Out All Existing Timeslots Before Adding A New One."
        );
        setTimeout(() => setError(""), 4000);
        return prev;
      }

      const previousSlots = daySlots.slice(0, -1);

      if (
        previousSlots.length > 0 &&
        lastSlot &&
        doesOverlap(lastSlot, previousSlots)
      ) {
        setError(
          "The New Timeslot Overlaps With An Existing Timeslot. Please Adjust The Timeslot."
        );
        setTimeout(() => setError(""), 4000);
        return prev;
      }

      const convertTimeToMinutes = (time, period) => {
        let [hour, minute] = time.split(":").map(Number);

        if (period.toUpperCase() === "PM" && hour !== 12) {
          hour += 12;
        } else if (period.toUpperCase() === "AM" && hour === 12) {
          hour = 0;
        }

        return hour * 60 + minute;
      };
      const cutoffTimeStart = convertTimeToMinutes("6:00", "AM");
      const cutoffTimeEnd = convertTimeToMinutes("8:00", "PM");
      const isValidSlot = (slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        return startTime >= cutoffTimeStart && endTime <= cutoffTimeEnd;
      };

      if (lastSlot && !isValidSlot(lastSlot)) {
        setError(
          "Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot."
        );
        setTimeout(() => setError(""), 4000);
        return prev;
      }
      const isOvernightSlot = (slot) => {
        // if (!slot || typeof slot !== "object") return false;

        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        // If end time is before start time, it's an overnight slot
        if (startTime === endTime) {
          return true;
        }

        if (endTime < startTime) {
          return true;
        }

        return false;
      };
      if (lastSlot && isOvernightSlot(lastSlot)) {
        setError("Invalid Timeslot, Please Select A valid Timeslot.");
        setTimeout(() => setError(""), 4000);
        return prev;
      }

      return {
        ...prev,
        [day]: [
          ...daySlots,
          { startTime: "", endTime: "", ampmStart: "", ampmEnd: "" },
        ],
      };
    });
  };

  const saveTimeSlot = async (email) => {
    const selectedDays = weekDays.filter((_, index) => localCheckedDays[index]);

    const hasOverlap = (slots) => {
      return slots.some((slot, index) =>
        doesOverlap(
          slot,
          slots.filter((_, i) => i !== index)
        )
      );
    };

    const hasInvalidTimeslot = (slots) => {
      return slots.some((slot) => slot.startTime === slot.endTime);
    };

    function convertTimeToMinutes(time, period) {
      let [hour, minute] = time.split(":").map(Number);

      if (period.toUpperCase() === "PM" && hour !== 12) {
        hour += 12;
      } else if (period.toUpperCase() === "AM" && hour === 12) {
        hour = 0;
      }

      return hour * 60 + minute;
    }

    const hasSameSlot = (slots) => {
      return slots.some((slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        if (startTime === endTime) {
          return true;
        }

        return false;
      });
    };
    const cutoffTime = convertTimeToMinutes("8:00", "PM");
    const hasTimeslotAfterCutoff = (slots) => {
      return slots.some((slot) => {
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);
        return endTime > cutoffTime;
      });
    };

    const timeslotAfterCutoffDays = selectedDays.filter(
      (day) =>
        localTimeslots[day] && hasTimeslotAfterCutoff(localTimeslots[day])
    );
    const cutoffTimeStart = convertTimeToMinutes("6:00", "AM");
    const hasTimeslotBeforeCutoff = (slots) => {
      return slots.some((slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        return startTime < cutoffTimeStart;
      });
    };

    const timeslotBeforeCutoffDays = selectedDays.filter(
      (day) =>
        localTimeslots[day] && hasTimeslotBeforeCutoff(localTimeslots[day])
    );
    const hasOvernightSlot = (slots) => {
      return slots.some((slot) => {
        const startTime = convertTimeToMinutes(slot.startTime, slot.ampmStart);
        const endTime = convertTimeToMinutes(slot.endTime, slot.ampmEnd);

        // if (startTime === endTime) {
        //   return true;
        // }

        if (endTime < startTime) {
          return true;
        }

        return false;
      });
    };

    const emptyDays = selectedDays.filter(
      (day) => !localTimeslots[day] || localTimeslots[day].length === 0
    );

    const overlappingDays = selectedDays.filter(
      (day) => localTimeslots[day] && hasOverlap(localTimeslots[day])
    );

    const invalidTimeslotDays = selectedDays.filter(
      (day) => localTimeslots[day] && hasInvalidTimeslot(localTimeslots[day])
    );

    const overnightSlotDays = selectedDays.filter(
      (day) => localTimeslots[day] && hasOvernightSlot(localTimeslots[day])
    );

    const sameSlots = selectedDays.filter(
      (day) => localTimeslots[day] && hasSameSlot(localTimeslots[day])
    );

    const incompleteDays = selectedDays.filter(
      (day) => localTimeslots[day] && !isLastSlotFilled(localTimeslots[day])
    );

    if (emptyDays.length > 0) {
      setError(`Please Select Timeslots For: ${emptyDays.join(", ")}`);
      setTimeout(() => setError(""), 4000);
    } else if (timeslotAfterCutoffDays.length > 0) {
      setError(
        `Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot In: ${timeslotAfterCutoffDays.join(
          ", "
        )}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (timeslotBeforeCutoffDays.length > 0) {
      setError(
        `Timeslot Must Start After 6 AM And End Before 8 PM. Please Adjust The Timeslot In: ${timeslotBeforeCutoffDays.join(
          ", "
        )}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (overlappingDays.length > 0) {
      setError(
        `There Are Overlapping Timeslots For: ${overlappingDays.join(", ")}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (incompleteDays.length > 0) {
      setError("Please Fill Out The Timeslots Before Saving.");
      setTimeout(() => setError(""), 4000);
    }
    // else if (invalidTimeslotDays.length > 0) {
    //   setError(
    //     `Invalid Timeslots Found In: ${invalidTimeslotDays.join(
    //       ", "
    //     )} (Start Time Cannot Be The Same As End Time)`
    //   );
    //   setTimeout(() => setError(""), 4000);
    // }
    else if (sameSlots.length > 0) {
      setError(
        `Start Time And End Time Cannot Be Same In: ${sameSlots.join(", ")}`
      );
      setTimeout(() => setError(""), 4000);
    } else if (overnightSlotDays.length > 0) {
      setError(
        `Overnight Timeslots Found In: ${overnightSlotDays.join(
          ", "
        )} (Start Time Cannot Be greater Than End Time)`
      );
      setTimeout(() => setError(""), 4000);
    } else {
      setSelectedProvider("");
      setIsSlotModelOpen(false);

      const filteredTimeslots = {};

      // Set state with local variables
      setTimeslots(localTimeslots);
      setCheckedDays(localCheckedDays);

      // Iterate over localTimeslots instead of timeslots
      for (const [day, slots] of Object.entries(localTimeslots)) {
        if (slots.length > 0) {
          filteredTimeslots[day] = slots;
        }
      }

      console.log(
        "filteredTimeslot",
        convertToDesiredFormat(filteredTimeslots)
      );

      try {
        const response = await postData(
          "practitioners/practitionersWorkingHoursMgmt",
          convertToDesiredFormat(filteredTimeslots)
        );
        console.log(response);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    }
  };

  const handleDeleteSlot = (day, idx) => {
    setLocalTimeslots((prev) => {
      const updatedDaySlots = prev[day].filter((_, i) => {
        return i !== idx;
      });

      return {
        ...prev,
        [day]: updatedDaySlots,
      };
    });
  };

  const isLastSlotFilled = (slots) => {
    return slots.every(
      (slot) => slot.startTime && slot.endTime && slot.ampmStart && slot.ampmEnd
    );
  };

  const handleSlotModal = (email) => {
    setSelectedProvider(email);
    setIsSlotModelOpen(true);
    console.log("timeslots", timeslots);
    setLocalTimeslots(timeslots);
    console.log("local", localTimeslots);
    setLocalCheckedDays(checkedDays);
  };

  const handleSelectChange = (day, idx, field, value) => {
    setLocalTimeslots((prev) => ({
      ...prev,
      [day]: prev[day].map((slot, i) =>
        i === idx ? { ...slot, [field]: value } : slot
      ),
    }));
  };
  // const toggleStatus = async () => {
  //   const newIsActive = !isActive;
  //   setIsActive(newIsActive);

  //   try {
  //     const payload = {
  //       modelName: "practitioners",

  //       id: practitioner_id,

  //       inputData: {
  //         is_active_today: newIsActive ? 1 : 0,
  //       },
  //     };

  //     const response = await postData("masters/createAndUpdateMaster", payload);
  //   } catch (error) {
  //     console.error("Error updating active status:", error);
  //   }
  // };

  const toggleStatus = async () => {
    const newIsActive = !isActive;

    if (!newIsActive) {
      const result = await Swal.fire({
        title: "Are You Sure?",
        text: "Only Providers Who Are 'Available' Will Be Visible For Scheduling.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change It!",
        cancelButtonText: "No, Cancel!",
      });

      if (!result.isConfirmed) {
        return;
      }
    }

    setIsActive(newIsActive);

    try {
      const payload = {
        modelName: "practitioners",
        id: practitioner_id,
        inputData: {
          is_active_today: newIsActive ? 1 : 0,
        },
      };

      const response = await postData("masters/createAndUpdateMaster", payload);
      await Swal.fire({
        title: "Status Updated!",
        text: `The Provider's Status Has Been ${newIsActive ? "Activated" : "Deactivated"
          } Successfully.`,
        icon: "success",
        confirmButtonColor: "#3085d6",
      });
    } catch (error) {
      console.error("Error updating active status:", error);
    }
  };

  return (
    <div className="flex justify-between">
      <div
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className="w-2/6 m-2 py-2 cursor-pointer"
      >
        <img
          src={src || "/placeholder.png"}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/placeholder.png";
          }}
          className="rounded-full mx-auto w-10 h-10"
          alt={name}
        />
        <div className="w-full font-fontPoppins text-xs font-semibold text-center">
          {name}
        </div>
      </div>

      <div className="pt-2 pl-2 my-1 bg-white w-4/12 rounded">
        {Object.entries(timeslots).some(
          ([day, slots]) =>
            checkedDays[weekDays.indexOf(day)] && slots.length > 0
        ) ? (
          // Sort the entries by the order of weekdays
          Object.entries(timeslots)
            .sort((a, b) => weekDays.indexOf(a[0]) - weekDays.indexOf(b[0]))
            .map(([day, slots]) =>
              checkedDays[weekDays.indexOf(day)]
                ? slots.map((slot, idx) => (
                  <div key={idx} className="flex justify-between items-start">
                    <div className="">{day.slice(0, 3)}:</div>
                    <div className="w-3/4">
                      {slot.startTime} {slot.ampmStart}-{slot.endTime}{" "}
                      {slot.ampmEnd}
                    </div>
                  </div>
                ))
                : null
            )
        ) : (
          <div
            onClick={() => handleSlotModal(practitioner_id)}
            className="text-blue-500 text-base font-bold cursor-pointer"
          >
            Add
          </div>
        )}

        {Object.entries(timeslots).some(
          ([day, slots]) =>
            checkedDays[weekDays.indexOf(day)] && slots.length > 0
        ) && (
            <div
              onClick={() => handleSlotModal(practitioner_id)}
              className="text-blue-500 text-base font-bold cursor-pointer"
            >
              Edit
            </div>
          )}
      </div>

      <div>
        <div className="p-2 m-6 mr-5 h-10 bg-white rounded w-32">
          <div onClick={toggleStatus}>
            {isActive ? (
              <div className="flex items-center text-green-500 font-bold cursor-pointer">
                <div
                  className="w-3 h-3 bg-green-500 rounded-full mr-2 "
                  style={{ marginTop: "1px" }}
                />
                <span>Available</span>
              </div>
            ) : (
              <div className="flex items-center text-red-500 font-bold cursor-pointer">
                <div
                  className="w-3 h-3 bg-red-500 rounded-full mr-2 "
                  style={{ marginTop: "1px" }}
                />
                <span>Unavailable</span>
              </div>
            )}
          </div>
        </div>
      </div>

      {isSlotModalOpen && selectedProvider === practitioner_id && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-85 backdrop-filter backdrop-blur-sm flex justify-center items-center">
          <div className="bg-white rounded-lg md:w-3/5 shadow-lg border border-gray-200 h-2/3  sm:w-3/4 overflow-x-auto overflow-y-auto">
            {/* <div className="bg-white rounded-lg w-full sm:w-3/4 md:w-1/2 lg:w-1/2 xl:w-1/3 md:h-2/3 sm:h-2/3 lg:h-4/5 xl:h-5/6 shadow-lg border border-gray-200 overflow-auto"> */}
            <div className="sticky top-0 bg-white border-b border-gray-300 z-10">
              <div className="flex p-2 px-4 justify-center items-center">
                <p className="text-xl sm:text-xl font-semibold">
                  Working Hours
                </p>
              </div>
            </div>
            <div>
              {weekDays.map((day, index) => (
                <div key={index} className="flex p-2  px-4">
                  <div className="flex items-start mr-3 m-3 w-32 max-h-auto">
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-500 mr-2 mt-1"
                      checked={localCheckedDays[index]}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <span>{day}</span>
                  </div>

                  {localCheckedDays[index] && (
                    <div className="w-full flex flex-col justify-center">
                      <div className="w-full flex flex-col ">
                        {(localTimeslots[day] || []).map((slot, idx) => (
                          <div
                            key={idx}
                            className="flex max-1000:flex-col items-center max-1000:space-y-2 space-x-2 mt-2"
                          >
                            <CustomSelect
                              name="Start Time"
                              className="border rounded m-3 p-1 text-gray-700 "
                              value={slot.startTime}
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "startTime",
                                  e.target.value
                                )
                              }
                              options={timeOptions.map((time, optionIdx) => ({
                                value: time.time,
                                label: time.label,
                              }))}
                            />
                            {/* { => (
                                <option key={optionIdx} value={time}>
                                  {time}
                                </option>
                              ))} */}
                            {/* </select> */}
                            <CustomSelect
                              className="border rounded p-1 text-gray-700"
                              name="AM/PM"
                              value={slot.ampmStart}
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "ampmStart",
                                  e.target.value
                                )
                              }
                              options={[
                                { value: "AM", label: "AM" },
                                { value: "PM", label: "PM" },
                              ]}
                            />

                            <span className="text-gray-700">to</span>

                            <CustomSelect
                              className="border rounded p-1 text-gray-700 w-10 "
                              name="End Time"
                              value={slot.endTime}
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "endTime",
                                  e.target.value
                                )
                              }
                              options={timeOptions.map((time, optionIdx) => ({
                                value: time.time,
                                label: time.label,
                              }))}
                            />
                            <CustomSelect
                              className="border rounded p-1 text-gray-700"
                              value={slot.ampmEnd}
                              name="AM/PM"
                              onChange={(e) =>
                                handleSelectChange(
                                  day,
                                  idx,
                                  "ampmEnd",
                                  e.target.value
                                )
                              }
                              options={[
                                { value: "AM", label: "AM" },
                                { value: "PM", label: "PM" },
                              ]}
                            />

                            <span className="ml-3 cursor-pointer ">
                              <i
                                onClick={() => handleDeleteSlot(day, idx)}
                                className="fa-solid fa-close"
                              ></i>
                            </span>
                          </div>
                        ))}
                      </div>
                      <button
                        onClick={() => addTimeslot(day)}
                        className=" w-1/6 mt-3 p-1 ml-2 bg-gray-500 text-white rounded "
                        title="Add Timeslot"
                      >
                        <i className="fa-solid fa-plus text-xl"></i>
                      </button>

                      {timeslotError && (
                        <p className="text-red-500 text-sm mt-2 ">
                          {timeslotError}
                        </p>
                      )}
                    </div>
                  )}

                  {!localCheckedDays[index] && (
                    <div className="text-gray-500 text-sm mt-3">
                      Unavailable
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="sticky bottom-0 border-0 bg-white border-t border-gray-300  ">
              <div className=" h-20 flex justify-center items-center relative">
                <div className="flex flex-col items-center">
                  <div className="flex justify-between items-center ">
                    <button
                      onClick={handleSlotModal}
                      className="ml-2 p-2 bg-blue-500 text-white rounded "
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => saveTimeSlot(practitioner_id)}
                      className="ml-2 p-2 bg-blue-500 text-white rounded "
                    >
                      Submit
                    </button>
                  </div>
                  {error && (
                    <p className="text-red-500 text-base font-semibold absolute mt-9 ">
                      {error}
                    </p>
                  )}
                  {saveError && (
                    <p className="text-green-500 text-base font-semibold  absolute mt-9">
                      {saveError}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ServiceRow = ({
  service,
  onDropProvider,
  onRemoveProvider,
  isChecked,
}) => {
  console.log("mapping", isChecked);
  const isCheckedRef = useRef(isChecked);

  useEffect(() => {
    isCheckedRef.current = isChecked;
  }, [isChecked]);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.PROVIDER,
      drop: (item) => {
        console.log("Dropping provider with mapping:", isChecked);
        onDropProvider(service, item, isCheckedRef.current);
      },

      canDrop: (item) => {
        // console.log("Item being dropped:", item);
        // console.log("Current providers:", service.providers);

        // Check if any provider with the same name exists
        const canDrop = !service.providers.some(
          (provider) => provider.practitioner_id === item.practitioner_id
        );

        // console.log("Can drop:", canDrop);
        return canDrop;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),

    [service.providers, isChecked]
  );

  const removeItem = (provider) => {
    onRemoveProvider(service, provider);
  };

  return (
    // <div
    //   ref={drop}
    //   className={` w-full overflow-auto border border-l-0 border-r-0 border-t-0 border-solid border-gray-100 py-3 flex items-center gap-3 xss:overflow-auto`}
    // >
    //   <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full w-1/5  py-3">
    //     {service?.services}
    //   </button>
    //   <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full  w-8 p-2">
    //     {service?.duration}
    //   </button>
    //   <span className="text-gray-400">
    //     <i className="fa-solid fa-play fa-lg"></i>
    //   </span>
    //   {service &&
    //   service.providers &&
    //   Array.isArray(service.providers) &&
    //   service.providers.length > 0 ? (
    //     service.providers.map((provider, index) => (
    //       <div
    //         key={index}
    //         className="w-auto p-0 m-0 flex flex-col items-center justify-center"
    //       >
    //         <img
    //           src={provider?.src}
    //           className="rounded-full mx-auto w-10 h-10"
    //           alt={provider?.name}
    //           onError={(e) => {
    //             e.target.onerror = null;
    //             e.target.src = "/placeholder.png";
    //           }}
    //         />
    //         <div className="font-fontPoppins text-xs font-semibold text-center">
    //           {provider?.name}
    //         </div>
    //         <span
    //           onClick={() => removeItem(provider)}
    //           className="cursor-pointer"
    //         >
    //           <i className="  fa-regular fa-circle-xmark"></i>
    //         </span>
    //       </div>
    //     ))
    //   ) : (
    //     <div className="text-gray-400 text-sm">No Providers Added</div>
    //   )}
    // </div>
    <div
      ref={drop}
      className={`w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 pr-0 flex items-center gap-3 xss:overflow-auto`}
    >
      <button className="font-fontPoppins  text-xs font-medium bg-white shadow-sm rounded-full w-[120px]  py-3">
        {service?.services}
      </button>
      <button className="font-fontPoppins text-xs font-medium bg-white shadow-sm rounded-full  min-w-[40px] p-2">
        {service?.duration}
      </button>
      <span className="text-gray-400">
        <i className="fa-solid fa-play fa-lg"></i>
      </span>
      <div className=" gap-3 flex w-[400px] overflow-x-auto">
        {service &&
          service.providers &&
          Array.isArray(service.providers) &&
          service.providers.length > 0 ? (
          service.providers.map((provider, index) => (
            <div
              key={index}
              className="w-auto p-0 m-0 flex flex-col items-center justify-center"
            >
              <img
                src={provider?.src || "/placeholder.png"}
                className="rounded-full mx-auto w-10 h-10"
                alt={provider?.name}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/placeholder.png";
                }}
              />
              <div className="font-fontPoppins text-xs  font-semibold text-center">
                {provider?.name}
              </div>

              <span
                onClick={() => removeItem(provider)}
                className="cursor-pointer"
              >
                <i className="  fa-regular fa-circle-xmark"></i>
              </span>
            </div>
          ))
        ) : (
          <div className="text-gray-400 text-sm">No Providers Added</div>
        )}
      </div>
    </div>
  );
};

function ServiceProviderMappingProfile() {
  const [services, setServices] = useState([]);
  const [clinicServicesData, setClinicServicesData] = useState([]);
  const [clinicServicesMapping, setClinicServicesMapping] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNewService, setAddnewService] = useState("");
  const [addNewDuration, setAddNewDuration] = useState("");
  const [isAddNewService, setIsAddnewService] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { organizationData, providerData } = location.state || {};
  const [clinicId, setclinicId] = useState("");
  const [accountType, setAccountType] = useState("");
  const [errorInvalid, setErrorInvalid] = useState("");
  const [isSlotModalOpen, setIsSlotModelOpen] = useState(false);
  const [providerTimeslots, setProviderTimeSlots] = useState({});
  const navigate = useNavigate();
  const [mappingisChecked, setMappingisChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const backend =
    typeof window !== "undefined" &&
      window.navigator.userAgent.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i
      )
      ? TouchBackend
      : HTML5Backend;
  useEffect(() => {
    setMappingisChecked(setMappingisChecked);
  }, []);
  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");
    const storedAccountType = localStorage.getItem("accountType");

    setclinicId(storedClinicId);
    setAccountType(storedAccountType);
    fetchServices(storedClinicId).then(() => {
      getProviderList(storedClinicId);
    });
  }, []);

  useEffect(() => {
    if (services.length > 0) {
      const storedClinicId = localStorage.getItem("clinicId");
      fetchClinicServicesMapping(storedClinicId);
    }
  }, [providerList, clinicId]);

  const fetchServices = async (clinicId) => {
    try {
      setLoading(true);
      const payload = {
        modelName: "clinic_services",
        whereCondition: {
          clinic_id: clinicId,
          is_active: 1,
        },
      };
      const response = await postData("masters/getMasterList", payload);

      const servicesWithProviders = response.data.map((service) => ({
        ...service,
        providers: [],
      }));

      setServices(servicesWithProviders);

      const clinicServices = response.data.map((service) => ({
        services_id: service.id,
        name: service.services,
        duration: service.duration,
        is_active: 1,
      }));
      setClinicServicesData(clinicServices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching services:", error);
      setLoading(false);
    }
  };

  const getProviderList = async (clinicId) => {
    try {
      setLoading(true);
      var accountType = localStorage.getItem("accountType");
      var refernceId = localStorage.getItem("refernceId");

      if (accountType == "Practitioners") {
        var payload = {
          modelName: "practitioners",
          whereCondition: {
            id: refernceId,
            is_active: 1,
          },
        };
      } else if (accountType == "Assistances") {
        var payload = {
          modelName: "assistance_practitioners_mapping",
          pagination: {
            page: 1,
            pageSize: 500,
          },
          whereCondition: {
            clinic_id: clinicId,
            is_active: 1,
            assistance_id: refernceId,
          },
        };
        var assistance_practitioners_mapping_response = await postData(
          "masters/getMasterList",
          payload
        );
        if (assistance_practitioners_mapping_response.code === 1) {
          var assistance_practitioners_mapping_data =
            assistance_practitioners_mapping_response.data;
          var varpractitionerIds = [];
          for (
            let i = 0;
            i < assistance_practitioners_mapping_data.length;
            i++
          ) {
            const element = assistance_practitioners_mapping_data[i];
            varpractitionerIds.push(element.practitioner_id);
          }
        }

        var payload = {
          modelName: "practitioners",
          whereCondition: {
            id: varpractitionerIds,
            is_active: 1,
          },
        };
      } else {
        var payload = {
          modelName: "practitioners",
          whereCondition: {
            clinic_id: clinicId,
            is_active: 1,
          },
        };
      }

      const response = await postData("masters/getMasterList", payload);

      setProviderList(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching clinic details:", error);
    }
  };

  const fetchClinicServicesMapping = async (clinicId) => {
    try {
      setLoading(true);
      const payload = {
        modelName: "clinic_services_mapping",
        pagination: {
          page: 1,
          pageSize: 500,
        },
        whereCondition: {
          clinic_id: clinicId,
          is_active: 1,
        },
      };
      const response = await postData("masters/getMasterList", payload);

      const mappingByServiceId = response.data.reduce((acc, curr) => {
        if (!acc[curr.services_id]) {
          acc[curr.services_id] = [];
        }
        acc[curr.services_id].push(curr);
        return acc;
      }, {});

      const updatedServices = services.map((service) => {
        const mappings = mappingByServiceId[service.id] || [];
        const providers = mappings.map((mapping) => {
          const provider = providerList.find(
            (provider) => provider.id === mapping.practitioner_id
          );
          return provider
            ? {
              name: `${provider.f_name} ${provider.l_name}`,
              src: `${imageBaseUrl}/${provider.photo}`,
              practitioner_id: provider.id,
            }
            : null;
        });
        return {
          ...service,
          providers: providers.filter((provider) => provider !== null),
        };
      });

      setServices(updatedServices);

      const updatedClinicServicesMapping = response.data
        .map((mapping) => {
          const provider = providerList.find(
            (provider) => provider.id === mapping.practitioner_id
          );
          const providerName = provider
            ? `${provider.f_name} ${provider.l_name}`
            : "";

          return {
            services_id: mapping.services_id,
            services:
              services.find((service) => service.id === mapping.services_id)
                ?.services || "",
            name: providerName,
            provider_practitioner_id: mapping.practitioner_id,
            is_active: mapping.is_active,
          };
        })
        .filter((mapping) => mapping.name !== "");

      setClinicServicesMapping(
        updatedClinicServicesMapping.length > 0
          ? updatedClinicServicesMapping
          : []
      );
      setLoading(false);
    } catch (error) {
      console.error("Error fetching services:", error);
      setLoading(false);
    }
  };

  const handleDropProvider = (service, item, isChecked) => {
    console.log("mappingisChecked", mappingisChecked, service, item);

    if (isChecked === false) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    console.log(isChecked);

    setServices((prevServices) => {
      const updatedServices = prevServices.map((s) =>
        s.services === service.services
          ? {
            ...s,
            providers: [
              ...s.providers,
              {
                name: item.name,
                services: service.services,
                duration: service.duration,
                src: item.src,
                practitioner_id: item.practitioner_id,
                is_active: 1,
              },
            ],
          }
          : s
      );

      return updatedServices;
    });
    setClinicServicesMapping((prevMappings) => {
      const updatedMappings = [
        ...prevMappings,
        {
          services_id: service.id,
          services: service.services,
          duration: service.duration,
          name: item.name,
          provider_practitioner_id: item.practitioner_id,
          is_active: 1,
        },
      ];
      console.log("data", updatedMappings, clinicServicesData);
      setTimeout(async () => {
        if (updatedMappings.length > 0 && clinicServicesData.length > 0) {
          setError("");

          const existingServices1 = clinicServicesData.filter(
            (service) => service.services_id > 0
          );
          const existingServices2 = clinicServicesData.filter(
            (service) => service.services_id === 0
          );

          const mappedServices = existingServices1.map((service) => ({
            id: service.services_id,
            services: service.name,
            duration: service.duration,
          }));

          if (existingServices2.length > 0) {
            mappedServices.push(
              ...existingServices2.map((service) => ({
                services: service.name,
                duration: service.duration,
              }))
            );
          }

          const servicesMapping = updatedMappings.map((mapping) => {
            if (mapping.services_id === 0) {
              return {
                services_id: mapping.services_id,
                services: mapping.services,
                duration: mapping.duration,
                practitioner_id: mapping.provider_practitioner_id,
              };
            } else {
              return {
                services_id: mapping.services_id,
                practitioner_id: mapping.provider_practitioner_id,
              };
            }
          });

          const payload = {
            clinic_id: clinicId,
            services: mappedServices,
            servicesMapping: servicesMapping,
          };

          console.log("payload1---->", payload);
          // return false;
          try {
            // setLoading(true);
            const response = await postData(
              "practitioners/practitionersServiceMapping",
              payload
            );

            if (response.success) {
              setLoading(false);
              console.log(
                "Success",
                "Mapping  Updated Successfully",
                "success"
              );
            } else {
              setLoading(false);
              Swal.fire("Error", "Failed to Update Mapping ", "error");
            }
          } catch (error) {
            setLoading(false);
            console.error("Error posting data:", error);
            Swal.fire(
              "Error",
              "Failed to Update Mapping or Working Hours",
              "error"
            );
          }
        } else {
          setLoading(false);
          setError("Please Add Providers To Appointment Type");
          setTimeout(() => setError(""), 3000);
        }
      }, 0);

      return updatedMappings;
    });
    console.log("data--", clinicServicesData, clinicServicesMapping);
  };

  const handleRemoveProvider = (service, provider) => {
    if (mappingisChecked === false) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    // if (clinicServicesMapping.length === 1) {
    //   Swal.fire(
    //     "Error",
    //     "There Must Be At Least One Provider Mapped To the Appointment Type",
    //     "error"
    //   );
    //   return;
    // }
    setServices((prevServices) => {
      const updatedServices = prevServices.map((s) =>
        s.services === service.services
          ? {
            ...s,
            providers: s.providers.filter(
              (p) => p.practitioner_id !== provider.practitioner_id
            ),
          }
          : s
      );

      return updatedServices;
    });

    setClinicServicesMapping((prevMappings) => {
      const updatedMappings = prevMappings.filter(
        (mapping) =>
          !(
            mapping.services === service.services &&
            mapping.name === provider.name &&
            mapping.provider_practitioner_id === provider.practitioner_id
          )
      );
      setTimeout(async () => {
        // if (updatedMappings.length > 0 && clinicServicesData.length > 0) {
        setError("");

        const existingServices1 = clinicServicesData.filter(
          (service) => service.services_id > 0
        );
        const existingServices2 = clinicServicesData.filter(
          (service) => service.services_id === 0
        );

        const mappedServices = existingServices1.map((service) => ({
          id: service.services_id,
          services: service.name,
          duration: service.duration,
        }));

        if (existingServices2.length > 0) {
          mappedServices.push(
            ...existingServices2.map((service) => ({
              services: service.name,
              duration: service.duration,
            }))
          );
        }

        const servicesMapping = updatedMappings.map((mapping) => {
          if (mapping.services_id === 0) {
            return {
              services_id: mapping.services_id,
              services: mapping.services,
              duration: mapping.duration,
              practitioner_id: mapping.provider_practitioner_id,
            };
          } else {
            return {
              services_id: mapping.services_id,
              practitioner_id: mapping.provider_practitioner_id,
            };
          }
        });

        const payload = {
          clinic_id: clinicId,
          services: mappedServices,
          servicesMapping: servicesMapping,
        };

        console.log("payload1---->", payload);
        // return false;
        try {
          // setLoading(true);
          const response = await postData(
            "practitioners/practitionersServiceMapping",
            payload
          );

          if (response.success) {
            setLoading(false);
            console.log("Success", "Mapping  Updated Successfully", "success");
          } else {
            setLoading(false);
            Swal.fire("Error", "Failed to Update Mapping ", "error");
          }
        } catch (error) {
          setLoading(false);
          console.error("Error posting data:", error);
          Swal.fire(
            "Error",
            "Failed to Update Mapping or Working Hours",
            "error"
          );
        }
        // }
        // else {
        //   setLoading(false);
        //   setError("Please Add Providers To Appointment Type");
        //   setTimeout(() => setError(""), 3000);
        // }
      }, 0);
      return updatedMappings;
    });
  };

  const handleModal = () => {
    console.log(clinicServicesMapping, clinicServicesData);

    setTimeout(async () => {
      // if (!mappingisChecked) {
      //   setErrorMessage(
      //     "You Must Verify The Information And Provide Your Consent."
      //   );
      //   setTimeout(() => setErrorMessage(""), 2000);
      //   return;
      // }

      const existingServices1 = clinicServicesData.filter(
        (service) => service.services_id > 0
      );
      const existingServices2 = clinicServicesData.filter(
        (service) => service.services_id === 0
      );

      const mappedServices = existingServices1.map((service) => ({
        id: service.services_id,
        services: service.name,
        duration: service.duration,
      }));

      if (existingServices2.length > 0) {
        mappedServices.push(
          ...existingServices2.map((service) => ({
            services: service.name,
            duration: service.duration,
          }))
        );
      }

      const servicesMapping = clinicServicesMapping.map((mapping) => {
        if (mapping.services_id === 0) {
          return {
            services_id: mapping.services_id,
            services: mapping.services,
            duration: mapping.duration,
            practitioner_id: mapping.provider_practitioner_id,
          };
        } else {
          return {
            services_id: mapping.services_id,
            practitioner_id: mapping.provider_practitioner_id,
          };
        }
      });

      const payload = {
        clinic_id: clinicId,
        services: mappedServices,
        servicesMapping: servicesMapping,
      };

      console.log("payload1---->", payload);
      // return false;
      try {
        // setLoading(true);
        const response = await postData(
          "practitioners/practitionersServiceMapping",
          payload
        );

        if (response.success) {
          // setLoading(false);
          // Swal.fire("Success", "Mapping  Updated Successfully", "success");
        } else {
          setLoading(false);
          Swal.fire("Error", "Failed to Update Mapping ", "error");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error posting data:", error);
        Swal.fire(
          "Error",
          "Failed to Update Mapping or Working Hours",
          "error"
        );
      }
      // } else {
      //   setLoading(false);
      //   setError("Please Add Providers To Appointment Type");
      //   setTimeout(() => setError(""), 3000);
      // }
    }, 0);
    setIsModalOpen(false);
    setIsAddnewService(false);
    setAddnewService("");
    setAddNewDuration("");
  };

  const HandleAddNewService = () => {
    setIsAddnewService(true);
  };

  const handleAddService = (e) => {
    e.preventDefault();
    if (mappingisChecked === false) {
      setError("You Must Verify The Information And Provide Your Consent.");
      setTimeout(() => setError(""), 2000);
      return;
    }
    if (addNewService.trim() && addNewDuration.trim()) {
      const serviceExists = services.some(
        (service) =>
          service.services.toLowerCase() === addNewService.trim().toLowerCase()
      );

      if (serviceExists) {
        setError("Service Already Exists");
        setTimeout(() => setError(""), 2000);
        return;
      }
      const newServices = [
        ...services,
        {
          services: addNewService.trim(),
          duration: addNewDuration.trim(),
          id: 0,
          providers: [],
        },
      ];
      setServices(newServices);

      const newClinicService = {
        name: addNewService.trim(),
        duration: addNewDuration.trim(),
        services_id: 0,
        is_active: 1,
      };
      const newClinicServicesData = [...clinicServicesData, newClinicService];

      setClinicServicesData(newClinicServicesData);

      setAddnewService("");
      setAddNewDuration("");
      setIsAddnewService(false);
    } else {
      setError("Please Enter Both Appointment Type And Duration");
      setTimeout(() => setError(""), 2000);
    }
  };

  // const handleDeleteService = (serviceName) => {
  //   // if (clinicServicesData.length === 1) {
  //   //   setError("");
  //   //   Swal.fire(
  //   //     "Error",
  //   //     "There Must Be At Least One Appointment Type",
  //   //     "error"
  //   //   );
  //   //   return;
  //   // }
  //   const filteredServices = services.filter(
  //     (service) => service.services !== serviceName
  //   );
  //   setServices(filteredServices);

  //   const filteredClinicServicesData = clinicServicesData.filter(
  //     (service) => service.name !== serviceName
  //   );
  //   setClinicServicesData(filteredClinicServicesData);

  //   const filteredClinicServicesMapping = clinicServicesMapping.filter(
  //     (mapping) => mapping.services !== serviceName
  //   );
  //   setClinicServicesMapping(filteredClinicServicesMapping);
  // };
  const handleDeleteService = async (serviceName) => {
    const result = await Swal.fire({
      title: "Are You Sure?",
      text: "You Won't Be Able To Revert This!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete It!",
      cancelButtonText: "No, Cancel!",
    });

    if (result.isConfirmed) {
      // Proceed with the deletion
      const filteredServices = services.filter(
        (service) => service.services !== serviceName
      );
      setServices(filteredServices);

      const filteredClinicServicesData = clinicServicesData.filter(
        (service) => service.name !== serviceName
      );
      setClinicServicesData(filteredClinicServicesData);

      const filteredClinicServicesMapping = clinicServicesMapping.filter(
        (mapping) => mapping.services !== serviceName
      );
      setClinicServicesMapping(filteredClinicServicesMapping);

      // Swal.fire(
      //   "Deleted!",
      //   `The service "${serviceName}" has been deleted.`,
      //   "success"
      // );
    } else {
      // Optionally handle the cancel action here
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const onlyNumbersRegex = /^\d+$/;
      const onlySpecialCharsRegex = /^[!@#$%^&*(),.?":{}|<>0-9]+$/;

      if (onlyNumbersRegex.test(value) || onlySpecialCharsRegex.test(value)) {
        setErrorInvalid("Invalid Appointment Type");
        setAddnewService("");
      } else {
        setAddnewService(value);
        setErrorInvalid("");
      }
    } else if (name === "duration") {
      const onlyNumbersRegex = /^\d+$/;

      if (onlyNumbersRegex.test(value)) {
        setAddNewDuration(value);
        setErrorInvalid("");
      } else {
        setErrorInvalid("Invalid Duration");
        setAddNewDuration("");
      }
    }
  };

  const handleSubmit = async () => {
    if (!mappingisChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }
    if (clinicServicesMapping.length > 0 && clinicServicesData.length > 0) {
      setError("");

      const existingServices1 = clinicServicesData.filter(
        (service) => service.services_id > 0
      );
      const existingServices2 = clinicServicesData.filter(
        (service) => service.services_id === 0
      );

      const mappedServices = existingServices1.map((service) => ({
        id: service.services_id,
        services: service.name,
        duration: service.duration,
      }));

      if (existingServices2.length > 0) {
        mappedServices.push(
          ...existingServices2.map((service) => ({
            services: service.name,
            duration: service.duration,
          }))
        );
      }

      const servicesMapping = clinicServicesMapping.map((mapping) => {
        if (mapping.services_id === 0) {
          return {
            services_id: mapping.services_id,
            services: mapping.services,
            duration: mapping.duration,
            practitioner_id: mapping.provider_practitioner_id,
          };
        } else {
          return {
            services_id: mapping.services_id,
            practitioner_id: mapping.provider_practitioner_id,
          };
        }
      });

      const payload = {
        clinic_id: clinicId,
        services: mappedServices,
        servicesMapping: servicesMapping,
      };

      console.log("payload---->", payload);
      // return false;
      try {
        // setLoading(true);
        const response = await postData(
          "practitioners/practitionersServiceMapping",
          payload
        );

        if (response.success) {
          // setLoading(false);
          console.log("Mapping  Updated Successfully", "success");
        } else {
          setLoading(false);
          Swal.fire("Error", "Failed to Update Mapping ", "error");
        }
      } catch (error) {
        setLoading(false);
        console.error("Error posting data:", error);
        Swal.fire(
          "Error",
          "Failed to Update Mapping or Working Hours",
          "error"
        );
      }
    } else {
      setLoading(false);
      setError("Please Add Providers To Appointment Type");
      setTimeout(() => setError(""), 3000);
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <DndProvider backend={backend}>
            <section className="w-full">
              <div className="container mx-auto px-4">
                <div>
                  <h1 className="font-fontPoppins md:text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal">
                    Appointment To Provider Mapping
                  </h1>
                  <h2 className="font-fontPoppins text-center text-gray-700 mt-2 text-l font-bold  xss:text-center sm:text-center">
                    Drag The Provider Icon To The Appointment Type-Row To Map
                  </h2>
                </div>

                <div className="w-full mt-4 md:flex md:justify-center md:gap-4">
                  <div className="md:w-4/5 xss:w-full tiny:w-full xss-w-full sm:w-full">
                    <div className="font-fontPoppins text-sm font-semibold px-3  pl-17">
                      Add Appointment Type{" "}
                      {/* <span class="bg-gray-500 rounded-full ml-1 p-1 hover:bg-gray-400 cursor-pointer shadow-md transition duration-300">
                  <i
                    onClick={() => setIsModalOpen(true)}
                    className="fa-solid fa-gear fa-xs text-gray-700"
                  ></i> 
                  </span> */}
                      <span class="rounded-full ml-2 p-1 bg-gray-100 cursor-pointer shadow-md transition duration-100">
                        <i
                          onClick={() => setIsModalOpen(true)}
                          class="fa-solid fa-plus fa-lg text-gray-900 hover:text-gray-100"
                          title="Click here to add Appointment services"
                        ></i>
                      </span>
                    </div>
                    {/* <div className="w-full min-h-full bg-grayColorLight rounded-lg mt-1"> */}
                    <div className="w-full min-h-96 bg-grayColorLight rounded-lg mt-3 max-h-96 overflow-y-auto">
                      <div className="w-full border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 flex items-center gap-3 xss:overflow-auto">
                        <div className="  text-l text-center min-w-[120px] text-center  font-semibold">
                          Appointment <br /> Type
                        </div>
                        <div className="text-l text-center min-w-[40px]  font-semibold">
                          Duration
                        </div>
                        <div className=" text-l w-[330px] text-center font-semibold">
                          Providers
                        </div>
                      </div>

                      {services &&
                        services.map(
                          (service, index) =>
                            service && (
                              <div
                                key={index}
                              // className=" overflow-x-auto border border-l-0 border-r-0 border-t-0 border-solid border-gray-200 p-3 flex items-center gap-3 xss:overflow-auto"
                              >
                                <ServiceRow
                                  service={service}
                                  onDropProvider={handleDropProvider}
                                  onRemoveProvider={handleRemoveProvider}
                                  isChecked={mappingisChecked}
                                />
                              </div>
                            )
                        )}
                    </div>
                  </div>

                  <div className="md:w-1/5 xss:w-full tiny:w-full sm:w-full ">
                    <div className="font-fontPoppins text-sm font-semibold px-3 text-center">
                      Providers
                    </div>
                    {/* <div className="w-full min-h-full bg-grayColorLight rounded-lg p-3 mt-1"> */}
                    <div className="w-full min-h-96 bg-grayColorLight rounded-lg max-h-96 mt-3 overflow-y-auto">
                      <div className="w-full border-b py-4 border-solid border-gray-200 flex justify-between  items-center ">
                        <div className="text-l w-2/6  text-center py-2 font-semibold">
                          Providers
                        </div>
                        <div
                          // className="  text-l pt-2 pl-8 my-1 w-4/12 text-center font-semibold"

                          className=" text-l  my-1  text-center w-4/12 font-semibold"
                        // style={{ paddingLeft: "60px" }}
                        >
                          Working Hours
                        </div>
                        <div
                          // className=" text-l text-center rounded w-24  font-semibold"
                          className="p-2 ml-6 mr-5  font-semibold w-32 text-center"
                        >
                          Status
                        </div>
                      </div>
                      {providerList &&
                        providerList.map((provider, index) => (
                          <Provider
                            key={index}
                            name={`${provider.f_name} ${provider.l_name}`}
                            src={`${imageBaseUrl}/${provider.photo}`}
                            practitioner_id={provider.id}
                            email={provider.email}
                            isSlotModalOpen={isSlotModalOpen}
                            setIsSlotModelOpen={setIsSlotModelOpen}
                            providerTimeslots={providerTimeslots}
                            setProviderTimeSlots={setProviderTimeSlots}
                            isActiveStatus={provider.is_active_today}
                          />
                        ))}
                    </div>
                  </div>
                </div>
                <div className="flex mt-4 items-center gap-2">
                  <input
                    type="checkbox"
                    checked={true}
                  // onChange={(e) => setMappingisChecked(e.target.checked)}
                  />

                  <span className="font-fontPoppins text-xs font-semibold relative">
                    I have verified all the information and provide consent to
                    Samara Well to use and display this information accordingly.
                  </span>
                </div>
                {errorMessage && (
                  <p className="text-red-500  text-xs absolute">
                    {errorMessage}
                  </p>
                )}
              </div>
            </section>
            {isModalOpen && (
              <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-75 backdrop-filter backdrop-blur-sm flex justify-center items-center p-4 sm:p-6 lg:p-8">
                <div className="bg-white rounded-lg w-full sm:w-3/4 lg:w-1/2 shadow-lg border border-gray-200">
                  <div className="flex justify-between items-center border-b border-gray-300 p-2 px-4">
                    <p className="text-lg sm:text-xl font-semibold">
                      Appointment Types
                    </p>
                    <button
                      onClick={HandleAddNewService}
                      className="text-blue-700 border border-blue-700 bg-transparent px-2 py-1 hover:text-blue-400 rounded-full focus:outline-none"
                    >
                      Add New Appointment Type
                    </button>
                  </div>
                  <div className="p-2 px-4 flex justify-between border-b border-gray-200 font-semibold">
                    <p className="w-1/3 text-center text-l sm:text-sm">
                      Appointment Type
                    </p>
                    <p className="w-1/3 text-center text-l sm:text-sm">
                      Duration In Minutes
                    </p>
                    <p className="w-1/3 text-center text-l sm:text-sm">
                      Action
                    </p>
                  </div>
                  {services &&
                    services.map((service, index) => (
                      <div
                        key={index}
                        className="flex p-2 px-4 justify-between items-center border-b border-gray-200"
                      >
                        <p className="w-1/3 text-center text-s sm:text-sm">
                          {service?.services}
                        </p>
                        <p className="w-1/3 text-center text-s sm:text-sm">
                          {service?.duration}
                        </p>
                        <div className="w-1/3 flex justify-center">
                          <span
                            onClick={() =>
                              handleDeleteService(service.services)
                            }
                            className="text-gray-400 cursor-pointer hover:text-red-500 text-s sm:text-sm"
                          >
                            <i className="fa-solid fa-trash"></i> Delete
                          </span>
                        </div>
                      </div>
                    ))}
                  {isAddNewService && (
                    <>
                      <div className="p-2 px-4 flex justify-between border-b border-gray-200">
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter Appointment Type"
                          className="border border-black rounded-lg py-2 px-3 mb-3 mr-4 w-full shadow-sm"
                          value={addNewService}
                          onChange={handleChange}
                        />

                        <input
                          type="text"
                          name="duration"
                          placeholder="Enter Duration In Minutes"
                          className="border border-black rounded-lg py-2 px-3 mb-3 w-full shadow-sm"
                          value={addNewDuration}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="text-red-500 ml-2">{error}</div>
                      <div className="flex justify-center items-center mt-4">
                        <span
                          onClick={handleAddService}
                          className="text-black mr-3 cursor-pointer hover:text-blue-500 text-l sm:text-sm"
                        >
                          <i className="fas fa-add"></i> Add
                        </span>
                        <span
                          onClick={() => {
                            setIsAddnewService(false);
                            setErrorInvalid("");
                            setAddnewService("");
                            setAddNewDuration("");
                          }}
                          className="text-black mr-2 cursor-pointer hover:text-red-500 text-l sm:text-sm"
                        >
                          <i className="fa-solid fa-trash"></i> Cancel
                        </span>
                      </div>
                    </>
                  )}
                  <div className="flex justify-center pb-2">
                    <span
                      onClick={handleModal}
                      className="mr-2 cursor-pointer text-l pt-14 sm:text-sm"
                    >
                      <i className="fa-solid fa-close"></i> Close
                    </span>
                  </div>
                </div>
              </div>
            )}
          </DndProvider>
          <section className="w-full mt-10 xss:mt-5 sm:mt-5">
            {/* <div className="container mx-auto px-4 text-center">
              <button
                onClick={handleSubmit}
                className="w-40 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
              >
                <span className="me-2">Submit</span>
              </button>
              {<p className="text-red-500 ml-2">{error}</p>}
            </div> */}
          </section>

          <Footer />
        </div>
      </>
    </>
  );
}

export default ServiceProviderMappingProfile;
