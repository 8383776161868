import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { postData, apiUrl, imageBaseUrl } from "../../Networking/Api";
import Swal from "sweetalert2";
import axios from "axios";
import CustomSelect from "../CustomSelect";
import CustomSelectHealth from "../CustomSelectHealth";
import Loader2 from "../Loader2";
const ProviderInfo = () => {
  const [formData, setFormData] = useState({
    npi_number: "",
    f_name: "",
    l_name: "",
    type: "",
    email: "",
    photo: "",
    calendar: "",
    is_calendar_sync: 0,
    taxonomy_description: "",
    latitude: "",
    longitude: "",
    clinicLocation: "",
  });

  const [provaddress, setProvAddress] = useState("");

  const [lattitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const photoInputRef = useRef(null);
  const [errors, setErrors] = useState({});
  const [providers, setProviders] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [npiError, setNpiError] = useState("");
  const [isNpiRegistered, setIsNpiRegistered] = useState(false);
  const [isCalendarSynced, setIsCalendarSynced] = useState(false);
  const [clinicLocations, setClinicLocations] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [practitionerIsChecked, setPractitionerIsChecked] = useState(true);
  const storedAccountType = localStorage.getItem("accountType");
  console.log("accountType....", storedAccountType);
  const fetchData = async (clinicId) => {
    try {
      const payload = {
        modelName: "clinic_locations",
        whereCondition: {
          clinic_id: clinicId,
          is_deleted: 0,
          is_active: 1,
        },
      };

      const response = await postData("masters/getMasterList", payload);

      setClinicLocations(response.data);
    } catch (error) {
      console.error("Error updating active status:", error);
    }
  };

  const getclinicLocation = async (clinicId) => { };

  useEffect(() => {
    const storedClinicId = localStorage.getItem("clinicId");

    getPractitionersDetails(storedClinicId).then(() => {
      getclinicLocation(storedClinicId);
    });
    fetchData(storedClinicId);
  }, []);

  useEffect(() => {
    const { npi_number } = formData;

    if (npi_number && !isEditing) {
      const timeoutId = setTimeout(() => {
        handleFetchProviderData(npi_number);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [formData.npi_number, isEditing]);
  useEffect(() => {
    if (provaddress) {
      getProviderCoordinates(provaddress);
    }
  }, [provaddress]);
  const getProviderCoordinates = async (provaddress) => {
    try {
      const response = await axios.get(
        "https://nominatim.openstreetmap.org/search",
        {
          params: {
            q: provaddress,
            format: "json",
            limit: 1,
          },
        }
      );

      if (response.data.length > 0) {
        const location = response.data[0];

        setLatitude(location.lat);
        setLongitude(location.lon);
      }
    } catch (error) {
      console.error("Error fetching the coordinates:", error);
      alert("Error fetching the coordinates.");
    }
  };
  const checkNpiRegistered = async (npi_number) => {
    try {
      const response = await postData("practitioners/providerSearch", {
        number: npi_number,
      });
      return response.data && Object.keys(response.data).length > 0;
    } catch (error) {
      console.error("Error checking NPI number:", error);
      return false;
    }
  };
  const getNPINumberData = async (npi_number) => {
    try {
      const npiRegistered = await checkNpiRegistered(npi_number);
      setIsNpiRegistered(npiRegistered);
      if (!npiRegistered) {
        setNpiError("This NPI Number Is Not Registered On NPPES.");
        setFormData((prevData) => ({
          ...prevData,
          f_name: "",
          l_name: "",
          taxonomy_description: "",
          // calendar: "",
        }));

        setErrors({
          f_name: "",
          l_name: "",
          taxonomy_description: "",
          email: "",
          // calendar: "",
        });
        return;
      }

      setNpiError("");
      const payload = {
        number: formData.npi_number,
      };

      const response = await postData("practitioners/providerSearch", payload);

      setProvAddress(response?.data?.addresses[0]?.addressLine1);
      const number = response?.data?.number || "";
      const firstName = response?.data?.basic?.firstName || "";
      const lastName = response?.data?.basic?.lastName || "";
      const specialization =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.specialization
          : "";
      const desc =
        response?.data?.taxonomies?.length > 0
          ? response?.data?.taxonomies[0]?.desc
          : "";
      const taxonomyDescription = `${desc}  ${specialization}`;

      setFormData((prevData) => ({
        ...prevData,
        npi_number: number || prevData.npi_number,
        f_name: firstName || prevData.f_name,
        l_name: lastName || prevData.l_name,
        taxonomy_description:
          taxonomyDescription || prevData.taxonomy_description,
      }));
      setErrors({
        f_name: "",
        l_name: "",
        taxonomy_description: "",
        email: "",
        // calendar: "",
        clinicLocation: "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setNpiError("An Error Occurred While Fetching NPI Data.");
    }
  };
  const handleFetchProviderData = async () => {
    const { npi_number } = formData;
    await getNPINumberData(npi_number);
  };
  const getPractitionersDetails = async (clinicId) => {
    try {
      setLoading(true);
      // const payload1 = {
      //   modelName: "practitioners",
      //   whereCondition: {
      //     clinic_id: clinicId,
      //     is_active: 1,
      //   },
      // };

      // const response1 = await postData("masters/getMasterList", payload1);
      // const practitioners = response1.data;
      // console.log("response", response1);
      // const payload2 = {
      //   modelName: "practitioners",
      //   whereCondition: {
      //     clinic_id: clinicId,
      //   },
      //   relations: [
      //     {
      //       module: "clinic_locations",
      //       moduleas: "clinic_locations",
      //     },
      //   ],
      // };

      // const response2 = await postData("masters/getMasterList", payload2);
      // const updatedProviders = response2.data;

      // setLoading(false);
      // const updatedProvidersMap = new Map();
      // updatedProviders.forEach((provider) => {
      //   updatedProvidersMap.set(provider.id, provider);
      // });

      // const combinedProviders = practitioners.map((provider) => {
      //   const updatedProvider = updatedProvidersMap.get(provider.id);
      //   return updatedProvider ? { ...provider, ...updatedProvider } : provider;
      // });

      // setProviders(combinedProviders);
      const accountType = localStorage.getItem("accountType");
      const assistanceId = localStorage.getItem("refernceId");
      if (accountType === "Assistances") {
        // Fetch the mapping data
        const payload = {
          modelName: "assistance_practitioners_mapping",
          pagination: {
            page: 1,
            pageSize: 500,
          },
          whereCondition: {
            assistance_id: assistanceId,
            is_active: 1,
          },
          relations: [
            {
              module: "practitioners",
            },
          ],
        };

        const response = await postData("masters/getMasterList", payload);

        if (response.code === 1) {
          // Extract practitioners data from the response
          const practitionerData = response.data.map(
            (item) => item?.practitioners
          );
          setProviders(practitionerData);
        }
      } else {
        const payload1 = {
          modelName: "practitioners",
          whereCondition: {
            clinic_id: clinicId,
            is_active: 1,
          },
        };

        const response1 = await postData("masters/getMasterList", payload1);
        const practitioners = response1.data;

        const payload2 = {
          modelName: "practitioners",
          whereCondition: {
            clinic_id: clinicId,
          },
          relations: [
            {
              module: "clinic_locations",
              moduleas: "clinic_locations",
            },
          ],
        };

        const response2 = await postData("masters/getMasterList", payload2);
        const updatedProviders = response2.data;

        const updatedProvidersMap = new Map();
        updatedProviders.forEach((provider) => {
          updatedProvidersMap.set(provider.id, provider);
        });

        const combinedProviders = practitioners.map((provider) => {
          const updatedProvider = updatedProvidersMap.get(provider.id);
          return updatedProvider
            ? { ...provider, ...updatedProvider }
            : provider;
        });

        setProviders(combinedProviders);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching or updating practitioner details:", error);
      setLoading(false);
    }
  };

  // const validateForm = (data) => {
  //   let isValid = true;
  //   const errors = {};

  //   if (data.npi_number && !/^\d{10}$/.test(data.npi_number)) {
  //     isValid = false;
  //     errors.npi_number = "Invalid NPI Number (Must Be 10 Digits)";
  //   }

  //   if (!data.f_name) {
  //     isValid = false;
  //     errors.f_name = "First Name Is Required";
  //   }
  //   if (!data.l_name) {
  //     isValid = false;
  //     errors.l_name = "Last Name Is Required";
  //   }
  //   if (!data.email.trim()) {
  //     isValid = false;
  //     errors.email = "Email Is Required.";
  //   } else if (
  //     !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(data.email)
  //   ) {
  //     isValid = false;
  //     errors.email = "Invalid Email Format.";
  //   }
  //   if (!data.photo) {
  //     isValid = false;
  //     errors.photo = "Profile Photo Is Required";
  //   }
  //   if (!data.clinicLocation) {
  //     isValid = false;
  //     errors.clinicLocation = "Clinic Location Is Required";
  //   }

  //   if (!data.taxonomy_description) {
  //     isValid = false;
  //     errors.taxonomy_description = "Taxonomy Description Is Required";
  //   }
  //   // if (!data.calendar) {
  //   //   isValid = false;
  //   //   errors.calendar = "Provider Calendar is required";
  //   // }

  //   setErrors(errors);
  //   return isValid;
  // };
  const validateFormSync = (data) => {
    let isValid = true;
    const errors = {};

    if (data.npi_number && !/^\d{10}$/.test(data.npi_number)) {
      isValid = false;
      errors.npi_number = "Invalid NPI Number (Must Be 10 Digits)";
    }
    if (!data.f_name) {
      isValid = false;
      errors.f_name = "First Name Is Required";
    }
    if (!data.l_name) {
      isValid = false;
      errors.l_name = "Last Name Is Required";
    }
    if (!data.email.trim()) {
      isValid = false;
      errors.email = "Email Is Required.";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(data.email)
    ) {
      isValid = false;
      errors.email = "Invalid Email Format.";
    }
    // if (!data.photo) {
    //   isValid = false;
    //   errors.photo = "Profile Photo Is Required";
    // }
    if (!data.clinicLocation) {
      isValid = false;
      errors.clinicLocation = "Clinic Location Is Required";
    }

    if (!data.taxonomy_description) {
      isValid = false;
      errors.taxonomy_description = "Taxonomy Description Is Required";
    }
    // if (!data.calendar) {
    //   isValid = false;
    //   errors.calendar = "Provider Calendar is required";
    // }

    return { isValid, errors };
  };

  const checkEmailExists = async (email) => {
    try {
      const payload = {
        modelName: "practitioners",
        whereCondition: {
          email: email,
        },
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code === 1 && apiResponse.data.length > 0) {
        return apiResponse.code === 1;
      }

      return false;
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };

  // const validateForm = async (data) => {
  //   // First, run the synchronous validation
  //   const { isValid: isSyncValid, errors: syncErrors } = validateFormSync(data);

  //   if (!isSyncValid) {
  //     setErrors(syncErrors);
  //     return false;
  //   }

  //   // Check if we are editing and if the email has changed
  //   if (editingIndex !== null && providers[editingIndex].email === data.email) {
  //     setErrors({});
  //     return true;
  //   }

  //   const emailExists = await checkEmailExists(data.email);

  //   if (emailExists) {
  //     syncErrors.email = "This Email Is Already Register With Samara-Well.";
  //     setErrors(syncErrors);
  //     return false;
  //   }

  //   const isNpiRegistered = await checkNpiRegistered(data?.npi_number);
  //   if (!isNpiRegistered) {
  //     syncErrors.npi_number = "Please Enter A Valid Npi Number";
  //     setErrors(syncErrors);
  //     return false;
  //   }

  //   setErrors({});
  //   return true;
  // };

  const validateForm = async (data) => {
    const { isValid: isSyncValid, errors: syncErrors } = validateFormSync(data);

    if (!isSyncValid) {
      setErrors(syncErrors);
      return false;
    }

    if (editingIndex !== null) {
      const currentProvider = providers[editingIndex];

      if (currentProvider.email !== data.email) {
        const emailExists = await checkEmailExists(data.email);
        if (emailExists) {
          syncErrors.email = "This Email Is Already Register With Samara-Well.";
          setErrors(syncErrors);
          return false;
        }
      }

      if (data?.npi_number && currentProvider.npi_number !== data.npi_number) {
        const isNpiRegistered = await checkNpiRegistered(data?.npi_number);
        if (data?.npi_number && !isNpiRegistered) {
          syncErrors.npi_number = "Please Enter A Valid Npi Number";
          setErrors(syncErrors);
          return false;
        }
      }
    } else {
      const emailExists = await checkEmailExists(data.email);
      if (emailExists) {
        syncErrors.email = "This Email Is Already Register With Samara-Well.";
        setErrors(syncErrors);
        return false;
      }

      const isNpiRegistered = await checkNpiRegistered(data?.npi_number);
      if (data?.npi_number && !isNpiRegistered) {
        syncErrors.npi_number = "Please Enter A Valid Npi Number";
        setErrors(syncErrors);
        return false;
      }
    }

    setErrors({});
    return true;
  };

  const handleEdit = async (index) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const provider = providers[index];
    const formData = {
      npi_number: provider.npi_number || "",
      f_name: provider.f_name,
      l_name: provider.l_name,
      email: provider.email,
      photo: provider.photo,
      // calendar: provider.calendar,
      taxonomy_description: provider.taxonomy_description,
      clinicLocation: provider.clinic_locations
        ? provider.clinic_locations.id
        : "",
    };
    setErrors({
      f_name: "",
      l_name: "",
      taxonomy_description: "",
      email: "",
      // calendar: "",
      clinicLocation: "",
    });

    const npiRegistered = await checkNpiRegistered(provider.npi_number);
    console.log("npiRegistered", npiRegistered);

    setIsNpiRegistered(npiRegistered);
    setFormData(formData);
    setEditingIndex(index);
    setIsEditing(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!practitionerIsChecked) {
      setErrorMessage(
        "You Must Verify The Information And Provide Your Consent."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    const storedClinicId = localStorage.getItem("clinicId");
    const clinicId = storedClinicId;

    const isValid = await validateForm(formData);
    if (!isValid) {
      return;
    }

    try {
      setLoading(true);
      const npiExists =
        formData.npi_number &&
        providers.some(
          (provider) => provider.npi_number === formData.npi_number
        );

      // const emailExists = providers.some(
      //   (provider, index) =>
      //     provider.email === formData.email && index !== editingIndex
      // );

      // if (emailExists && editingIndex === null) {
      //   setLoading(false);
      //   Swal.fire({
      //     title: "Error",
      //     text: "The Provider Email Is Already Registered With Samara-Well.",
      //     icon: "error",
      //     timer: 2000,
      //     showConfirmButton: true,
      //   });

      //   return;
      // }

      let payload;
      if (editingIndex !== null) {
        const providerId = providers[editingIndex].id;

        payload = {
          modelName: "practitioners",
          id: providerId,
          inputData: {
            clinic_id: clinicId,
            f_name: formData.f_name,
            l_name: formData.l_name,
            email: formData.email,
            npi_number: formData.npi_number,
            photo: formData.photo,
            provider_type: formData.type,
            // calendar: formData.calendar,
            // is_calendar_sync: formData.is_calendar_sync,
            taxonomy_description: formData.taxonomy_description,
            practitioner_location_id: formData.clinicLocation,
            latitude: lattitude,
            longitude: longitude,
          },
        };
      } else {
        if (npiExists) {
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: "The Provider Is Already Registered With Samara-Well.",
            icon: "error",
            timer: 2000,
            showConfirmButton: true,
          });

          return;
        }
        payload = {
          modelName: "practitioners",
          inputData: {
            clinic_id: clinicId,
            f_name: formData.f_name,
            l_name: formData.l_name,
            npi_number: formData.npi_number,
            photo: formData.photo,
            email: formData.email,
            // calendar: formData.calendar,
            // is_calendar_sync: formData.is_calendar_sync,
            taxonomy_description: formData.taxonomy_description,
            practitioner_location_id: formData.clinicLocation,
            is_active: 1,
            latitude: lattitude,
            longitude: longitude,
          },
        };
      }

      const response = await postData("masters/createAndUpdateMaster", payload);

      if (response.code === 1) {
        setLoading(false);
        Swal.fire({
          title: "Success",
          text:
            editingIndex !== null
              ? "Provider Info Updated Successfully"
              : "Provider Info Added Successfully",
          icon: "success",
          timer: 2000,
          showConfirmButton: true,
        });
      } else if (response.code === 0) {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "Error Updating Provider Info ",
          icon: "error",
          timer: 2000,
          showConfirmButton: true,
        });
        return;
      }
      getPractitionersDetails(clinicId);
      setFormData({
        npi_number: "",
        f_name: "",
        l_name: "",
        email: "",
        photo: "",
        taxonomy_description: "",
        // is_calendar_sync: 0,
        // calendar: "",
        clinicLocation: "",
      });
      setIsNpiRegistered(false);
      setEditingIndex(null);
      setErrors({});
      setNpiError("");
      // setIsCalendarSynced(false);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      Swal.fire({
        title: "Error",
        text: "There Was An Error Updating The Provider. Please Try Again.",
        icon: "error",
        timer: 2000,
        showConfirmButton: true,
      });
    }
  };

  const handleChange = async (e) => {
    var { name, value, files } = e.target;
    if (name === "f_name" || name === "l_name") {
      value = value.replace(/[^a-zA-Z]/g, "");
    }
    if (name === "taxonomy_description" || name === "email") {
      value = value.replace(/^\s+/, "");
    }
    if (name === "npi_number") {
      const numbersOnly = value.replace(/[^\d]/g, "");

      if (numbersOnly !== value) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: numbersOnly,
        }));
        return;
      }
    }
    if (name === "photo" && files && files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;

        setFormData({ ...formData, photo: reader.result });
      };
      reader.readAsDataURL(file);
    } else if (name === "f_name" || name === "l_name") {
      const validNameRegex = /^[a-zA-Z\s]*$/;
      if (validNameRegex.test(value)) {
        setFormData({ ...formData, [name]: value });
      } else {
        setErrors({
          ...errors,
          [name]: "Name Can Only Contain Letters And Spaces.",
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
    if (name === "npi_number") {
      setNpiError("");
    }
  };

  const handleDelete = async (index) => {
    try {
      if (index === editingIndex) {
        Swal.fire({
          title: "Cannot Delete!",
          text: "You Cannot Delete A Record While It Is Being Edited.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      const providerId = providers[index];
      // const payload = {
      //   modelName: "practitioners",
      //   id: providerId.id,
      //   inputData: { is_active: 0 },
      // };
      const payload = {
        modelName: "practitioners",
        id: providerId.id,
        inputData: { is_active: 0 },
      };

      const confirmationResult = await Swal.fire({
        title: "Are You Sure?",
        text: "You Won't Be Able To Revert This!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete It!",
      });

      if (confirmationResult.isConfirmed) {
        const response = await postData(
          "masters/createAndUpdateMaster",
          payload
        );

        if (response.code === 1) {
          Swal.fire({
            title: "Deleted!",
            text: "Practioner Record Has Been Deleted.",
            icon: "success",
          });

          const storedClinicId = localStorage.getItem("clinicId");
          getPractitionersDetails(storedClinicId);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSyncCalendar = async () => {
    try {
      const currentDate = new Date().toISOString().split("T")[0];
      const timeMin = `${currentDate}T00:00:00Z`;
      const timeMax = `${currentDate}T23:59:59Z`;

      const payload = {
        user: formData.calendar,
        timeMin,
        timeMax,
      };

      const response = await postData(
        "googlecalendar/checkPublicCalendar",
        payload
      );

      if (response.success == true) {
        setIsCalendarSynced(true);
        setFormData((prevData) => ({
          ...prevData,
          is_calendar_sync: 1,
        }));
        Swal.fire({
          icon: "success",
          title: "Calendar Sync Successful",
          text: "Your Calendar Has Been Successfully Synced!",
        });
      } else {
        setIsCalendarSynced(false);
        setFormData((prevData) => ({
          ...prevData,
          is_calendar_sync: 0,
        }));
        Swal.fire({
          icon: "error",
          title: "Sync Failed",
          text: "Make Your Google Calendar Public.",
        });
      }
    } catch (error) {
      console.error("Error syncing calendar:", error);
      setIsCalendarSynced(false);
      setFormData((prevData) => ({
        ...prevData,
        is_calendar_sync: 0,
      }));
      Swal.fire({
        icon: "error",
        title: "Sync Failed",
        text: "Make Your Google Calendar Public.",
      });
    }
  };

  const options = clinicLocations.map((location) => {
    return {
      value: location.id,
      label: `${location.street}, ${location.city}`,
    };
  });

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <div className="w-full mt-3">
            <section className="w-full py-2">
              <div className="container mx-auto">
                <h1 className="font-fontPoppins md:text-2xl text-center font-semibold xss:text-xs sm:text-lg leading-normal">
                  Practitioner Info
                </h1>
                <label className="font-fontPoppins text-xs font-semibold">
                  Add Practitioner<span className="text-red-500">*</span>
                </label>

                <div className="w-full bg-grayColor rounded-lg p-3 mt-1">
                  <div className="medium:w-full w-full md:flex justify-between gap-3">
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        NPI Number
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter NPI Number"
                        name="npi_number"
                        value={formData.npi_number}
                        onChange={handleChange}
                        maxLength={10}
                        disabled={isNpiRegistered}
                      />
                      {errors?.npi_number && (
                        <p className="text-red-500 text-xs">
                          {errors?.npi_number}
                        </p>
                      )}
                      {npiError && (
                        <p className="text-red-500 text-xs">{npiError}</p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        First Name
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter First Name"
                        name="f_name"
                        value={formData.f_name}
                        onChange={handleChange}
                        disabled={isNpiRegistered}
                      />
                      {errors?.f_name && (
                        <p className="text-red-500 text-xs">{errors?.f_name}</p>
                      )}
                    </div>
                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Last Name
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter Last Name"
                        name="l_name"
                        value={formData.l_name}
                        onChange={handleChange}
                        disabled={isNpiRegistered}
                      />
                      {errors?.l_name && (
                        <p className="text-red-500 text-xs">{errors?.l_name}</p>
                      )}
                    </div>

                    <div className="w-full xss:mt-3 sm:mt-3">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Email<span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors?.email && (
                        <p className="text-red-500 text-xs">{errors?.email}</p>
                      )}
                    </div>
                  </div>

                  <div className="w-full md:flex justify-between gap-3 mt-3">
                    <div className="md:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Profile Photo
                      </label>
                      <div className="w-full relative">
                        <span className="pl-1 z-10 absolute left- px-2 py-1 pt-1.5 top-">
                          <i className="fa-solid fa-camera"></i>
                        </span>
                        <input
                          className="rounded-full w-full h-9 px-3 text-sm indent-5 py-1 font-fontPoppins text-gray-400  focus:outline-none focus:ring ring-gray-200 shadow"
                          // className="w-full h-9 indent-5  text-center rounded-full px-4 py-1 text-xs font-fontPoppins text-gray-400  focus:outline-none focus:ring ring-gray-200 shadow"
                          type="file"
                          placeholder="Upload"
                          name="photo"
                          accept="image/jpeg, image/png"
                          onChange={handleChange}
                        />
                        {errors?.photo && (
                          <p className="text-red-500 text-xs">
                            {errors?.photo}
                          </p>
                        )}
                        {formData.photo && (
                          <img
                            src={
                              formData.photo.startsWith("data:")
                                ? formData.photo
                                : `${imageBaseUrl}/${formData.photo}` ||
                                "/placeholder.png"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/placeholder.png";
                            }}
                            alt="photo"
                            className="absolute top-1/2 transform -translate-y-1/2 right-2 w-9 h-9 rounded-full"
                          />
                        )}
                      </div>
                    </div>
                    <div className="md:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Clinic Location
                        <span className="text-red-500">*</span>
                      </label>
                      <CustomSelectHealth
                        name="clinicLocation"
                        options={options}
                        value={formData.clinicLocation}
                        onChange={handleChange}
                        placeholder="Clinic Location"
                      />
                      {errors?.clinicLocation && (
                        <p className="text-red-500 text-xs">
                          {errors?.clinicLocation}
                        </p>
                      )}
                    </div>
                    {/* <div className="md:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Calendar
                      </label>
                      <div className="flex items-center">
                        <input
                          className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                          type="text"
                          name="calendar"
                          value={formData.calendar}
                          placeholder="Calendar link"
                          onChange={handleChange}
                        />
                        {!isCalendarSynced && (
                          <span
                            className="cursor-pointer hover:text-blueColor ml-2 flex items-center"
                            onClick={handleSyncCalendar}
                          >
                            <i className="fa-solid fa-calendar-plus mr-2"></i>
                            Sync
                          </span>
                        )}
                      </div>
                    </div> */}

                    <div className="md:w-80">
                      <label className="font-fontPoppins text-xs font-semibold">
                        Taxanomy Description
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-700 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                        type="text"
                        name="taxonomy_description"
                        value={formData.taxonomy_description}
                        placeholder="Enter Provider Taxonomy"
                        onChange={handleChange}
                        disabled={isNpiRegistered}
                      />
                      {errors?.taxonomy_description && (
                        <p className="text-red-500 text-xs">
                          {errors?.taxonomy_description}
                        </p>
                      )}
                    </div>
                    <div className="w-52">
                      <label className="font-fontPoppins text-xs font-semibold text-transparent">
                        .
                      </label>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="w-full h-10 bg-blueColor text-white font-fontPoppins text-xs rounded-full"
                      >
                        {editingIndex !== null ? "Update" : "Add"}
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="w-full mt-3  overflow-auto">
                  <table className="border-collapse table-auto w-full text-sm">
                    <thead>
                      <tr>
                        <th className="rounded-tl-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          NPI Number
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          First Name
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Last Name
                        </th>

                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Email
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Profile Photo
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Clinic Location
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b dark:border-slate-600 font-semibold p-3 pl-8 pb-3 text-left">
                          Calendar
                        </th>
                        <th className="font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Taxanomy Description
                        </th>
                        <th className="rounded-tr-lg font-fontPoppins text-xs bg-grayColor border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {providers.map((provider, index) => (
                        <tr key={index}>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.npi_number ? provider.npi_number : "N/A"}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.f_name}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.l_name}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.email}
                          </td>
                          <td className="border-b border-slate-200 p-3 pl-8 text-left">
                            <img
                              src={`${apiUrl}/${provider.photo}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/placeholder.png";
                              }}
                              alt="photo"
                              className="h-10 w-10 rounded-full"
                            />
                            
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 dark:border-slate-700 p-3 pl-8">
                            {provider?.clinic_locations?.street
                              ? `${provider.clinic_locations.street},`
                              : ""}
                            {provider?.clinic_locations?.city || ""}
                          </td>

                          <td className="font-fontPoppins text-xs border-b border-slate-100 dark:border-slate-700 p-3 pl-8">
                            {provider.calendar}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.taxonomy_description}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            <div className="flex items-center">
                              <span
                                className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor flex items-center"
                                onClick={() => handleEdit(index)}
                              >
                                <i className="fa-solid fa-pencil mr-1"></i> Edit
                              </span>

                              <span
                                className="text-gray-700 mr-2 cursor-pointer hover:text-red-500 flex items-center"
                                onClick={() => handleDelete(index)}
                              >
                                <i className="fa-solid fa-trash mr-1"></i>{" "}
                                Delete
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
                <div
                  className="w-full overflow-auto mt-4 "
                  style={{ maxHeight: "400px" }}
                >
                  <table className="border-collapse  table-auto w-full text-sm">
                    <thead>
                      <tr>
                        <th className="sticky top-0 rounded-tl-lg font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          NPI Number
                        </th>
                        <th className="sticky top-0 font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          First Name
                        </th>
                        <th className="sticky top-0 font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Last Name
                        </th>
                        <th className="sticky top-0 font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Email
                        </th>
                        <th className="sticky top-0 font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Profile Photo
                        </th>
                        <th className="sticky top-0 font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Clinic Location
                        </th>
                        <th className="sticky top-0 font-fontPoppins text-xs bg-gray-200 border-b dark:border-slate-600 font-semibold p-3 pl-8 pb-3 text-left">
                          Calendar
                        </th>
                        <th className="sticky top-0 font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Taxonomy Description
                        </th>
                        <th className="sticky top-0 rounded-tr-lg font-fontPoppins text-xs bg-gray-200 border-b font-semibold p-3 pl-8 pb-3 text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {providers.map((provider, index) => (
                        <tr key={index}>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.npi_number || "N/A"}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.f_name}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.l_name}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.email}
                          </td>
                          <td className="border-b border-slate-200 p-3 pl-8 text-left">
                            <img
                              src={
                                `${imageBaseUrl}/${provider.photo}` ||
                                "/placeholder.png"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/placeholder.png";
                              }}
                              alt="photo"
                              className="h-10 w-10 rounded-full"
                            />
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 dark:border-slate-700 p-3 pl-8">
                            {provider?.clinic_locations?.street
                              ? `${provider.clinic_locations.street},`
                              : ""}
                            {provider?.clinic_locations?.city || ""}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 dark:border-slate-700 p-3 pl-8">
                            {provider.calendar}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            {provider.taxonomy_description}
                          </td>
                          <td className="font-fontPoppins text-xs border-b border-slate-100 p-3 pl-8">
                            <div className="flex items-center">
                              <span
                                className="text-gray-700 mr-2 cursor-pointer hover:text-blueColor flex items-center"
                                onClick={() => handleEdit(index)}
                              >
                                <i className="fa-solid fa-pencil mr-1"></i> Edit
                              </span>
                              {storedAccountType !== "Assistances" && (
                                <span
                                  className="text-gray-700 mr-2 cursor-pointer hover:text-red-500 flex items-center"
                                  onClick={() => handleDelete(index)}
                                >
                                  <i className="fa-solid fa-trash mr-1"></i>{" "}
                                  Delete
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="flex mt-4 items-center gap-2">
                  <input
                    type="checkbox"
                    checked={practitionerIsChecked}
                    onChange={(e) => setPractitionerIsChecked(e.target.checked)}
                  />

                  <span className="font-fontPoppins text-xs font-semibold relative">
                    I have verified all the information and provide consent to
                    Samara Well to use and display this information accordingly.
                  </span>
                </div>
                {errorMessage && (
                  <p className="text-red-500  text-xs absolute">
                    {errorMessage}
                  </p>
                )}
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </>
    </>
  );
};
export default ProviderInfo;
