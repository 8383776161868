import React from "react";
import ReactApexChart from "react-apexcharts";

class BarChart extends React.Component {
  constructor(props) {
    super(props);

    // Determine the data type and initialize state accordingly
    const dataType = this.detectDataType(props.data);
    this.state = {
      series: [
        {
          name: "Appointments",
          data: this.getSeriesData(props.data, dataType),
        },
      ],
      options: {
        chart: {
          height: 400,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: "30%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: this.getCategories(props.data, dataType),
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            },
            style: {
              colors: "#78909C",
              fontSize: "12px",
            },
          },
          menu: {
            show: false,
          },
        },
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const dataType = this.detectDataType(this.props.data);
      this.setState({
        series: [
          {
            name: "Appointments",
            data: this.getSeriesData(this.props.data, dataType),
          },
        ],
        options: {
          ...this.state.options,
          xaxis: {
            ...this.state.options.xaxis,
            categories: this.getCategories(this.props.data, dataType),
          },
        },
      });
    }
  }

  detectDataType(data) {
    if (!data || data.length === 0) return "unknown";
    if (data[0].day) return "daily";
    if (data[0].week) return "weekly";
    if (data[0].month) return "monthly";
    if (data[0].year) return "yearly";
    return "unknown";
  }

  getSeriesData(data, dataType) {
    if (!data) return [];
    return data.map((item) => parseInt(item.count, 10));
  }

  getCategories(data, dataType) {
    if (!data) return [];
    if (dataType === "daily") {
      return data.map((item) =>
        new Date(item.day).toLocaleDateString("en-US", {
          weekday: "short",
          month: "short",
          day: "2-digit",
        })
      );
    } else if (dataType === "weekly") {
      //return data.map((_, index) => `Week ${index + 1}`);
        return data.map((item) => {
          const date = new Date(item.week);
          const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1); // Start of the month
          const dayOfMonth = date.getDate();
          const weekNumber = Math.ceil(
            (dayOfMonth + startOfMonth.getDay()) / 7
          ); // Calculate the week number within the month
          return `Week ${weekNumber}`;
        });

    } else if (dataType === "monthly") {
      return data.map((item) =>
        new Date(item.month).toLocaleDateString("en-US", { month: "short" })
      );
    } else if (dataType === "yearly") {
      return data.map((item) => new Date(item.year).getFullYear());
    }
    return [];
  }

  render() {
    return (
      <div>
        <div>
          <h2>Scheduling</h2>
        </div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={400}
          />
        </div>
        <div></div>
      </div>
    );
  }
}

BarChart.defaultProps = {
  data: [],
};

export default BarChart;
