import PatientsHeader from "./PatientsHeader";
import PatientsFooter from "./PatientsFooter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { postData } from "../../Networking/Api";
import Header from "../Header";
import Swal from "sweetalert2";

function YourAppointmentBooked() {
  return (
    <>
      <Header />
      <div className="pt-16">
        <YourBookingDetails />
      </div>
      <PatientsFooter />
    </>
  );
}

function YourBookingDetails() {
  //const [appointmentData,setAppointmentData] = useState("");
  const location = useLocation();
  const { patientType, insuranceType, appointmentType, type, name, viewId } =
    useParams();
  //const {userData,insuranceType,appointmentType,patientType,appointmentData} = location.state || {};
  // console.log("userData",userData);
  console.log("viewpatientId", viewId);
  const navigate = useNavigate();
  const [viewDetails, setViewDetails] = useState("");
  const [address, setAddress] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [appoinmentName, setAppoinmentName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [patientTypeName, setPatientTypeName] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState([]);
  // useEffect(()=>{
  //   const patientIdData = localStorage.getItem("patientId");

  //   console.log('patientId',patientIdData);

  //   setPatientId(patientIdData)
  //   // console.log('patientId',patientId)

  // },[patientId])
  useEffect(() => {
    const getClinicDetails = async (clinicId) => {
      try {
        const payload = {
          modelName: "clinics",
          whereCondition: {
            id: viewDetails.clinic_id,
          },
        };

        const response = await postData("masters/getMasterList", payload);
        console.log("Clinic data response:", response);

        const clinicData = response.data[0];

        const addressParts = [
          clinicData.address,
          clinicData.city,
          clinicData.street,
          clinicData.state,
          clinicData.country,
          clinicData.zipcode,
        ];

        const formattedAddress = addressParts.filter(Boolean).join(", ");
        setAddress(formattedAddress);
        console.log("formattedAddress", formattedAddress);
      } catch (error) {
        console.error("Error fetching clinic details:", error);
      }
    };
    getClinicDetails();
  }, [viewDetails]);
  useEffect(() => {
    getAppointmentDetails();
  }, []);
  const getAppointmentDetails = async () => {
    try {
      const payload = {
        modelName: "patient_appointments",
        whereCondition: {
          //id: "1"
          id: viewId,
        },
        relations: [
          {
            module: "clinics",
          },
          {
            module: "patients",
          },
          {
            module: "practitioners",
          },
        ],
      };

      const apiResponse = await postData("masters/getMasterList", payload);

      if (apiResponse.code == 1) {
        setViewDetails(apiResponse.data[0]);
        setPatientId(apiResponse.data[0].patient_id);
        console.log("viewPatient", apiResponse.data[0]);
        //return apiResponse.data; // Assuming data is in the 'data' field
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error searching provider:", error);
      return null; // Error occurred or no data available
    }
  };
  useEffect(() => {
    const selectedAppointmentData = JSON.parse(
      localStorage.getItem("selectedAppointment")
    );
    if (selectedAppointmentData) {
      setSelectedAppointment(selectedAppointmentData);
      console.log("selectedAppointmentData", selectedAppointmentData);
    }
  }, [selectedAppointment]);
  useEffect(() => {
    if (patientType === "newPatient" || patientType === "existingPatient") {
      setPatientTypeName(
        patientType === "newPatient" ? "New Patient" : "Existing Patient"
      );
    }
    const getAppointmentType = async () => {
      try {
        const payload = {
          modelName: "clinic_services",
          whereCondition: {
            id: appointmentType,
          },
        };
        const response = await postData("masters/getMasterList", payload);
        console.log("AppoinmentData....", response.data);
        //   localStorage.setItem("doctorsData", JSON.stringify(response.data));
        //address
        console.log("data", response.data[0].services);
        setAppoinmentName(response.data[0].services);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getAppointmentType();
  }, [appointmentType, patientType]);
  useEffect(() => {
    if (insuranceType === "other" || insuranceType === "noInsurance") {
      setInsuranceName(insuranceType === "other" ? "Other" : "No Insurance");
      // console.log(insurance);
    }

    const getInsuranceType = async () => {
      try {
        const payload = {
          modelName: "clinic_insurances",
          whereCondition: {
            id: insuranceType,
          },
          relations: [
            {
              module: "insurance_masters",
            },
            {
              module: "health_insurance_carrier_master",
            },
          ],
        };
        const response = await postData("masters/getMasterList", payload);
        console.log("InsuranceData....", response.data[0]);
        setInsuranceName(response.data[0]);
        //   localStorage.setItem("doctorsData", JSON.stringify(response.data));
        //address
        // console.log('data',response.data[0].insurance_master.insurance_name);
        // setInsuranceName(response.data[0].insurance_master.insurance_name);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };

    getInsuranceType();
  }, [insuranceType]);
  // const handleShare = () => {
  //   const patientName = `${viewDetails?.patient?.first_name} ${viewDetails?.patient?.last_name}`;
  //   const appointmentDetails = `${patientType} | ${appointmentType}`;
  //   const appointmentDate = `Date: ${viewDetails?.available_date_time_new}`;
  //   const address = viewDetails?.practitioner?.address ?? '--';
  //   const doctorName = `${viewDetails?.practitioner?.f_name} ${viewDetails?.practitioner?.l_name}`;
  //   const insurance = insuranceType || 'Medicaid';
  //   const note = `Please arrive 15 minutes prior to your scheduled appointment time to complete any necessary paperwork. Remember to bring your insurance card and a valid photo ID.`;

  //   const shareText = `
  //     Patient's Name: ${patientName}
  //     Appointment: ${appointmentDetails}
  //     ${appointmentDate}
  //     Address: ${address}
  //     Consulting Doctor: ${doctorName}
  //     Insurance: ${insurance}

  //     Note: ${note}
  //   `;

  //   if (navigator.share) {
  //     navigator.share({
  //       title: 'Appointment Details',
  //       text: shareText,
  //       url: window.location.href,
  //     })
  //     .then(() => console.log('Successfully shared'))
  //     .catch((error) => console.error('Error sharing:', error));
  //   } else {
  //     alert('Web Share API is not supported in your browser.');
  //   }
  // };

  function formatAppointmentDate(dateString) {
    const date = new Date(dateString);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayName = days[date.getUTCDay()];
    const day = date.getUTCDate();
    const monthName = months[date.getUTCMonth()];
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    return `${dayName}, ${day} ${monthName} ${formattedHours}:${formattedMinutes}${ampm}`;
  }
  const HandleBookAnotherAppoinment = () => {
    localStorage.removeItem("selectedOptions");
    localStorage.removeItem("selectedDoctor");
    localStorage.removeItem("selectedAppointment");
    localStorage.removeItem("doctorsData");
    localStorage.removeItem("selectedSlot");
    localStorage.removeItem("options");
    localStorage.removeItem("selectedDate");

    navigate(`/bookAnAppoinment/${type}/${name}`);
  };
  const hanldeAddToCalender = async (patientId) => {
    try {
      const payload = {
        appointmentId: viewId,
        patientId: patientId,
      };

      const apiResponse = await postData(
        "googlecalendar/createEventPatient",
        payload
      );

      if (apiResponse.code === 1) {
        Swal.fire({
          title: "Success",
          text: apiResponse.message,
          icon: "success",
        });
        setIsButtonDisabled(true);
      }
    } catch (error) {
      console.error("Error :", error);
      return null;
    }
  };
  return (
    <section>
      <div className="container mx-auto px-4 py-4">
        <h1 className="font-fontPoppins text-3xl xss:text-xl sm:text-xl font-bold text-center mb-7">
          Your Appointment is Booked!
        </h1>

        <div className="w-3/5 xss:w-full sm:w-full mx-auto relative bg-purple-100 px-4 py-5 shadow-md rounded-xl xss:mt-3 sm:mt-3 md:mt-0">
          {/* <button  
          // onClick={handleShare} 
          className="z-10 absolute right-4 top-4 font-fontPoppins text-xs font-semibold text-gray-500 hover:text-blueColor bg-white rounded-full px-6 py-2">
            SHARE <i className="fa-solid fa-share fa-lg ms-1"></i>
          </button> */}
          <div className="w-full">
            <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
              Patient's Name
            </h2>
            <p className="font-fontPoppins text-sm font-semibold text-black">
              {/* Will Hughes */}
              {viewDetails &&
                viewDetails.patient &&
                viewDetails.patient.first_name}{" "}
              {viewDetails &&
                viewDetails.patient &&
                viewDetails.patient.last_name}
            </p>
          </div>

          <div className="w-full mt-4">
            <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
              Appointment
            </h2>
            <p className="font-fontPoppins text-sm font-semibold text-black">
              {/* New Patient | Medication Management <br /> 
              Saturday, 13 April, 2024 @ 1:20pm */}
              {patientTypeName} | {appoinmentName} <br />
              {/* {appointmentData.available_date_time_new} */}
              {formatAppointmentDate(viewDetails.appointment_date_time)}
            </p>
          </div>
          <div className="w-full mt-4">
            <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
              Appointment Duration
            </h2>
            <p className="font-fontPoppins text-sm font-semibold text-black">
              {selectedAppointment.duration} minutes.
            </p>
          </div>
          <div className="w-full mt-4">
            <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
              Address
            </h2>
            <p className="font-fontPoppins text-sm font-semibold text-black">
              {/* 90 N 4th St, Martins Ferry OH 43935 */}
              {/* {viewDetails && viewDetails.practitioner && viewDetails.practitioner.address ? viewDetails && viewDetails.practitioner && viewDetails.practitioner.address : '--'} */}
              {address}
            </p>
          </div>

          <div className="w-full mt-4">
            <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
              Consulting Doctor
            </h2>
            <p className="font-fontPoppins text-sm font-semibold text-black">
              {/* Dr. Rashmi Kaul */}
              {viewDetails &&
                viewDetails.practitioner &&
                viewDetails.practitioner.f_name}{" "}
              {viewDetails &&
                viewDetails.practitioner &&
                viewDetails.practitioner.l_name}
            </p>
          </div>

          {(insuranceType === "other" || insuranceType === "noInsurance") && (
            <div className="w-full mt-4">
              <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                Insurance Type
              </h2>
              <p className="font-fontPoppins text-sm font-semibold text-black">
                {insuranceName}
              </p>
            </div>
          )}
          {insuranceType !== "other" && insuranceType !== "noInsurance" && (
            <div className="w-full mt-4">
              <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                Insurance Carrier
              </h2>
              <p className="font-fontPoppins text-sm font-semibold text-black">
                {
                  insuranceName?.health_insurance_carrier_master
                    ?.health_insurance_carrier_name
                }
              </p>
            </div>
          )}
          {insuranceType !== "other" && insuranceType !== "noInsurance" && (
            <div className="w-full mt-4">
              <h2 className="font-fontPoppins text-sm font-medium text-purple-500">
                Insurance Plan
              </h2>
              <p className="font-fontPoppins text-sm font-semibold text-black">
                {insuranceName?.insurance_master?.insurance_name}
              </p>
            </div>
          )}

          <div className="w-full mt-4 bg-yellow-100 px-4 py-2 rounded-lg shadow-sm">
            <h4 className="font-fontPoppins text-sm font-medium text-orange-400">
              Note
            </h4>
            <p className="font-fontPoppins text-xs font-normal">
              Please arrive 15 minutes prior to your scheduled appointment time
              to complete any any necessary paperwork. Remember to bring your
              insurance card and a valid photoID.
            </p>
          </div>
        </div>

        <div className="w-3/5 xss:w-full sm:w-full mx-auto mt-4 xss:text-center md:flex md:justify-between">
          <button
            disabled={isButtonDisabled}
            onClick={() => hanldeAddToCalender(patientId)}
            className={`h-12 font-fontPoppins text-sm rounded-full text-white font-semibold px-4 xss:mb-2 ${
              isButtonDisabled
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
          >
            <i className="fa-solid fa-calendar-plus fa-lg"></i> Add To Calendar
          </button>

          <button
            onClick={() => navigate(`/patientreschedule/${viewId}`)}
            className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
          >
            <i className="fa-solid fa-rotate-right fa-lg"></i> Reschedule{" "}
          </button>

          <button
            onClick={HandleBookAnotherAppoinment}
            className="h-12 font-fontPoppins hover:bg-gray-300 text-sm rounded-full text-gray-600 font-semibold hover:opacity-85 px-4 xss:mb-2"
          >
            Book Another Appointment{" "}
            <i className="fa-solid fa-arrow-right-long fa-lg"></i>
          </button>
        </div>
      </div>
    </section>
  );
}

export default YourAppointmentBooked;
