// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';

// const steps = [
//   { step: 1, path: '/outpatientPracticeDetails2', title: 'CLINIC DETAILS' },
//   { step: 2, path: '/serviceToProvider', title: 'SERVICE TO PROVIDER' },
//   { step: 3, path: '/frontDeskAssistant', title: 'FRONT DESK ASSISTANT' },
//   { step: 4, path: '/fourthpage', title: 'FOURTH PAGE' },
//   { step: 5, path: '/healthInsuranceCarrier', title: 'HEALTH INSURANCE CARRIER' },
//   { step: 6, path: '/brandIdentity', title: 'BRAND IDENTITY' },
//   { step: 7, path: '/paymentSetup', title: 'PAYMENT SETUP' },
// ];

// function StepsForRegistration() {
//   const location = useLocation();
//   const currentPath = location.pathname;
//   const currentStep = steps.find(step => step.path === currentPath)?.step || 1;
//   const [highestCompletedStep, setHighestCompletedStep] = useState(() => {
//     // Initialize to 1 or get from local storage if available
//     const storedStep = localStorage.getItem('highestCompletedStep');
//     return storedStep ? parseInt(storedStep) : 1;
//   });

//   useEffect(() => {
//     if (currentStep > highestCompletedStep) {
//       setHighestCompletedStep(currentStep);
//       localStorage.setItem('highestCompletedStep', currentStep.toString());
//     }
//   }, [currentStep, highestCompletedStep]);

//   return (
//     <div>
//       <section className="w-full py-2">
//         <div>
//           <p className="font-fontPoppins text-xs text-gray-400">Steps for registration</p>
//           <nav className="bg-repeat-x bg-center flex items-center text-center gap-3 xss:gap-2 mt-1" style={{ backgroundImage: "url(./d-line.png)" }}>
//             {steps.map(({ step, path, title }) => (
//               <Link to={path} key={step} title={title} className={`h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid rounded-lg cursor-pointer
//                  ${step <= highestCompletedStep ? 'border-greenColorDark bg-greenColorDark text-greenColorLight' : 'border-gray-300 bg-grayColor'}
//                 ${step === currentStep ? 'bg-greenColorDark border-greenColorLight text-white' : ''}
//                 ${step > highestCompletedStep ? 'pointer-events-none opacity-50' : 'hover:border-greenColorDark hover:bg-greenColorLight'}`}
//               >
//                 {step}
//               </Link>
//             ))}
//           </nav>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default StepsForRegistration;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const getCurrentUserId = () => "user123";
const steps = [
  { step: 1, path: "/outpatientPracticeDetails2", title: "CLINIC DETAILS" },
  { step: 2, path: "/serviceToProvider", title: "SERVICE TO PROVIDER" },
  { step: 3, path: "/frontDeskAssistant", title: "FRONT OFFICE TEAM" },
  // { step: 4, path: '/fourthpage', title: 'FOURTH PAGE' },
  {
    step: 4,
    path: "/healthInsuranceCarrier",
    title: "HEALTH INSURANCE CARRIER",
  },
  // { step: 5, path: "/brandIdentity", title: "BRAND IDENTITY" },
  { step: 5, path: "/pricing", title: "PRICING" },
  // { step: 7, path: '/paymentSetup', title: 'PAYMENT SETUP' },
];

function StepsForRegistration() {
  const location = useLocation();
  const currentUserId = getCurrentUserId();
  const currentPath = location.pathname;
  const currentStep =
    steps.find((step) => step.path === currentPath)?.step || 1;

  const [highestCompletedStep, setHighestCompletedStep] = useState(() => {
    const storedStep = localStorage.getItem(
      `highestCompletedStep_${currentUserId}`
    );
    return storedStep ? parseInt(storedStep, 10) : 1;
  });
  useEffect(() => {
    const organizationData = JSON.parse(
      localStorage.getItem("organizationData")
    );
    const providerData = JSON.parse(localStorage.getItem("providerData"));
    const cliniServicesMapping = JSON.parse(
      localStorage.getItem("clinic_services_mapping")
    );
    const clinicServicesData = JSON.parse(
      localStorage.getItem("clinic_services_data")
    );
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const records = JSON.parse(localStorage.getItem("records"));
    const facilityImages = JSON.parse(localStorage.getItem("facilityImages"));
    const subscription = JSON.parse(localStorage.getItem("subscription"));

    if (!providerData && !organizationData) {
      setHighestCompletedStep(1);
      return;
    }
    if (!cliniServicesMapping && !clinicServicesData) {
      setHighestCompletedStep(1);
      return;
    }
    if (!userDetails) {
      setHighestCompletedStep(2);
      return;
    }
    if (!records) {
      setHighestCompletedStep(3);
      return;
    }

    if (!facilityImages) {
      setHighestCompletedStep(4);
      return;
    }
  }, []);
  useEffect(() => {
    if (currentStep > highestCompletedStep) {
      setHighestCompletedStep(currentStep);
      localStorage.setItem(
        `highestCompletedStep_${currentUserId}`,
        currentStep.toString()
      );
    }
  }, [currentStep, highestCompletedStep, currentUserId]);

  return (
    <div>
      <section className="w-full py-2">
        <div>
          <p className="font-fontPoppins text-xs text-gray-400">
            Steps For Registration
          </p>
          <nav
            className="bg-repeat-x bg-center flex items-center text-center gap-3 xss:gap-2 mt-1"
            style={{ backgroundImage: "url(./d-line.png)" }}
          >
            {steps.map(({ step, path, title }) => {
              let stepClass = "";
              if (step < currentStep) {
                stepClass =
                  "bg-greenColorDark border-greenColorDark text-white";
              } else if (step === currentStep) {
                stepClass =
                  "border-greenColorLight bg-greenColorDark text-greenColorLight";
              } else if (step <= highestCompletedStep) {
                stepClass =
                  "border-greenColorDark bg-greenColorLight text-greenColorDark";
              } else {
                stepClass =
                  "border-gray-300 bg-grayColor pointer-events-none opacity-50";
              }

              return (
                <Link
                  to={path}
                  key={step}
                  title={title}
                  className={`h-5 w-5 flex items-center justify-center text-xs font-fontPoppins border-2 border-solid rounded-lg cursor-pointer ${stepClass}`}
                >
                  {step}
                </Link>
              );
            })}
          </nav>
        </div>
      </section>
    </div>
  );
}

export default StepsForRegistration;
