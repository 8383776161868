import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
const CardDetails=()=>{
    const [formData, setFormData] = useState({
        cardNumber: "",
        expiration: "",
        cvc: "",
        country: "",
        password: ""
      });
      const [errors, setErrors] = useState({});
      const validateForm = () => {
        let isValid = true;
        const errors = {};
        if (!formData.cardNumber){
            isValid= false;
            errors.cardNumber = "Card Number is required";
        } 
        if (!formData.expiration){
          isValid= false;
          errors.expiration = "Expiration is required";
        } 
        if (!formData.cvc) errors.cvc = "Fax Number is required";
        if (!formData.country) errors.country = "Address is required";
        setErrors(errors);
        return isValid;
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'expiration') {
          const numericValue = value.replace(/[^\d]/g, '');
          let formattedValue = numericValue;
          if (numericValue.length >= 3) {
            formattedValue = numericValue.slice(0, 2) + '/' + numericValue.slice(2, 4);
          }
          setFormData({ ...formData, [name]: formattedValue });
        } else if (name === 'cardNumber' || name === 'cvc') {
          // Allow only numeric input
          const numericValue = value.replace(/[^\d]/g, '');
          setFormData({ ...formData, [name]: numericValue });
        } else {
          setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
      };
      const handleSubmit = () => {
        if(validateForm()){
            console.log("Form submitted:",formData);
        }
      };
    return(
        <>
        <Header/>
       <section className="w-full">
       <div className="container mx-auto px-4">
       <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal mt-3">
          Card Details
          </h1>
          <div className="bg-grayColorLight rounded-lg p-3">
          <div>
                  <label className="font-fontPoppins text-xs font-semibold">
                    Card Number
                  </label>
                  <div className="w-full relative">
                    <span className="z-10 absolute right-3 top-2">
                      <i className="fa-brands fa-cc-mastercard text-red-500"></i>
                      <i className="fa-brands fa-cc-visa text-blue-500"></i>
                      <i className="fa-brands fa-cc-mastercard text-blue-400"></i>
                      <i className="fa-brands fa-cc-jcb text-red-500"></i>
                    </span>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="xxxx xxxx xxxx"
                      name="cardNumber"
                      value={formData.cardNumber}
                      maxLength={12}
                      onChange={handleChange}
                    />
                     {errors.cardNumber && <p className="text-red-500 text-xs">{errors.cardNumber}</p>}
                  </div>
                </div>
                <div className="w-full md:flex justify-between gap-3 mt-3">
                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Expiration
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="MM/YY"
                      name="expiration"
                      value={formData.expiration}
                      maxLength={5}
                      onChange={handleChange}

                    />
                     {errors.expiration && <p className="text-red-500 text-xs">{errors.expiration}</p>}
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3">
                    <label className="font-fontPoppins text-xs font-semibold">
                      CVC
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="CVC"
                      name="cvc"
                      value={formData.cvc}
                      onChange={handleChange}
                      maxLength={4}
                    />
                     {errors.cvc && <p className="text-red-500 text-xs">{errors.cvc}</p>}
                  </div>
                </div>
            <div className="w-full">
              <div className="w-full md:flex items-center gap-3">
              <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Country
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="text"
                      placeholder="United States of America"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                     {errors.country && <p className="text-red-500 text-xs">{errors.country}</p>}
                  </div>

                  <div className="w-full">
                    <label className="font-fontPoppins text-xs font-semibold">
                      Password
                    </label>
                    <input
                      className="w-full h-9 rounded-full px-4 text-xs font-fontPoppins text-gray-400 mt-1 focus:outline-none focus:ring ring-gray-200 shadow"
                      type="password"
                      placeholder="**********"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4 text-center mt-4">
          <button
            onClick={handleSubmit}
            className="w-24 h-9 font-fontPoppins text-xs bg-blueColor rounded-full text-white font-semibold hover:opacity-85"
          >
            <span>Submit</span>
          </button>
        </div>
      </section>
       <Footer/>
       </>
    )
};
export default CardDetails;