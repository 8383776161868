// import {createSlice} from "@reduxjs/toolkit";
// const data = localStorage.getItem('userLog')
// const UserData = JSON.parse(data);



// export const userSlice = createSlice({
//     name:"user",
//     initialState:{
//         // user: UserData ? UserData :null,
//         isLoggedIn :  UserData ? UserData : false
        
//     },
//     reducers:{
//         login:(state, action) => {
//             state.isLoggedIn = action.payload;
//             localStorage.setItem('userLog', JSON.stringify(action.payload));
//         },
//         logout:(state) => {
//             state.isLoggedIn = false;
//             localStorage.removeItem('userLog');
//         },
//     },
// });

// export const {login, logout} = userSlice.actions;

// export const selectUser = (state) => state.user;

// export default userSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const patientData = localStorage.getItem('patientLog');
const providerData = localStorage.getItem('providerLog');

const UserData = {
  patient: JSON.parse(patientData),
  provider: JSON.parse(providerData),
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    patient: UserData.patient ? UserData.patient : false,
    provider: UserData.provider ? UserData.provider : false,
  },
  reducers: {
    loginPatient: (state, action) => {
      state.patient = action.payload;
      localStorage.setItem('patientLog', JSON.stringify(action.payload));
    },
    logoutPatient: (state) => {
      state.patient = false;
      localStorage.removeItem('patientLog');
    },
    loginProvider: (state, action) => {
      state.provider = action.payload;
      localStorage.setItem('providerLog', JSON.stringify(action.payload));
    },
    logoutProvider: (state) => {
      state.provider = false;
      localStorage.removeItem('providerLog');
    },
  },
});

export const { loginPatient, logoutPatient, loginProvider, logoutProvider } = userSlice.actions;

export const selectPatient = (state) => state.user.patient;
export const selectProvider = (state) => state.user.provider;

export default userSlice.reducer;

