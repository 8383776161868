import PatientsHeader from "./PatientsHeader";
import PatientsFooter from "./PatientsFooter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { postData, putData } from "../../Networking/Api";
import Footer from "../Footer";
import Swal from "sweetalert2";
import Loader from "../Loader";
import CustomSelect from "../CustomSelect";
import ReCAPTCHA from "react-google-recaptcha";
import Header from "../Header";
import { loginPatient } from "../../redux/actions/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectPatient } from "../../redux/actions/userSlice";

function PersonalAppointmentDetails() {
  return (
    <>
      <Header />
      <div className="pt-16">
        <PersonalDetails />
      </div>
      {/* <PersonalDetails /> */}
      <Footer />
    </>
  );
}

function PersonalDetails() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    zipcode: "",
    date_of_birth: "",
    reasonForVisit: "",
  });
  const [verified, setVerified] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const { selectedAppointment } = location.state || {};
  console.log("SelectedAppSlot:..", selectedAppointment);
  const [patientId, setPatientId] = useState("");
  const [practitionerId, setPractitionerId] = useState("");
  const [doctorDetail, setDoctorDetail] = useState("");
  const [viewpatientId, setViewPatientId] = useState("");
  const [loading, setLoading] = useState(false);
  const [doctorName, setDoctorName] = useState([]);
  const [manualDetailsEntered, setManualDetailsEntered] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [notLogin, setNotLogin] = useState(false);
  const [otp, setOtp] = useState("");
  const [patientTypeName, setPatientTypeName] = useState("");
  const [appoinmentName, setAppoinmentName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");

  const {
    patientType,
    insuranceType,
    appointmentType,
    chooseCarrierAndPlanId,
    type,
    name,
  } = useParams();
  const [appointmentData, setAppointmentData] = useState("");
  const [address, setAddress] = useState("");
  //const parsedSelectedAppointment = typeof selectedAppointment === 'string' ? JSON.parse(selectedAppointment) : selectedAppointment;
  console.log("patientType", patientType);
  console.log("insuranceType", insuranceType);
  //console.log("selectedAppointment",selectedAppointment);
  const Dispatch = useDispatch();

  useEffect(() => {
    if (patientType === "newPatient" || patientType === "existingPatient") {
      setPatientTypeName(
        patientType === "newPatient" ? "New Patient" : "Existing Patient"
      );
    }
    const getAppointmentType = async () => {
      try {
        const payload = {
          modelName: "clinic_services",
          whereCondition: {
            id: appointmentType,
          },
        };
        const response = await postData("masters/getMasterList", payload);
        console.log("AppoinmentData....", response.data);

        console.log("data", response.data[0].services);
        setAppoinmentName(response.data[0].services);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };
    getAppointmentType();
  }, [appointmentType, patientType]);

  useEffect(() => {
    if (insuranceType === "other" || insuranceType === "noInsurance") {
      setInsuranceName(insuranceType === "other" ? "Other" : "No Insurance");
      // console.log(insurance);
    }

    const getInsuranceType = async () => {
      try {
        const payload = {
          modelName: "clinic_insurances",
          whereCondition: {
            id: insuranceType,
          },
          relations: [
            {
              module: "insurance_masters",
            },
            {
              module: "health_insurance_carrier_master",
            },
          ],
        };
        const response = await postData("masters/getMasterList", payload);
        console.log("InsuranceData....", response.data[0]);
        setInsuranceName(response.data[0]);
        //   localStorage.setItem("doctorsData", JSON.stringify(response.data));
        //address
        // console.log('data',response.data[0].insurance_master.insurance_name);
        // setInsuranceName(response.data[0].insurance_master.insurance_name);
      } catch (error) {
        console.error("Error fetching insurance options:", error);
      }
    };

    getInsuranceType();
  }, [insuranceType]);

  useEffect(() => {
    const doctorsData = JSON.parse(localStorage.getItem("doctorsData")) || [];
    console.log("doctorsData", doctorsData);
    setDoctorName(doctorsData[0]);
    // console.log('doctorsDatasl',doctorName.f_name);
  }, []);
  useEffect(() => {
    const getClinicDetails = async () => {
      try {
        const payload = {
          modelName: "clinics",
          whereCondition: {
            id: doctorDetail?.clinic_id,
          },
        };

        const response = await postData("masters/getMasterList", payload);
        console.log("Clinic data response:", response);

        const clinicData = response.data[0];

        const addressParts = [
          clinicData.address,
          clinicData.street,
          clinicData.city,
          clinicData.state,
          clinicData.country,
          clinicData.zipcode,
        ];

        const formattedAddress = addressParts.filter(Boolean).join(", ");
        setAddress(formattedAddress);
        console.log("formattedAddress", formattedAddress);
      } catch (error) {
        console.error("Error fetching clinic details:", error);
      }
    };
    getClinicDetails();
  }, [doctorDetail]);
  useEffect(() => {
    const savedUserData =
      JSON.parse(localStorage.getItem("userCreateInfo")) || {};
    // console.log("SavedUser", savedUserData[0]);
    // setPatientId(savedUserData.id);
    // console.log("patientId",savedUserData.id);
    if (!savedUserData.email) {
      setManualDetailsEntered(true);
    }
    // setUserData(savedUserData[0]);
    if (Array.isArray(savedUserData)) {
      setManualDetailsEntered(false);
      // If it's an array, handle the first element
      console.log("SavedUser (array)", savedUserData[0]);
      setPatientId(savedUserData[0]?.id || null);
      setUserData(savedUserData[0] || {});
      console.log("dateofBirth", userData.date_of_birth);
      // console.log('formateddob',new Date(userData.date_of_birth));
      const date = new Date(userData.date_of_birth);
      const formattedDate =
        ("0" + (date.getUTCMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getUTCDate()).slice(-2) +
        "/" +
        date.getUTCFullYear();
      console.log("formattedDate", formattedDate);
    } else {
      // If it's an object, handle the object directly
      console.log("SavedUser (object)", savedUserData);
      setPatientId(savedUserData.id || null);
      setUserData(savedUserData || {});
    }
    const appointDetails =
      JSON.parse(localStorage.getItem("selectedAppointment")) || {};
    //console.log("appointDetails",appointDetails);
    setAppointmentData(appointDetails);
    setPractitionerId(appointDetails.practitioner_id);
    console.log("pracId", appointDetails.practitioner_id);
  }, []);
  console.log("slottime", appointmentData.available_date_time_new);
  console.log("calendarId", appointmentData.id);
  const today = new Date();

  useEffect(() => {
    getPractitionerList(practitionerId);
  }, [practitionerId]);
  const validateForm = () => {
    let isValid = true;
    const errors = {};
    if (!userData.first_name) {
      isValid = false;
      errors.first_name = "First Name is required";
    }
    if (!userData.last_name) errors.last_name = "Last Name is required";
    if (!userData.phone) {
      isValid = false;
      errors.phone = "Phone number is required";
    }
    if (!userData.email) {
      isValid = false;
      errors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
      isValid = false;
      errors.email = "Invalid email format.";
    }
    if (!userData.gender) {
      isValid = false;
      errors.gender = "Sex is required";
    }
    if (!userData.zipcode) {
      isValid = false;
      errors.zipcode = "Zipcode is required";
    }
    if (!userData.date_of_birth) {
      isValid = false;
      errors.date_of_birth = "Date of birth is required";
    }
    setErrors(errors);
    return isValid;
  };
  // function formatphoneNumber(value) {
  //   const numbersOnly = value.replace(/[^\d+]/g, '');
  //   if (numbersOnly.length <= 3) {
  //     return numbersOnly; // Don't format if less than 3 digits
  //   }
  //   return `(${numbersOnly.slice(0, 3)})-${numbersOnly.slice(3, 6)}-${numbersOnly.slice(6)}`;
  // }
  function formatphoneNumber(value) {
    // Remove all non-digit characters, except for the leading '+' for international numbers
    const numbersOnly = value.replace(/[^\d+]/g, "");

    // Format the number based on its length
    if (numbersOnly.length <= 3) {
      return numbersOnly; // If 3 or fewer digits, return as is
    } else if (numbersOnly.length <= 6) {
      return `(${numbersOnly.slice(0, 3)})-${numbersOnly.slice(3)}`; // If 4 to 6 digits, format as (XXX)-XXX
    } else {
      return `(${numbersOnly.slice(0, 3)}) ${numbersOnly.slice(
        3,
        6
      )}-${numbersOnly.slice(6)}`; // If more than 6 digits, format as (XXX)-XXX-XXXX
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (
      (name === "first_name" || name === "last_name") &&
      !/^[A-Za-z\s]*$/.test(value)
    ) {
      return;
    }
    if (name === "phone") {
      // Remove non-digit characters from the value
      //const numbersOnly = value.replace(/[^\d]/g, "");
      const formattedValue = formatphoneNumber(value);
      // Update the value only if it has changed after removing non-digit characters
      if (formattedValue !== value) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: formattedValue,
        }));
        return;
      }
    }
    if (name === "zipcode") {
      // const numericValue = value.replace(/[^\d]/g, "");
      // if (numericValue !== value) {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [name]: value,
      }));
      return;
      // }
    }
    if (name === "gender") {
      const characterValue = value.replace(/[^a-zA-Z]/g, "");
      if (characterValue !== value) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          [name]: characterValue,
        }));
        return;
      }
    }
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    // setErrors({ ...errors, [name]: "" });
  };

  const getPractitionerList = async (practitionerId) => {
    try {
      const payload = {
        modelName: "practitioners",
        whereCondition: {
          id: practitionerId,
        },
      };
      const response = await postData("masters/getMasterList", payload);
      console.log("Doctors....", response.data);
      setDoctorDetail(response.data[0]);
      localStorage.setItem("doctorsData", JSON.stringify(response.data));
      //address
      console.log("doctorsData", response.data[0]);
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Formdata", userData);
      const dobFormatted = formatDate(userData.date_of_birth);
      if (notLogin) {
        console.log("userdetails", {
          first_name: userData.first_name,
          last_name: userData.last_name,
          zipcode: userData.zipcode,
          gender: userData.gender,
          latitude: "00",
          longitude: "00",
        });

        try {
          const payload2 = {
            modelName: "patients",
            id: patientId,
            inputData: {
              first_name: userData.first_name,
              last_name: userData.last_name,
              zipcode: userData.zipcode,
              gender: userData.gender,
              latitude: "00",
              longitude: "00",
              phone: userData.phone,
              date_of_birth: userData.date_of_birth,
            },
          };

          const response2 = await postData(
            "/masters/createAndUpdateMaster",
            payload2
          );

          var patientInfo = []; // Correctly declare an empty array
          patientInfo[0] = response2.data; // Assign value to the first element of the array

          console.log("response2", response2);

          console.log("patientInfo[0]", patientInfo);

          localStorage.setItem("userCreateInfo", JSON.stringify(patientInfo));
          Dispatch(
            loginPatient({
              isLoggedIn: true,
            })
          );
        } catch (error) {
          console.log("error", error);
        }
      }
      try {
        setLoading(true);
        console.log("data==========>", {
          patient_id: patientId,
          dob: dobFormatted,
          firstname: userData.first_name,
          lastname: userData.last_name,
          email: userData.email,
          zipcode: userData.zipcode,
          gender: userData.gender,
          phone: userData.phone,
          reasonForVisit: userData.reasonForVisit,
          patientType: patientType,
          insuranceType: insuranceType,
          appointmentType: appointmentType,
          appointmentid: appointmentData.athena_appointment_id,
          inputData: {
            is_patient_family: 0,
            patient_id: patientId,
            patient_family_id: 0,
            clinic_id: 1,
            practitioner_id: appointmentData.practitioner_id,
            price: 0,
            appointment_date_time: appointmentData.available_date_time,
            payment_details: "NA",
            status: "Booked",
            practitioner_calendars_id: appointmentData.id,
          },
          practitionerCalendarsData: appointmentData,
        });
        const appointmentPayload = {
          patient_id: patientId,
          dob: dobFormatted,
          firstname: userData.first_name,
          lastname: userData.last_name,
          email: userData.email,
          zipcode: userData.zipcode,
          gender: userData.gender,
          phone: userData.phone,
          reasonForVisit: userData.reasonForVisit,
          patientType: patientType,
          insuranceType: insuranceType,
          appointmentType: appointmentType,
          appointmentid: appointmentData.athena_appointment_id,
          inputData: {
            is_patient_family: 0,
            patient_id: patientId,
            patient_family_id: 0,
            clinic_id: 1,
            practitioner_id: appointmentData.practitioner_id,
            price: 0,
            appointment_date_time: appointmentData.available_date_time,
            payment_details: "NA",
            status: "Booked",
            practitioner_calendars_id: appointmentData.id,
            insurance:
              insuranceType === "other"
                ? "Other"
                : insuranceType === "noInsurance"
                ? "No Insurance"
                : null,
            patient_type_id: patientType === "newPatient" ? 1 : 2,
            clinic_insurance_id:
              insuranceType === "other" || insuranceType === "noInsurance"
                ? null
                : insuranceType,
            clinic_service_id: appointmentType,
            appointments_from: 1,
          },
          practitionerCalendarsData: appointmentData,
        };
        console.log("payload....", appointmentPayload);
        const response = await putData(
          "athenaapi/bookAppointments",
          appointmentPayload
        );
        if (response.code === 1) {
          // Handle success
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: response.message,
            icon: "success",
            // timer: 2000,
            showConfirmButton: true,
          });
          localStorage.setItem("patientId", patientId);
          console.log("viewId", response.data.id);
          setViewPatientId(response.data.id);
          const viewId = response.data.id;
          navigate(
            `/yourAppointmentBooked/${type}/${name}/${patientType}/${insuranceType}/${appointmentType}/${viewId}`
          );
          //localStorage.removeItem('userCreateInfo');
          //localStorage.removeItem('selectedAppointment');
          console.log("Appointment created successfully!");
        } else if (response.code === 0) {
          // Handle error when response code is 0
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: response.message,
            icon: "error",
            showConfirmButton: true,
          }).then(() => {
            navigate("/patientLanding");
          });
          console.error("Failed to create appointment:", response.message);
        } else {
          // Handle other error codes
          console.error("Failed to create appointment:", response.message);
        }
      } catch (error) {
        // Handle error
        console.error("Error creating appointment:", error);
      }
    }
  };

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }

  const handleVerifyEmail = async (value) => {
    // const { first_name, last_name, zipcode, gender } = userData;
    // if (!first_name || !last_name || !zipcode || !gender) {
    //   Swal.fire({
    //     title: 'Error',
    //     text: "Please fill in all required details (first name, last name, zipcode, gender)",
    //     icon: 'error',
    //   });
    //   return;
    // }
    if (!value) {
      Swal.fire({
        title: "Error",
        text: "Email is required",
        icon: "error",
      });
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      Swal.fire({
        title: "Error",
        text: "Please Enter a valid email",
        icon: "error",
      });
      return;
    }
    // const verify= async(value)=>{
    try {
      const payload = {
        email: value,
      };

      const response = await postData("/patients/sentOtpRequest", payload);
      console.log("verifyEmailResponse", response);
      if (response.code === 1) {
        Swal.fire({
          title: "Success",
          text: "OTP sent successfully",
          icon: "success",
        });
        setSendOtp(true);
        setIsEmailDisabled(true);
        console.log("userdetails", {
          firstname: userData.first_name,
          lastname: userData.last_name,
          zipcode: userData.zipcode,
          gender: userData.gender,
          latitude: "00",
          longitude: "00",
        });
      }
      if (response.code === 0) {
        Swal.fire({
          title: "Error",
          text: "Email Already exist",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching insurance options:", error);
    }

    console.log("valueEmail", value);
  };
  const handleVerifyOtp = async (otp, value) => {
    try {
      const payload1 = {
        email: value,
        otp: otp,
      };

      const response1 = await postData("/patients/verifyOtp", payload1);
      console.log("verifyEmailOtpResponse", response1);

      setPatientId(response1.data.patientId);

      // localStorage.setItem('patientId',patientId);

      if (response1.code === 1) {
        Swal.fire({
          title: "Success",
          text: "OTP verified successfully",
          icon: "success",
        });

        console.log("response1.data.patientId", response1.data.patientId);
        // console.log('userdetails', {
        //   first_name: userData.first_name,
        //   last_name: userData.last_name,
        //   zipcode: userData.zipcode,
        //   gender: userData.gender,
        //   latitude: '00',
        //   longitude: '00',
        // });

        // try {
        //   const payload2 = {
        //     modelName: "patients",
        //     id: response1.data.patientId,
        //     inputData: {
        //       first_name: userData.first_name,
        //       last_name: userData.last_name,
        //       zipcode: userData.zipcode,
        //       gender: userData.gender,
        //       latitude: '00',
        //       longitude: '00',
        //     }
        //   };

        //   const response2 = await postData("/masters/createAndUpdateMaster", payload2);
        //   console.log('response2', response2);
        // } catch (error) {
        //   console.log("error", error);
        // }

        setSendOtp(false);
        setManualDetailsEntered(false);
        setNotLogin(true);
      } else if (response1.code === 0) {
        Swal.fire({
          title: "Error",
          text: "Please Enter a valid OTP",
          icon: "error",
        });
        return;
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full mt-4">
          <div className="container mx-auto px-4">
            <span
              onClick={() => navigate(`/bookAnAppoinment/${type}/${name}`)}
              className="font-fontPoppins text-sm font-semibold cursor-pointer"
            >
              <i className="fa-solid fa-long-arrow-left text-gray-300"></i>{" "}
              Personal & Appointment Details
            </span>

            <div className="w-full my-4 md:flex md:justify-between md:gap-4">
              <div className="w-full bg-gray-100 px-4 py-5 rounded-lg">
                <h1 className="font-fontPoppins text-base font-semibold text-center">
                  Please enter your exact details
                </h1>

                <div className="w-full mt-3">
                  {/* <label className="w-full font-fontPoppins text-base font-medium">
                Full Name<span className="text-red-500">*</span>
              </label> */}
                  <div className="w-full mt-2 md:flex md:justify-between md:gap-3">
                    <div className="w-full">
                      <label className="w-full font-fontPoppins text-base font-medium">
                        First Name<span className="text-red-500">*</span>
                      </label>

                      <input
                        type="text"
                        placeholder="Enter first name"
                        name="first_name"
                        value={userData.first_name}
                        onChange={handleChange}
                        className="w-full h-11 xss:mt-3 sm:mt-3 md:mt-0 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                      />
                      {errors.first_name && (
                        <p className="text-red-500 text-xs">
                          {errors.first_name}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="w-full font-fontPoppins text-base font-medium">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter last name"
                        name="last_name"
                        value={userData.last_name}
                        onChange={handleChange}
                        className="w-full h-11 xss:mt-3 sm:mt-3 md:mt-0 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                      />
                      {errors.last_name && (
                        <p className="text-red-500 text-xs">
                          {errors.last_name}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="w-full mt-3 md:flex md:justify-between md:gap-3">
                  <div className="w-full">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      Email<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter email ID"
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                      className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs">{errors.email}</p>
                    )}
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3 md:mt-0">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      Phone<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your phone"
                      name="phone"
                      value={userData.phone}
                      maxLength={14}
                      onChange={handleChange}
                      className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-xs">{errors.phone}</p>
                    )}
                  </div>
                </div> */}
                <div className="w-full mt-3 md:flex md:justify-between md:gap-3">
                  <div className="w-full">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      Email<span className="text-red-500">*</span>
                    </label>
                    <input
                      disabled={isEmailDisabled}
                      type="text"
                      placeholder="Enter your email"
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                      className={`w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200 ${
                        isEmailDisabled
                          ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                          : ""
                      }`}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs">{errors.email}</p>
                    )}
                    {manualDetailsEntered &&
                      (sendOtp ? (
                        <div className="mt-3">
                          <input
                            type="text"
                            placeholder="Enter your otp"
                            name="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                          />
                          <button
                            className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-fontPoppins py-1 px-3 rounded-full focus:outline-none"
                            onClick={() =>
                              handleVerifyOtp(
                                otp,
                                userData.email,
                                userData.first_name,
                                userData.last_name
                              )
                            }
                          >
                            Verify OTP
                          </button>
                        </div>
                      ) : (
                        <button
                          className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-fontPoppins py-2 px-4 rounded-full focus:outline-none"
                          onClick={() => handleVerifyEmail(userData.email)}
                        >
                          Verify
                        </button>
                      ))}
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3 md:mt-0">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      Phone<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your phone Number"
                      name="phone"
                      value={userData.phone}
                      maxLength={14}
                      onChange={handleChange}
                      className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-xs">{errors.phone}</p>
                    )}
                  </div>
                </div>

                <div className="w-full mt-3 md:flex md:justify-between md:gap-3">
                  <div className="w-full">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      Sex<span className="text-red-500">*</span>
                    </label>
                    <CustomSelect
                      options={[
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                      ]}
                      name="gender"
                      onChange={(e) => handleChange(e)}
                      value={userData.gender}
                      placeholder={"Select your Sex"}
                      className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                    />
                    {errors.gender && (
                      <p className="text-red-500 text-xs">{errors.gender}</p>
                    )}
                  </div>
                  <div className="w-full xss:mt-3 sm:mt-3 md:mt-0">
                    <label className="w-full font-fontPoppins text-base font-medium">
                      Zip code<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter zip code"
                      name="zipcode"
                      maxLength={10}
                      value={userData.zipcode}
                      onChange={handleChange}
                      className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                    />
                    {errors.zipcode && (
                      <p className="text-red-500 text-xs">{errors.zipcode}</p>
                    )}
                  </div>
                </div>

                <div className="w-full mt-3">
                  <label className="w-full font-fontPoppins text-base font-medium">
                    Date of Birth<span className="text-red-500">*</span>
                  </label>
                  <div className="w-full relative">
                    <span className="z-10 absolute left-4 top-1/2 -translate-y-2/4">
                      <i className="fa-solid fa-calendar-alt text-blueColor"></i>
                    </span>
                    <ReactDatePicker
                      selected={
                        userData.date_of_birth
                          ? `${(
                              "0" +
                              (new Date(userData.date_of_birth).getUTCMonth() +
                                1)
                            ).slice(-2)}/${(
                              "0" +
                              new Date(userData.date_of_birth).getUTCDate()
                            ).slice(-2)}/${new Date(
                              userData.date_of_birth
                            ).getUTCFullYear()}`
                          : null
                      }
                      onChange={(date) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          date_of_birth: date,
                        }))
                      }
                      placeholderText="Enter DOB"
                      className="w-full h-11 indent-8 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={today}
                    />
                    {errors.date_of_birth && (
                      <p className="text-red-500 text-xs">
                        {errors.date_of_birth}
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-full mt-3">
                  <label className="w-full font-fontPoppins text-base font-medium">
                    Reason for Visit
                    <span className="text-f11 text-gray-300">
                      {" "}
                      (in 140 characters)
                    </span>
                  </label>
                  <div className="w-full">
                    {/* <input
                      type="text"
                      placeholder="Enter reason"
                      name="reasonForVisit"
                      value={userData.reasonForVisit}
                      onChange={handleChange}
                      maxLength={140}
                      className="w-full h-11 bg-white rounded-full font-fontPoppins text-sm font-medium text-black shadow-sm px-3 focus:outline-none focus:ring focus:ring-gray-200"
                    /> */}
                    <textarea
                      placeholder="Enter reason"
                      name="reasonForVisit"
                      value={userData.reasonForVisit}
                      onChange={handleChange}
                      maxLength={140}
                      className="w-full h-24 bg-white rounded-md font-fontPoppins text-sm font-medium text-black shadow-sm px-3 py-2 focus:outline-none focus:ring focus:ring-gray-200"
                    ></textarea>
                  </div>
                </div>
                <div className="w-full m-3 ">
                  <ReCAPTCHA
                    sitekey="6LeQUPYpAAAAAB_WZcNRTsEhk-zC4ktXWLLO16Rb"
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="w-full bg-gray-100 px-4 py-5 rounded-xl xss:mt-3 sm:mt-3 md:mt-0">
                <h1 className="font-fontPoppins text-base font-semibold text-center">
                  Appointment Details
                </h1>

                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-xs font-medium text-gray-400">
                    Appointment
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {patientTypeName} | {appoinmentName} <br />
                    {/* Saturday, 13 April, 2024 @ 1:20pm */}
                    {appointmentData.available_date_time_new}
                  </p>
                </div>
                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-xs font-medium text-gray-400">
                    Appointment Duration
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {appointmentData.duration} minutes.
                  </p>
                </div>
                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-xs font-medium text-gray-400">
                    Address
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {/* 90 N 4th St, Martins Ferry OH 43935 */}
                    {/* {doctorDetail.address ? doctorDetail.address : '--'} */}
                    {address}
                  </p>
                </div>

                <div className="w-full mt-4">
                  <h2 className="font-fontPoppins text-xs font-medium text-gray-400">
                    Consulting Doctor
                  </h2>
                  <p className="font-fontPoppins text-sm font-semibold text-black">
                    {/* Dr. Rashmi Kaul */}
                    Dr.{" "}
                    {doctorDetail?.f_name
                      ? `${doctorDetail?.f_name} ${doctorDetail?.l_name}`
                      : `${doctorName?.f_name} ${doctorName?.l_name}`}
                  </p>
                </div>

                {(insuranceType === "other" ||
                  insuranceType === "noInsurance") && (
                  <div className="w-full mt-4">
                    <h2 className="font-fontPoppins text-xs font-medium text-gray-400">
                      Insurance Type
                    </h2>
                    <p className="font-fontPoppins text-sm font-semibold text-black">
                      {insuranceName}
                    </p>
                  </div>
                )}
                {insuranceType !== "other" &&
                  insuranceType !== "noInsurance" && (
                    <div className="w-full mt-4">
                      <h2 className="font-fontPoppins text-xs font-medium text-gray-400">
                        Insurance Carrier
                      </h2>
                      <p className="font-fontPoppins text-sm font-semibold text-black">
                        {
                          insuranceName?.health_insurance_carrier_master
                            ?.health_insurance_carrier_name
                        }
                      </p>
                    </div>
                  )}
                {insuranceType !== "other" &&
                  insuranceType !== "noInsurance" && (
                    <div className="w-full mt-4">
                      <h2 className="font-fontPoppins text-xs font-medium text-gray-400">
                        Insurance Plan
                      </h2>
                      <p className="font-fontPoppins text-sm font-semibold text-black">
                        {insuranceName?.insurance_master?.insurance_name}
                      </p>
                    </div>
                  )}
              </div>
            </div>

            <div className="w-full mt-4 text-center">
              <button
                onClick={handleSubmit}
                className={`w-56 h-10 font-fontPoppins bg-blueColor text-xs rounded-full text-white font-semibold hover:opacity-85 ${
                  !verified ? "cursor-not-allowed" : ""
                }`}
                disabled={!verified}
              >
                Book Your Appointment{" "}
                <i className="fa-solid fa-arrow-right-long"></i>
              </button>
              {/* <button className="w-56 h-10 font-fontPoppins bg-gray-300 text-xs rounded-full text-gray-600 font-semibold hover:opacity-85 hover:cursor-not-allowed">
            Choose Appointment <i className="fa-solid fa-arrow-right-long"></i>
          </button> */}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default PersonalAppointmentDetails;
