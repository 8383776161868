import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Swal from "sweetalert2";
import { postData } from "../Networking/Api";
import Loader2 from "./Loader2";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const ClinicBookingHistory = () => {
  const { tab } = useParams();
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("All Bookings");
  const bookingsPerPage = 10;
  const [appointmentTypes, setAppointmentTypes] = useState({});
  const clinicId = localStorage.getItem("clinicId");
  const refernceId = localStorage.getItem("refernceId");
  const userMasterId = localStorage.getItem("userMasterId");
  const accountType = localStorage.getItem("accountType");
  const [insuranceName, setInsuranceName] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (tab) {
      console.log("tab", tab);

      setSelectedTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    const fetchBookings = async () => {
      console.log("page", currentPage, bookingsPerPage, selectedTab);

      try {
        setLoading(true);
        const tabMapping = {
          "All Bookings": "All",
          "Booked Bookings": "Booked",
          "Confirmed Bookings": "Confirmed",
          "Cancelled Bookings": "Cancelled",
          "Discharged Bookings": "Discharged",
        };

        const payload = {
          clinic_id: clinicId,
          reference_id: parseInt(refernceId),
          type: accountType,
          userMasterId: userMasterId,
          page: currentPage,
          limit: bookingsPerPage,
          selectedTab: tabMapping[selectedTab],
        };
        //         {
        //     "clinic_id": "32",
        //     "reference_id": 32,
        //     "type": "Clinic",
        //     "userMasterId": "114",
        //     "page": 1,
        //     "limit": 10
        // }

        const lowerCaseAccountType = accountType.toLowerCase();

        if (lowerCaseAccountType === "practitioners") {
          payload.userMasterId = userMasterId;
        } else if (
          lowerCaseAccountType === "provider" ||
          lowerCaseAccountType === "clinic"
        ) {
          payload.clinic_id = clinicId;
          payload.reference_id = parseInt(refernceId);
        }

        const response = await postData(
          "clinics/getPatientBookingHistoryForClinics",
          payload
        );
        setLoading(false);

        if (response.success) {
          let updatedBookings = response.data;

          if (lowerCaseAccountType === "practitioners") {
            updatedBookings = updatedBookings.filter(
              (booking) => booking.practitioner_id === parseInt(refernceId)
            );
          }

          // updatedBookings = updatedBookings.map((booking) =>
          //   booking.status === "Pending"
          //     ? { ...booking, status: "Booked" }
          //     : booking
          // );

          setBookings(updatedBookings);
          setTotalRecords(response.count);
        } else {
          setLoading(false);
          console.error("Failed to fetch bookings:", response.message);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [
    accountType,
    clinicId,
    userMasterId,
    refernceId,
    currentPage,
    selectedTab,
  ]);
  console.log("TotalRecords", totalRecords, bookings);

  useEffect(() => {
    const fetchInsuranceTypes = async () => {
      try {
        const filteredBookings = bookings.filter(
          (booking) => booking.insurance === null && booking.clinic_insurance_id
        );
      } catch (error) {
        console.error("Error fetching insurance types:", error);
      }
    };

    fetchInsuranceTypes();
  }, [bookings]);

  const filteredBookings = bookings.filter((booking) => {
    switch (selectedTab) {
      case "Booked Bookings":
        return booking.status === "Booked";
      case "Confirmed Bookings":
        return booking.status === "Confirmed";
      case "Cancelled Bookings":
        return booking.status === "Cancelled";
      case "Discharged Bookings":
        return booking.status === "Discharged";
      default:
        return true;
    }
  });

  const totalPages = Math.ceil(totalRecords / bookingsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;

  const currentBookings = bookings;

  const updateBookingStatus = (bookingId, newStatus) => {
    setBookings((prevBookings) =>
      prevBookings.map((booking) => {
        if (booking.id === bookingId) {
          return { ...booking, status: newStatus };
        }
        return booking;
      })
    );

    filteredBookings();
  };

  const handleCancel = (bookingId) => {
    Swal.fire({
      title: "Cancel Appointment",
      html: `
        <p>Are You Sure You Want To Cancel This Appointment?</p><p> You Won't Be Able To Revert This!</p>
        <textarea id="cancelReason" class="swal2-textarea" placeholder="Enter Reason For Cancelling"></textarea>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel It!",
      preConfirm: () => {
        const reason = Swal.getPopup().querySelector("#cancelReason").value;
        if (!reason) {
          Swal.showValidationMessage("Reason Is Required");
          return false;
        }
        return reason;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const payload = {
            inputData: {
              id: bookingId,
              user_type: accountType,
              user_type_id: userMasterId,
              reasonForCancel: result.value,
            },
          };
          const response = await postData("common/cancel", payload);
          if (response.success) {
            setLoading(false);
            updateBookingStatus(bookingId, "Cancelled");
            setLoading(false);
            Swal.fire({
              title: "Cancelled!",
              text: "Your Appointment Has been Cancelled And Email Sent.",
              icon: "success",
              timer: 2000,
            });
          } else if (response.code === 0) {
            setLoading(false);
            Swal.fire({
              title: "Error!",
              text:
                response.message || "Something went wrong. Please try again.",
              icon: "error",
            });
          } else {
            console.error("Failed to cancel booking");
          }
        } catch (error) {
          setLoading(false);

          console.error("Error cancelling booking:", error);
        }
      }
    });
  };

  const handleConfirmAppointment = (bookingId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want To Confirm This Appointment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Confirm It!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const payload = {
            inputData: {
              id: bookingId,
              user_type: accountType,
              user_type_id: userMasterId,
            },
          };
          const response = await postData("common/confirm", payload);
          setLoading(false);

          if (response.success) {
            updateBookingStatus(bookingId, "Confirmed");
            Swal.fire({
              title: "Confirmed!",
              text: "Your Appointment Has Been Confirmed.",
              icon: "success",
              timer: 2000,
            });
          } else if (response.code === 0) {
            Swal.fire({
              title: "Error!",
              text:
                response.message || "Something went wrong. Please try again.",
              icon: "error",
            });
          } else {
            console.error("Failed to confirm booking");
          }
        } catch (error) {
          setLoading(false);

          console.error("Error confirming booking:", error);
        }
      }
    });
  };

  const handleDischargeAppointment = (booking) => {
    Swal.fire({
      title: "Are You Sure?",
      text: "Do you Want To Discharge This Appointment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Discharge It!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const payload = {
            user_type: accountType,
            user_type_id: userMasterId,
            patient_appointment_id: booking.id,
            patient_id: booking.patient_id,
          };
          const response = await postData(
            "patients/appointmentDischarge",
            payload
          );
          setLoading(false);
          if (response.success || response.code === 1) {
            updateBookingStatus(booking.id, "Discharged");
            Swal.fire({
              title: "Discharged!",
              text: "Your Appointment Has Been Discharged.",
              icon: "success",
              timer: 2000,
            });
            setSelectedTab("Discharged Bookings");
          } else if (response.code === 0) {
            Swal.fire({
              title: "Error!",
              text:
                response.message || "Something went wrong. Please try again.",
              icon: "error",
            });
          } else {
            console.error("Failed to discharge booking");
          }
        } catch (error) {
          console.error("Error discharging booking:", error);
        }
      }
    });
  };

  const formatDate = (dateTimeString, timeZone) => {
    console.log("timezone", timeZone);
    const utcTimestamp = dateTimeString;
    const localTimestamp = moment
      .utc(utcTimestamp)
      .tz(timeZone || "America/New_York")

      .format("MM/DD/YYYY");
    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZone: "UTC",
    };
    const date = new Date(dateTimeString);
    return date.toLocaleDateString("en-US", options);
  };

  // const formatDateTime = (dateTimeString) => {
  //   // alert("dateTimeString--->" + dateTimeString);

  //   const utcTimestamp = dateTimeString;
  //   const localTimestamp = moment
  //     .utc(utcTimestamp)
  //     .tz("America/New_York")
  //     .format("HH:mm A");
  //   console.log("Retrieved local timestamp:", localTimestamp);
  //   return localTimestamp;

  //   const options = {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //     timeZone: "UTC",
  //   };
  //   const date = new Date(dateTimeString);
  //   return date.toLocaleTimeString("en-US", options);
  // };
  const formatDateTime = (dateTimeString, timeZone) => {
    console.log("time", timeZone);

    // Convert UTC timestamp to New York time zone and format to 12-hour time format
    const localTimestamp = moment
      .utc(dateTimeString)
      .tz(timeZone || "America/New_York")
      .format("hh:mm A"); // 12-hour format with AM/PM

    console.log("Retrieved local timestamp:", localTimestamp);
    return localTimestamp;
  };
  const handleBackClick = () => {
    navigate("/dashboard");
  };
  const isToday = (dateString) => {
    const date = moment(dateString).startOf("day");
    const today = moment().startOf("day");
    console.log("dateee", date, "todayyy", today, date.isSame(today, "day"));
    return date.isSame(today, "day");
  };

  const isYesterday = (dateString) => {
    const date = moment(dateString).startOf("day");
    const today = moment().startOf("day");
    console.log(
      "date",
      date.format("YYYY-MM-DD"),
      "today",
      today.format("YYYY-MM-DD")
    );
    return date.isBefore(today, "day");
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setCurrentPage(1);
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <div className="pt-16">
          <div className="w-full mt-3">
            <section className="w-full">
              {tab && (
                <div className="flex justify-start ml-4">
                  <button
                    onClick={handleBackClick}
                    className="flex items-center text-black px-3 py-2 rounded-lg transition duration-300"
                  >
                    <i className="fas fa-arrow-left text-white-500 mr-2"></i>
                    Back
                  </button>
                </div>
              )}
              <div className="container mx-auto flex flex-col sm:flex-row justify-center sm:justify-between items-center">
                <h1 className="text-2xl font-semibold mb-4 sm:mb-0 text-center sm:text-left sm:pl-8 mt-2 md:w-full md:mb-4">
                  Booking Details
                </h1>
                <ul className="flex flex-wrap justify-center sm:flex-nowrap text-center sm:justify-center md:justify-start">
                  {[
                    "All Bookings",
                    "Booked Bookings",
                    "Confirmed Bookings",
                    "Discharged Bookings",
                    "Cancelled Bookings",
                  ].map((tab) => (
                    <li key={tab} className="me-2">
                      <button
                        onClick={() => handleTabClick(tab)}
                        className={`inline-block px-2 py-2 rounded-lg transition duration-300 ${
                          selectedTab === tab
                            ? "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-white border border-gray-300 dark:border-gray-600"
                            : "hover:text-gray-900 hover:bg-gray-300 hover:border border-gray-300 dark:hover:bg-gray-800 dark:hover:text-white dark:hover:border-gray-600"
                        }`}
                      >
                        {tab.split(" ")[0]}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </section>

            <section className="w-full p-3">
              <div className="container mx-auto px-4 py-4 rounded-lg shadow-lg bg-white">
                {currentBookings.length > 0 ? (
                  currentBookings.map((booking, index) => {
                    const appointmentType =
                      appointmentTypes[booking.clinic_service_id];
                    return (
                      <div
                        key={index}
                        className="relative w-full max-w-6xl mx-auto bg-gray-200 p-4 mb-4 rounded-xl shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl big-height flex flex-col align-items-start"
                      >
                        <div className="absolute top-2 right-4">
                          {booking.status !== "Confirmed" &&
                            booking.status !== "Cancelled" &&
                            booking.status !== "Discharged" && (
                              <>
                                {!isYesterday(
                                  formatDate(
                                    booking?.appointment_date_time,
                                    booking?.clinic_locations?.time_zone
                                  )
                                ) && (
                                  <>
                                    <button
                                      className="bg-gray-800 hover:bg-gray-300 text-white py-2 px-2 rounded mr-2"
                                      onClick={() =>
                                        handleConfirmAppointment(booking?.id)
                                      }
                                    >
                                      Confirm
                                    </button>
                                  </>
                                )}
                                <button
                                  className="bg-red-500 hover:bg-red-600 text-white py-2 px-2 rounded mr-2"
                                  onClick={() => handleCancel(booking?.id)}
                                >
                                  Cancel
                                </button>
                              </>
                            )}
                          {booking.status === "Confirmed" &&
                            isToday(
                              formatDate(
                                booking?.appointment_date_time,
                                booking?.clinic_locations?.time_zone
                              )
                            ) && (
                              <button
                                className="bg-gray-800 hover:bg-gray-300 text-white py-2 px-2 rounded mr-2"
                                onClick={() =>
                                  handleDischargeAppointment(booking)
                                }
                              >
                                Discharge
                              </button>
                            )}
                          {booking.status === "Confirmed" && (
                            <button
                              className="bg-red-500 hover:bg-red-600 text-white py-2 px-2 rounded mr-2"
                              onClick={() => handleCancel(booking?.id)}
                            >
                              Cancel
                            </button>
                          )}
                        </div>
                        <h2 className="bookingheading text-xl font-semibold mb-2 flex justify-between px-4 pl-8">
                          {booking?.practitioners?.f_name}{" "}
                          {booking?.practitioners?.l_name}
                        </h2>
                        <div className="flex flex-wrap px-4 pl-8">
                          <div className="w-full md:w-1/2">
                            <p className="text-gray-700">
                              <strong>Appointment Id:</strong>{" "}
                              {booking?.unique_id || "N/A"}
                            </p>
                            <p className="text-gray-700">
                              <strong>Patient:</strong>{" "}
                              {booking?.patients?.first_name}{" "}
                              {booking?.patients?.last_name}
                            </p>
                            <p className="text-gray-700">
                              <strong>Appointment Date:</strong>{" "}
                              {formatDate(
                                booking?.appointment_date_time,
                                booking?.clinic_locations?.time_zone
                              )}
                            </p>
                            <p className="text-gray-700">
                              <strong>Appointment Time:</strong>{" "}
                              {formatDateTime(
                                booking?.appointment_date_time,
                                booking?.clinic_locations?.time_zone
                              )}
                            </p>
                            <p className="text-gray-700">
                              <strong>Appointment Duration:</strong>{" "}
                              {booking?.duration || "N/A"}
                            </p>
                          </div>
                          <div className="w-full md:w-1/2 md:pl-8">
                            <p className="text-gray-700">
                              <strong>Status:</strong> {booking?.status}
                            </p>
                            {/* <p className="text-gray-700">
                              {booking.insurance ? (
                                <p className="text-gray-700">
                                  <strong>Insurance Type:</strong>{" "}
                                  {booking.insurance}
                                </p>
                              ) : insuranceName[booking.id] ? (
                                <>
                                  <p className="text-gray-700">
                                    <strong>Insurance Carrier Name:</strong>{" "}
                                    {
                                      booking.clinic_insurances
                                        .health_insurance_carrier_master
                                        .health_insura
                                    }
                                  </p>
                                  <p className="text-gray-700">
                                    <strong>Insurance Name:</strong>{" "}
                                    {
                                      booking.clinic_insurances
                                        .insurance_masters.insurance_name
                                    }
                                  </p>
                                </>
                              ) : (
                                <p className="text-gray-700">
                                  <strong>Insurance Type:</strong> N/A
                                </p>
                              )}
                            </p> */}
                            <div className="text-gray-700">
                              {booking.insurance ? (
                                <p className="text-gray-700">
                                  <strong>Insurance Type:</strong>{" "}
                                  {booking.insurance}
                                </p>
                              ) : (
                                <>
                                  <p className="text-gray-700">
                                    <strong>Insurance Carrier Name:</strong>{" "}
                                    {booking.clinic_insurances
                                      ?.health_insurance_carrier_master
                                      ?.health_insura || "N/A"}
                                  </p>
                                  <p className="text-gray-700">
                                    <strong>Insurance Name:</strong>{" "}
                                    {booking.clinic_insurances
                                      ?.insurance_masters?.insurance_name ||
                                      "N/A"}
                                  </p>
                                </>
                              )}
                            </div>

                            <p className="text-gray-700">
                              <strong>Appointment Type:</strong>{" "}
                              {/* {appointmentType
                                ? appointmentType.services
                                : "N/A"} */}
                              {booking.clinic_services.services || "N/A"}
                            </p>
                            <p className="text-gray-700">
                              <strong>Reason:</strong>{" "}
                              {booking?.reason || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center justify-center">
                    <div className="flex flex-col items-center justify-center w-98 h-96 bg-white-200 p-8 rounded-lg shadow-lg border width-800px border-gray-300">
                      <div className="h-20 w-20 rounded-full bg-blue-500 flex items-center justify-center mb-4">
                        <svg
                          className="h-16 w-16 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                          />
                        </svg>
                      </div>
                      <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                        No Appointments
                      </h2>
                      <p className="text-lg text-gray-700 text-center">
                        You Don't Have Any Upcoming Appointments.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
        <div className="absolute bottom-4 right-4 mb-4">
          <nav>
            <ul className="flex">
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index} className="mx-1">
                  <button
                    className={`px-3 py-1 rounded-full ${
                      currentPage === index + 1
                        ? "bg-gray-800 text-white"
                        : "bg-gray-200 text-gray-800"
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <Footer />
      </>
    </>
  );
};

export default ClinicBookingHistory;
