import PatientsLandingPageHeader from "./PatientsLandingPageHeader";
import Footer from "./Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useEffect, useState } from "react";
import { postData, apiUrl } from "../Networking/Api";
import StepsForRegistration from "./StepsForRegistration";
import Swal from "sweetalert2";
import Loader2 from "./Loader2";
import { logEvent } from "../ga";

function Pricing() {
  const [customer, setCustomer] = useState("");
  const [metadata, setmetadata] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  console.log("location--->", location);
  const {
    organizationData,
    providerData,
    userDetails,
    clinicServicesMapping,
    clinicServicesData,
    records,
    facilityImages,
    providerTimeslots,
    addLocations,
  } = location.state || {};

  const [monthlyData, setMonthlyData] = useState([]);
  const [annuallyData, setAnnuallyData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState({
    subscriptions_id: "",
    is_active: 1,
  });

  const [data, setdata] = useState([]);
  const [userPostDetails, setUserPostDetails] = useState({
    organizationData: "",
    providerData: "",
    clinicServicesData: "",
    clinicServicesMapping: "",
    userDetails: "",
    records: "",
    facilityImages: "",
    subscription: "",
  });
  const [durations, setDurations] = useState("");
  const [LogoImage, setLogoImage] = useState("");
  const [FacilityImages, setFacilityImages] = useState([]);
  const [OrganizationData, setOrganizationData] = useState({});
  const [ProviderData, setProviderData] = useState({});
  const [ClinicService, setClinicServicesData] = useState({});
  const [providersTimeslot, setProvidersTimeslot] = useState({});
  const [ClinicServiceMapping, setClinicServicesMapping] = useState({});
  const [addLocation, setAddLocation] = useState([]);
  const [UserDetails, setUserDetails] = useState([]);
  const [Records, setRecords] = useState([]);
  const webUrl = window.location.origin;
  useEffect(() => {
    const fetchData = async () => {
      const organizationId = localStorage.getItem("organizationId");

      if (organizationId) {
        try {
          setLoading(true);
          const payload = {
            modelName: "subscription_temp",
            whereCondition: {
              id: parseInt(organizationId),
            },
          };

          const apiResponse = await postData("masters/getMasterList", payload);

          if (apiResponse.code === 1 && apiResponse.data.length > 0) {
            setLoading(false);
            const parsedPayloadData = JSON.parse(
              apiResponse.data[0].payload_data
            );
            // Extract clinics_data and practitioners_data
            console.log("parsepayload...", parsedPayloadData);
            const {
              clinic_services_data,
              clinic_services_mapping,
              practitioners_data,
              providerTimeslots,
              clinics_data,
              clinic_locations,
              assistances,
              clinic_insurances,
              clinic_photo,
            } = parsedPayloadData;
            setUserDetails(assistances || []);
            setClinicServicesData(clinic_services_data || []);
            setProvidersTimeslot(providerTimeslots || []);
            setClinicServicesMapping(clinic_services_mapping || []);
            setAddLocation(clinic_locations || []);
            setOrganizationData(clinics_data || []);
            setProviderData(practitioners_data || []);
            setRecords(clinic_insurances || []);
            setFacilityImages(clinic_photo || []);
          } else {
            console.error("No data found for organizationId:", organizationId);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);
  // useEffect(()=> {
  //   const organizationData = JSON.parse(localStorage.getItem('organizationData')) || {};
  //   const logoImage = localStorage.getItem('logoImage'); // Retrieve the logo image from local storage

  //   // Set the logo image as logo_image in the organizationData object
  //   const updatedOrganizationData = { ...organizationData, logo_image: logoImage };

  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     organizationData: updatedOrganizationData,
  //   }));
  //   console.log('organizationData', updatedOrganizationData);

  //   const providerData = JSON.parse(localStorage.getItem('providerData')) || [];
  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     providerData: providerData,
  //   }));
  //   console.log('providerData',providerData);

  //   const clinicServicesData = JSON.parse(localStorage.getItem('clinic_services_data')) || [];
  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     clinicServicesData: clinicServicesData,
  //   }));
  //   console.log('clinicServicesData',clinicServicesData);

  //   const clinicServicesMapping = JSON.parse(localStorage.getItem('clinic_services_mapping')) || [];
  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     clinicServicesMapping: clinicServicesMapping,
  //   }));
  //   console.log('clinicServicesMapping',clinicServicesMapping);

  //   const userDetails = JSON.parse(localStorage.getItem('userDetails')) || [];
  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     userDetails: userDetails,
  //   }));
  //   console.log('userDetails',userDetails);

  //   const records = JSON.parse(localStorage.getItem('records')) || [];
  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     records: records,
  //   }));
  //   console.log('records',records);

  //   const facilityImages = JSON.parse(localStorage.getItem('facilityImages')) || [];
  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     facilityImages: facilityImages,
  //   }));
  //   console.log('facilityImages',facilityImages);

  //   const subscription = JSON.parse(localStorage.getItem('subscription')) || [];
  //   setUserPostDetails((prevDetails) => ({
  //     ...prevDetails,
  //     subscription: subscription,
  //   }));
  //   console.log('subscription',subscription);
  //   },[])

  // useEffect(()=>{
  //   // const organizationData =
  //   if(!JSON.parse(localStorage.getItem('organizationData')) ){
  //     navigate('/outpatientPracticeDetails2')
  //   }else if(!JSON.parse(localStorage.getItem('providerData')) && !JSON.parse(localStorage.getItem('clinic_services_data'))){
  //     navigate('/serviceToProvider')
  //   }else if(!JSON.parse(localStorage.getItem('userDetails')))
  //     navigate('/frontDeskAssistant')
  // },[])

  useEffect(() => {
    // var metadataArray = {};

    getMonthlyPricingData();
    getAnnuallyPricingData();

    getSubscriptionData();
    fetchDurations();
  }, []);
  const fetchDurations = async () => {
    try {
      const payload = {
        modelName: "subscription_masters",
        whereCondition: {
          id: 1,
        },
      };
      const response = await postData("masters/getMasterList", payload);

      setDurations(response?.data[0]?.duration);
      console.log("Duration Res", response);
    } catch (error) {
      console.error("Error fetching durations:", error);
    }
  };
  const getSubscriptionData = async () => {
    try {
      const payload = {
        modelName: "subscription_masters",
        whereCondition: {
          type: "Clinic",
          is_active: 1,
        },
      };
      const Response = await postData("masters/getMasterList", payload);
      const filteredData = Response.data.filter((record) => record.price !== 0);
      setdata(filteredData);
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false; // Error occurred or email doesn't exist
    }
  };
  const getMonthlyPricingData = async () => {
    try {
      const payload = {
        modelName: "subscription_masters",
        whereCondition: {
          type: "Clinic",
          duration_type: "Monthly",
          is_active: 1,
        },
      };
      const Response = await postData("masters/getMasterList", payload);
      const filteredData = Response.data.filter((record) => record.price !== 0);
      setMonthlyData(filteredData);
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false; // Error occurred or email doesn't exist
    }
  };
  const getAnnuallyPricingData = async () => {
    try {
      const payload = {
        modelName: "subscription_masters",
        whereCondition: {
          type: "Clinic",
          duration_type: "Annually",
          is_active: 1,
        },
      };
      const Response = await postData("masters/getMasterList", payload);
      const filteredData = Response.data.filter((record) => record.price !== 0);
      setAnnuallyData(filteredData);
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false; // Error occurred or email doesn't exist
    }
  };
  const handleNextClick = (subscriptionId) => {
    setSubscriptionData({
      subscriptions_id: subscriptionId,
      is_active: 1,
    });
    localStorage.setItem(
      "subscription",
      JSON.stringify({
        subscriptions_id: subscriptionId,
        is_active: 1,
      })
    );
    navigate("/paymentSetup", {
      state: {
        userDetails,
        organizationData,
        providerData,
        clinicServicesMapping,
        clinicServicesData,
        providerTimeslots,
        records,
        facilityImages,
        subscriptionData: { subscriptions_id: subscriptionId, is_active: 1 },
      },
    });
  };

  ///////////////////////////////
  const checkout = async (id) => {
    const organizationEmail = localStorage.getItem("organizationEmail");
    var metadataArray = {
      // clinics_data: userPostDetails.organizationData,
      // practitioners_data: userPostDetails.providerData,
      // clinic_services_data: userPostDetails.clinicServicesData,
      // clinic_services_mapping: userPostDetails.clinicServicesMapping,
      // assistances: userPostDetails.userDetails,
      // clinic_insurances: userPostDetails.records,
      clinics_data: OrganizationData,
      practitioners_data: ProviderData,
      clinic_services_data: ClinicService,
      clinic_services_mapping: ClinicServiceMapping,
      providerTimeslots: providersTimeslot,
      clinic_locations: addLocation,
      assistances: UserDetails,
      clinic_insurances: Records,
      clinic_photo: facilityImages,
    };
    console.log("metadataArray", metadataArray);
    setLoading(true);
    const res = await postData(
      "stripegateway/checkEmailIsExist",
      metadataArray
    );
    // console.log("res createSubscriptionCheckoutSessionForSignup---->", res);
    if (res.code == 0) {
      Swal.fire("Error", res.message || "Something went wrong.", "error");
      setLoading(false);
      return false;
    } else {
      setLoading(true);
      fetch(
        apiUrl +
          "/api/stripegateway/createSubscriptionCheckoutSessionForSignup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({
            id: id,
            email: organizationEmail,
            metadata: metadataArray,
            apiUrl: webUrl,
            clinicId: "0",
          }),
        }
      )
        .then(async (res) => {
          if (res.ok) return res.json();

          console.log(res);
          const json = await res.json();

          setLoading(false);
          logEvent("User", "Created an Account'", "Signup Button");
          return await Promise.reject(json);
        })
        .then(({ session }) => {
          window.location = session.url;
        })

        .catch((e) => {
          console.log(e.error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading && <Loader2 />}
      <>
        <Header />
        <>
          <div className="pt-16">
            <div className="container mx-auto px-4 flex items-center justify-between gap-16 xss:gap-2 sm:gap-5 relative">
              <StepsForRegistration />
              <h1 className="font-fontPoppins md:text-xl font-semibold text-xs xss:text-xs sm:text-sm leading-normal absolute left-1/2  sm:left-1/2 sm:transform sm:-translate-x-1/2 xss:right-0">
                Subscriptions
              </h1>
            </div>
            <div className="flex flex-col items-center w-full mx-auto min-h-screen diagonal-background overflow-x-hidden text-center">
              {/* 
          <div
            className="grid lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-5 z-50 place-items-center w-9/12 mx-auto
        mt-20"
          >
           
            {data.map((item, idx) => (
              <div key={idx} className={`bg-white px-6 py-8 rounded-xl text-[#4f7cff] w-full mx-auto grid 
              place-items-center border border-gray-400 border-[16px] `}>
                <img
                  src='/basic.svg'
                  alt=""
                  width={200}
                  height={200}
                  className="h-40"
                />
                <div className="text-4xl text-slate-700 text-center py-4 font-bold">
                  {item.name}
                </div>
                <p className="lg:text-sm text-xs text-center px-6 text-slate-500">
                  Free trial for {durations} month{durations > 1 ? 's' : ''}.<br />
                  Unlimited consultations with our experienced healthcare providers.<br />
                  Access to a variety of specialists without the need for referrals.<br />
                  Routine check-ups, preventive screenings, and wellness exams included.
                </p>
               
                <div className="text-4xl text-center font-bold py-4">
                  <span style={{ fontSize: '0.875rem', textDecoration: 'line-through' }}>${item.actual_price}</span>
                  <span style={{ fontSize: '1.25rem' }}>${item.price}</span>
                </div>

                <div className="mx-auto flex justify-center items-center my-3">
                  <button
                    onClick={() => checkout(Number(item.id))}
                    className="bg-[#3d5fc4] text-white rounded-md text-base uppercase w-24 py-2 font-bold"
                  >
                    BUY
                  </button>
                </div>
              </div>
            ))}

          </div>
 */}
              <div className="grid lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-8  place-items-center w-11/12 mx-auto mt-10 bg-gradient-to-r from-blue-100 to-blue-50 p-10 rounded-lg shadow-lg">
                {data.map((item, idx) => (
                  <div
                    key={idx}
                    className="bg-white px-8 py-10 rounded-xl text-[#4f7cff] w-full mx-auto grid place-items-center border border-gray-300 shadow-lg transform transition duration-500 hover:scale-105"
                  >
                    {/* <img
        src="/basic.svg"
        alt=""
        width={200}
        height={200}
        className="h-40 mb-4"
      /> */}
                    {item.duration_type === "Monthly" && (
                      <img
                        src="/monthly.png"
                        alt="Monthly Subscription"
                        width={200}
                        height={200}
                        className="h-40 mb-4"
                      />
                    )}
                    {item.duration_type === "Annually" && (
                      <img
                        src="/annually.png"
                        alt="Annual Subscription"
                        width={200}
                        height={200}
                        className="h-40 mb-4"
                      />
                    )}
                    <div className="text-4xl text-slate-700 text-center py-4 font-bold">
                      {item.name}
                    </div>
                    <ul className="lg:text-sm text-xs text-center  text-slate-500 mb-3 list-disc list-inside  ">
                      <li className="font-bold text-xl mb-1">
                        Free Trial For {durations} Month.
                      </li>
                      {/* <li className="font-bold text-sm mb-1">
                      Unlimited consultations with our experienced healthcare
                      providers.
                    </li>
                    <li className="font-bold text-sm mb-1">
                      Access to a variety of specialists without the need for
                      referrals.
                    </li>
                    <li className="font-bold text-sm mb-1">
                      Routine check-ups, preventive screenings, and wellness
                      exams included.
                    </li> */}
                    </ul>
                    <div className="text-4xl text-center font-bold py-4">
                      <span className="text-base line-through">
                        ${item.actual_price}
                      </span>
                      <br />
                      <span className="text-2xl ml-2">
                        Starting At -${item.price}
                      </span>
                    </div>
                    <div className="mx-auto flex justify-center items-center my-3">
                      <button
                        onClick={() => checkout(Number(item.id))}
                        className="bg-[#3d5fc4] text-white rounded-md text-base uppercase w-28 py-2 font-bold hover:bg-[#2c4a9a]"
                      >
                        BUY
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      </>
      {/* /////////////////////////////////////// */}
      <div
        className="container mx-auto px-4 flex items-center gap-16 xss:gap-2 sm:gap-5"
        style={{ display: "none" }}
      >
        <StepsForRegistration />
        <h1 className="font-fontPoppins text-2xl font-semibold xss:text-xs sm:text-lg leading-normal"></h1>
      </div>
      <section className="w-full mt-6" style={{ display: "none" }}>
        <div className="container mx-auto px-0">
          <div className="w-full">
            <h1 className="font-fontPoppins text-3xl font-semibold text-center">
              Pricing
            </h1>
            <p className="font-fontPoppins text-xs font-normal text-center mt-2">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard <br />
              dummy text ever since the 1500s.
            </p>
          </div>

          <div className="lg:w-1/2 h-screen mx-auto flex items-start justify-between mt-10">
            {monthlyData.map((plan, index) => (
              <div className="w-full min-h-80 bg-white shadow-md p-4 border border-transparent hover:border hover:border-blueColor">
                <h1 className="w-full text-center">
                  <span className="font-fontPoppins text-f10 text-center bg-blueColor text-white font-semibold uppercase px-3 py-1 rounded-full">
                    {plan.name}
                  </span>
                </h1>
                <div className="w-full text-center mt-4 mb-4">
                  <span className="font-fontPoppins text-sm font-semibold">
                    <i class="fa-solid fa-dollar-sign"></i>
                  </span>{" "}
                  <span className="font-fontPoppins text-5xl font-bold">
                    {plan.price}
                  </span>{" "}
                  <span className="font-fontPoppins text-sm font-semibold">
                    <del>{plan.actual_price}</del>
                  </span>
                </div>

                <div className="w-full mt-4 flex items-start gap-2">
                  <i className="fa-regular fa-circle-check text-blueColor"></i>
                  <span className="font-fontPoppins text-xs font-semibold leading-tight">
                    Lorem Ipsum has been the industry's standard dummy text.
                  </span>
                </div>

                <div className="w-full mt-4 flex items-start gap-2">
                  <i className="fa-regular fa-circle-check text-blueColor"></i>
                  <span className="font-fontPoppins text-xs font-semibold leading-tight">
                    Lorem Ipsum has been the industry's standard dummy text.
                  </span>
                </div>

                <div className="w-full mt-4 flex items-start gap-2">
                  <i className="fa-regular fa-circle-check text-blueColor"></i>
                  <span className="font-fontPoppins text-xs font-semibold leading-tight">
                    Lorem Ipsum has been the industry's standard dummy text.
                  </span>
                </div>

                <div className="w-full text-center mt-6">
                  <button
                    onClick={() => handleNextClick(plan.id)}
                    className="font-fontPoppins bg-gray-200 text-gray-600 hover:bg-blueColor hover:text-white text-xs rounded-full font-semibold hover:opacity-85 px-4 py-2 xss:mb-2"
                  >
                    {/* <Link 
            to="/paymentSetup"   state={{ userDetails,services,records,images,subscriptionData }}
            > */}
                    <span className="me-2">Next</span>
                    <i className="fa-solid fa-arrow-right-long"></i>
                    {/* </Link> */}
                  </button>
                </div>
              </div>
            ))}
            {annuallyData.map((plan, index) => (
              <div className="w-full min-h-80 bg-white shadow-md p-4 border border-transparent hover:border hover:border-blueColor">
                <h1 className="w-full text-center">
                  <span className="font-fontPoppins text-f10 text-center bg-blueColor text-white font-semibold uppercase px-3 py-1 rounded-full">
                    {plan.name}
                  </span>
                </h1>
                <div className="w-full text-center mt-4 mb-4">
                  <span className="font-fontPoppins text-sm font-semibold">
                    <i class="fa-solid fa-dollar-sign"></i>
                  </span>{" "}
                  <span className="font-fontPoppins text-5xl font-bold">
                    {plan.price}
                  </span>{" "}
                  <span className="font-fontPoppins text-sm font-semibold">
                    <del>{plan.actual_price}</del>
                  </span>
                </div>

                <div className="w-full mt-4 flex items-start gap-2">
                  <i className="fa-regular fa-circle-check text-blueColor"></i>
                  <span className="font-fontPoppins text-xs font-semibold leading-tight">
                    Lorem Ipsum has been the industry's standard dummy text.
                  </span>
                </div>

                <div className="w-full mt-4 flex items-start gap-2">
                  <i className="fa-regular fa-circle-check text-blueColor"></i>
                  <span className="font-fontPoppins text-xs font-semibold leading-tight">
                    Lorem Ipsum has been the industry's standard dummy text.
                  </span>
                </div>

                <div className="w-full mt-4 flex items-start gap-2">
                  <i className="fa-regular fa-circle-check text-blueColor"></i>
                  <span className="font-fontPoppins text-xs font-semibold leading-tight">
                    Lorem Ipsum has been the industry's standard dummy text.
                  </span>
                </div>

                <div className="w-full text-center mt-6">
                  <button
                    onClick={() => handleNextClick(plan.id)}
                    className="font-fontPoppins bg-gray-200 text-gray-600 hover:bg-blueColor hover:text-white text-xs rounded-full font-semibold hover:opacity-85 px-4 py-2 xss:mb-2"
                  >
                    {/* <Link 
            to="/paymentSetup"   state={{ userDetails,organizationData,providerData,clinicServicesMapping,clinicServicesData,records,facilityImages,subscriptionData }} */}
                    {/* > */}
                    <span className="me-2">Next</span>
                    {/* <i className="fa-solid fa-arrow-right-long"></i>
            </Link> */}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

// function PricingPackage() {
//   return (
//     // <section className="w-full mt-6">
//     //   <div className="container mx-auto px-0">
//     //     <div className="w-full">
//     //       <h1 className="font-fontPoppins text-3xl font-semibold text-center">
//     //         Pricing
//     //       </h1>
//     //       <p className="font-fontPoppins text-xs font-normal text-center mt-2">
//     //         Lorem Ipsum is simply dummy text of the printing and typesetting
//     //         industry. Lorem Ipsum has been the industry's standard <br />
//     //         dummy text ever since the 1500s.
//     //       </p>
//     //     </div>

//     //     <div className="lg:w-1/2 h-screen mx-auto flex items-start justify-between mt-10">
//     //       <div className="w-full min-h-80 bg-white shadow-md p-4 border border-transparent hover:border hover:border-blueColor">
//     //         <h1 className="w-full text-center">
//     //           <span className="font-fontPoppins text-f10 text-center bg-blueColor text-white font-semibold uppercase px-3 py-1 rounded-full">
//     //             Monthly
//     //           </span>
//     //         </h1>
//     //         <div className="w-full text-center mt-4 mb-4">
//     //           <span className="font-fontPoppins text-sm font-semibold">
//     //             <i className="fa-solid fa-indian-rupee-sign"></i>
//     //           </span>{" "}
//     //           <span className="font-fontPoppins text-5xl font-bold">49</span>{" "}
//     //           <span className="font-fontPoppins text-sm font-semibold">
//     //             <del>99</del>
//     //           </span>
//     //         </div>

//     //         <div className="w-full mt-4 flex items-start gap-2">
//     //           <i className="fa-regular fa-circle-check text-blueColor"></i>
//     //           <span className="font-fontPoppins text-xs font-semibold leading-tight">
//     //             Lorem Ipsum has been the industry's standard dummy text.
//     //           </span>
//     //         </div>

//     //         <div className="w-full mt-4 flex items-start gap-2">
//     //           <i className="fa-regular fa-circle-check text-blueColor"></i>
//     //           <span className="font-fontPoppins text-xs font-semibold leading-tight">
//     //             Lorem Ipsum has been the industry's standard dummy text.
//     //           </span>
//     //         </div>

//     //         <div className="w-full mt-4 flex items-start gap-2">
//     //           <i className="fa-regular fa-circle-check text-blueColor"></i>
//     //           <span className="font-fontPoppins text-xs font-semibold leading-tight">
//     //             Lorem Ipsum has been the industry's standard dummy text.
//     //           </span>
//     //         </div>

//     //         <div className="w-full text-center mt-6">
//     //           <button className="font-fontPoppins bg-gray-200 text-gray-600 hover:bg-blueColor hover:text-white text-xs rounded-full font-semibold hover:opacity-85 px-4 py-2 xss:mb-2">
//     //             Sign Up Today
//     //           </button>
//     //         </div>
//     //       </div>

//     //       <div className="w-full min-h-80 bg-white shadow-md p-4 border border-transparent hover:border hover:border-blueColor">
//     //         <h1 className="w-full text-center">
//     //           <span className="font-fontPoppins text-f10 text-center bg-blueColor text-white font-semibold uppercase px-3 py-1 rounded-full">
//     //             Annually
//     //           </span>
//     //         </h1>
//     //         <div className="w-full text-center mt-4 mb-4">
//     //           <span className="font-fontPoppins text-sm font-semibold">
//     //             <i className="fa-solid fa-indian-rupee-sign"></i>
//     //           </span>{" "}
//     //           <span className="font-fontPoppins text-5xl font-bold">49</span>{" "}
//     //           <span className="font-fontPoppins text-sm font-semibold">
//     //             <del>99</del>
//     //           </span>
//     //         </div>

//     //         <div className="w-full mt-4 flex items-start gap-2">
//     //           <i className="fa-regular fa-circle-check text-blueColor"></i>
//     //           <span className="font-fontPoppins text-xs font-semibold leading-tight">
//     //             Lorem Ipsum has been the industry's standard dummy text.
//     //           </span>
//     //         </div>

//     //         <div className="w-full mt-4 flex items-start gap-2">
//     //           <i className="fa-regular fa-circle-check text-blueColor"></i>
//     //           <span className="font-fontPoppins text-xs font-semibold leading-tight">
//     //             Lorem Ipsum has been the industry's standard dummy text.
//     //           </span>
//     //         </div>

//     //         <div className="w-full mt-4 flex items-start gap-2">
//     //           <i className="fa-regular fa-circle-check text-blueColor"></i>
//     //           <span className="font-fontPoppins text-xs font-semibold leading-tight">
//     //             Lorem Ipsum has been the industry's standard dummy text.
//     //           </span>
//     //         </div>

//     //         <div className="w-full text-center mt-6">
//     //           <button className="font-fontPoppins bg-gray-200 text-gray-600 hover:bg-blueColor hover:text-white text-xs rounded-full font-semibold hover:opacity-85 px-4 py-2 xss:mb-2">
//     //             Sign Up Today
//     //           </button>
//     //         </div>
//     //       </div>
//     //     </div>
//     //   </div>
//     // </section>
//   );
// }

export default Pricing;
