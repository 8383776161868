import PatientsLandingPageHeader from "./PatientsLandingPageHeader";
import PatientsLandingPageFooter from "./PatientsLandingPageFooter";
import { postData, apiUrl, imageBaseUrl } from "../Networking/Api";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { logPageView } from "../ga";
import ProviderLandingPageFooter from "./ProviderLandingPageFooter/ProviderLandingPageFooter";

function PatientsLandingPage() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50 bg-white shadow-md">
        <PatientsLandingPageHeader />
      </div>
      <div className="pt-16">
        <Banner />
        {/* <Doctors />
      <PersonDoctorVist />
      <Terms /> */}
        <WeCanHelp />
        <WhatPatientsSay />
        {/* <PatientsLandingPageFooter /> */}
        <ProviderLandingPageFooter />
      </div>
    </>
  );
}

function Banner() {
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [locationName, setLocationName] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const navigate = useNavigate();

  // const GOOGLE_MAPS_API_KEY = "YOUR_GOOGLE_MAPS_API_KEY";
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (searchTerm) {
          const response = await postData("patients/patientsSearchBy", {
            searchData: searchTerm,
          });
          setResults(response.data);
          localStorage.removeItem("selectedSlot");
          localStorage.removeItem("selectedDate");
          localStorage.removeItem("selectedOptions");
        } else {
          setResults([]);
        }
      } catch (error) {
        // console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, [searchTerm]);
  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {

  //         setLocation({
  //           lat: position.coords.latitude,
  //           lon: position.coords.longitude,
  //         });
  //         // console.log("lat",lat);
  //         // console.log("lat",lon);
  //       },
  //       (error) => {
  //         console.error("Error getting location:", error);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // };
  useEffect(() => {
    getLocationFromIP();

    // Update the time every second
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  const getLocationFromIP = async () => {
    try {
      var currentTimeZone = localStorage.getItem("currentTimeZone");
      // console.log("currentTimeZone----", currentTimeZone);

      if (
        currentTimeZone == "null" ||
        currentTimeZone == null ||
        currentTimeZone == ""
      ) {
        // console.log("if--->");
        try {
          const response = await fetch("https://ipapi.co/json/");
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();

          setLocation({
            lat: data.latitude,
            lon: data.longitude,
          });

          const locationName = `${data.city}, ${data.region_code}`;
          setLocationName(locationName);

          const timeZone = `${data.utc_offset}, ${data.timezone}`;
          setTimeZone(timeZone);
          localStorage.setItem("currentTimeZone", timeZone);
          console.log("time zone--->", timeZone);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        // console.log("else--->");
        setTimeZone(JSON.parse(currentTimeZone));
        console.log("timeZone currentTimeZone-->", currentTimeZone);
        console.log("timeZone state-->", timeZone);
      }
    } catch (error) {
      console.error("Error fetching IP location:", error);
    }
  };
  // console.log("timeZone-->", timeZone);

  useEffect(() => {
    if (searchTerm) {
      const filtered = results.filter((result) =>
        result.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredResults(filtered);
    } else {
      setFilteredResults([]);
    }
  }, [searchTerm, results]);

  const handleResultClick = (result) => {
    const type = result.type;
    const name = result.name;
    navigate(`/bookAnAppoinment/${type}/${name}`);
  };

  // const handlePlaceChanged = () => {
  //   if (autocomplete !== null) {
  //     const place = autocomplete.getPlace();
  //     setLocation(place.formatted_address);
  //   } else {
  //   // console.log('Autocomplete is not loaded yet!');
  //   }
  // };
  // console.log("timezone..",timeZone);
  const navigateToBooking = () => {
    console.log("currentDomain", window.location.origin);
    const currentDomain = window.location.origin;

    //const targetUrl = `${currentDomain}/bookAnAppoinmentThirdParty/clinicServices/126`;
    const targetUrl = `${currentDomain}/bookAppoinment/capitalcitydental`;
    window.location.href = targetUrl;
  };
  return (
    <section className="w-full z-2">
      <div className="container mx-auto px-4 py-8 md:flex md:gap-4 md:items-center">
        <div className="w-full">
          {/* <h1 className="text-center font-fontPoppins text-3xl xss:text-base sm:text-lg font-semibold">
            Get medical care starting just $18.90
          </h1> */}
          <div className="flex justify-center">
            <button
              className="bg-blueColor text-white font-bold px-8 py-3 mb-2 text-md rounded-full transition duration-100 hover:bg-black hover:text-blue-500"
              onClick={navigateToBooking}
            >
              Book Appointment: Demo
            </button>
          </div>
          <div
            style={{ display: "none" }}
            className="w-full flex items-center bg-white gap-2 p-2 justify-center border border-blueColor border-solid rounded-full shadow-sm mt-3"
          >
            <i className="fa-solid fa-search text-blueColor ms-2"></i>
            <input
              className="w-full h-9 focus:outline-none font-fontPoppins text-xs text-gray-500"
              type="text"
              placeholder="Search by symptom, service, provider, medical..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {/* <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
              <Autocomplete 
                onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                onPlaceChanged={handlePlaceChanged}
              >
                 <input
                  className="w-25 h-9 px-2 ml-2 focus:outline-none font-fontPoppins text-xs text-gray-500 rounded-full border border-gray-300"
                  type="text"
                  placeholder="Location..."
                  value={location}
                  onChange={e => setLocation(e.target.value)}
                />
              </Autocomplete>
            </LoadScript> */}
            <span className="w-48 h-9 px-2 flex justify-center bg-gray-100 rounded-full items-center text-xs leading-normal ">
              <img src={"./loc.png"} width="24px" alt="Location Icon" />
              {/* Martins Ferry, OH */}
              {locationName}
            </span>
          </div>
          <div
            className="shadow-md z-5 rounded-lg absolute bg-white w-1/3 max-h-80 overflow-y-auto mx-3"
            style={{ width: "44%" }}
          >
            {filteredResults.map((result, index) => (
              <p
                className="p-2 pl-5 w-full hover:bg-blue-700 cursor-pointer"
                onClick={() => handleResultClick(result)}
                key={index}
              >
                {result.name}
              </p>
            ))}
          </div>
        </div>
        <div className="w-full text-right xss:mt-4 sm:mt-4">
          <img
            src={"./banner-image.png"}
            alt="logo"
            className="max-w-full ml-auto"
          />
        </div>
      </div>
    </section>
  );
}
function WeCanHelp() {
  const [helpData, setHelpData] = useState([]);

  useEffect(() => {
    const fetchHelpDetails = async () => {
      try {
        const payload = {
          modelName: "free_text_details",
          whereCondition: { is_active: 1 },
        };

        const response = await postData("masters/getMasterList", payload);
        const helpDetails = response.data;

        if (Array.isArray(helpDetails)) {
          setHelpData(helpDetails);
        }
      } catch (error) {
        console.error("Error Fetching Details", error);
      }
    };
    fetchHelpDetails();
  }, []);

  return (
    <section className="w-full py-12 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="font-fontPoppins text-center text-3xl font-bold mb-8">
          We Can Help
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {helpData.map((item, index) => (
            <div key={index} className="bg-gray-200 p-6 rounded-lg shadow-md">
              <h5 className="font-fontPoppins text-lg font-bold mb-2">
                {item.title}
              </h5>
              <p
                className="font-fontPoppins text-base"
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

// function Doctors() {
//   return (
//     <section className="w-full">
//       <div className="container mx-auto px-4">
//         <div className="w-full">
//           <h2 className="text-center font-fontPoppins text-2xl xss:text-base sm:text-lg font-semibold">
//             Top-rated online doctors & specialists available now!
//           </h2>
//           <p className="text-center font-fontPoppins text-sm xss:text-xs sm:text-xs font-normal mt-2">
//             Choose from thousands of providers at every day affordable prices.
//             Book online today
//           </p>
//         </div>

//         <div className="w-full mt-4 flex justify-center ">
//           <div className="shadow rounded-xl">
//             <button className="w-36 py-3 xss:w-24 xss:py-2 bg-blueColor rounded-xl font-fontPoppins text-xs text-white me-2 xss:me-0">
//               Most Booked
//             </button>
//             <button className="w-36 py-3 xss:w-24 xss:py-2 hover:bg-blueColor rounded-xl font-fontPoppins text-xs hover:text-white me-2 xss:me-0">
//               Doctors
//             </button>
//             <button className="w-36 py-3 xss:w-24 xss:py-2 hover:bg-blueColor rounded-xl font-fontPoppins text-xs hover:text-white me-2 xss:me-0">
//               Specialities
//             </button>
//             <button className="w-36 py-3 xss:w-24 xss:py-2 hover:bg-blueColor rounded-xl font-fontPoppins text-xs hover:text-white me-2 xss:me-0">
//               Symptoms
//             </button>
//           </div>
//         </div>

//         <div className="w-full mt-6 md:flex md:justify-between md:gap-3 ">
//           <div className="w-full min-h-24 d-box grid content-between bg-gray-200 hover:bg-blueColor hover:text-white p-3 rounded-lg xss:mt-2 sm:mt-2">
//             <div className="w-full">
//               <img src={"./file-prescription.png"} alt="file-prescription" />
//               <h3 className="font-fontPoppins text-sm font-semibold">
//                 Prescription Refill
//               </h3>
//             </div>
//             <div className="w-full">
//               <a
//                 href={" "}
//                 className="font-fontPoppins text-xs  font-normal cursor-pointer align-text-bottom no-underline"
//               >
//                 Book <i className="fa-solid fa-arrow-right"></i>
//               </a>
//             </div>
//           </div>

//           <div className="w-full min-h-24 d-box grid content-between bg-gray-200 hover:bg-blueColor hover:text-white p-3 rounded-lg xss:mt-2 sm:mt-2">
//             <div className="w-full">
//               <img src={"./person-booth.png"} alt="file-prescription" />
//               <h3 className="font-fontPoppins text-sm font-semibold">
//                 IN-person Doctor Visit
//               </h3>
//             </div>
//             <div className="w-full">
//               <a
//                 href={" "}
//                 className="font-fontPoppins text-xs  font-normal cursor-pointer align-text-bottom no-underline"
//               >
//                 Book <i className="fa-solid fa-arrow-right"></i>
//               </a>
//             </div>
//           </div>

//           <div className="w-full min-h-24 d-box grid content-between bg-gray-200 hover:bg-blueColor hover:text-white p-3 rounded-lg xss:mt-2 sm:mt-2">
//             <div className="w-full">
//               <img src={"./kidney.png"} alt="file-prescription" />
//               <h3 className="font-fontPoppins text-sm font-semibold">
//                 UTI Consult
//               </h3>
//             </div>
//             <div className="w-full">
//               <a
//                 href={" "}
//                 className="font-fontPoppins text-xs  font-normal cursor-pointer align-text-bottom no-underline"
//               >
//                 Book <i className="fa-solid fa-arrow-right"></i>
//               </a>
//             </div>
//           </div>

//           <div className="w-full min-h-24 d-box grid content-between bg-gray-200 hover:bg-blueColor hover:text-white p-3 rounded-lg xss:mt-2 sm:mt-2">
//             <div className="w-full">
//               <img src={"./stomach.png"} alt="file-prescription" />
//               <h3 className="font-fontPoppins text-sm font-semibold">
//                 ED Consult
//               </h3>
//             </div>
//             <div className="w-full">
//               <a
//                 href={" "}
//                 className="font-fontPoppins text-xs  font-normal cursor-pointer align-text-bottom no-underline"
//               >
//                 Book <i className="fa-solid fa-arrow-right"></i>
//               </a>
//             </div>
//           </div>

//           <div className="w-full min-h-24 d-box grid content-between bg-gray-200 hover:bg-blueColor hover:text-white p-3 rounded-lg xss:mt-2 sm:mt-2">
//             <div className="w-full">
//               <img src={"./awesome-brain.png"} alt="file-prescription" />
//               <h3 className="font-fontPoppins text-sm font-semibold">
//                 Mental Health Consult
//               </h3>
//             </div>
//             <div className="w-full">
//               <a
//                 href={" "}
//                 className="font-fontPoppins text-xs  font-normal cursor-pointer align-text-bottom no-underline"
//               >
//                 Book <i className="fa-solid fa-arrow-right"></i>
//               </a>
//             </div>
//           </div>

//           <div className="w-full min-h-24 d-box grid content-between bg-gray-200 hover:bg-blueColor hover:text-white p-3 rounded-lg xss:mt-2 sm:mt-2">
//             <div className="w-full">
//               <img src={"./awesome-plus-circle.png"} alt="file-prescription" />
//               <h3 className="font-fontPoppins text-sm font-semibold">
//                 Urgent Care Visit
//               </h3>
//             </div>
//             <div className="w-full">
//               <a
//                 href={" "}
//                 className="font-fontPoppins text-xs  font-normal cursor-pointer align-text-bottom no-underline"
//               >
//                 Book <i className="fa-solid fa-arrow-right"></i>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// function PersonDoctorVist() {
//   return (
//     <section className="w-full">
//       <div className="container mx-auto px-4 py-8">
//         <div className="flex">
//           <h2 className="w-full text-center font-fontPoppins text-2xl xss:text-base sm:text-lg font-semibold">
//             In-person doctor visit
//           </h2>
//           <span className="w-24 text-right ml-auto text-xs font-fontPoppins text-gray-400">
//             View All
//           </span>
//         </div>

//         <div className="w-full mt-4 flex justify-center gap-2">
//           <span className="font-fontPoppins text-xs font-semibold">
//             Urgent appointment within
//           </span>
//           <InputRange />
//         </div>

//         <div className="w-full">
//           <DoctorProfile />
//         </div>
//       </div>
//     </section>
//   );
// }

// function InputRange() {
//   return (
//     <div>
//       <input type="range" minLength={0} maxLength={10} value={0} />
//       <div className="w-full font-fontPoppins text-xs text-gray-400 text-right flex justify-between">
//         <span>2-hr</span> <span>4-hr</span> <span>8 hr</span>
//       </div>
//     </div>
//   );
// }

// function DoctorProfile() {
//   return (
//     <div className="w-full md:flex md:items-center md:gap-4 mt-4 overflow-auto lg:px-4 lg:py-4">
//       <div className="w-full p-3 rounded-lg shadow relative mt-4 xss:mt-5">
//         <span className="w-28 font-fontPoppins text-f10 bg-green-500 rounded-full text-center text-white py-1 z-30 absolute left-2/4 -ml-16 -top-3.5">
//           Available Today
//         </span>

//         <div className="flex items-center justify-center gap-2 mt-5">
//           <span>
//             <img
//               src={"./kw.png"}
//               className="rounded-full shadow-lg"
//               width="60px"
//               height="60px"
//               alt="logo"
//             />
//           </span>
//           <div>
//             <h4 className="font-fontPoppins text-lg font-bold">
//               Dr. Rashmi Kaul
//             </h4>
//             <div className="flex items-center gap-2">
//               <i class="fa-solid fa-stethoscope text-f10"></i>
//               <span className="text-xs font-fontPoppins font-semibold">
//                 PSYCHIATRIST
//               </span>
//               <span className="py-1 px-2 text-f9 font-fontPoppins bg-gray-300 rounded-lg">
//                 MD
//               </span>
//             </div>
//             <div className="flex justify-center items-center gap-1">
//               <Stars />
//               <span className="font-fontPoppins text-xs font-semibold">
//                 4.6
//               </span>
//               <span className="font-fontPoppins text-gray-400 text-xs">
//                 (125)
//               </span>
//               <span className="font-fontPoppins text-xs font-normal">
//                 <i className="fa-solid fa-heart text-red-500"></i>
//                 Popularity 80%
//               </span>
//             </div>
//           </div>
//         </div>

//         <div className="w-full mt-3 flex justify-center">
//           <div className="bg-gray-300 w-52 flex justify-center items-center gap-3 py-3 rounded-full">
//             <div className="border border-solid border-l-0 border-b-0 border-t-0 border-gray-400 pr-3">
//               <span className="font-fontPoppins text-xs mr-1">$</span>
//               <span className="font-fontPoppins text-lg font-bold mr-1">
//                 24
//               </span>
//               <span className="font-fontPoppins text-xs">00</span>
//             </div>

//             <div>
//               <span className="mr-1">Icon</span>
//               <span className="font-fontPoppins text-lg font-bold mr-1">3</span>
//               <span className="font-fontPoppins text-xs">Mi</span>
//             </div>
//           </div>
//         </div>

//         <div className="w-full flex justify-center gap-1 mt-3">
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//         </div>
//         <div className="w-full flex justify-center gap-1 mt-2">
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg font-fontPoppins text-f10 bg-gray-100">
//             More Slots
//           </button>
//         </div>
//       </div>

//       <div className="w-full p-3 rounded-lg shadow relative mt-4 xss:mt-5">
//         <span className="w-28 font-fontPoppins text-f10 bg-green-500 rounded-full text-center text-white py-1 z-30 absolute left-2/4 -ml-16 -top-3.5">
//           Available Today
//         </span>

//         <div className="flex items-center justify-center gap-2 mt-5">
//           <span>
//             <img
//               src={"./kw.png"}
//               className="rounded-full shadow-lg"
//               width="60px"
//               height="60px"
//               alt="logo"
//             />
//           </span>
//           <div>
//             <h4 className="font-fontPoppins text-lg font-bold">
//               Dr. Rashmi Kaul
//             </h4>
//             <div className="flex items-center gap-2">
//               <i class="fa-solid fa-stethoscope text-f10"></i>
//               <span className="text-xs font-fontPoppins font-semibold">
//                 PSYCHIATRIST
//               </span>
//               <span className="py-1 px-2 text-f9 font-fontPoppins bg-gray-300 rounded-lg">
//                 MD
//               </span>
//             </div>
//             <div className="flex justify-center items-center gap-1">
//               <Stars />
//               <span className="font-fontPoppins text-xs font-semibold">
//                 4.6
//               </span>
//               <span className="font-fontPoppins text-gray-400 text-xs">
//                 (125)
//               </span>
//               <span className="font-fontPoppins text-xs font-normal">
//                 <i className="fa-solid fa-heart text-red-500"></i>
//                 Popularity 80%
//               </span>
//             </div>
//           </div>
//         </div>

//         <div className="w-full mt-3 flex justify-center">
//           <div className="bg-gray-300 w-52 flex justify-center items-center gap-3 py-3 rounded-full">
//             <div className="border border-solid border-l-0 border-b-0 border-t-0 border-gray-400 pr-3">
//               <span className="font-fontPoppins text-xs mr-1">$</span>
//               <span className="font-fontPoppins text-lg font-bold mr-1">
//                 24
//               </span>
//               <span className="font-fontPoppins text-xs">00</span>
//             </div>

//             <div>
//               <span className="mr-1">Icon</span>
//               <span className="font-fontPoppins text-lg font-bold mr-1">3</span>
//               <span className="font-fontPoppins text-xs">Mi</span>
//             </div>
//           </div>
//         </div>

//         <div className="w-full flex justify-center gap-1 mt-3">
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//         </div>
//         <div className="w-full flex justify-center gap-1 mt-2">
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg font-fontPoppins text-f10 bg-gray-100">
//             More Slots
//           </button>
//         </div>
//       </div>

//       <div className="w-full p-3 rounded-lg shadow relative mt-4 xss:mt-5">
//         <span className="w-28 font-fontPoppins text-f10 bg-green-500 rounded-full text-center text-white py-1 z-30 absolute left-2/4 -ml-16 -top-3.5">
//           Available Today
//         </span>

//         <div className="flex items-center justify-center gap-2 mt-5">
//           <span>
//             <img
//               src={"./kw.png"}
//               className="rounded-full shadow-lg"
//               width="60px"
//               height="60px"
//               alt="logo"
//             />
//           </span>
//           <div>
//             <h4 className="font-fontPoppins text-lg font-bold">
//               Dr. Rashmi Kaul
//             </h4>
//             <div className="flex items-center gap-2">
//               <i class="fa-solid fa-stethoscope text-f10"></i>
//               <span className="text-xs font-fontPoppins font-semibold">
//                 PSYCHIATRIST
//               </span>
//               <span className="py-1 px-2 text-f9 font-fontPoppins bg-gray-300 rounded-lg">
//                 MD
//               </span>
//             </div>
//             <div className="flex justify-center items-center gap-1">
//               <Stars />
//               <span className="font-fontPoppins text-xs font-semibold">
//                 4.6
//               </span>
//               <span className="font-fontPoppins text-gray-400 text-xs">
//                 (125)
//               </span>
//               <span className="font-fontPoppins text-xs font-normal">
//                 <i className="fa-solid fa-heart text-red-500"></i>
//                 Popularity 80%
//               </span>
//             </div>
//           </div>
//         </div>

//         <div className="w-full mt-3 flex justify-center">
//           <div className="bg-gray-300 w-52 flex justify-center items-center gap-3 py-3 rounded-full">
//             <div className="border border-solid border-l-0 border-b-0 border-t-0 border-gray-400 pr-3">
//               <span className="font-fontPoppins text-xs mr-1">$</span>
//               <span className="font-fontPoppins text-lg font-bold mr-1">
//                 24
//               </span>
//               <span className="font-fontPoppins text-xs">00</span>
//             </div>

//             <div>
//               <span className="mr-1">Icon</span>
//               <span className="font-fontPoppins text-lg font-bold mr-1">3</span>
//               <span className="font-fontPoppins text-xs">Mi</span>
//             </div>
//           </div>
//         </div>

//         <div className="w-full flex justify-center gap-1 mt-3">
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//         </div>
//         <div className="w-full flex justify-center gap-1 mt-2">
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg border border-solid border-gray-200 hover:border-blueColor font-fontPoppins text-f10">
//             2:30pm
//           </button>
//           <button className="shadow-lg py-1 px-2 rounded-lg font-fontPoppins text-f10 bg-gray-100">
//             More Slots
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// function Stars() {
//   return (
//     <span>
//       <i className="fa-solid fa-star fa-xs text-orange-400"></i>
//       <i className="fa-solid fa-star fa-xs text-orange-400"></i>
//       <i className="fa-solid fa-star fa-xs text-orange-400"></i>
//       <i className="fa-solid fa-star fa-xs text-orange-400"></i>
//       <i className="fa-solid fa-star fa-xs text-orange-400"></i>
//     </span>
//   );
// }

// function Terms() {
//   return (
//     <section className="w-full px-4">
//       <div className="container mx-auto bg-gray-100 rounded-lg py-4 px-6">
//         <h5 className="text-center font-fontPoppins text-base font-semibold">
//           The following terms will apply
//         </h5>

//         <div className="w-full mt-4 md:flex md:items-center md:gap-10">
//           <div className="w-full">
//             <div className="flex items-center  gap-2 mb-2">
//               <i className="fa-solid fa-check fa-sm text-sky-400"></i>
//               <p className="font-fontPoppins text-xs font-medium">
//                 Lorem Ipsum has been the industry's standard dummy text ever
//                 since the 1500s
//               </p>
//             </div>
//             <div className="flex justify-center gap-2 mb-2">
//               <i className="fa-solid fa-check fa-sm text-sky-400"></i>
//               <p className="font-fontPoppins text-xs font-medium">
//                 Lorem Ipsum is simply dummy text of the printing and typesetting
//                 industry. Lorem Ipsum has been the industry's standard dummy
//                 text ever since the 1500s.
//               </p>
//             </div>
//             <div className="flex items-center gap-2 mb-2">
//               <i className="fa-solid fa-check fa-sm text-sky-400"></i>
//               <p className="font-fontPoppins text-xs font-medium">
//                 Lorem Ipsum has been the industry's standard dummy text ever
//                 since the 1500s
//               </p>
//             </div>
//           </div>
//           <div className="w-full">
//             <div className="flex items-center gap-2 mb-2">
//               <i className="fa-solid fa-check fa-sm text-sky-400"></i>
//               <p className="font-fontPoppins text-xs font-medium">
//                 Lorem Ipsum has been the industry's standard dummy text ever
//                 since the 1500s
//               </p>
//             </div>
//             <div className="flex justify-center gap-2 mb-2">
//               <i className="fa-solid fa-check fa-sm text-sky-400"></i>
//               <p className="font-fontPoppins text-xs font-medium">
//                 Lorem Ipsum is simply dummy text of the printing and typesetting
//                 industry. Lorem Ipsum has been the industry's standard dummy
//                 text ever since the 1500s.
//               </p>
//             </div>
//             <div className="flex items-center gap-2 mb-2">
//               <i className="fa-solid fa-check fa-sm text-sky-400"></i>
//               <p className="font-fontPoppins text-xs font-medium">
//                 Lorem Ipsum has been the industry's standard dummy text ever
//                 since the 1500s
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

function WhatPatientsSay() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const payload = {
      modelName: "blogs",
      whereCondition: {
        is_active: 1,
        type: "patient",
      },
    };

    const fetchData = async () => {
      try {
        const response = await await postData("masters/getMasterList", payload);

        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="w-full">
      <div className="container mx-auto py-8 px-4">
        <h6 className="w-full text-center font-fontPoppins xss:text-center text-3xl font-bold xss:text-lg sm:text-lg leading-normal">
          What our <span className="text-blueColor">Patients</span> are saying
        </h6>
        {/* <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {testimonials.map((testimonial, index) => (
            <div className="w-full xss:mt-3 shadow-md bg-gray-100 p-1 rounded-md" key={index}>
              <div className="text-center">
                <i className="fa-solid fa-quote-left fa-4x text-gray-400"></i>
              </div>
             
              <p
                className="font-fontPoppins mb-2 text-sm text-center font-medium"
                dangerouslySetInnerHTML={{ __html: testimonial.description }}
              ></p>
              <img
                src={`${apiUrl}/${testimonial.image}`} 
              
               
                className="rounded-full h-16 w-16 shadow-lg mx-auto"
                alt="Profile Pic"
              />
              <p className="font-fontPoppins text-xs mt-1 font-medium text-center">
                {testimonial.title} 
              </p>
            </div>
          ))}
        </div> */}
        <div
          className={`mt-4 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3`}
        >
          {testimonials.map((testimonial, index) => (
            <div
              className={`w-full xss:mt-3 shadow-md bg-gray-100 p-4 rounded-md ${testimonials.length === 4 && index === 3 ? "md:col-start-2" : ""
                }`}
              key={index}
            >
              <div className="text-center">
                {/* Changed quote icon size */}
                <i className="fa-solid fa-quote-left fa-2x text-gray-400"></i>
              </div>
              <p
                className="font-fontPoppins mb-4 text-sm text-center font-medium text-gray-700"
                // dangerouslySetInnerHTML={{ __html: testimonial.description }}
                dangerouslySetInnerHTML={{
                  __html: testimonial.description.replace(
                    /style="color:\s?rgb\(153,\s?51,\s?255\);?"/g,
                    'style="color: black;"'
                  ),
                }}
              ></p>
              <img
                // Increased image size and added object-cover
                src={`${imageBaseUrl}/${testimonial.image}`}
                className="rounded-full h-24 w-24 shadow-lg mx-auto mb-2 object-cover"
                alt="Profile Pic"
              />
              <p className="font-fontPoppins text-xs mt-1 font-medium text-center text-gray-700">
                {testimonial.title}{" "}
                {/* Adjust based on actual API response structure */}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default PatientsLandingPage;
